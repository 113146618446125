<ng-progress id="dashboardProgress"></ng-progress>
<div class="container d-flex align-items-center vh-100" style="flex-direction:column">
  <div class="login-logo-banner d-flex justify-content-center">
    <img src="assets/png/logotipo_h_black_124.png">
  </div>
  <div class="login-box row justify-content-center">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="text-dark mb-3">Entrar</h4>
          <form ngNativeValidate [formGroup]="UserLoginModel" autocomplete="off" (submit)="onSubmitLogin()">
            <div class="row">
              <div class="form-group col-md-12 mb-4">
                <label for="txtUserName">Digite seu login</label>
                <input class="form-control input-lg"
                  [class.is-invalid]="UserLoginModel.get('UserName').touched && UserLoginModel.get('UserName').errors?.required"
                  id="txtUserName" placeholder="Login" formControlName="UserName" (ngModelChange)="onChangeFields()">
                <span class="invalid-feedback"
                  *ngIf="UserLoginModel.get('UserName').touched && UserLoginModel.get('UserName').errors?.required">É
                  necessário informar um login</span>
              </div>
              <div class="form-group col-md-12 mb-4">
                <label for="txtPassword">Digite sua senha</label>
                <input type="password" class="form-control input-lg"
                  [class.is-invalid]="UserLoginModel.get('Password').touched && UserLoginModel.get('Password').errors?.required"
                  id="txtPassword" placeholder="Senha" formControlName="Password" (ngModelChange)="onChangeFields()">
                <span class="invalid-feedback"
                  *ngIf="UserLoginModel.get('Password').touched && UserLoginModel.get('Password').errors?.required">É
                  necessário informar uma senha</span>
                <div>
                  <div class="d-flex my-2 justify-content-between">
                    <p><a class="text-blue" [routerLink]="['/reset-password']">Esqueceu sua senha?</a></p>
                  </div>
                </div>
                <div>
                  <div class="d-flex my-2 justify-content-between message-danger" *ngIf="hasToShowError">
                    <p class="text-red">{{ErrorMessage}}</p>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-12">
                <ngx-recaptcha2 #captchaElem
                                [siteKey]="'6LeyMq8aAAAAAN56Ng9gEJ8i_SIo_stfs-Dq1WL2'"
                                
                                
                                (error)="handleError()"
                                
                                (success)="handleSuccess($event)"
                                [useGlobalDomain]="false"
                                [size]="'normal'"
                                [hl]="'pt'"
                                [theme]="'light'"
                                [type]="'image'"
                                
                                formControlName="ReCaptcha">
                </ngx-recaptcha2>
              </div>
              <div class="col-md-12">
                <button type="submit" class="btn btn-lg btn-primary btn-block" id="loginButton"><span class="button__text">Entrar</span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
