import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';

/**
 * AuthGuard - Implementa as restrições de Login do Sistema
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  /**
   * Construtor do AuthGuard
   *
   * @param router Serviço de rotas do Angular
   * @param userService Serviço do Usuário
   */
  constructor(private router: Router, private userService: UserService) { }

  /**
   * Código que avalia se o usuário pode ou não acessar determinada página
   *
   * @param next Próxima rota (URL)
   * @param state Rota Atual (URL)
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //Se não existe o Token
    if (localStorage.getItem('UniqMobile_Message_Token') == null) {
      this.router.navigate(['/login']);
      return false;
    }

    //Verifica se está logado
    return this.userService.IsLoggedIn();
  }
}
