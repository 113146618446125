import { AuditoriaService } from './../auditoria.service';
import { Component, OnInit } from '@angular/core';
import { ListagemAtividadesUsuarios } from '../models_auditoria/ListagemAtividadesUsuarios.model';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-atividades-users',
  templateUrl: './atividades-users.component.html',
  styleUrls: ['./atividades-users.component.scss']
})
export class AtividadesUsersComponent implements OnInit {

  constructor(private auditoriaService: AuditoriaService, private clientService: UserService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;
  userInformation: any;

  PeriodoDe: any;
  PeriodoAte: any;

  TotalAtividade: any;
  listAtividadesUsuarios: any;

  ngOnInit() {
    this.listagemAtividadeUsuarios();
    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.nome.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listAtividadesUsuarios = this.paginate(this.TotalAtividade.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemAtividadeUsuarios(page: number = 1, type?) {
    this.load = true;

    this.page = page;


    this.auditoriaService.carregaListagemAtivdadesUsuarios(this.page, this.searchQuery, type, 10).subscribe(
      (res: ListagemAtividadesUsuarios) => {
        this.TotalAtividade = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalAtividade.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();
      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }

  exportActivityHistoric(startDate?, endDate?) {
    if (startDate != undefined && endDate != undefined) {
      this.auditoriaService.exportActivityExcel(this.TotalAtividade.lista.filter(x => Date.parse(x.criado).toLocaleString() >= startDate && x.criado <= endDate)).subscribe((res: any) => {
        const blob = new Blob([res]);
        let currentDate = new Date(Date.now());
        let currentDateFormat = currentDate.toLocaleDateString();
        currentDateFormat = currentDateFormat.replace("/", "");
        currentDateFormat = currentDateFormat.replace("/", "");

        saveAs(blob, this.userInformation.clientName + "_Historico_Atividades" + ".xlsx");
      });
    } else {
      this.auditoriaService.exportActivityExcel(this.TotalAtividade.lista).subscribe((res: any) => {
        const blob = new Blob([res]);
        let currentDate = new Date(Date.now());
        let currentDateFormat = currentDate.toLocaleDateString();
        currentDateFormat = currentDateFormat.replace("/", "");
        currentDateFormat = currentDateFormat.replace("/", "");

        saveAs(blob, this.userInformation.clientName + "_Historico_Atividades" + ".xlsx");
      });
    }
  }

}
