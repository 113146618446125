<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-body pt-0 pb-5" style="margin-bottom: 30px">
        <form ngNativeValidate [formGroup]="userService.UserRegistrationModel" autocomplete="off"
          (submit)="OnSubmitRegister()">
          <div id="accordionClienteRegister" class="accordion accordion-shadow">
            <div class="card mt-4">
              <div class="card-header" id="headingFour">
                <button class="btn btn-link" data-toggle="collapse" data-target="#geralForm" aria-expanded="true"
                  aria-controls="geralForm" type="button">
                  Dados gerais
                </button>
              </div>

              <div id="geralForm" class="collapse show" aria-labelledby="headingFour"
                data-parent="#accordionClienteRegister" style="margin-top: 1rem">
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-3">
                      <label for="exampleFormControlSelect3">Tipo de pessoa: *
                      </label>
                      <select class="form-control" id="exampleFormControlSelect3" formControlName="TipoCliente">
                        <option value="1">Fisica</option>
                        <option value="2">Jurídica</option>
                      </select>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('TipoCliente')
                            .touched &&
                          userService.UserRegistrationModel.get('TipoCliente')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4" *ngIf="
                        userService.UserRegistrationModel.get('TipoCliente')
                          .value === '1'
                      ">
                      <label>CPF: * </label>
                      <input formControlName="Documento" placeholder="Seu Cpf" class="form-control" type="text" [mask]="
                          documentMask[
                            userService.UserRegistrationModel.get('TipoCliente')
                              .value
                          ]
                        " />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Documento')
                            .touched &&
                          userService.UserRegistrationModel.get('Documento')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4" *ngIf="
                        userService.UserRegistrationModel.get('TipoCliente')
                          .value === '2'
                      ">
                      <label>CNPJ: * </label>
                      <input formControlName="Documento" placeholder="Seu Cnpj" class="form-control" type="text" [mask]="
                          documentMask[
                            userService.UserRegistrationModel.get('TipoCliente')
                              .value
                          ]
                        " />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Documento')
                            .touched &&
                          userService.UserRegistrationModel.get('Documento')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-5" *ngIf="
                        userService.UserRegistrationModel.get('TipoCliente')
                          .value
                      ">
                      <label for="txtFullName" *ngIf="
                          userService.UserRegistrationModel.get('TipoCliente')
                            .value === '2'
                        ">Nome fantasia: *
                      </label>

                      <label *ngIf="
                          userService.UserRegistrationModel.get('TipoCliente')
                            .value === '1'
                        " for="txtFullName">Nome *</label>

                      <input class="form-control" id="txtFullName" placeholder="Enter FullName"
                        formControlName="FullName" [(ngModel)]="ResponsibleName" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('FullName')
                            .touched &&
                          userService.UserRegistrationModel.get('FullName')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>
                  </div>

                  <div class="form-row">
                    <div *ngIf="
                        userService.UserRegistrationModel.get('TipoCliente')
                          .value === '2'
                      " class="form-group col-sm-12 col-md-6">
                      <label for="txtFullName" *ngIf="
                          userService.UserRegistrationModel.get('TipoCliente')
                            .value === '2'
                        ">Razão social: *
                      </label>
                      <input class="form-control" id="txtFullName" placeholder="Enter razao"
                        formControlName="RazaoSocial" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('RazaoSocial')
                            .touched &&
                          userService.UserRegistrationModel.get('RazaoSocial')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-6">
                      <label for="txtFullName">Site: </label>
                      <input class="form-control" id="txtFullName" placeholder="Enter site" formControlName="Site" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Site')
                            .touched &&
                          userService.UserRegistrationModel.get('Site')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-4">
                      <label for="txtEmail">Endereço de Email: *</label>
                      <input type="email" class="form-control" id="txtEmail" placeholder="Enter Email"
                        formControlName="Email" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Email')
                            .touched &&
                          userService.UserRegistrationModel.get('Email').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Email')
                            .touched &&
                          userService.UserRegistrationModel.get('Email').errors
                            ?.email
                        ">Este email é inválido</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label for="txtNumber">Telefone: * </label>
                      <input class="form-control" id="txtNumber" placeholder="Enter Number" formControlName="Number"
                        mask="(00) 00000-0000" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Number')
                            .touched &&
                          userService.UserRegistrationModel.get('Number').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label>Nome responsável * </label>

                      <input class="form-control" placeholder="Enter ResponsibleName"
                        formControlName="ResponsibleNameGeral" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get(
                            'ResponsibleNameGeral'
                          ).touched &&
                          userService.UserRegistrationModel.get(
                            'ResponsibleNameGeral'
                          ).errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <button type="button" class="btn btn-link collapsed" data-toggle="collapse"
                  data-target="#outrosContatosForm" aria-controls="outrosContatosForm">
                  Outros contatos
                </button>
              </div>
              <div id="outrosContatosForm" class="collapse" aria-labelledby="headingFive"
                data-parent="#accordionClienteRegister">
                <div class="card-body">
                  <br />
                  <div formArrayName="OtherContats">
                    <div *ngFor="
                        let contact of userService.UserRegistrationModel
                          .get('OtherContats')['controls'];
                        let i = index
                      ">
                      <div [formGroup]="
                          userService.UserRegistrationModel
                          .get('OtherContats')['controls'][i]
                        ">
                        <div class="form-row">
                          <div class="form-group col-sm-12 col-md-3">
                            <label>Pessoa de contato: </label>
                            <input class="form-control" placeholder="Digite Pessoa de contato"
                              formControlName="FullName" />
                          </div>
                          <div class="form-group col-sm-12 col-md-2">
                            <label>Email: </label>
                            <input class="form-control" placeholder="Enter emai" formControlName="Email" />
                          </div>

                          <div class="form-group col-sm-12 col-md-2">
                            <label>Telefone comercial: </label>
                            <input class="form-control" placeholder="Enter phone" formControlName="PhoneOne"
                              mask="(00) 00000-0000" />
                          </div>

                          <div class="form-group col-sm-12 col-md-2">
                            <label>Celular: </label>

                            <input class="form-control" class="form-control" placeholder="Enter phone 2"
                              formControlName="PhoneTwo" mask="(00) 00000-0000" />
                          </div>

                          <div class="form-group col-sm-12 col-md-2">
                            <label>Cargo: </label>

                            <input class="form-control" class="form-control" placeholder="Enter cargo"
                              formControlName="Cargo" />
                          </div>

                          <div class="form-group col-sm-12 col-md-1">
                            <br />
                            <button type="button" style="margin-top: 7px" class="btn btn-block btn-primary"
                              (click)="removeContact(i)">
                              <span class="mdi mdi-trash-can"></span>
                            </button>
                          </div>

                          <!-- <small
                        [hidden]="
                        userService.UserRegistrationModel
                        .controls.OtherContats.controls[i]
                            .controls.nome.valid
                        "
                      >
                        Telefone primario obrigatario
                      </small> -->
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-3">
                        <button type="button" class="btn btn-primary" (click)="addContact()">
                          Adicionar Contato
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#enderecoForm"
                  aria-expanded="false" aria-controls="enderecoForm">
                  Endereço
                </button>
              </div>
              <div id="enderecoForm" class="collapse" aria-labelledby="headingFive"
                data-parent="#accordionClienteRegister">
                <div class="card-body">
                  <br />

                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-2">
                      <label>País</label>
                      <select style="cursor: not-allowed;" class="form-control" disabled>
                        <option selected>Brasil</option>
                      </select>
                    </div>
                    <div class="form-group col-sm-12 col-md-4">
                      <label>Cep: *</label>
                      <input type="txt" class="form-control" id="txtEmail" placeholder="Enter cep" formControlName="Cep"
                        mask="00.000-000" (change)="buscaCep($event.target.value)" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Cep')
                            .touched &&
                          userService.UserRegistrationModel.get('Cep').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-6">
                      <label>Endereco: *</label>
                      <input type="txt" class="form-control" placeholder="Enter endereco" formControlName="Endereco" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Endereco')
                            .touched &&
                          userService.UserRegistrationModel.get('Endereco')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                  </div>

                  <div class="form-row" *ngIf="Estados.estados.length > 0">

                    <div class="form-group col-sm-12 col-md-2">
                      <label>Numero: *</label>
                      <input type="text" class="form-control" placeholder="Enter numero" formControlName="Numero" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Numero')
                            .touched &&
                          userService.UserRegistrationModel.get('Numero').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-5">
                      <label for="exampleFormControlSelect3">Estado: * </label>
                      <select class="form-control" id="exampleFormControlSelect3" formControlName="Estado"
                        (change)="selecionaCidade($event.target.value, 'sigla')" *ngIf="Estados.estados">
                        <option *ngFor="let estado of Estados.estados" [value]="estado.sigla">
                          {{ estado.nome }}
                        </option>
                      </select>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Estado')
                            .touched &&
                          userService.UserRegistrationModel.get('Estado').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-5">
                      <label for="exampleFormControlSelect3">Cidade: * </label>
                      <select class="form-control" id="exampleFormControlSelect3" formControlName="Cidade">
                        <option *ngFor="let cidade of Cidades" [value]="cidade">
                          {{ cidade }}
                        </option>
                      </select>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Cidade')
                            .touched &&
                          userService.UserRegistrationModel.get('Cidade').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                  </div>

                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-6">
                      <label for="txtFullName">Bairro: * </label>
                      <input class="form-control" id="txtUserName" placeholder="Enter Bairro"
                        formControlName="Bairro" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Bairro')
                            .touched &&
                          userService.UserRegistrationModel.get('Bairro').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-6">
                      <label for="txtFullName">Complemento: </label>
                      <input class="form-control" id="txtUserName2" placeholder="Enter Bairro"
                        formControlName="Complemento" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Complemento')
                            .touched &&
                          userService.UserRegistrationModel.get('Complemento')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFive">
                <button type="button" class="btn btn-link collapsed" data-toggle="collapse"
                  data-target="#infoUsuarioForm" aria-expanded="true" aria-controls="infoUsuarioForm">
                  Informações de usuário
                </button>
              </div>
              <div id="infoUsuarioForm" class="collapse show" aria-labelledby="headingFive"
                data-parent="#accordionClienteRegister">
                <div class="card-body">
                  <br />
                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-4">
                      <label>Cliente: </label>

                      <h3>
                        <input class="form-control" [value]="ResponsibleName" disabled />
                      </h3>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label>Nome:* </label>

                      <h3>
                        <input class="form-control" formControlName="NomeFullUsuario" />
                      </h3>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label for="exampleFormControlSelect3">Tipo de conta: *
                      </label>
                      <select class="form-control" id="exampleFormControlSelect3" formControlName="Role">
                        <option value="Owner">Owner</option>
                        <option value="Administrador">Administrador</option>
                        <option selected value="Comum">Comum</option>
                      </select>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Role')
                            .touched &&
                          userService.UserRegistrationModel.get('Role').errors
                            ?.required
                        ">Este campo é obrigatório!</span>
                    </div>


                  </div>

                  <div class="form-row">


                    <div class="form-group col-sm-12 col-md-4">
                      <label>Nome responsavel:* </label>

                      <h3>
                        <input class="form-control" formControlName="ResponsibleName" />
                      </h3>
                      <span class="mt-2 d-block text-danger" *ngIf="
                        userService.UserRegistrationModel.get('NomeFullUsuario')
                          .touched &&
                        userService.UserRegistrationModel.get('NomeFullUsuario')
                          .errors?.required
                      ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label>Telefone do responsavel:* </label>

                      <h3>
                        <input class="form-control" formControlName="ResponsibleNameContato" mask="(00) 00000-0000" />
                      </h3>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label for="txtFullName">Nome de Usuário: * </label>
                      <input class="form-control" id="txtUserName3" placeholder="Enter Username"
                        formControlName="UserName" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('UserName')
                            .touched &&
                          userService.UserRegistrationModel.get('UserName')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                  </div>

                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-4">
                      <label>E-mail:* </label>

                      <h3>
                        <input class="form-control" formControlName="EmailUsuario" />
                      </h3>
                      <span class="mt-2 d-block text-danger" *ngIf="
                        userService.UserRegistrationModel.get('EmailUsuario')
                          .touched &&
                        userService.UserRegistrationModel.get('EmailUsuario')
                          .errors?.required
                      ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label for="txtPassword">Senha: *</label>
                      <!-- <input
                        type="password"
                        class="form-control"
                        id="txtPassword"
                        placeholder="Enter Password"
                        formControlName="Password"
                      /> -->

                      <div class="input-group">
                        <input type="{{TypeOfPasswordInput}}" class="form-control" id="txtPassword"
                          placeholder="Enter Password" formControlName="Password" />
                        <div class="input-group-append">
                          <button class="btn btn-primary" type="button" (click)=TogglePasswordDisplay()><i
                              class="mdi mdi-eye"
                              [ngClass]="{ 'mdi-eye': TypeOfPasswordInput === 'password', 'mdi-eye-off': TypeOfPasswordInput === 'text' }"></i></button>
                          <button class="btn btn-primary" type="button" (click)=GeneratePassword()><i
                              class="mdi mdi-auto-fix"></i></button>
                        </div>
                      </div>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Password')
                            .touched &&
                          userService.UserRegistrationModel.get('Password')
                            .errors?.required
                        ">Este campo é obrigatório!</span>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get('Password')
                            .touched &&
                          userService.UserRegistrationModel.get('Password')
                            .errors?.minlength
                        ">A senha precisa de pelo menos 8 caracteres</span>

                    </div>
                    <div class="form-group col-sm-12 col-md-4">
                      <label for="txtConfirmPassword">Repita a Senha: * </label>
                      <input type="password" class="form-control" id="txtConfirmPassword" placeholder="Enter Pumber"
                        formControlName="ConfirmPassword" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get(
                            'ConfirmPassword'
                          ).touched &&
                          userService.UserRegistrationModel.get(
                            'ConfirmPassword'
                          ).errors?.required
                        ">Este campo é obrigatório!</span><span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get(
                            'ConfirmPassword'
                          ).touched &&
                          userService.UserRegistrationModel.get(
                            'ConfirmPassword'
                          ).errors?.passwordMismatch
                        ">As senhas não são iguais!</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="card" *ngIf="CheckIfRole(['Owner'])">
              <div class="card-header" id="headingFive">
                <button type="button" class="btn btn-link collapsed" data-toggle="collapse"
                  data-target="#fornecedorForm" aria-expanded="false" aria-controls="fornecedorForm">
                  Fornecedor
                </button>
              </div>
              <div id="fornecedorForm" class="collapse" aria-labelledby="headingFive"
                data-parent="#accordionClienteRegister">
                <div class="card-body">
                  <br />
                  <div class="form-row">
                    <div class="form-group col-sm-12 col-md-4">
                      <label for="exampleFormControlSelect3">Selecione fornecedor preferêncial: *
                      </label>
                      <select class="form-control" id="exampleFormControlSelect3"
                        formControlName="IdFornecedorPreferencial">
                        <option value="0" selected>Selecione...</option>
                        <option *ngFor="let prov of providers" [value]="prov.id"> {{ prov.label }} </option>
                      </select>
                      <span class="mt-2 d-block text-danger" *ngIf="
                          userService.UserRegistrationModel.get(
                            'IdFornecedorPreferencial'
                          ).touched &&
                          userService.UserRegistrationModel.get(
                            'IdFornecedorPreferencial'
                          ).errors?.required
                        ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label>Quantidade: * </label>
                      <input placeholder="Sua quantidade" class="form-control" formControlName="Quantidade"
                        type="text" />

                    </div>

                    <div class="form-group col-sm-12 col-md-4">
                      <label>Validade: * </label>

                      <input placeholder="Sua quantidade" class="form-control" (input)="printValidade()"
                        formControlName="Validade" type="date" />
                      <!-- To-do corrigir posição do calendario -->
                      <!-- <div
                        class="input-group date"
                        id="datetimepicker1"
                        data-target-input="nearest"
                      >
                        <input
                          type="text"
                          class="form-control datetimepicker-input"
                          formControlName="Validade"
                          data-target="#datetimepicker3"
                        />
                        <div
                          class="input-group-append"
                          data-target="#datetimepicker1"
                          data-toggle="datetimepicker"
                        >
                          <div class="input-group-text">
                            <i class="mdi mdi-calendar"></i>
                          </div>
                        </div>
                      </div> -->
                    </div>

                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label>Descrição:</label>
                      <textarea class="form-control" formControlName="DescricaoSaldo" rows="3"></textarea>
                    </div>
                    <div class="form-group col-md-4">
                      <!-- <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                       To-do:Cortando o calendario -->


                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div col-12 col-xs-6 col-md-4 col-lg-3>
                <input type="submit" value="Cadastrar" class="btn btn-block btn-primary"
                  [disabled]="!userService.UserRegistrationModel.valid" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>