import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { MessageService } from '../menssage.service';
import { Mensagem } from '../model_message/Mensagem_model';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-send-file-sms',
  templateUrl: './send-file-sms.component.html',
  styleUrls: ['./send-file-sms.component.scss']
})
export class SendFileSmsComponent implements OnInit {


  constructor(private fb: FormBuilder, private toastr: ToastrService, public router: Router, private msgService: MessageService, public clientService: UserService, public datepipe: DatePipe) { }

  Content = '';
  totalContatos = 0;
  isFlash = false;
  Sheduled = '';
  SheduledDate = '';
  SheduledTime = '';
  isSheduledMsg = false;
  from = '';
  limitMessage = 0;
  canFlash = false;
  charCount = 0;
  selectedFile: File = null;
  selectedFileData: any[] = [];
  parametros: any[] = [];
  parametrosView: any[] = [];
  listaModelos: any[] = [];
  linksModelo = `${document.getElementsByTagName('base')[0].href}templatesUploads/UNIQ MESSAGE - MODELO EXCEL - SMS ARQUIVO.xlsx`;

  listaParamentros = ['Campo Customizado', 'Primeiro Nome'];
  paramDict: any;
  dictOfParams: any = {};


  ngOnInit() {
    this.clientService.GetUserInformation().subscribe((userInfo: any) => {
      this.limitMessage = userInfo.limitMessage;
      this.canFlash = userInfo.canSMSFlash;
    });

    this.msgService.carregaListaModelos(1, '').subscribe(
      (res: any) => {
        this.listaModelos = res.lista;
        //this.load = false;
      },
      (erros) => {
        console.log(erros);
        //this.load = false;
      }
    );

  }


  validaCampos(): boolean {
    if (this.from === null || this.Content === null) {
      this.toastr.error('Favor preencha os campos', 'Mensagem');
      return true;
    }
    return false;
  }
  SelectModelo(id) {
    if (id == "none" || id == undefined) {
      this.Content = '';

    } else {
      this.Content = this.listaModelos.find(x => x.id == id).content;

    }
    this.CountSMSChar();
  }

  CheckScheduledMessage() {


    this.SheduledDate = this.Sheduled;
    this.SheduledDate = this.datepipe.transform(this.SheduledDate, 'dd/MM/yyyy');
    if ((this.Sheduled !== undefined && this.Sheduled !== null && this.Sheduled != '') && (this.SheduledTime !== undefined && this.SheduledTime !== null && this.Sheduled != '')) {
      //gambiarra braba. mudar dps
      let hour = this.SheduledTime[0] + this.SheduledTime[1];
      let min = this.SheduledTime[2] + this.SheduledTime[3];
      this.SheduledTime = hour + ":" + min;

      this.isSheduledMsg = true;
    } else {
      this.isSheduledMsg = false;
    }
  }

  SelectParameter(value) {

    this.Content += value;
    //if (value == 'Primeiro Nome') {
    //  this.Content = '[PRIMEIRONOME]';
    //} else {
    //  this.Content = '';
    //}
    this.contadorMensagem();
  }


  CountSMSChar() {
    let count = this.from.length;
    if (count > 0) {
      count += 2;
    }
    let conteudo = this.Content;

    this.contadorMensagem();

    if (this.charCount > this.limitMessage - 1) {
      this.Content = this.Content.substring(0, this.limitMessage - 1);

      $('#avisoMessageLimit').modal('show');
      $('#contentMessage').val(this.Content);
    }

  }

  contadorMensagem() {

    let count = 0;

    if (!this.from || !this.from.trim()) {
    } else {
      count += this.from.length + 2;
    }

    let src = this.Content;

    let params = src.match(/\[(.*?)\]/gi);

    src = src.replace(/\[(.*?)\]/gi, "");

    count += src.length;

    //se o texto possuir algum parâmetro
    if (params) {
      for (let i = 0; i < this.parametros.length; i++) {

        let aux = this.dictOfParams[this.parametros[i]]

        //soma ao count o valor de max se tem um campo '[PARAMETRO X]'no content
        let param = "[Parâmetro " + (i + 1) + "]";
        if (params.includes(param)) {
          count += aux.max;
        }

        //soma ao count o valor de pp se tem um campo '[PARAMETRO X_PP]'no content
        let paramPP = "[Parâmetro " + (i + 1) + "_PP]";
        if (params.includes(paramPP)) {
          count += aux.pp;
        }

      }
    }
    this.charCount = count;

  }



  GetHTMLFromContent() {
    let str = '';

    if (!this.from || !this.from.trim()) {
      //str is null, undefined, or contains only spaces
      str = this.Content;
      if (str == "") {
        return;
      }
    } else {
      str = this.from + ': ' + this.Content;
    }

    if (this.selectedFileData.length > 0) {

      for (let i = 0; i < this.parametros.length; i++) {

        //troca os parametros por FirstWord
        let param = "[Parâmetro " + (i + 1) + "]";
        str = str.replace(param, this.selectedFileData[0][this.parametros[i]]);

        //troca os parametros q sao PP por ppString
        let paramPP = "[Parâmetro " + (i + 1) + "_PP]";

        let aux = this.dictOfParams[this.parametros[i]]

        str = str.replace(paramPP, aux.ppString);
      }

    }

    str.replace(/(\r\n|\n|\r)/gm, '<br/>');
    return str;
  }



  RedirectToDashboard() {
    this.router.navigateByUrl('/');
  }


  onSelectFile(fileInput: any) {
    this.selectedFile = <File>fileInput.target.files[0];

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const arrayBuffer: any = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {
        type: 'binary', raw: true
      });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];

      this.selectedFileData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.parametros = Object.keys(this.selectedFileData[0]);

      this.parametrosView = Object.keys(this.selectedFileData[0]);

      let telefoneIndex = this.parametrosView.findIndex(x => x.trim().toLowerCase() == "DDI+DDD+Celular".toLowerCase());

      if (telefoneIndex <= -1) {
        $('#ModalFalhaPadrao').modal('show');
        return;
      }


      this.parametrosView[this.parametrosView.findIndex(x => x.trim().toLowerCase() == "DDI+DDD+Celular".toLowerCase())] = "Telefone"

      this.selectedFileData.forEach(x => {
        if (!x["DDI+DDD+Celular"].toString().startsWith('55')) {
          x["DDI+DDD+Celular"] = "55" + x["DDI+DDD+Celular"]
        }
      })

      //this.selectedFileData = Array.from(this.selectedFileData.reduce((m, t) => m.set(t["DDI+DDD+Celular"], t), new Map()).values());
      this.selectedFileData = this.selectedFileData.filter((v, i, a) => a.findIndex(t => (t["DDI+DDD+Celular"] === v["DDI+DDD+Celular"])) === i)
      this.totalContatos = this.selectedFileData.length;
      console.log(this.parametrosView)



      let indexNumero = this.parametros.indexOf("DDI+DDD+Celular");

      if (indexNumero < 0) {
        return;
      }
      let paramNumero = this.parametros.splice(indexNumero, 1);
      this.parametros.unshift(paramNumero[0]);
      this.GetHTMLFromContent();

      this.dictOfParams = this.getParamSize();
      this.paramDict = this.getFields(this.dictOfParams);


    };
    fileReader.readAsArrayBuffer(this.selectedFile);
  }

  getFields(dict) {
    let dictAsArray = [];

    for (let i = 0; i < this.parametros.length; i++) {
      let key = this.parametros[i];
      if (key == "DDI+DDD+Celular") {
        dictAsArray.push({ text: "Parâmetro 1(" + (dict[key].max) + ")", value: "[Parâmetro 1]", firstWord: dict[key].firstWord });
        continue;
      }
      dictAsArray.push({ text: "Parâmetro " + (i + 1) + " (" + dict[key].max + ")", value: "[Parâmetro " + (i + 1) + "]", firstWord: dict[key].firstWord, ppString: dict[key].ppString });
      dictAsArray.push({ text: "Parâmetro " + (i + 1) + "_PP (" + dict[key].pp + ")", value: "[Parâmetro " + (i + 1) + "_PP]", firstWord: dict[key].firstWord, ppString: dict[key].ppString });

    }

    return dictAsArray
  }

  getParamSize() {

    let dict = {};

    for (let i = 0; i < this.parametros.length; i++) {

      let key = this.parametros[i];
      let max = 0;
      let pp = 0;
      let ppString = "";
      let maxString = "";
      let firstWordAux = "";

      for (let j = 0; j < this.selectedFileData.length; j++) {

        let firstWord = String(this.selectedFileData[j][key]);
        let firstWordLength = firstWord.length;


        if (max < firstWordLength) {
          max = firstWordLength;
          maxString = firstWord;
        }
        let firstWordSplit = firstWord.split(" ")[0];
        if (pp < firstWordSplit.length) {
          pp = firstWordSplit.length;
          ppString = firstWordSplit;
        }


        if (max > 20) {
          max = 20;
        }
        if (pp > 20) {
          pp = 20;
        }

      }

      dict[this.parametros[i]] = {
        pp: pp,
        ppString: ppString,
        max: max,
        firstWord: this.selectedFileData[0][key],
        maxString: maxString,
      }
    }
    return dict;
  }






  onSubmit() {

    if (this.selectedFile === null) {
      this.toastr.error('Informe o anexo', 'Mensagem');

      return;
    }
    let src = this.Content;

    let scheduled = null;

    if ((this.Sheduled !== undefined && this.Sheduled !== null && this.Sheduled != '') && (this.SheduledTime !== undefined && this.SheduledTime !== null && this.Sheduled != '')) {
      scheduled = this.Sheduled + ' ' + this.SheduledTime;
    } else {
      scheduled = '';
    }




    //let modelSend = {
    //  Arquivo: this.selectedFile,
    //  IsFlash: String(this.isFlash)),
    //  Sheduled: scheduled,
    //  From: this.from,
    //  Content: src
    //}


    //console.log("formData: ", formData);
    //console.log("formData: ", formData.get('Content'));



    //substituindo parametro por nome na tabela
    for (let i = 0; i < this.parametros.length; i++) {
      let keys = Object.keys(this.dictOfParams);

      //troca os parametros por FirstWord
      let param = "Parâmetro " + (i + 1);
      src = src.replace(param, keys[i].trim());

      let paramPP = "Parâmetro " + (i + 1) + "_PP";
      src = src.replace(paramPP, keys[i] + "_PP");

    }

    const formData = new FormData();
    formData.append('Content', src);
    formData.append('Arquivo', this.selectedFile);
    formData.append('IsFlash', String(this.isFlash));
    formData.append('Sheduled', scheduled);
    formData.append('From', this.from);


    this.msgService.SendMessagemForFile(formData).subscribe(
      (body: any) => {
        //console.log(body);

        //this.toastr.success('Enviado com sucesso!', 'Mensagem', { timeOut: 2000, progressBar: true });
        document.getElementById('botaoConfirmacaoEnvio').click();
        this.Content = '';
        this.isFlash = false;
        this.Sheduled = '';
        this.from = '';
        this.limitMessage = this.limitMessage;
        this.charCount = 0;
        this.selectedFile = null;
      },
      (erro: any) => {
        console.log('ERRO =>', erro);
        if (typeof erro.error === 'string') {

          this.toastr.error(erro.error, 'Mensagem');
        } else {

          this.toastr.error('Ocorreu um erro ao importar', 'Mensagem');
        }

      }

    );




  }

}
