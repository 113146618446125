import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListaHistoricoLogin } from './models_configuracao/ListaHistoricoLogin.model';
import { ListaHistoricoAtividades } from './models_configuracao/ListaHistoricoAtividades.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;

  alteraSenha(body: any) {
    return this.http.post(`${this.baseUrl}configurations/alteraraosenha`, body);
  }

  carregaDadosMeuPerfil() {
    return this.http.get(`${this.baseUrl}configurations/getbyperfil`);
  }

  carregarRelatorioLogin(page: number = 1, query: string = '', type?, itemsPerPage: number = 10): Observable<ListaHistoricoLogin[]> {
    return this.http.get<ListaHistoricoLogin[]>(`${this.baseUrl}configurations/getreportlogin?page=${page}&query=${query}&itemsPerPage=${itemsPerPage}`);
  }

  carregarHistoricoAtividades(page: number = 1, query: string = '', type?, itemsPerPage: number = 10): Observable<ListaHistoricoAtividades[]> {
    return this.http.get<ListaHistoricoAtividades[]>(`${this.baseUrl}configurations/getactivityhistory?page=${page}&query=${query}&itemsPerPage=${itemsPerPage}`);
  }

  buscarDadosTelefone(numero: string) {
    const n = `55${numero}`;
    return this.http.get(`${this.baseUrl}configurations/searchphone?number=${n}`);
  }

  buscaDadosDetalhesPhone(smsId: number) {
    return this.http.get(`${this.baseUrl}configurations/detalhardadossmsphone/${smsId}`);

  }
  getDeveloperInfo() {
    return this.http.get(`${this.baseUrl}configurations/getDeveloperInfo`);

  }
  changeSMSNotification() {
    return this.http.get(`${this.baseUrl}configurations/ChangeSMSNotification`);

  }
  changeEmailNotification() {
    return this.http.get(`${this.baseUrl}configurations/ChangeEmailNotification`);

  }

}
