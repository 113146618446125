import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/user.service';

/**
 * Componente de Redefinição de Senha do Sistema
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  /**
   * Enumeração da Janela Ativa do Sistema
   *
   * @type {String}
   */
  PageName = 'ResetPassword';

  /**
   * Modelo do Formulário de Redefinição de Senha
   *
   * @type {FormBuilder}
   */
  UserResetModel = null;

  /**
   * Modelo do Formulário de Redefinição de Senha com Token Válido
   *
   * @type {FormBuilder}
   */
  UserResetPasswordModel = null;

  /**
   * Botão de Resetar Senhar
   */
  resetPasswordButton = null;

  /**
    * Deve mostrar o erro
    *
    * @type boolean
    */
  public hasToShowError = false;

  /**
   * Texto do Erro
   *
   * @type string
   */
  public ErrorMessage = '';

  /**
   * Promessa para receber a rota
   */
  private sub: any;

  /**
   * Token para resetar uma senha
   */
  private resetToken: string;

  /**
   * Constrututor da Classe ResetPasswordComponent
   *
   * @param userService Serviço do Usuário
   * @param route Serviço de Rotas Ativas
   */
  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.UserResetModel = userService.UserResetModel;
    this.UserResetPasswordModel = userService.UserResetPasswordModel;
  }

  /**
   * Ao alterar os campos
   */
  onChangeFields() {
    this.hasToShowError = false;
    this.ErrorMessage = '';
  }

  /**
   * Ao clicar em reenviar
   */
  onClickResend() {
    this.PageName = 'ResetPassword';
  }

  /**
   * Quando o Usuário clicar em Logar, realiza o processo de Login
   */
  onSubmitReset() {
    this.resetPasswordButton.classList.add('button--loading');
    this.userService.ResetPassword().subscribe(
      (res: any) => {
        if (res.erros != null && res.erros.length > 0) {
          this.resetPasswordButton.classList.remove('button--loading');
          this.hasToShowError = true;
          this.ErrorMessage = res.erros[0];
        } else {
          this.PageName = 'Success';
        }
      },
      err => {
        this.resetPasswordButton.classList.remove('button--loading');
      }
    );
  }

  /**
   * Quando o Usuário clicar em Logar, realiza o processo de Login
   */
  onSubmitResetPassword() {
    this.userService.ResetPasswordWithToken(this.resetToken).subscribe(
      (res: any) => {
        if (res.erros != null && res.erros.length > 0) {
          this.hasToShowError = true;
          this.ErrorMessage = res.erros[0];
        } else {
          this.PageName = 'Success2';
        }
      },
      err => {
        if (err.erros != null && err.erros.length > 0) {
          this.hasToShowError = true;
          this.ErrorMessage = err.erros[0];
        }
      }
    );
  }

  /**
   * Ao Inicializar este componente
   */
  ngOnInit() {


    this.sub = this.route.params.subscribe(params => {

      //Recupera o Token
      this.resetToken = params['id'];

      //Se não existe token, acabamos por aqui
      if (this.resetToken === undefined) {
        return;
      }

      //Checa se o token ainda é válido
      this.userService.ValidResetPasswordToken(this.resetToken).subscribe(
        (res: any) => {
          if (!res) {
            this.PageName = 'ResetPassword';
          } else {
            this.PageName = 'ResetPasswordStep2';
          }
        },
        err => {
          this.PageName = 'ResetPassword';
        }
      );

      // //Se for cria um novo formulário
    });

    //setTimeout(() => { this.resetPasswordButton = document.getElementById('resetPasswordButton'); },200)

  }

  ngAfterViewInit() {
    this.resetPasswordButton = document.getElementById('resetPasswordButton');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
