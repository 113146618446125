import { ConfiguracaoService } from '../configuracao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-senha',
  templateUrl: './senha.component.html',
  styleUrls: ['./senha.component.scss']
})
export class SenhaComponent implements OnInit {

  constructor(private fb: FormBuilder, private configService: ConfiguracaoService, private toastr: ToastrService, private userService: UserService, private router: Router) {
    this.criarForm();
  }

  novaSenhaForm: FormGroup;

  ngOnInit() {
  }

  criarForm() {
    this.novaSenhaForm = this.fb.group({
      SenhaAtual: ['', [Validators.required, Validators.minLength(6)]],
      NovaSenha: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmaNovaSenha: ['', Validators.required],
    }, { validator: this.ValidaNovaSenha });


  }

  ValidaNovaSenha(fb: FormGroup) {
    const ConfirmaNovaSenha = fb.get('ConfirmaNovaSenha');
    if (ConfirmaNovaSenha.errors == null || 'passwordMismatch' in ConfirmaNovaSenha.errors) {
      if (fb.get('NovaSenha').value != ConfirmaNovaSenha.value) {
        ConfirmaNovaSenha.setErrors({ passwordMismatch: true });
      } else {
        ConfirmaNovaSenha.setErrors(null);
      }
    }
  }

  OnSubmitChangePassword() {
    this.configService.alteraSenha(this.novaSenhaForm.value).subscribe(
      (data: any) => {
        $('#modalSenhaAlterada').modal('show');
      },
      (erro: any) => {
        this.toastr.error(erro.error, 'Senha');
      }
    );

    this.novaSenhaForm.reset();
  }

  redirectToDashboard() {
    this.router.navigateByUrl('/');

  }









}
