import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../../menssage.service';
import { ListagemListagemPalavrasSuspeitasNiver } from '../../model_message/ListagemPalavrasSuspeitas.model';

@Component({
  selector: 'app-palavrasusp',
  templateUrl: './palavrasusp.component.html',
  styleUrls: ['./palavrasusp.component.scss']
})
export class PalavrasuspComponent implements OnInit {

  constructor(private msgService: MessageService, private toastr: ToastrService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  palavraSelecionado: any;

  TotalPalavras: any;
  listagemPalavraSuspeita: any;

  palavra = '';

  ngOnInit() {
    this.listagemPalavras();
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.word.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listagemPalavraSuspeita = this.paginate(this.TotalPalavras.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemPalavras(page: number = 1) {
    this.load = true;
    this.page = page;


    this.msgService.listarPalavrasSuspeitas(this.page, this.searchQuery).subscribe(
      (res: ListagemListagemPalavrasSuspeitasNiver) => {
        this.TotalPalavras = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalPalavras.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }

  adicionarPalavra() {
    this.msgService.addPalavraOuLinkSuspeito(this.palavra, 0).subscribe(
      (res: any) => {
        this.toastr.success('Palavra cadastrada com sucesso');
        this.listagemPalavras();
      },
      (erros) => {
        this.toastr.success('Não foi possivel salvar a palavra');
        console.log(erros);

      }
    );
  }

  removerPalavra(id: any) {
    this.msgService.removerItemSuspeito(id).subscribe(
      (res: any) => {
        this.listagemPalavras();

      },
      (erros) => {
        console.log(erros);

      }
    );
  }

  openModal(dados: any) {
    this.palavraSelecionado = dados;
  }

  closeModal() {
    this.palavraSelecionado = null;
    this.palavra = null;
  }



}
