<div class="breadcrumb-wrapper d-flex">
  <div style=" margin-right:1rem">
    <span class="mdi mdi-account-details font-size-20" style="height:100%;width:100%"></span>
  </div>
  <h1 *ngIf="!idGrupoJaSelecionado">Listagem de contatos</h1>
  <h1 *ngIf="idGrupoJaSelecionado">{{grupoNome}}</h1>
</div>
<div class="row">
  <div class="col-md-6 col-lg-6 col-xl-3">
    <div class="media widget-media p-4 bg-white border border-light rounded">
      <i class="mdi mdi-account-outline text-blue mr-4"></i>
      <div class="media-body align-self-center">
        <h4 class="text-primary mb-2" *ngIf="!load">
          {{ this.totalItems }}
        </h4>
        <p>Contatos cadastrados</p>
      </div>
    </div>
  </div>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <a *ngIf="!idGrupoJaSelecionado" type="button" class="btn btn-primary" [routerLink]="['/contacts/register']">
      <i class="mdi mdi-plus mr-1"></i> Novo contato
    </a>
    <a *ngIf="idGrupoJaSelecionado" type="button" class="btn btn-primary"
      [routerLink]="['/contacts/register', idGrupoJaSelecionado]">
      <i class="mdi mdi-plus mr-1"></i> Novo contato
    </a>
    &nbsp;

    <a type="button" class="btn btn-primary" [routerLink]="['/contacts/import']">
      <i class="mdi mdi-file-document mr-1"></i> Importar contato
    </a>
  </div>

  <!-- <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial;">
        <a class="dropdown-item" (click)="gerarExcelContatosGrupos()" style="cursor: pointer">Exportar como Excel</a>
        
      </div>
    </div>
  </div> -->

  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
        <!--<i class="mdi mdi-file-chart" style="font-size: large"></i>-->
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
        <!-- <a
          class="dropdown-item"
          data-toggle="modal"
          data-target="#importaExcel"
          style="cursor: pointer"
          >Importar como Excel</a
        > -->
        <a class="dropdown-item" (click)="gerarExcelContatosGrupos(false)" style="cursor: pointer">Exportar como
          Excel</a>
        <a class="dropdown-item" (click)="gerarExcelContatosGrupos(true)" style="cursor: pointer">Exportar como CSV</a>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header mb-0">
        <div class="row w-100 justify-content-between">
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" style="width: 100%; overflow-x: scroll" *ngIf="!load">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Telefone</th>
              <th scope="col">Email</th>
              <!-- <th scope="col">Aniversário</th>
          <th scope="col">Genero</th>
          <th scope="col">Bairro</th>
          <th scope="col">Cidade</th>
          <th scope="col">Estado</th> -->
              <th scope="col">Criado em</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of listagemContato">
            <tr>
              <td scope="row">{{ item.name }}</td>
              <td scope="row">{{ item.phone }}</td>
              <td scope="row">{{ item.email }}</td>
              <!-- <td scope="row">{{ item.niver }}</td>
          <td scope="row">{{ item.genero }}</td>
          <td scope="row">{{ item.bairro }}</td>
          <td scope="row">{{ item.cidade }}</td>
          <td scope="row">{{ item.estado }}</td> -->
              <td scope="row">{{ item.criadoEm }}</td>
              <td>
                <div class="dropdown widget-dropdown">
                  <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">
                    <li class="dropdown-item">
                      <a (click)="openModal(item)" data-toggle="modal" data-target="#confirmaDelete"
                        style="cursor: pointer">Excluir</a>
                    </li>
                    <li class="dropdown-item">
                      <a [routerLink]="['/contacts/edit', item.id]" style="cursor: pointer">Editar</a>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <div class="btn-group"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="form-inline justify-content-between" *ngIf="!load">
  <ul class="pagination ">
    <li [ngClass]="this.hasPreviousPage ? 'page-item' : 'page-item disabled'" style="cursor: pointer">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li [ngClass]="this.hasNextPage ? 'page-item' : 'page-item disabled'" style="cursor: pointer">
      <a class="page-link" style="cursor: pointer" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
</div>

<!-- modal excluir -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDelete" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse contato ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="excluirContato(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal importar excel -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="importaExcel" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Anexe seu excel para importação
        </h5>
      </div>
      <div class="modal-footer">
        <label>
          <a target="_blank" [href]="linksModelo">Baixar modelo de exemplo</a>
        </label>
        <input type="file" class="form-control" (change)="onSelectFile($event)" />
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button type="button" class="btn btn-primary btn-pill" (click)="onSubmit(this.selectedFile)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>