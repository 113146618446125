<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-chart-bar text-blue mr-4 font-size-20"></i>
  <h3 class="ml-0 mb-3" *ngIf="userInformation">
    {{userInformation.clientName}} - {{period}} {{
    status != -1 ? " - " +
    (status[0] | ConversaoStatusSms) : ''
    }} - Total: {{listaResultadoBackup.length}}
  </h3>
</div>
<div class="breadcrumb-wrapper  align-items-center">

  <div class="d-flex justify-content-between ml-3">
    <div class="row">
      <div class="dropdown d-inline-block mb-1">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" data-display="static">
          Exportar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
          <a class="dropdown-item" style="cursor:pointer" (click)="exportarMensagens(false)">Exportar como Excel</a>
          <a class="dropdown-item" style="cursor:pointer" (click)="exportarMensagens(true)">Exportar como CSV</a>
          <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
        </div>
      </div>

    </div>
  </div>
</div>
<div class="card card-default">
  <div class="input-group mx-3 my-3" style="width:auto;flex: 1">
    <input type="text" (input)="buscarQuery()" [(ngModel)]="searchQuery" class="form-control"
      placeholder="Busca rápida" />
    <div class="input-group-append">
      <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
        <i class=" mdi mdi-magnify font-size-20"></i>
      </button>
    </div>
  </div>
  <div class="col-12" *ngIf="!load">
    <div class="card-body pl-0" style="width: 100%; overflow-x: scroll;max-height:400px">
      <table class="table table-responsive table-responsive-large table-hover" style="width: 100%;">
        <thead>
          <tr>
            <th scope="col" style="min-width:400px">Mensagem</th>
            <th scope="col">Status</th>
            <th scope="col">Número</th>
            <th scope="col" style="min-width:150px">Data do envio</th>
            <th scope="col" style="min-width:150px">Data do retorno</th>
            <!--<th scope="col">Prestadora</th>-->
            <th scope="col">Operadora</th>
            <th scope="col">Tipo</th>
            <th scope="col" *ngFor="let key of [].constructor(7); let i = index">
              Paramentro {{ i + 2 }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="listaResultado.length">
          <tr *ngFor="let msg of listaResultado">
            <td scope="row" style="font-weight:100">{{ msg.content }}</td>
            <td>{{ msg.status }}</td>
            <td>{{ msg.number }}</td>
            <td>{{ msg.scheduled }}</td>
            <td>{{ msg.lastUpdateOn }}</td>
            <!--<td>{{ msg.mobileOperatorName?.toUpperCase() }}</td>-->
            <td>{{ msg.operator?.toUpperCase() }}</td>
            <td>{{ msg.smsType }}</td>
            <!--<td *ngFor="let pvalue of msg.paramsValue">{{ pvalue }}</td>-->
            <td *ngFor="let x of [].constructor(7);let i = index ">
              {{ msg.paramsValues[i]? msg.paramsValues[i]: "" }}
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="!listaResultado.length">
          <tr>
            <td colspan="4" style="text-align: center">
              Não encontrado registros
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>