import { ConfiguracaoService } from './../configuracao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/user.service';
import { ListaHistoricoAtividades } from '../models_configuracao/ListaHistoricoAtividades.model';
import { ListaHistoricoLogin } from '../models_configuracao/ListaHistoricoLogin.model';
declare let $: any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  constructor(private configService: ConfiguracaoService, private fb: FormBuilder, public userService: UserService) { }

  perfilForm: FormGroup;

  carregadoDisponivel = false;

  historicoDisponivelLogin = false;

  searchQueryLogin = '';
  searchQueryAtv = '';

  historicoAtsDisponivel = false;

  SmsNotification = false;
  EmailNotification = false;

  load = false;
  loadLogin = false;
  loadAtv = false;

  userBasicData: any;

  pageActivity = 1;
  neighborPagesActivity = [];

  totalItemsLogin = 0;
  pageSizeLogin: any = '10';
  pageCountLogin = 0
  hasPreviousPageLogin = false;
  hasNextPageLogin = false;

  totalItemsActivity = 0;
  pageSizeActivity: any = '10';
  pageCountActivity = 0
  hasPreviousPageActivity = false;
  hasNextPageActivity = false;

  pageLogin = 1;
  neighborPagesLogin = [];
  maxPagesOnPagination = 5;

  documentMask: object = {
    Fisica: '000.000.000-00',
    Juridica: '00.000.000/0000-00'
  };

  TotalLogin: any;
  listaHistoricoLogin: any = { lista: [], metadados: [] };

  TotalAtividade: any;
  listaHistoricoAtvs: any = [];

  CheckIfRole(roles) {
    if (this.userBasicData == undefined) {
      return false;
    }
    return this.userBasicData.roleType ? roles.includes(this.userBasicData.roleType) : false;
  }

  ngOnInit() {

    this.carregaDadosPerfil();

    this.userService.GetUserInformation().subscribe((userInfo: any) => {
      this.userBasicData = userInfo;
    });
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.usuario.toLowerCase().includes(this.searchQueryLogin.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }
  paginateAtv(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.log.toLowerCase().includes(this.searchQueryAtv.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginateLogin(page: number = 1) {
    this.pageCountLogin = Math.floor((this.totalItemsLogin + parseInt(this.pageSizeLogin)) / parseInt(this.pageSizeLogin));
    this.pageLogin = page;
    this.listaHistoricoLogin = this.paginate(this.TotalLogin.lista, this.pageSizeLogin, this.pageLogin);
    this.UpdatePagesLogin();
  }

  UpdatePagesLogin() {
    var actualPageCount = this.pageCountLogin;
    this.neighborPagesLogin = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesLogin.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageLogin - 1) - ratioLeft < 0) {
        let result = ((this.pageLogin - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageLogin) + ratioRight > actualPageCount) {
        let result = ((this.pageLogin) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageLogin - ratioLeft; i <= this.pageLogin; i++) {
        this.neighborPagesLogin.push(i);
      }
      for (let i = this.pageLogin + 1; i <= this.pageLogin + ratioRight; i++) {
        this.neighborPagesLogin.push(i);

      }

    }
  }

  getPaginateAtividade(page: number = 1) {
    this.pageCountActivity = Math.floor((this.totalItemsActivity + parseInt(this.pageSizeActivity)) / parseInt(this.pageSizeActivity));
    console.log("TOTAL:", this.totalItemsActivity)
    console.log("PAGECOUNT", this.pageCountActivity)
    this.pageLogin = page;
    this.listaHistoricoAtvs = this.paginateAtv(this.TotalAtividade.lista, this.pageSizeActivity, this.pageActivity);
    this.UpdatePagesAtividade();
  }

  UpdatePagesAtividade() {
    var actualPageCount = this.pageCountActivity;
    this.neighborPagesActivity = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesActivity.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageActivity - 1) - ratioLeft < 0) {
        let result = ((this.pageActivity - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageActivity) + ratioRight > actualPageCount) {
        let result = ((this.pageActivity) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageActivity - ratioLeft; i <= this.pageActivity; i++) {
        this.neighborPagesActivity.push(i);
      }
      for (let i = this.pageActivity + 1; i <= this.pageActivity + ratioRight; i++) {
        this.neighborPagesActivity.push(i);

      }

    }
  }


  carregaDadosPerfil() {
    this.load = true;
    this.configService.carregaDadosMeuPerfil().subscribe(
      (retorno: any) => {
        this.load = false;
        this.carregadoDisponivel = true;
        this.SmsNotification = retorno.detalhes_conta.notificacaoSms;
        this.EmailNotification = retorno.detalhes_conta.notificacaoEmail;
        this.perfilForm = this.fb.group({
          // SenhaAtual: [{ value: this.usuario.number, disabled: false }, Validators.required],
          Id: [{ value: retorno.detalhes_conta.id, disabled: true }],
          Cliente: [{ value: retorno.detalhes_conta.cliente_name, disabled: true }],
          Usuario: [{ value: retorno.detalhes_conta.usuario_name, disabled: true }],
          Criando_Em: [{ value: retorno.detalhes_conta.criado_em, disabled: true }],
          TipoPessoa: [{ value: retorno.infoGerais.tipo_pessoa, disabled: true }],
          Documento: [{ value: retorno.infoGerais.documento, disabled: true }],
          Site: [{ value: retorno.infoGerais.site, disabled: true }],
          Email: [{ value: retorno.infoGerais.email, disabled: true }],
          Nome: [{ value: retorno.infoGerais.nome, disabled: true }],
          RazaoSocial: [{ value: retorno.infoGerais.razao_social, disabled: true }],
          Telefone: [{ value: retorno.infoGerais.telefone, disabled: true }],
          Responsavel: [{ value: retorno.infoGerais.responsavel, disabled: true }],
          Cep: [{ value: retorno.endereco.cep, disabled: true }],
          Endereco: [{ value: retorno.endereco.enderecotxt, disabled: true }],
          Numero: [{ value: retorno.endereco.numero, disabled: true }],
          Estado: [{ value: retorno.endereco.estado, disabled: true }],
          Cidade: [{ value: retorno.endereco.cidade, disabled: true }],
          Bairro: [{ value: retorno.endereco.bairro, disabled: true }],
          Complemento: [{ value: retorno.endereco.complemento, disabled: true }],

        });
      },
      (errr: any) => {
        this.carregadoDisponivel = true;

        alert('erro');
      }
    );
  }

  alterarNotificacaoSMS() {
    this.SmsNotification = !this.SmsNotification;
    this.configService.changeSMSNotification().subscribe((res: any) => console.log(res));

  }
  alterarNotificacaoEmail() {
    this.EmailNotification = !this.EmailNotification;
    this.configService.changeEmailNotification().subscribe((res: any) => console.log(res));
  }

  carregarMeuHistoricoLogin(page: number = 1, type?) {
    this.loadLogin = true;
    this.pageLogin = page;
    this.configService.carregarRelatorioLogin(this.pageLogin, this.searchQueryLogin, type, 10).subscribe(
      (retorno: any) => {
        this.loadLogin = false;
        console.log("HIST", retorno)
        this.historicoDisponivelLogin = true;
        this.TotalLogin = retorno;

        //DADOS PAGINAÇÃO
        this.totalItemsLogin = this.TotalLogin.lista.length;
        this.pageCountLogin = (this.totalItemsLogin + this.pageSizeLogin) / this.pageSizeLogin;
        this.hasPreviousPageLogin = this.pageLogin == 1 ? false : true;
        this.hasNextPageLogin = this.pageLogin == this.pageCountLogin ? false : true;

        this.getPaginateLogin();


      },
      (errr: any) => {
        this.historicoDisponivelLogin = true;

        alert('erro');
      }
    );
  }

  carregarMeuHistoricoAtividades(page: number = 1, type?) {
    this.loadAtv = true;
    this.pageActivity = page;
    this.historicoAtsDisponivel = true;
    this.configService.carregarHistoricoAtividades(this.pageActivity, this.searchQueryAtv, type, 10).subscribe(
      (retorno: any) => {
        this.loadAtv = false;
        this.historicoAtsDisponivel = true;
        this.TotalAtividade = retorno;
        console.log("HIST", this.listaHistoricoAtvs);

        //DADOS PAGINAÇÃO
        this.totalItemsActivity = this.TotalAtividade.lista.length;
        this.pageCountActivity = (this.totalItemsActivity + this.pageSizeActivity) / this.pageSizeActivity;
        this.hasPreviousPageActivity = this.pageActivity == 1 ? false : true;
        this.hasNextPageActivity = this.pageActivity == this.pageCountActivity ? false : true;

        this.getPaginateAtividade();
      },
      (errr: any) => {
        this.historicoAtsDisponivel = false;

        //alert('erro')
      }
    );
  }


  OnSubmitEditPerfil() {
    alert('junior não repassou os campos editaveis');
  }


}
