<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-email text-blue mr-4 font-size-20"></i>
  <h1>Mensagens Recebidas</h1>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <!-- <div>
    <a
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#cadastoContatoBlack"
      style="cursor: pointer"
    >
      <i class="mdi mdi-plus mr-1"></i> Adicionar a black list
    </a>
  </div> -->

  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="modal"
        data-target="#modalPeriodo" aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <!--<div class="dropdown-menu"
           aria-labelledby="dropdownMenuButton"
           style="right: 0; left: initial">-->
      <!--<a class="dropdown-item" style="cursor: pointer;" (click)="exportaRespostaEnviadas()">Exportar como Excel</a>-->
      <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
      <!--</div>-->
    </div>
  </div>
</div>


<div class="card card-default">
  <div class="card-header justify-content-center ">
    <!--<h2>
      <div class="dropdown d-inline-block mb-1">
        <button class="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-display="static">
          Filtrar por
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Aguardando Envio</a>
          <a class="dropdown-item" href="#">Em Andamento</a>
          <a class="dropdown-item" href="#">Finalizado</a>
        </div>
      </div>
    </h2>-->
    <!--<label class="form-inline">Busca rápida</label>-->
    <div class="row w-100">
      <div class="col-12 px-0">
        <div class="input-group mb-0 ">
          <input type="text" style="height:45px" (input)="listagemRespostas()" [(ngModel)]="searchQuery"
            class="form-control " placeholder="Busca rápida" />
          <div class="input-group-append">
            <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
              <i class=" mdi mdi-magnify font-size-20"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-0 pb-5" *ngIf="!load">
    <hr class="my-0" />
    <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
      <thead>
        <tr>
          <th scope="col">Data</th>
          <th scope="col">Resposta</th>
          <th scope="col">Número</th>
          <th scope="col" style="text-align:center">Ação</th>
        </tr>
      </thead>
      <tbody *ngFor="let msg of listReceivedSmsGrouped">
        <tr style="background-color:lightgray;" *ngIf="msg.type == 'message'">
          <td scope="row" colspan="4" style="background-color:lightgray;">{{ msg.messageId }} - {{msg.mtContent}}</td>
        </tr>
        <tr *ngIf="msg.type == 'sms'">
          <td scope="row">{{ msg.receivedOn }}</td>
          <td>{{ msg.content }}</td>
          <td>{{ msg.number }}</td>
          <td>
            <div style="width:250px; justify-content:center;text-align:center; margin-left:auto; margin-right:auto">
              <button data-toggle="tooltip" title="Responder Mensagem" data-target="#modalResponder"
                (click)="selectMessage(row)" data-toggle="modal" class="btn btn-sm btn-primary">
                <i class="mdi mdi-reply font-size-20"></i>
              </button>
              <button data-toggle="tooltip" title="Adicionar número à Lista Negra" style="margin-left: 1rem;"
                class="btn btn-sm btn-secondary">
                <i class="mdi mdi-alpha-b-box font-size-20"></i>
              </button>
              <button data-toggle="tooltip" title="Deletar Mensagem" style="margin-left: 1rem;"
                data-target="#confirmaDeleteResposta" data-toggle="modal" (click)="selectMessage(row)"
                class="btn btn-sm btn-danger">
                <i class="mdi mdi-delete font-size-20"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="form-inline justify-content-between" *ngIf="!load">
  <ul class="pagination">
    <li [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" style="cursor: pointer" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li [ngClass]="
        this.hasNextPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" style="cursor: pointer" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
</div>

<!-- modal excluir -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDeleteResposta" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse resposta ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="excluirResposta(msgData.id)"
          data-dismiss="modal">
          Excluir
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal adicionar a black list -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaAddListaNegra" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja adicionar esse contato a lista negra ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="adicionarAblackList(msgData.number)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal responder resposta de sms -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="modalResponder" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Responder mensagem recebida
        </h5>
      </div>

      <div class="card-body" *ngIf="msgResposta">
        <p><b>Mensagem Enviada: </b> {{msgResposta.mtContent}}</p>
        <br>
        <p><b>Mensagem Recebida: </b> {{msgResposta.content}}</p>
        <br>

        <b>Resposta</b>
        <input type="text" class="form-control" [(ngModel)]="contentResponsa">
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="respondeSms()" data-dismiss="modal">
          Responder
        </button>
      </div>
    </div>
  </div>
</div>
<!-- escolha de periodo -->
<div class="modal fade" id="modalPeriodo" data-keyboard="false" data-backdrop="static" tabindex="-1"
  aria-labelledby="modalDetalhesMensagensEnviadas" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Escolha um periodo para a exportação do relatorio
        </h5>
        <button type="button" data-dismiss="modal" (click)="closeModal()">
          <span data-dismiss="modal" aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <div style="display: flex">
            <div class="form-group" style="flex: 1">
              <label>De</label>
              <!--<label *ngIf="formulario.invalid"><span style="color:red">Obrigatório</span></label>-->
              <input [(ngModel)]="PeriodoDe" class="form-control" type="date" [value]="PeriodoDe" />
            </div>
            <div class="form-group" style="margin: 40px 15px 0 15px">
              <span>à</span>
            </div>
            <div class="form-group" style="flex: 1">
              <label>Até</label>
              <input [(ngModel)]="PeriodoAte" class="form-control" type="date" [value]="PeriodoAte" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal"
          (click)="exportaRespostaEnviadas(PeriodoDe, PeriodoAte)">
          Exportar
        </button>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" id="botaoConfirmacaoEnvio" data-toggle="modal" style="display:none"
  data-target="#ModalEnvioConfirmado"> ok</button>

<!-- modal envio confirmado -->
<div class="modal fade" id="ModalEnvioConfirmado" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Envio de mensagem confirmada
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>