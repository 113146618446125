
<!-- Owner Only-->
<section class="owner-section" >
  <h3 class="mb-5 d-flex justify-content-between">
    <div>Ajuda</div>
  </h3>
  <div class="row">
    <div class="col-xl-12">

      <div class="row">
        <div class="col-lg-12">
          <div class="card card-default">
            <div class="card-header card-header-border-bottom">
              <h2>FAQ</h2>
            </div>
            <div class="card-body mt-3">
              <div id="accordion3" class="accordion accordion-bordered ">
                <div class="card">
                  <div class="card-header d-flex" id="heading2">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse2" aria-expanded="false"
                            aria-controls="collapse2">
                      Como faço para enviar uma mensagem para grupos e/ou contatos?
                    </button>
                  </div>
                  <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Para enviar mensagem para grupos previamente cadastrados ou para contatos avulsos, basta clicar no botão Enviar Mensagem p/ grupos e/ou contatos na tela inicial do sistema.
                      Você também pode acessar o módulo pelo menu lateral, clicando em mensagens > nova mensagem.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading3">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse3" aria-expanded="false"
                            aria-controls="collapse3">
                      Como faço para enviar um SMS para contatos em um arquivo EXCEL?
                    </button>
                  </div>
                  <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Para enviar uma mensagem para contatos de um arquivo Excel, basta clicar no botão Enviar Mensagem por Arquivo na tela inicial do sistema.
                      Você também pode acessar o módulo pelo menu lateral, clicando em mensagens > envio por arquivo.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading4">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse4" aria-expanded="false"
                            aria-controls="collapse4">
                      Como monto meu arquivo excel para envio?
                    </button>
                  </div>
                  <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Para montar o arquivo de envio corretamente, você precisa seguir o padrão do modelo do sistema, seguindo os tópicos abaixo:
                      <br /><br />
                      1 - A primeira linha do seu arquivo deve ser composta pelo cabeçalho;
                      <br />
                      2 - A primeira coluna deve ter o título DDI+DDD+Celular;
                      <br />
                      3 - O campo celular deve estar sempre na primeira coluna, junto com o DDD, o 9 e sem espaços, logo abaixo do título. Exemplo: 21989009999;
                      <br />
                      4 - O campo nome deve estar sempre na segunda coluna, sendo que a primeira linha é composta pelo título – Nome;
                      <br />
                      5 - Campos personalizados devem constar da 3 coluna em diante;
                      <br />
                      6 - Fique atento ao limite de 8 colunas para campos personalizados;
                      <br />
                      7 - O arquivo deve ser salvo em xlsx ou csv separado por vírgula;
                      <br />
                      8 - O arquivo deve ter até 5 MB para upload

                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading5">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse5" aria-expanded="false"
                            aria-controls="collapse5">
                      Como acompanho o meu envio?
                    </button>
                  </div>
                  <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Existem duas formas de acompanhar o envio das mensagens: Acompanhamento geral pela fila de mensagens e Acompanhamento geral por status, através do menu relatórios.
                      <br /><br />
                      Após realizar o envio da sua mensagem, você será redirecionado para a tela inicial do sistema. Caso queira acompanhar o envio pela fila de mensagens, basta clicar no botão Acompanhar Envio (Mensagens).
                      <br /><br />
                      Se deseja acompanhar por status, basta clicar em Relatórios, na barra vertical de menus, na esquerda da tela.
                      Ao clicar, você será direcionado para o módulo de relatórios do sistema. Para visualizar, no campo tipo de relatório,
                      informe Acompanhamento de Envio, Data do Envio, Tipo – Consolidado e clique no botão gerar relatório.
                      Logo abaixo o sistema exibirá o acompanhamento geral dos envios de acordo com o status de cada mensagem.

                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading6">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse6" aria-expanded="false"
                            aria-controls="collapse6">
                      Qual a diferença entre o Acompanhamento via mensagens e por status?
                    </button>
                  </div>
                  <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion3">
                    <div class="card-body m-2">
                      No acompanhamento de envio (Mensagens), passamos uma visão geral do envio das mensagens, de acordo com 3 posições: AGUARDANDO ENVIO – EM ANDAMENTO E FINALIZADO.
                      <br /><br />
                      AGUARDANDO ENVIO – Aguardando processamento para envio. Geralmente são mensagens que foram agendadas.
                      <br /><br />
                      EM ANDAMENTO – Existe pelo menos 1 mensagem em status parcial – SENDO PROCESSADO.
                      <br /><br />
                      FINALIZADO – Todos as mensagens do envio estão em status finais (Enviado/Não Enviado) e o envio foi finalizado com sucesso.


                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading7">
                    <i class="mdi mdi-help mb-1"  style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse7" aria-expanded="false"
                            aria-controls="collapse7">
                      Como cadastro modelos de mensagens para enviar?
                    </button>
                  </div>
                  <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Para cadastrar modelos de textos de mensagens, clique no menu mensagens > modelo. Na tela de cadastro, informe
                      o titulo do modelo e seu respectivo texto. Dessa forma, sempre que quiser reutilizá-lo, basta selecioná-lo quando for enviar sua mensagem.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading8">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse8" aria-expanded="false"
                            aria-controls="collapse8">
                      Como crio um grupo de contatos?
                    </button>
                  </div>
                  <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Para criar um grupo de contatos no sistema, clique no menu contatos > grupos.
                      Na tela dos grupos, clique no botão azul novo grupo, informa o titulo do grupo, descrição e em seguida clique no botão cadastrar.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header d-flex" id="heading9">
                    <i class="mdi mdi-help mb-1" style="color:#3063e9"></i>
                    <button class="btn btn-link collapsed" style="flex:1;font-weight:500" data-toggle="collapse" data-target="#collapse9" aria-expanded="false"
                            aria-controls="collapse9">
                      Preciso adicionar alguns contatos esporádicos de forma manual no sistema, como faço?
                    </button>
                  </div>
                  <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordion3">
                    <div class="card-body m-2">
                      Para adicionar um contato individual, clique no menu contatos > novo contato. Preencha as informações no formulário e clique em cadastrar.

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle2">Atividade Usuário: Leonardo</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-hover ">
            <thead>
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Cliente</th>
                <th scope="col">Usuario</th>
                <th scope="col">Ação</th>
                <th scope="col">Sistema</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">22/03/2021</td>
                <td>UNIQ Mobile</td>
                <td>Junior</td>
                <td>Desativou o grupo UFMA</td>
                <td>Dashboard</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Pendentes-->
  <div class="modal fade" id="ModalPendentes" tabindex="-1" role="dialog" aria-labelledby="ModalPendentes"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle2">Mensagem Pendente - Nº 205412</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <table class="table table-hover ">
              <thead>
                <tr>
                  <th scope="col">Mensagem</th>
                  <th scope="col">Id</th>
                  <th scope="col">Data</th>
                  <th scope="col">Quantidade</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Usuario</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">BB: Acesse já o nosso app e garanta suas vantagens!</td>
                  <td>205412</td>
                  <td>22/03/2021</td>
                  <td>100</td>
                  <td>UNIQ Mobile</td>
                  <td>Junior</td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Agendadas -->
  <div class="modal fade" id="ModalAgendadas" tabindex="-1" role="dialog" aria-labelledby="ModalRecebida"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle2">Alterar Agendamento</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group" style=" margin-top: 2rem;">
              <div style="display:flex">
                <p for="exampleInputPassword1"><strong>Data:</strong></p>
                <p style="margin-left: 1rem;">23/03/2021</p>
              </div>
              <div class="form-group" style=" margin-top: 2rem;">
                <div style="display:flex">
                  <p for="exampleInputPassword1"><strong>Quantidade:</strong></p>
                  <p style="margin-left: 1rem;">100</p>
                </div>
              </div>
              <div class="form-group" style=" margin-top: 2rem;">
                <div style="display:flex">
                  <p for="exampleInputPassword1"><strong>Mensagem:</strong></p>
                  <p style="margin-left: 1rem;">BB: Acesse já o nosso app e garanta suas vantagens!</p>

                </div>
              </div>

              <div class="form-group">
                <label for="exampleFormControlInput3">Nova Data</label>
                <input type="date" class="form-control" id="exampleFormControlInput3" placeholder="Enter Email">
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput3">Novo Horario</label>
                <input type="time" class="form-control" id="exampleFormControlInput3" placeholder="Enter Email">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Recebidas -->
  <div class="modal fade" id="ModalRecebida" tabindex="-1" role="dialog" aria-labelledby="ModalRecebida"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle2">Detalhes da Resposta</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group" style="display:flex">
              <p for="exampleInputEmail1"><strong>Id:</strong></p>
              <p style="margin-left: 1rem;">268451</p>

            </div>
            <div class="form-group" style=" margin-top: 2rem;">
              <div style="display:flex">
                <p for="exampleInputPassword1"><strong>Mensagem Enviada:</strong></p>
                <p style="margin-left: 1rem;">BB: Acesse já o nosso app e garanta suas vantagens!</p>

              </div>
            </div>
            <div class="form-group" style=" margin-top: 2rem;">
              <div style="display:flex">
                <p for="exampleInputPassword1"><strong>Resposta:</strong></p>
                <p style="margin-left: 1rem;">Recebido!</p>

              </div>
            </div>
            <div class="form-group" style="margin-top: 2rem;">
              <div style="display:flex">
                <p for="exampleInputPassword1"><strong>Recebido por:</strong></p>
                <p style="margin-left: 1rem;">+55 (98) 991639595</p>

              </div>
            </div>
            <div class="form-group" style="margin-top: 2rem;">
              <div style="display:flex">
                <p for="exampleInputPassword1"><strong>Operadora:</strong></p>
                <p style="margin-left: 1rem;">Oi</p>

              </div>
            </div>
            <div class="form-group" style=" margin-top: 2rem;">
              <div style="display:flex">
                <p for="exampleInputPassword1"><strong>Data:</strong></p>
                <p style="margin-left: 1rem;">23/03/2021</p>

              </div>
            </div>
          </form>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary btn-pill">Save Changes</button>
        </div> -->
      </div>
    </div>
  </div>

</section>
