import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GruposService } from '../Grupos.service';

@Component({
  selector: 'app-edicao-grupos',
  templateUrl: './edicao-grupos.component.html',
  styleUrls: ['./edicao-grupos.component.scss']
})
export class EdicaoGruposComponent implements OnInit {

  constructor(private fb: FormBuilder, private grupoService: GruposService, private toastr: ToastrService, private route: Router, private activatedRoute: ActivatedRoute) { }

  grupoForm: FormGroup;
  load: boolean;


  ngOnInit() {
    this.carregarGrupo();
  }


  carregarGrupo() {
    this.load = true;
    this.grupoService.getGrupoId(this.activatedRoute.snapshot.params.id).subscribe(
      (data: any) => {
        this.load = false;

        this.grupoForm = this.fb.group({
          NomeGroup: [{ value: data.name, disabled: false }, Validators.required],
          Descricao: [{ value: data.description, disabled: false }],

        });
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar grupo!', 'Grupo');
        this.load = false;

      }
    );
  }


  OnSubmitEdit() {

    this.grupoService.editGrupo(Number(this.activatedRoute.snapshot.params.id), this.grupoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Você está sendo redirecionado!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      },
      (erro: any) => {
        this.toastr.error('Erro ao atualizar!', 'Grupo');

      }
    );
  }



}
