<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="pacman" [fullScreen]="true">
  <p style="color: white">Carregando...</p>
</ngx-spinner>
<div class="breadcrumb-wrapper d-flex">
  <div style=" margin-right:1rem">
    <span class="mdi mdi-account-plus font-size-20" style="height:100%;width:100%"></span>
  </div>
  <h1>Novo Contato</h1>
</div>
<div class="row">
  <div class="col-12" *ngIf="!load">
    <form ngNativeValidate [formGroup]="contatoForm" autocomplete="off">
      <div class="card card-default card-form-register">
        <!--<div class="card-header card-header-border-bottom">
          <h2>Adicionar contato</h2>
        </div>-->
        <div class="card-body pb-1 mt-3">
          <div class="row">
            <div class="col-sm-6">
              <div class="">
                <h4>Adicionar Contato</h4>
                <p style="color:#808080; font-size:small">Os campos marcados com * são obrigatórios</p>
                <hr />
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect3">
                  Nome do contato:
                </label>
                <input class="form-control" placeholder="Digite nome do contato" formControlName="Nome" />
                <span class="mt-2 d-block text-danger" *ngIf="
                    contatoForm.get('Nome').touched &&
                    contatoForm.get('Nome').errors?.required
                  ">Este campo é obrigatório!</span>
              </div>

              <div class="form-group">
                <label for="txtNumber">Telefone: * </label>
                <input class="form-control" id="txtNumber" placeholder="Digite telefone" formControlName="Telefone"
                  mask="(00) 00000-0000" />
                <span class="mt-2 d-block text-danger" *ngIf="
                    contatoForm.get('Telefone').touched &&
                    contatoForm.get('Telefone').errors?.required
                  ">Este campo é obrigatório!</span>
              </div>

              <div class="form-group">
                <label for="txtNumber">Email:</label>
                <input class="form-control" id="txtNumber" placeholder="Digite seu email" formControlName="Email" />
                <span class="mt-2 d-block text-danger" *ngIf="
                    contatoForm.get('Email').touched &&
                    contatoForm.get('Email').errors?.required
                  ">Este campo é obrigatório!</span>
              </div>

              <div class="form-group">
                <label for="txtNumber">Aniversario: </label>
                <input class="form-control" placeholder="Digite seu aniversario" formControlName="Aniversario"
                  type="date" />
              </div>

              <div class="form-group">
                <label>Gênero: </label>
                <select class="form-control" formControlName="Genero">
                  <option value="0">Selecione...</option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                  <option value="O">Outro</option>
                </select>
              </div>

              <div class="form-group">
                <label>Bairro: </label>
                <input class="form-control" placeholder="Digite seu bairro" formControlName="Bairro" />
              </div>

              <div class="form-group">
                <label>Cidade: </label>
                <input class="form-control" placeholder="Digite sua cidade" formControlName="Cidade" />
              </div>

              <div class="form-group">
                <label>Estado: </label>
                <input class="form-control" placeholder="Digite seu estado" formControlName="Estado" />
              </div>
            </div>

            <div class="col-sm-6" *ngIf="!idGrupoJaSelecionado">
              <div class="">
                <h4>Associar a Grupo(s)</h4>
                <p style="color:#808080; font-size:small">Associe o contato criado com um ou mais grupos.</p>
                <hr />
              </div>
              <label for="formGroupExampleInput">Grupos</label>
              <div class="form-group" *ngIf="listaGrupos.length > 0">
                <!--<div class="form-group" *ngIf="listaGrupos.length > 0"> o certo -->

                <div class="col-12 mt-2 ml-1" style="max-height: 400px; overflow-y:auto">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">
                          <label class="control outlined control-checkbox ml-2">
                            <input [(NgModel)]="isAllChecked" type="checkbox"
                              (change)="SelectAllGroups($event.target.checked)"
                              [checked]="item | loadGruposContatos: gruposSelecionaodos" />
                            <div class="control-indicator"></div>
                          </label>
                        </th>
                        <th scope="col">Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of listaGrupos">
                        <td scope="row">
                          <label class="control outlined control-checkbox ml-2">


                            <input [value]="gruposSelecionados.indexOf(item.id) > -1" type="checkbox"
                              (change)="SelectGroup(item.id)" [checked]="gruposSelecionados.indexOf(item.id) > -1" />
                            <div class="control-indicator"></div>
                          </label>
                        </td>
                        <td scope="row">{{ item.name }}</td>

                      </tr>
                    </tbody>
                    <tfoot *ngIf="listaGrupos.length <= 0">
                      <td style="text-align: center" colspan="3">Não encontrado itens</td>
                    </tfoot>
                  </table>

                  <!--<div *ngFor="let item of listaGrupos">
        <label class="control control-checkbox checkbox-primary ml-2">
          <p class="mb-1">{{ item.name }}</p>


          <div class="control-indicator"></div>
        </label>-->
                  <!--<input [value]="item.id"
              type="checkbox"
              [checked]="item | loadGruposContatos: gruposSelecionaodos" />
      {{ item.name }}-->
                  <!--</div>-->
                  <!--<div class="form-check">
        <label class="control control-radio radio-primary font-size-15">
          <p>
          Associar o novo contato a um grupo existente

          </p>
          <input class="form-check-input"
                 type="radio"
                 name="exampleRadios"
                 (click)="tipoImportacao('existente', true)"
                 id="exampleRadios1"
                 value="option1" />
          <div class="control-indicator"></div>
        </label>
        <div *ngIf="isExistenteGrupo">
          <div class="form-group">
            <label class="control control-checkbox checkbox-primary" for="formGroupExampleInput">Grupos Disponíveis</label>
          </div>
        </div>
      </div>
      <div class="form-check">
        <label class="control control-radio radio-primary">
          <p>
          Associar o novo contato a um novo grupo

          </p>
          <input class="form-check-input"
                 type="radio"
                 name="exampleRadios"
                 (click)="tipoImportacao('new_grupo', true)"
                 id="exampleRadios2"
                 value="option2" />
          <div class="control-indicator"></div>
        </label>-->
                  <!--<div *ngIf="isNovoGrupo">
      <div class="form-group">-->
                  <!--<label for="exampleFormControlInput3">Novo Grupo</label>-->
                  <!--<button data-target="#CriarGrupoModal" data-toggle="modal" class="btn btn-primary"> Criar Novo Grupo</button>-->
                  <!--<input type="email" class="form-control" id="exampleFormControlInput3" placeholder="Nome do Grupo">-->
                  <!--</div>
      </div>-->
                </div>
                <!--<div class="form-check">
      <label class="control control-radio radio-primary">
        <p>
        Somente adicionar contato

        </p>
        <input class="form-check-input"
               type="radio"
               checked="true"
               name="exampleRadios"
               (click)="tipoImportacao('', false)"
               id="exampleRadios3"
               value="option3" />
        <div class="control-indicator"></div>
      </label>
    </div>-->
                <!--<div *ngFor="let item of listaGrupos">
      <input [value]="item.id"
             type="checkbox"
             (change)="onCheckChange($event)" />
      {{ item.name }}
    </div>-->


              </div>

              <div *ngIf="listaGrupos.length == 0">
                <p class="mt-2 mb-3">Você não possui Grupos disponíveis.</p>
                <button data-target="#CriarGrupoModal" data-toggle="modal" class="btn btn-primary"> Criar Novo
                  Grupo</button>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="idGrupoJaSelecionado">
              <div class="form-group" *ngIf="listaGrupos.length > 0">
                <label for="formGroupExampleInput">Grupos</label>
                <div *ngFor="let item of listaGrupos">
                  <input [value]="item.id" type="checkbox" [checked]="item | loadGruposContatos: gruposSelecionaodos" />
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div col-12 col-xs-6 col-md-4 col-lg-3>
              <button (click)="OnSubmitCreate()" class="btn btn-block btn-primary" [disabled]="!contatoForm.valid">
                Cadastrar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


<!-- Modal Criar Grupo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="CriarGrupoModal" tabindex="-1" aria-labelledby="CriarGrupoModal" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="CriarGrupoModal">
          Adicionar Grupo
        </h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12" *ngIf="!load">
            <form ngNativeValidate [formGroup]="grupoForm" autocomplete="off" (submit)="OnSubmitCreateGroup()">
              <div class="card-body pb-1">
                <div class="row">
                  <div class="col-12 ">
                    <div class="form-group">
                      <label for="exampleFormControlSelect3">Nome do grupo: * </label>
                      <input class="form-control" placeholder="Digite nome do grupo" formControlName="NomeGroup" />
                      <span class="mt-2 d-block text-danger"
                        *ngIf="grupoForm.get('NomeGroup').touched && grupoForm.get('NomeGroup').errors?.required">
                        Este campo é obrigatório!
                      </span>
                    </div>

                    <div class="form-group">
                      <label>Descricao: * </label>
                      <textarea class="form-control" placeholder="Digite descricao" formControlName="Descricao"
                        cols="30" rows="10">
                      </textarea>
                      <span class="mt-2 d-block text-danger"
                        *ngIf="grupoForm.get('Descricao').touched && grupoForm.get('Descricao').errors?.required">
                        Este campo é obrigatório!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          Fechar
        </button>

        <button data-dismiss="modal" (click)="OnSubmitCreateGroup()" class="btn btn-pill btn-primary"
          [disabled]="!grupoForm.valid">
          Cadastrar
        </button>
      </div>
    </div>
  </div>
</div>