import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../shared/user.service';
import { EdicaoUser } from '../models_users/edicaoUser.model';
import { ListagemClientes } from '../models_users/listagemClientes.model';
import { ListagemUsuarios } from '../models_users/listagemUsuarios.model';
import { UsersInClientService } from '../UsersInClient.service';

@Component({
  selector: 'app-listagem-usuarios',
  templateUrl: './listagem-usuarios.component.html',
  styleUrls: ['./listagem-usuarios.component.css']
})
export class ListagemUsuariosComponent implements OnInit {

  constructor(private usersincliente: UsersInClientService, private toastr: ToastrService, private userService: UserService, private router: Router) { }


  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  pageHistoric = 1;
  neighborPagesHistoric = [];

  totalItems = 0;
  pageSize = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  totalItemsHistoric = 0;
  pageSizeHistoric = 5;
  pageCountHistoric = 0
  hasPreviousPageHistoric = false;
  hasNextPageHistoric = false;

  load = false;

  usuariosDados: any;

  user: any;

  currentUserId: any;

  userInformation: any;

  TotalUserLog: any;
  userLog: any;

  TotalUsuarios: {
    ativos: 0,
    inativos: 0,
    lista: []
  };

  usuario: EdicaoUser;

  saldoForm: FormGroup;

  quantidade = 1;

  listaCliente: ListagemClientes[];

  fornecedor = 'Zenvia';

  documentMask: object = {
    1: '000.000.000-00',
    2: '00.000.000/0000-00'
  };


  ngOnInit() {
    this.listagemUsersCadastrado();
    this.userService.GetUserInformation().subscribe(res => {
      this.userInformation = res;
    })
  }

  CheckIfRole(roles) {
    if (this.userInformation == undefined) {
      return false;
    }
    return this.userInformation.roleType ? roles.includes(this.userInformation.roleType) : false;
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    this.page = page;
    this.usuariosDados = this.paginate(this.TotalUsuarios.lista, this.pageSize, this.page);
    this.UpdatePages();
  }
  getPaginateHistoric(page: number = 1) {
    this.pageHistoric = page;
    this.userLog = this.paginate(this.TotalUserLog.lista, this.pageSizeHistoric, this.pageHistoric);
    this.UpdatePagesHistoric();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  UpdatePagesHistoric() {
    var actualPageCount = this.pageCountHistoric;
    this.neighborPagesHistoric = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesHistoric.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageHistoric - 1) - ratioLeft < 0) {
        let result = ((this.pageHistoric - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageHistoric) + ratioRight > actualPageCount) {
        let result = ((this.pageHistoric) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageHistoric - ratioLeft; i <= this.pageHistoric; i++) {
        this.neighborPagesHistoric.push(i);
      }
      for (let i = this.pageHistoric + 1; i <= this.pageHistoric + ratioRight; i++) {
        this.neighborPagesHistoric.push(i);

      }

    }
  }


  listagemUsersCadastrado(page: number = 1, type?, active?) {
    this.load = true;
    this.page = page;


    this.usersincliente.GetallUsers(this.page, this.searchQuery, type, active).subscribe(
      (res: any) => {
        this.TotalUsuarios = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalUsuarios.lista.length;
        this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }


  deletarUsuario(id: number) {
    this.usersincliente.deleteUser(id).subscribe(
      (res: object) => {
        console.log(res);
        this.closeModal();
        this.listagemUsersCadastrado();
        this.toastr.success('Exclusão de usuario!', 'Deletado com sucesso');

      },
      (erro: any) => {
        this.toastr.error('Exclusão de usuario', 'Erro ao apagar usuario');


      }
    );
  }

  openModal(usuario: Object) {
    this.user = usuario;

  }

  closeModal() {
    this.user = null;

    this.quantidade = 1;
    this.fornecedor = 'Zenvia';

  }

  changeStatus(id: number, status: boolean) {

    this.usersincliente.alteraStatusUser(id, status).subscribe(
      (res: object) => {
        console.log(res);
        this.closeModal();
        this.listagemUsersCadastrado();
        this.toastr.success('Alteração de status!', 'Concluido');
      },
      (erro: any) => {
        this.toastr.error('Erro de status', 'Erro ao alterar status usuario');
      }
    );

  }

  detalharUser(id: number) {


    this.usersincliente.GetUserById(id).subscribe(
      (data: EdicaoUser) => {
        this.usuario = data;
        this.carregaListaCliente();
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar os detalhes!', 'Usuario');


      },
    );

  }

  carregaListaCliente() {
    this.load = true;
    this.usersincliente.GetallCliente().subscribe(
      (data: ListagemClientes[]) => {
        this.listaCliente = data;
        this.load = false;
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar!', 'Clientes');
        this.load = false;

      }
    );
  }

  trocarAPIKeyUsuario(id) {
    this.userService.ChangeUserAPIKEY(id).subscribe((res: any) => {
      this.toastr.success("Nova APIKEY criada com sucesso para este usuário!");
    });
  }

  loginAsUser(id) {
    this.userService.loginAsUser(id).subscribe((res: any) => {
      localStorage.setItem('UniqMobile_Message_Token', res.token);
      localStorage.setItem('UniqMobile_Message_SessionTime', res.sessionTime);
      this.toastr.success("Login realizado com sucesso!");
      this.userService.GetUserInformation().subscribe(res => {
        this.userInformation = res;
        this.router.navigate(['/']);
      });
    });
  }

  getUserLogHistoric(userId, page) {
    this.currentUserId = userId;
    this.pageHistoric = page;
    this.usersincliente.getUserLogHistoric(userId, page).subscribe((res: any) => {
      this.TotalUserLog = res

      //DADOS PAGINAÇÃO
      this.totalItemsHistoric = this.TotalUserLog.lista.length;
      this.pageCountHistoric = (this.totalItemsHistoric + this.pageSizeHistoric) / this.pageSizeHistoric;
      this.hasPreviousPageHistoric = this.pageHistoric == 1 ? false : true;
      this.hasNextPageHistoric = this.pageHistoric == this.pageCountHistoric ? false : true;

      this.getPaginateHistoric();
    });
  }

  exportUserLogHistoric(isCsv) {
    this.usersincliente.exportUserLogHistoric(this.TotalUserLog.lista, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let currentDate = new Date(Date.now());
      let currentDateFormat = currentDate.toLocaleDateString();
      currentDateFormat = currentDateFormat.replace("/", "");
      currentDateFormat = currentDateFormat.replace("/", "");

      if (isCsv) {
        saveAs(blob, this.userLog[0].fullName + "_Historico" + ".csv");
      } else {
        saveAs(blob, this.userLog[0].fullName + "_Historico" + ".xlsx");
      }
    });
  }
  exportUsers(isCsv) {
    this.usersincliente.exportUsers(this.TotalUsuarios.lista, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let currentDate = new Date(Date.now());
      let currentDateFormat = currentDate.toLocaleDateString();
      currentDateFormat = currentDateFormat.replace("/", "");
      currentDateFormat = currentDateFormat.replace("/", "");

      if (isCsv) {
        saveAs(blob, this.userInformation.clientName + "_Usuarios" + ".csv");
      } else {
        saveAs(blob, this.userInformation.clientName + "_Usuarios" + ".xlsx");
      }
    });
  }

}
