<div class="breadcrumb-wrapper d-flex">
  <div style=" margin-right:1rem">
    <span class="mdi mdi-alpha-b-box font-size-20" style="height:100%;width:100%"></span>
  </div>
  <h1>Lista Negra</h1>
</div>
<div class="row">
  <div class="col-md-6 col-lg-6 col-xl-3">
    <div class="media widget-media p-4 bg-white border border-light rounded">
      <i class="mdi mdi-account-outline text-blue mr-4"></i>
      <div class="media-body align-self-center">
        <h4 class="text-primary mb-2" *ngIf="!load">
          {{ this.totalItems }}
        </h4>
        <p>Cadastrados</p>
      </div>
    </div>
  </div>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#cadastoContatoBlack"
      style="cursor: pointer">
      <i class="mdi mdi-plus mr-1"></i> Adicionar a black list
    </a>
  </div>

  <!-- <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
        Importar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial;">
        <a class="dropdown-item"  data-toggle="modal" data-target="#importaExcel" style="cursor: pointer">Importar como Excel</a>
        
      </div>
    </div>
  </div> -->
</div>

<div class="card card-default" *ngIf="!load">
  <div class="card-header mb-0">
    <div class="row w-100">
      <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
        <input type="text" (input)="listagemBlackList()" [(ngModel)]="searchQuery" class="form-control"
          placeholder="Busca rápida" />
        <div class="input-group-append">
          <button data-toggle="tooltip" title="Buscar" class="btn btn-sm btn-primary" style="height:45px;width:45px">
            <i class=" mdi mdi-magnify font-size-20"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
  <div class="card-body">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Telefone</th>
          <th scope="col">Criado em</th>
          <th style="text-align:center" scope="col">Ações</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of listaBlackList">
        <tr>
          <td scope="row">{{ item.number }}</td>
          <td scope="row">{{ item.createAt }}</td>
          <td>
            <div style="text-align: center;">
              <button data-toggle="tooltip" title="Deletar Número da BlackList" style="margin-left: 1rem;"
                (click)="openModal(item)" data-toggle="modal" data-target="#confirmaDeleteContato"
                class="btn btn-sm btn-danger">
                <i class="mdi mdi-delete font-size-20"></i>
              </button>
            </div>
          </td>
          <td>
            <div class="btn-group"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="form-inline" *ngIf="!load">
  <ul class="pagination">
    <li [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" style="cursor: pointer" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li [ngClass]="
        this.hasNextPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" style="cursor: pointer" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</div>

<!-- modal excluir -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDeleteContato" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse contato ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="ExcluiContatoBlackList(contatoToBlackList.id)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal importar excel -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="cadastoContatoBlack" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Cadastrar numeros a black list
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <input type="file" class="form-control" (change)="onSelectFile($event)" />
        </div>

        <div class="form-group">
          <p style="float: left">
            Ou informe números(s) separe por: ',' ou ';' ou quebra linha
          </p>
          <textarea class="form-control" rows="10" [(ngModel)]="Numbers"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button type="button" class="btn btn-primary btn-pill" (click)="enviarContatoBlackList()" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>