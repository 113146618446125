import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../shared/user.service';
import { MessageService } from '../menssage.service';
import { ListagemNiver } from '../model_message/ListagemNiver.model';
declare let $: any;

@Component({
  selector: 'app-msganiversariantes',
  templateUrl: './msganiversariantes.component.html',
  styleUrls: ['./msganiversariantes.component.scss']
})
export class MsganiversariantesComponent implements OnInit {

  constructor(private msgNiverService: MessageService, private clientService: UserService, private toastr: ToastrService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  charCount = 0;

  aniversarianteSelecionado: any;

  TotalNiver: any;
  listagemNiverMsg: any;

  remetente = '';

  diasAntecedencia = '0';

  mensagem = '';

  personalizado = '';

  horaEnvio = '';

  limitMessage = 0;

  ngOnInit() {
    this.listagemMsgAniversariantes();

    this.clientService.GetUserInformation().subscribe((userInfo: any) => {
      this.limitMessage = userInfo.limitMessage;
    });
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.content.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listagemNiverMsg = this.paginate(this.TotalNiver.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemMsgAniversariantes(page: number = 1) {
    this.load = true;
    this.page = page;


    this.msgNiverService.listarNiver(this.page, this.searchQuery).subscribe(
      (res: ListagemNiver) => {
        this.TotalNiver = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalNiver.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();
      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }


  gravarMsgAniversariantes() {
    const data = {
      remetente: this.remetente,
      dias: Number(this.diasAntecedencia),
      HoraEnvio: this.horaEnvio,
      Mensagem: this.mensagem,
      Personalidado: this.personalizado
    };
    this.msgNiverService.addNiver(data).subscribe(
      (body: any) => {
        $('#avisoConfirmacao').modal('show');
        this.listagemMsgAniversariantes();
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel salvar a mensagem');
      }
    );
  }

  editarMsgAniversariantes(id: number) {
    const data = {
      remetente: this.remetente,
      dias: Number(this.diasAntecedencia),
      HoraEnvio: this.horaEnvio,
      Mensagem: this.mensagem,
      Personalidado: this.personalizado
    };
    this.msgNiverService.editNiver(id, data).subscribe(
      (body: any) => {
        this.listagemMsgAniversariantes();
      },
      (erro: any) => {
        alert('Não foi possivel editar a mensagem');
      }
    );
  }

  CountCharSMS() {
    let count = 0;
    if (count > 0) {
      count += 2;
    }
    let conteudo = this.mensagem;

    count += conteudo.length;
    if (count > this.limitMessage - 1) {
      this.mensagem = this.mensagem.substring(0, this.limitMessage - 1);
      //this.Content = conteudo;

      $('#avisoMessageLimit').modal('show');
      $('#contentMessage').val(this.mensagem);

    }
    this.charCount = count;

  }
  AddParameter(value) {
    if (value == '[Nome]') {
      if (!this.mensagem.includes('[Nome]')) {
        this.mensagem += '[Nome]';
      }
    } else {
      this.mensagem = this.mensagem.replace('[Nome]', '');
      $('#contentMessage').val(this.mensagem);
    }
    this.CountCharSMS();
  }

  openModalDados(inf: number) {
    this.msgNiverService.getNiverId(inf).subscribe(
      (res: any) => {
        this.aniversarianteSelecionado = res;
        this.remetente = res.sender;
        this.diasAntecedencia = res.daysBefore;
        this.horaEnvio = res.hour;
        this.mensagem = res.content;

        this.load = false;
      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );

  }

  closeModal() {
    this.aniversarianteSelecionado = null;
    this.aniversarianteSelecionado = null;
    this.remetente = null;
    this.diasAntecedencia = null;
    this.horaEnvio = null;
    this.mensagem = null;
    this.charCount = 0;
  }

  excluirNiver(id: number) {
    this.msgNiverService.exclusaoNiver(id).subscribe(
      (res: any) => {
        this.listagemMsgAniversariantes();
        this.load = false;
      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }

}
