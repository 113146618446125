<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-history text-blue mr-4 font-size-20"></i>
  <h1>Histórico de Login</h1>
</div>
<div class="row">
  <div class="col-12">
    <div class="mb-3">
      <div class="dropdown d-inline-block mb-1">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" data-display="static">
          Exportar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
          <a class="dropdown-item" style="cursor:pointer" (click)="exportLogin()">
            Exportar como Excel
            Completo
          </a>
          <a class="dropdown-item" style="cursor:pointer" data-toggle="modal" data-target="#modalPeriodo">
            Exportar como
            Excel Por Período
          </a>
          <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
        </div>
      </div>
    </div>
    <div class="card card-default">
      <div class="card-header mb-0">
        <div class="row w-100 justify-content-between">
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Cliente</th>
              <th scope="col">Usúario</th>
              <th scope="col">Dispositivo</th>
              <th scope="col">Login no sistema</th>
              <th scope="col">Tipo</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of listLogin">
            <tr>
              <td scope="row">{{ item.criado }}</td>
              <td scope="row">{{ item.client }}</td>
              <td scope="row">{{ item.usuario }}</td>
              <td scope="row">{{ item.dispositivo }}</td>
              <td scope="row">E-mail</td>
              <td scope="row">{{ item.tipo_user }}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="form-inline justify-content-between" *ngIf="!load">
  <ul class="pagination">
    <li [ngClass]="
          this.hasPreviousPage
            ? 'page-item'
            : 'page-item disabled'
        ">
      <a class="page-link" style="cursor: pointer" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li [ngClass]="
        this.hasNextPage
            ? 'page-item'
            : 'page-item disabled'
        ">
      <a class="page-link" style="cursor: pointer" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
</div>