import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListagemAtividadesUsuarios } from './models_auditoria/ListagemAtividadesUsuarios.model';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  carregaListagemAtivdadesUsuarios(page: number = 1, query: string = '', type?, itemsPerPage: number = 10): Observable<ListagemAtividadesUsuarios>{
    if (type == undefined) {
      return this.http.get<ListagemAtividadesUsuarios>(`${this.baseUrl}Historics/Index?page=${page}&query=${query}&itemsPerPage=${itemsPerPage}`);
    }
    return this.http.get<ListagemAtividadesUsuarios>(`${this.baseUrl}Historics/Index?page=${page}&query=${query}&type=${type}&itemsPerPage=${itemsPerPage}`);
  }
  getLoginHistoric(page: number = 1, query: string = '', itemsPerPage: number = 10) {
    if (query == undefined) {
      return this.http.get(`${this.baseUrl}Historics/GetLoginHistoric?page=${page}&itemsPerPage=${itemsPerPage}`);
    }
    return this.http.get(`${this.baseUrl}Historics/GetLoginHistoric?page=${page}&query=${query}&itemsPerPage=${itemsPerPage}`);
  }

  exportActivityExcel(data,startDate?, endDate?) {
    if (startDate == undefined && endDate == undefined) {
      return this.http.post(`${this.baseUrl}reports/GetActivityLogsAsExcel`, data, { responseType: 'blob' });
    }
    return this.http.post(`${this.baseUrl}reports/GetActivityLogsAsExcel?startDate=${startDate}&endDate=${endDate}`, data, { responseType: 'blob' });
  }

  exportLoginExcel(data, startDate?, endDate?) {
    if (startDate == undefined && endDate == undefined) {
      return this.http.post(`${this.baseUrl}reports/GetLoginLogsAsExcel`, data, { responseType: 'blob' });
    }
    return this.http.post(`${this.baseUrl}reports/GetLoginLogsAsExcel?startDate=${startDate}&endDate=${endDate}`, data, { responseType: 'blob' });
  }


}
