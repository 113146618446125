<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <!-- <div>
      <a type="button" class="btn btn-primary" [routerLink]="['/users/register']">
        <i class="mdi mdi-plus mr-1"></i> Novo usuário
      </a>
    </div> -->
  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial;">
        <a class="dropdown-item" style="cursor:pointer" (click)="ExportProvidersExcel(false)">Exportar como Excel</a>
        <a class="dropdown-item" style="cursor:pointer" (click)="ExportProvidersExcel(true)">Exportar como CSV</a>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header justify-content-between mb-1">
        <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
          <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
            placeholder="Busca rápida" />
          <div class="input-group-append">
            <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
              <i class=" mdi mdi-magnify font-size-20"></i>
            </button>
          </div>
        </div>

      </div>
      <div class="card-body pt-0 pb-5">
        <ul class="nav nav-pills nav-justified nav-style-fill nav-pill-table" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="active-tab" (click)="listagemFornecedorsCadastrado(1,true)" data-toggle="tab"
              href="#active" role="tab" aria-controls="active" aria-selected="true">Ativos <span
                class="labelTab text-success">{{
                TotalFornecedores.ativos }}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="inactive-tab" (click)="listagemFornecedorsCadastrado(1,false)" data-toggle="tab"
              href="#inactive" role="tab" aria-controls="inactive" aria-selected="false">Inativos <span
                class="labelTab text-secondary">
                {{TotalFornecedores.inativos}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="all-tab" (click)="listagemFornecedorsCadastrado(1)" data-toggle="tab" href="#all"
              role="tab" aria-controls="all" aria-selected="false">Todos <span
                class="labelTab text-primary">{{this.totalItems}}</span></a>
          </li>
        </ul>
        <table class="table table-responsive table-responsive-large table-hover" *ngIf="!load" style="width:100%">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">CNPJ</th>
              <th scope="col">Email</th>
              <th scope="col">Telefone</th>
              <th scope="col">Estoque</th>
              <th scope="col">Saldo Total</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody *ngFor="let fornecedor of fornecedoresDados">
            <tr>
              <td scope="row">{{ fornecedor.label }}</td>
              <td>{{ fornecedor.documente }}</td>
              <td>{{ fornecedor.email }}</td>
              <td>{{ fornecedor.telefone }}</td>
              <td>{{ fornecedor.stock }}</td>
              <td>{{ fornecedor.credito }}</td>
              <td>
                <div *ngIf="fornecedor.status">
                  <span class="badge badge-pill badge-success">Ativo</span>
                </div>
                <div *ngIf="!fornecedor.status">
                  <span class="badge badge-pill badge-warning">Inativo</span>
                </div>
              </td>
              <td>
                <div class="dropdown widget-dropdown">
                  <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"></a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">
                    <li class="dropdown-item">
                      <a [routerLink]="['/providers/edit', fornecedor.id]" data-toggle="modal"
                        style="cursor: pointer">Editar
                        fornecedor</a>
                    </li>
                    <li class="dropdown-item" *ngIf="fornecedor.status">
                      <a (click)="openModal(fornecedor)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Desativar fornecedor</a>
                    </li>
                    <li class="dropdown-item" *ngIf="!fornecedor.status">
                      <a (click)="openModal(fornecedor)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Ativar fornecedor</a>
                    </li>
                    <li *ngIf="fornecedor.status" class="dropdown-item">
                      <a (click)="openModal(fornecedor)" data-toggle="modal" data-target="#addValidity"
                        style="cursor: pointer">Adicionar saldo</a>
                    </li>
                    <li *ngIf="fornecedor.status" class="dropdown-item">
                      <a (click)="modalRemocaoSaldo(fornecedor.id)" data-toggle="modal" data-target="#remocaoSaldo"
                        style="cursor: pointer">remover saldo</a>
                    </li>
                    <li *ngIf="fornecedor.status" class="dropdown-item">
                      <a (click)="modalRemocaoSaldo(fornecedor.id)" data-toggle="modal" data-target="#remocaoSaldo"
                        style="cursor: pointer">Historico</a>
                    </li>
                    <!-- <li class="dropdown-item">
                        <a title="Adicionar saldo" (click)="modalSaldo(usuario)" data-toggle="modal"
                          style="cursor: pointer" data-target="#addSaldo">Adicionar saldo</a>
                      </li> -->

                  </ul>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="form-inline" *ngIf="!load">
  <!-- <nav aria-label="Page navigation example right"> -->
  <ul class="pagination ">
    <li style="cursor: pointer" [ngClass]="
          this.hasPreviousPage
            ? 'page-item'
            : 'page-item disabled'
        ">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li style="cursor: pointer" [ngClass]="
          this.hasNextPage ? 'page-item' : 'page-item disabled'
        ">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <!-- </nav> -->
</div>

<!-- alterar status -->
<div class="modal fade" *ngIf="fornecedorSelecionado" data-keyboard="false" aria-labelledby="staticBackdropLabel"
  data-backdrop="static" id="confirmaStatus" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar status ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill"
          (click)="changeStatus(fornecedorSelecionado.id, fornecedorSelecionado.status)" data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>




<!-- Remocao de Saldo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="remocaoSaldo" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Histórico de Saldo do Fornecedor
        </h5>
      </div>
      <div class="modal-body">
        <!-- <div class="card-body"> -->
        <table class="table">
          <thead>
            <th scope="col">Data do saldo</th>
            <th scope="col">Quantidade</th>
            <th scope="col">Validade</th>
            <th scope="col">Descrição</th>
            <!--<th scope="col">Excluir ?</th>-->
          </thead>
          <tbody *ngFor="let item of listaRemocoes">
            <tr>
              <td>{{ item.criadoEm }}</td>
              <td>{{ item.saldo }}</td>
              <td>{{ item.validity }}</td>
              <td>{{ item.descricao }}</td>

              <!--<td>
                <button type="button" class="btn btn-danger btn-sm" title="Excluir saldo"
                  (click)="excluirSaldoSelecionado(item.id)" data-dismiss="modal">
                  <i class="mdi mdi-trash-can"></i>
                </button>
              </td>-->
            </tr>
          </tbody>
          <tfoot *ngIf="listaRemocoes.length <= 0">
            <td colspan="3"><b>Este fornecedor não possui histórico</b></td>
          </tfoot>
        </table>
        <!-- </div> -->
      </div>
      <div class="form-inline mx-3 mb-3" *ngIf="!load">
        <!-- <nav aria-label="Page navigation example right"> -->
        <ul class="pagination ">
          <li style="cursor: pointer" [ngClass]="
                this.hasPreviousPageHistoric
                  ? 'page-item'
                  : 'page-item disabled'
              ">
            <a class="page-link" aria-label="Previous">
              <span aria-hidden="true" class="mdi mdi-chevron-left"
                (click)="getPaginateHistoric(pageHistoric - 1)"></span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesHistoric;"
            [ngClass]="{'active': pageHistoric == p }">
            <a class="page-link" (click)="getPaginateHistoric(p)">{{p}}</a>
          </li>
          <li style="cursor: pointer" [ngClass]="
                this.hasNextPageHistoric ? 'page-item' : 'page-item disabled'
              ">
            <a class="page-link" aria-label="Next">
              <span aria-hidden="true" class="mdi mdi-chevron-right"
                (click)="getPaginateHistoric(pageHistoric + 1)"></span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
        <!-- </nav> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal" (click)="closeModal()">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Adicionar Validade -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="addValidity" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja adicionar validade ?
        </h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Quantidade: </label>
          <input placeholder="Sua quantidade" class="form-control" [(ngModel)]="quantidade" type="text" />

          <div class="form-group mt-4">
            <label>Validade: </label>
            <div class="input-group date">
              <input type="date" [(ngModel)]="validade" class="form-control datetimepicker-input" />

            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill"
          (click)="AdicionarSaldoAoFornecedor(fornecedorSelecionado)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>