import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EdicaoUser } from '../models_users/edicaoUser.model';
import { ListagemClientes } from '../models_users/listagemClientes.model';
import { UsersInClientService } from '../UsersInClient.service';

@Component({
  selector: 'app-edicao-usuarios',
  templateUrl: './edicao-usuarios.component.html',
  styleUrls: ['./edicao-usuarios.component.scss']
})
export class EdicaoUsuariosComponent implements OnInit {

  constructor(private fb: FormBuilder, private router: Router, private usersincliente: UsersInClientService, private toastr: ToastrService, private route: Router, private activatedRoute: ActivatedRoute) { }

  usuariosform: FormGroup;
  listaCliente: ListagemClientes[];
  usuario: EdicaoUser;
  load: boolean;
  TypeOfPasswordInput = 'password';



  ngOnInit() {
    this.carregaListaCliente();
    this.carregaUsuario();
  }

  carregaListaCliente() {
    this.load = true;
    this.usersincliente.GetallCliente().subscribe(
      (data: ListagemClientes[]) => {
        this.listaCliente = data;
        this.load = false;
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar!', 'Clientes');
        this.load = false;

      }
    );
  }


  private dec2hex(number): string {
    const base = 16;
    const digito = new Array();
    let i = 0;

    while (number != 0) {
      i++;
      digito[i] = number % base;
      number = Math.floor(number / base);
    }
    let value = '';

    while (i >= 1) {
      switch (digito[i]) {
        case 10: { value += 'A'; break; }
        case 11: { value += 'B'; break; }
        case 12: { value += 'C'; break; }
        case 13: { value += 'D'; break; }
        case 14: { value += 'E'; break; }
        case 15: { value += 'F'; break; }
        default: { value += digito[i]; break; }
      }
      i--;
    }
    return value;
  }

  GeneratePassword(): void {
    const min = 32;
    const max = 126;
    let pass = '';
    for (let i = 1; i <= 10; i++) {
      const numberAsChar = min + Math.floor((Math.random() * (max - min)));  // 32 a 126
      let caracter = '%' + this.dec2hex(numberAsChar);
      caracter = unescape(caracter);
      pass += caracter;
    }
    // this.userService.UserRegistrationModel.controls.Cidade.setValue(this.selecionaCidade(res.uf, "sigla"));
    this.usuariosform.controls.Senha.setValue(pass);
    this.usuariosform.controls.ConfirmaSenha.setValue(pass);
  }

  /**
   * Altera a visibilidade do campo de senha
   */
  TogglePasswordDisplay(): void {
    this.TypeOfPasswordInput = this.TypeOfPasswordInput === 'text' ? 'password' : 'text';
  }


  carregaUsuario() {
    this.load = true;

    this.usersincliente.GetUserById(Number(this.activatedRoute.snapshot.params.id)).subscribe(
      (data: EdicaoUser) => {
        this.load = false;

        this.usuariosform = this.fb.group({
          IdClienteSelecionado: [data.clienteId, Validators.required],
          Email: [data.email, [Validators.email, Validators.required]],
          Login: [data.login, Validators.required],
          Senha: [''],
          ConfirmaSenha: [''],
          Nome: [data.fullName, Validators.required],
          RoleSelecionada: [data.role, Validators.required],
          NomeResponsavel: [data.responsibleName, Validators.required],
          NomeContato: [data.responsibleContact, Validators.required],

        }, { validator: this.ValidRegistration });
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar!', 'Usuario');
        this.load = false;

      },
    );
  }



  criarForm() {
    this.usuariosform = this.fb.group({
      IdClienteSelecionado: ['', Validators.required],
      Email: ['', [Validators.email, Validators.required]],
      Login: ['', Validators.required],
      Senha: ['', Validators.required],
      Nome: ['', Validators.required],
      ConfirmaSenha: ['', Validators.required],
      RoleSelecionada: ['', Validators.required],
    }, { validator: this.ValidRegistration });
  }

  ValidRegistration(fb: FormGroup) {



    const ConfirmPassword = fb.get('ConfirmaSenha');
    if (ConfirmPassword.errors == null || 'passwordMismatch' in ConfirmPassword.errors) {
      if (fb.get('ConfirmaSenha').value != ConfirmPassword.value) {
        ConfirmPassword.setErrors({ passwordMismatch: true });
      } else {
        ConfirmPassword.setErrors(null);
      }
    }
  }

  OnSubmitEdit() {
    this.usersincliente.updateUser(this.activatedRoute.snapshot.params.id, this.usuariosform.value).subscribe(
      (res: any) => {
        this.toastr.success('Você está sendo redirecionado!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/users/list');
        }, 2000);
      },
      err => {
        console.log(err);
        if (typeof err.error === 'string') {

          this.toastr.error(err.error, 'Usuario');
        } else {

          this.toastr.error('Não foi possivel realizar cadastro', 'Usuario');
        }
      }
    );
  }
  redirectToUserList() {
    this.router.navigateByUrl('/users/list');
  }


}
