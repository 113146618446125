<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-email text-blue mr-4 font-size-20"></i>
  <h1>Modelos de Mensagem</h1>
</div>

<div class="row">
  <div class="col-md-6 col-lg-6 col-xl-3">
    <div class="media widget-media p-4 bg-white border border-light rounded">
      <i class="mdi mdi-file-edit text-blue mr-4"></i>
      <div class="media-body align-self-center">
        <h4 class="text-primary mb-2" *ngIf="!load">
          {{ this.totalItems }}
        </h4>
        <p>Cadastrados</p>
      </div>
    </div>
  </div>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#cadastroNovoModelo"
      style="cursor: pointer">
      <i class="mdi mdi-plus mr-1"></i> Inserir novo modelo
    </a>
  </div>

  <!-- <div>
          <div class="dropdown d-inline-block mb-1">
            <button
              class="btn btn-outline-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-display="static"
            >
              Exportar
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style="right: 0; left: initial"
            >
              <a class="dropdown-item" style="cursor: pointer;" (click)="exportaRespostaEnviadas()">Exportar como Excel</a>
              <a class="dropdown-item" href="#">Exportar como PDF</a>
            </div>
          </div>
        </div> -->
</div>

<div class="card card-default" *ngIf="!load">
  <div class="card-header mb-0">
    <div class="row w-100">
      <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
        <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
          placeholder="Busca rápida" />
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
            <i class=" mdi mdi-magnify font-size-20"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
  <div class="card-body">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Descrição</th>
          <th scope="col">Mensagem</th>
          <th scope="col">Ações</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of listaModelos">
        <tr>
          <td scope="row">{{ item.description }}</td>
          <td scope="row">{{ item.content }}</td>
          <td>
            <div class="dropdown widget-dropdown">
              <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">
                <li class="dropdown-item">
                  <a (click)="openModal(item)" data-toggle="modal" data-target="#confirmaDeleteModelo"
                    style="cursor: pointer">Excluir</a>
                </li>

                <li class="dropdown-item">
                  <a (click)="carregaDadosModeloId(item.id)" data-toggle="modal" data-target="#cadastroNovoModelo"
                    style="cursor: pointer">Editar</a>
                </li>

                <!-- <li class="dropdown-item">
                      <a
                      [routerLink]="['/contacts/edit', item.id]"
                      style="cursor: pointer"
                      >Editar</a
                    >
                    </li> -->
              </ul>
            </div>
          </td>
          <td>
            <div class="btn-group"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="form-inline" *ngIf="!load">
  <ul class="pagination">
    <li [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" style="cursor: pointer" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li [ngClass]="
        this.hasNextPage ? 'page-item' : 'page-item disabled'
      ">
      <a class="page-link" style="cursor: pointer" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</div>

<!-- modal excluir -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDeleteModelo" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse modelo ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill"
          (click)="excluirModeloSelecionado(this.modeloSelecionado.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- inserir/editar modelo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="cadastroNovoModelo" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" *ngIf="!modeloSelecionado?.id">
          Novo modelo
        </h5>
        <h5 class="modal-title center" *ngIf="modeloSelecionado?.id">
          Editar modelo
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Descrição</label>
          <input type="file" class="form-control" [(ngModel)]="descricao" type="text" />
        </div>

        <div class="form-group">
          <label>Mensagem: {{ charCount }}</label>

          <textarea class="form-control" rows="5" id="contentMessage" [(ngModel)]="conteudo"
            (input)="CountCharSMS()"></textarea>
        </div>
        <div class="form-group">
          <select (change)="SelectParameter($event.target.value)" class="form-control">
            <option value="0">Selecione...</option>
            <option *ngFor="let item of listaParamentros; let i = index" [value]="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button *ngIf="modeloSelecionado?.id" type="button" class="btn btn-primary btn-pill"
          (click)="editarModelo(modeloSelecionado)" data-dismiss="modal">
          Editar
        </button>
        <button *ngIf="!modeloSelecionado?.id" type="button" class="btn btn-primary btn-pill" (click)="gravarModelo()"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-primary" id="botaoAvisoMessageLimit" data-toggle="modal" style="display:none"
  data-target="#avisoMessageModeloLimit"> ok</button>

<!-- aviso de limite execedio -->
<div class="modal fade" id="avisoMessageModeloLimit" tabindex="-1" aria-labelledby="exampleModalLabel"
  style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Tamanho da mensagem excedida
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Você excedeu o limite de {{ limitMessage }} caracteres
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>