<div class="row">
  <div class="col-md-12">
    <div class="card card-default">
      <div class="card-header d-block border-bottom justify-content-left">
        <h4>Pesquisa de número</h4>
        <p style="color:#808080; font-size:small">Insira o número ao qual deseja buscar o histórico e descobrir a operadora</p>
      </div>
      <div class="card-body">
        <div class="row">
          
        </div>
        <br/>
        <div class="row">
          <div class="col-lg-4">
            <label class="text-dark font-weight-medium" for="">Número:*</label>
            <div class=" mb-4">
              <input
                class="form-control"
                [(ngModel)]="numero"
                mask="(00) 00000-0000"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer bg-white">
        <div class="row">
          <div col-12 col-xs-6 col-md-4 col-lg-3>
            <button
              data-toggle="tooltip" title="Pesquisar Número"
              (click)="carregaListaDados()"
              class="btn btn-block btn-primary"
            >
              Pesquisa número
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12 px-0" *ngIf="listaSmsNumero.length">
      <div class="card card-default">
        <div class="card-body pt-0 pb-5">
          <table
            class="table table-responsive table-responsive-large table-hover"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th scope="col">Número</th>
                <th scope="col">Operadora</th>
                <th scope="col">Data Envio</th>
                <th scope="col">MessageId</th>
                <th scope="col">Texto da mensagem enviada</th>
                <th scope="col">Status</th>
                <!-- <th scope="col">MessageId</th> -->
                <!--<th scope="col">Mensagem recebida</th>-->
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody *ngIf="listaSmsNumero.length">
              <tr *ngFor="let smsNumero of listaSmsNumero">
                <td scope="row">{{ smsNumero.numero }}</td>
                <td scope="row">{{ smsNumero.operadora }}</td>
                <td >{{ smsNumero.data_envio }}</td>
                <td scope="row">{{ smsNumero.message_id }}</td>
                <td>{{ smsNumero.msg_enviada }}</td>
                <td>{{ smsNumero.status }}</td>
                <!--<td>{{ smsNumero.msg_recebida }}</td>-->
                <td>
                  <button
                    data-toggle="tooltip" title="Visualizar Mensagem"
                    data-toggle="modal"
                    data-target="#operadorSmsNumeroDetalhadoSms"
                    style="cursor: pointer"
                    (click)="detalharPhone(smsNumero.smsId)"
                    class="btn btn-sm btn-primary"
                  >
                    <span class="mdi mdi-magnify font-size-20"></span>
                  </button>
                </td>
              </tr>
            </tbody>

            <tfoot *ngIf="!listaSmsNumero.length">
              <tr>
                <td colspan="4" style="text-align: center">
                  Não encontrado registros
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- operadora numero aviso -->
<div class="modal fade"
     id="operadorSmsNumero"
     tabindex="-1"
     aria-labelledby="exampleModalLabel"
     style="display: none"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" *ngIf="listaSmsNumero.length">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="exampleModalLabel">Operadora</h5>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>Número:</strong> {{ listaSmsNumero.length ? listaSmsNumero[0].numero : "" }}
        <br />
        <br />
        <strong>Operadora:</strong>
        {{ listaSmsNumero.length ? listaSmsNumero[0]?.operadora : "" }}
      </div>
      <div class="modal-footer">
        <button
                data-toggle="tooltip" title="Fechar Modal"
                type="button"
                class="btn btn-primary btn-pill"
                data-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
  <div class="modal-dialog modal-dialog-centered" role="document" *ngIf="!listaSmsNumero.length">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Sem Resultados
        </h5>
      </div>
      <div class="modal-body" style="white-space:pre-wrap">
        <p>O Relatório não trouxe resultados</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>

      </div>
    </div>
  </div>
</div>

<!-- detalhes do numero -->
<div
  class="modal fade"
  id="operadorSmsNumeroDetalhadoSms"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="exampleModalLabel">Mensagem</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="this.numeroDetalhado">
        <b>MessageId</b>:&nbsp; {{ this.numeroDetalhado.message_id }}
        <br />
        <b>Texto</b>: &nbsp;{{ this.numeroDetalhado.texto }}
        <br />
        <b>Data Envio</b>: &nbsp;{{ this.numeroDetalhado.data_envio }}
        <br />
        <br />
        <b>Quantidade de números</b>:&nbsp; {{ this.numeroDetalhado.qtn_n }}
        <br />
        <b>Quantidade de sucessos</b>:&nbsp;
        {{ this.numeroDetalhado.quantidade_s }}
        <br />
        <b>Quantidade de erros</b>: &nbsp;{{
          this.numeroDetalhado.quantidade_E
        }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-pill"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
