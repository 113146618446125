import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { MessageService } from '../menssage.service';
import { ListagemSmsRespostas } from '../model_message/ListagemSmsRespostas.model';
import { Mensagem } from '../model_message/Mensagem_model';

@Component({
  selector: 'app-received-sms',
  templateUrl: './received-sms.component.html',
  styleUrls: ['./received-sms.component.scss']
})
export class ReceivedSmsComponent implements OnInit {

  constructor(private messageService: MessageService, private toastr: ToastrService, private clientService: UserService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;
  userInformation: any;

  listReceivedSms: any;
  listReceivedSmsGrouped: any[];
  TotalReceived: any;

  msgData: any;

  ReplyContent: any;

  public PeriodoDe = '';

  public PeriodoAte = '';

  msgResposta: any;

  contentResponsa: string;

  execelEnvidasRecebidas = [];

  ngOnInit() {
    this.listagemRespostas();
    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.content.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    this.page = page;
    this.listReceivedSms = [...this.paginate(this.TotalReceived.lista, this.pageSize, this.page)];

    this.listReceivedSmsGrouped = this.GroupBy(this.listReceivedSms, 'messageId');


    let aux = [];

    //FOR DICT
    for (const [key, value] of Object.entries(this.listReceivedSmsGrouped)) {
      aux.push({ type: "message", mtContent: this.listReceivedSmsGrouped[key][0].mtContent, messageId: this.listReceivedSmsGrouped[key][0].messageId });

      for (let j = 0; j < this.listReceivedSmsGrouped[key].length; j++) {

        aux.push(Object.assign({ type: "sms" }, this.listReceivedSmsGrouped[key][j]));
      }
    }
    this.listReceivedSmsGrouped = aux.sort((x,y) => x.messageId - y.messageId);

    console.log("AUX:", aux.sort((x, y) => y.messageId - x.messageId));

    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemRespostas(page: number = 1) {
    this.load = true;

    this.page = page;


    this.messageService.ListaRespostaRecebidadas(this.page, this.searchQuery).subscribe(
      (res: ListagemSmsRespostas) => {
        this.TotalReceived = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalReceived.lista.length;
        this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate(this.page);
        this.UpdatePages();


      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }

  GroupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    //console.log(groupBy(['one', 'two', 'three'], 'length')); <- EXEMPLO PASSANDO O ARRAY E ESCOLHENDO A KEY
  };

  openModal(ct: any) {
    this.msgData = ct;


  }

  closeModal() {
    this.msgData = null;
    this.contentResponsa = null;
    this.msgResposta = null;
  }

  openModalResponder(smsid: number) {
    this.messageService.loadSmsResposta(smsid).subscribe(
      (res) => {
        this.msgResposta = res;


      },
      (erros) => {
        console.log(erros);


      }
    );
  }

  excluirResposta(id: number) {
    this.messageService.deleteResposta(id).subscribe(
      (res) => {
        this.listagemRespostas();

        this.toastr.success('Excluido com sucesso', 'Resposta');



      },
      (erros) => {
        console.log(erros);


      }
    );
  }

  adicionarAblackList(numero: string) {
    this.messageService.addNumberBlackList(numero).subscribe(
      (res) => {
        this.listagemRespostas();

        this.toastr.success('Adicionado a lista negra', 'Resposta');



      },
      (erros) => {
        console.log(erros);


      }
    );
  }

  respondeSms() {
    const msg: Mensagem = { numbers: this.msgResposta.number, Content: this.contentResponsa, isFlash: false, Sheduled: null, From: null };
    this.messageService.SendMessagem(msg).subscribe(
      (data: any) => {
        console.log(data);
        //this.toastr.success('Enviado com sucesso', 'Mensagem');
        document.getElementById('botaoConfirmacaoEnvio').click();
        this.contentResponsa = null;
        this.closeModal();

      },
      (erro: any) => {
        this.toastr.error('Não foi possivel realizar envio', 'Mensagem');

      }
    );
  }

  selectMessage(msg) {
    this.msgResposta = msg;
  }



  exportaRespostaEnviadas(startDate, endDate) {
    this.messageService.loadListaSmsResposta(startDate, endDate).subscribe(
      (res: any) => {
        this.execelEnvidasRecebidas = res;
        const blob = new Blob([res]);
        let dateFromArray = startDate.split("-");
        let dateToArray = endDate.split("-");
        startDate = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
        endDate = dateToArray[2] + dateToArray[1] + dateToArray[0];

        saveAs(blob, this.userInformation.clientName + "_Mensagens_Recebidas_Periodo_" + startDate + "a" + endDate + ".xlsx");

        //this.messageService.exportAsExcelFile(this.execelEnvidasRecebidas, 'msg_recebidas_envidas');

      },
      (erros) => {
        console.log(erros);


      }
    );
  }

  onDetailToggle(event) {
  }
  toggleExpandGroup(group) {
    // this.table.groupHeader.toggleExpandGroup(group);
  }

}
