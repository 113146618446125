import { OtherContat, User } from './User.model';
import { UsersEditService } from './users-edit.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DadosCep } from '../register/dadosCep.model';
import { UserService } from 'src/app/shared/user.service';
import { ListaEstadosCidade } from '../register/listaEstadosCidade.model';
import { ListaProviders } from '../register/listaProviders.model';
import * as moment from 'moment'; import 'moment/locale/pt-br';
declare let $: any;


@Component({
  selector: 'app-edicao',
  templateUrl: './edicao.component.html',
  styleUrls: ['./edicao.component.scss']
})
export class EdicaoComponent implements OnInit {

  constructor(public userService: UserService, private router: Router, private userEdicaoService: UsersEditService, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private toastr: ToastrService, private route: Router) {
    // this.criarForm()
  }

  usuario: User;
  userId: number;
  userForm: FormGroup;
  Estados: ListaEstadosCidade;
  Cidades: string[];
  load: boolean;
  providers: ListaProviders[];
  ResponsibleName: string;
  Email: string;
  isUser: boolean;


  documentMask: object = {
    1: '000.000.000-00',
    2: '00.000.000/0000-00'
  };


  ngOnInit() {
    this.carregaUser();
    this.carregaEstados();
    this.carregarFornecedores();
    this.carregarDatePicker();
    // console.log(this.userForm.controls.OtherContats);
  }


  carregarFornecedores() {
    this.load = true;
    this.userService.getProvidersList().subscribe(
      (sucesso: ListaProviders[]) => {
        this.providers = sucesso;
        this.load = false;
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar listagem', 'Fornecedores');
        this.load = false;

      }
    );
  }

  carregarDatePicker(): void {
    $('#datetimepicker1').datetimepicker({
      locale: moment.locale('ru'),
      format: 'DD/MM/YYYY HH:mm:ss',
      date: moment(),
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });
  }

  carregaUser() {
    // this.spinner.show();
    this.isUser = true;
    this.userId = this.activatedRoute.snapshot.params.id;

    this.userEdicaoService.getById(this.userId).subscribe(
      (data) => {
        this.usuario = data;
        this.ResponsibleName = this.usuario.fullName;
        this.Email = this.usuario.email;

        this.userForm = this.fb.group({
          Id: this.userId,
          Email: [{ value: this.usuario.email, disabled: false }],
          Number: [{ value: this.usuario.number, disabled: false }],
          FullName: [{ value: this.usuario.fullName, disabled: false }],
          UserName: [{ value: this.usuario.userName, disabled: false }],
          Password: [''],
          ConfirmPassword: [''],
          Role: [{ value: this.usuario.role, disabled: true }, ],
          TipoCliente: [this.usuario.tipoCliente, ],
          Documento: [{ value: this.usuario.documento, disabled: true },],
          CompanyName: [{ value: this.usuario.companyName, disabled: false }],
          ResponsibleName: [{ value: this.usuario.responsibleName, disabled: false }],
          OtherContats: this.fb.array([this.initOtherContact()]),
          Bairro: [{ value: this.usuario.bairro, disabled: false }],
          Cep: [{ value: this.usuario.cep, disabled: false }, ],
          Endereco: [{ value: this.usuario.endereco, disabled: false }, ],
          Numero: [{ value: this.usuario.numero, disabled: false }, ],
          Estado: [{ value: this.usuario.estado, disabled: false }, ],
          Cidade: [{ value: this.usuario.cidade, disabled: false }, ],
          RazaoSocial: [{ value: this.usuario.companyName, disabled: false }],
          Complemento: [{ value: this.usuario.complemento, disabled: false }],
          DescricaoSaldo: [{ value: this.usuario.descricaoSaldo, disabled: false }],

          Site: [{ value: this.usuario.site, disabled: false }],
          ResponsibleNameGeral: [{ value: this.usuario.responsibleNameGeral, disabled: false }, ],
          ResponsibleNameContato: [{ value: this.usuario.responsibleNameContato, disabled: false }, ],
          NomeFullUsuario: [{ value: this.usuario.nomeFullUsuario, disabled: false }, ],
          EmailUsuario: [{ value: this.usuario.emailUsuario, disabled: false }],

        }, { validator: this.ValidRegistration });

        this.setFormListaContato(this.usuario.otherContats);


        this.isUser = false;

        console.log(this.userForm);

      },
      (erros) => {
        console.log(erros);
        // this.spinner.hide();
        this.isUser = false;
        this.toastr.error('Carregar o usuario', 'Ocorreu um erro ao carregar usuario');
        this.criarForm();

      }
    );
  }

  criarForm() {
    this.userForm = this.fb.group({
      Email: ['' ],
      Number: [''],
      FullName: [''],
      UserName: [''],
      Password: [''],
      ConfirmPassword: [''],
      Role: [''],
      TipoCliente: [''],
      Documento: [''],
      CompanyName: [''],
      ResponsibleName: [''],
      OtherContats: new FormArray(
        [
          this.initOtherContact()
        ]
      ),
      Bairro: [''],
      Cep: [''],
      Endereco: [''],
      Numero: [''],
      Estado: [''],
      Cidade: [''],
      // IdFornecedorPreferencial: [""],
      // Quantidade: ["", Validators.required],
      // Validade: [""],
      DescricaoSaldo: [''],
    }, { validator: this.ValidRegistration });

  }


  ValidRegistration(fb: FormGroup) {
    if (fb.get('TipoCliente')) {


      if (fb.get('TipoCliente').value === '2') {
        if (!fb.get('RazaoSocial').value) {
          fb.get('RazaoSocial').setErrors({ required: true });
        } else {
          fb.get('RazaoSocial').setErrors(null);

        }

      }
    }

    const ConfirmPassword = fb.get('ConfirmPassword');
    if (ConfirmPassword.errors == null || 'passwordMismatch' in ConfirmPassword.errors) {
      if (fb.get('Password').value != ConfirmPassword.value) {
        ConfirmPassword.setErrors({ passwordMismatch: true });
      } else {
        ConfirmPassword.setErrors(null);
      }
    }
  }


  OnSubmitEdit() {
    this.userEdicaoService.editUser(this.userId, this.userForm.value).subscribe(
      (res: any) => {
        this.toastr.success('Alteração de Cliente!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/clients/list');
        }, 2000);
      },
      (erro: any) => {
        console.log('Erro ao alterar  usuario', erro);
        this.toastr.error('Erro de sttus', 'Erro ao alterar usuario');
      }
    );

  }


  carregaEstados() {
    this.userService.buscaEstados().subscribe(
      (retorno: ListaEstadosCidade) => {
        this.Estados = retorno;
        this.load = true;
      },
      err => {
        this.toastr.error('Não encontrado estados!', 'Estados');

      }
    );
  }

  selecionaCidade(estado: any, atributo = 'nome') {
    this.Cidades = this.Estados.estados[this.buscaArrayComAtributo(this.Estados.estados, atributo, estado)].cidades;
  }

  buscaArrayComAtributo(array: any, attr: string, value: any) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  buscaCep(cep: string) {
    if (/\d{2}\.\d{3}\-\d{3}/.test(cep)) {
      // alert('Cep é válido!');
      const cepFormatado = cep.replace(/\.|\-/g, '');

      this.userService.getAddressCep(cepFormatado).subscribe(
        (res: DadosCep) => {
          this.userForm.patchValue({
            Bairro: res.bairro,
            Endereco: res.logradouro,
            Estado: res.uf,
            Cidade: res.localidade
          });
          this.selecionaCidade(res.uf, 'sigla');
          // this.userService.UserRegistrationModel.controls.Cidade.setValue(this.selecionaCidade(res.uf, "sigla"));
          // this.userService.UserRegistrationModel.controls.Cidade.setValue(this.selecionaCidade(res.uf, "sigla"));
        },
        (erro: any) => {
          this.toastr.error('Não encontrado dados baseado no cep informado, preencha-os!', 'Cep');
        }

      );
    } else {
      this.toastr.error('Informe um cep válido!', 'Cep');

    }


  }


  initOtherContact(): FormGroup {

    this.isUser = false;
    return this.fb.group({
      FullName: new FormControl(''),
      Email: new FormControl(''),
      PhoneOne: new FormControl(''),
      PhoneTwo: new FormControl(''),
      Cargo: new FormControl(''),
    });

  }

  setFormListaContato(listaContato: OtherContat[]) {
    if (listaContato.length) {

      const arrayContacts = this.userForm.get('OtherContats') as FormArray;

      arrayContacts.removeAt(0);

      for (const contato of listaContato) {

        arrayContacts.push(this.addItemContato(contato));

      }



    } else {
      this.initOtherContact();
    }
  }

  addItemContato(contato: any): FormGroup {
    return this.fb.group({
      FullName: new FormControl(contato.fullName),
      Email: new FormControl(contato.email),
      PhoneOne: new FormControl(contato.phoneOne),
      PhoneTwo: new FormControl(contato.phoneTwo),
      Cargo: new FormControl(contato.cargo),
    });
  }


  addContact() {
    const controls = <FormArray>this.userForm.controls['OtherContats'];
    controls.push(this.initOtherContact());

  }


  removeContact(i: number) {
    const controlContacts = <FormArray>this.userForm.controls['OtherContats'];
    controlContacts.removeAt(i);
  }

  redirectToUserList() {
    this.router.navigateByUrl('/users/list');
  }

}
