import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from '../../reports/Relatorios.service';
import { UserService } from '../../shared/user.service';
import { MessageService } from '../menssage.service';
import { ListaSms } from '../model_message/ListaSms.model';

@Component({
  selector: 'app-historic-sms',
  templateUrl: './historic-sms.component.html',
  styleUrls: ['./historic-sms.component.scss']
})
export class HistoricSmsComponent implements OnInit {

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private clientService: UserService, private toastr: ToastrService, private msgEnvidaService: MessageService, public relatorioService: RelatoriosService) { }

  load = false;

  mensagensEnviadas: ListaSms[] = [];
  mensagensEnviadasBackup: ListaSms[] = [];

  paramsKey = [];
  searchQuery = '';

  userInformation: any;


  ngOnInit() {
    this.modalDetalharMensagem();

    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);
  }

  modalDetalharMensagem() {
    this.load = true;
    this.msgEnvidaService.DetalharMensagem(Number(this.activatedRoute.snapshot.params.id), this.searchQuery).subscribe(
      (res: any) => {
        if (res.length && res[0].paramsKey !== null) {
          this.paramsKey = res[0].paramsKey;
          //deletando o primeiro parametro q é o numero
          this.paramsKey.splice(0, 1);
        }
        this.mensagensEnviadas = res;

        this.mensagensEnviadasBackup = this.mensagensEnviadas.map(x => ({ ...x }));

        //deleteando o primeiro parametro q é o numero
        this.mensagensEnviadas.forEach(msg => msg.paramsValues.splice(0, 1))

        if (this.mensagensEnviadas.length > 150) {
          this.mensagensEnviadas.splice(150, this.mensagensEnviadas.length);
        }
        this.load = false;
      },
      (erros) => {
        console.log(erros);
      }
    );
  }

  buscarQuery() {
    this.mensagensEnviadas = this.mensagensEnviadasBackup.filter(x => x.number.includes(this.searchQuery) || x.content.toLowerCase().includes(this.searchQuery.toLowerCase()))
  }
  exportarMensagens(isCsv) {

    this.relatorioService.exportAsExcelFileRelatorio(this.mensagensEnviadasBackup, 'relatorio_acompanhamento_envio_numeros', "", "", isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      if (isCsv) {
        saveAs(blob, this.userInformation.clientName + "_Id_" + this.activatedRoute.snapshot.params.id + ".csv");
      } else {
        saveAs(blob, this.userInformation.clientName + "_Id_" + this.activatedRoute.snapshot.params.id + ".xlsx");
      }
    });

    //this.msgEnvidaService.loadExportExcelDetailsSms(Number(this.activatedRoute.snapshot.params.id)).subscribe(
    //  (res: any) => {
    //    this.msgEnvidaService.exportAsExcelFile(res, 'historico_detalhes_sms');
    //  },
    //  (erros) => {
    //    console.log(erros);
    //  }
    //);
  }


}
