import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListagemGrupos } from './listagem-grupos/listagemGrupos.model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class GruposService {


  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;

  getContatcsGeral(page: number = 1, query: string = ''): Observable<ListagemGrupos> {
    return this.http.get<ListagemGrupos>(`${this.baseUrl}groups/getgroups?page=${page}&query=${query}`);
  }

  createGrupo(grupo: any) {
    return this.http.post(`${this.baseUrl}groups/postgroup`, grupo);
  }

  getGrupoId(id: number) {
    return this.http.get(`${this.baseUrl}groups/getgroup/${id}`);
  }

  editGrupo(id: number, grupo: any) {
    return this.http.put(`${this.baseUrl}groups/putgroup/${id}`, grupo);

  }

  delGrupo(id: number) {
    return this.http.delete(`${this.baseUrl}groups/deletegroup/${id}`);

  }


  ImportExcel(Arquivo: FormData) {
    return this.http.post(`${this.baseUrl}groups/importgroupfileexcel/excel`, Arquivo);
  }

  CarregarContatoGrupo(id: number) {
    return this.http.get(`${this.baseUrl}groups/exportcontatingroup/${id}`, { responseType: 'blob' });

  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  alterarStatusGrupo(id: number, status: boolean) {
    return this.http.get(`${this.baseUrl}groups/alterastatusgrupo/${id}/${status}`);

  }

}
