import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ListagemProviders } from '../model_providers/listagemProviders.model';
import { ProviderService } from '../provider.service';
import * as moment from 'moment'; import 'moment/locale/pt-br';
import { UserService } from '../../shared/user.service';
declare let $: any;


@Component({
  selector: 'app-listagem-fornecedores',
  templateUrl: './listagem-fornecedores.component.html',
  styleUrls: ['./listagem-fornecedores.component.scss']
})
export class ListagemFornecedoresComponent implements OnInit {

  constructor(private providerService: ProviderService, private toastr: ToastrService, private clientService: UserService) { }


  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  pageHistoric = 1;
  neighborPagesHistoric = [];
  totalItemsHistoric = 0;
  pageSizeHistoric: any = '5';
  pageCountHistoric = 0
  hasPreviousPageHistoric = false;
  hasNextPageHistoric = false;

  load = false;

  TotalFornecedores: any;
  fornecedoresDados: any;

  fornecedorSelecionado: any;
  userInfo: any;

  fornecedorForm: FormGroup;

  TotalHistorico: any;
  listaRemocoes = [];


  quantidade = 1;
  validade: any;

  ngOnInit() {
    this.listagemFornecedorsCadastrado();
    this.listaRemocoes = [];

    this.clientService.GetUserInformation().subscribe(res => this.userInfo = res)
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.label.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }
  paginateHistoric(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.fornecedoresDados = this.paginate(this.TotalFornecedores.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }
  getPaginateHistoric(page: number = 1) {
    this.pageCountHistoric = Math.floor((this.totalItemsHistoric + parseInt(this.pageSizeHistoric)) / parseInt(this.pageSizeHistoric));
    console.log("TOTAL:", this.totalItemsHistoric)
    console.log("PAGECOUNT", this.pageCountHistoric)
    this.pageHistoric = page;
    this.listaRemocoes = this.paginateHistoric(this.TotalHistorico, this.pageSizeHistoric, this.pageHistoric);
    console.log(this.listaRemocoes);
    console.log(this.TotalHistorico);

    this.UpdatePagesHistoric();
  }

  UpdatePagesHistoric() {
    var actualPageCount = this.pageCountHistoric;
    this.neighborPagesHistoric = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesHistoric.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageHistoric - 1) - ratioLeft < 0) {
        let result = ((this.pageHistoric - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageHistoric) + ratioRight > actualPageCount) {
        let result = ((this.pageHistoric) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageHistoric - ratioLeft; i <= this.pageHistoric; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.pageHistoric + 1; i <= this.pageHistoric + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemFornecedorsCadastrado(page: number = 1, active?) {
    this.load = true;
    this.page = page;


    this.providerService.ListAllProviders(this.page, this.searchQuery, active).subscribe(
      (res: ListagemProviders) => {
        this.TotalFornecedores = res;
        this.load = false;
        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalFornecedores.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();


      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }

  openModal(fornecedor: any) {
    this.fornecedorSelecionado = fornecedor;


    $('#datetimepicker3').datetimepicker({
      locale: moment.locale('ru'),
      format: 'DD/MM/YYYY HH:mm:ss',
      date: moment(),
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });
  }

  changeStatus(id: number, status: boolean) {

    this.providerService.alteraStatusProvider(id, status).subscribe(
      (res: object) => {
        this.closeModal();
        this.listagemFornecedorsCadastrado();
        this.toastr.success('Alteração de status!', 'Concluido');
      },
      (erro: any) => {
        this.toastr.error('Erro de status', 'Erro ao alterar status fornecedor');
      }
    );

  }


  closeModal() {
    this.fornecedorSelecionado = null;
    this.listaRemocoes = [];
    this.quantidade = 0;

  }


  modalRemocaoSaldo(fornecedorId: number) {

    this.providerService.getRemocoesProvider(fornecedorId).subscribe(
      (sucesso: any) => {
        this.TotalHistorico = sucesso;
        console.log("oi");

        //DADOS PAGINAÇÃO
        this.totalItemsHistoric = this.TotalHistorico.length;
        this.pageCountHistoric = Math.floor((this.totalItemsHistoric + this.pageSizeHistoric) / this.pageSizeHistoric);
        this.hasPreviousPageHistoric = this.pageHistoric == 1 ? false : true;
        this.hasNextPageHistoric = this.pageHistoric == this.pageCountHistoric ? false : true;

        this.getPaginateHistoric();
      },
      (erro: any) => {
        this.toastr.error('Ocorreu um erro ao carregar', 'Saldos');

      }

    );

  }

  excluirSaldoSelecionado(id: number) {
    this.providerService.removeSaldoProvider(id).subscribe(
      (res: object) => {


        this.toastr.success('Saldo removido!', 'Saldo');
        this.listagemFornecedorsCadastrado();

      },
      (erro: any) => {
        this.toastr.error('Erro ao remover saldo', 'Saldo');
      }
    );

  }

  AdicionarSaldoAoFornecedor(fornecedor) {

    const saldo = { QuantityBalance: this.quantidade, ValidityBalance: this.validade, IdProvider: fornecedor.id };

    this.providerService.AddSaldo(saldo).subscribe(
      (sucesso: any) => {

        this.toastr.success('Adicionaldo saldo com sucesso', 'Saldo');
        this.listagemFornecedorsCadastrado();
      },
      (erro) => {
        if (typeof erro.error === 'string') {
          this.toastr.error(erro.error, 'Saldo');

        } else {

          this.toastr.error('Ocorreu um erro ao adicionar saldo', 'Saldo');
        }


      }
    );

  }
  ExportProvidersExcel(isCsv) {
    this.providerService.ExportProvidersExcel(this.fornecedoresDados.lista, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let currentDate = new Date(Date.now());
      let currentDateFormat = currentDate.toLocaleDateString();
      currentDateFormat = currentDateFormat.replace("/", "");
      currentDateFormat = currentDateFormat.replace("/", "");
      if (isCsv) {
        saveAs(blob, this.userInfo.clientName + "_Fornecedores" + ".csv");
      } else {
        saveAs(blob, this.userInfo.clientName + "_Fornecedores" + ".xlsx");
      }
    })
  }















}
