<div class="card card-default">
  <!--<div class="card-header justify-content-left">
      <h2 class="card-title" style="text-align: left">Meu perfil</h2>
    </div>-->
  <div class="row no-gutters">
    <div class="col-lg-12 col-xl-12">
      <div class="profile-content-right profile-right-spacing">
        <ul *ngIf="carregadoDisponivel" class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
              aria-controls="profile" aria-selected="true">Perfil</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings"
              aria-selected="false">Notificações</a>
          </li>

          <li class="nav-item active" *ngIf="CheckIfRole(['Owner'])">
            <a class="nav-link" id="historicoLogin-tab" data-toggle="tab" href="#historicoLogin" role="tab"
              aria-controls="historicoLogin" aria-selected="false" (click)="carregarMeuHistoricoLogin()">Histórico de
              login</a>
          </li>

          <li class="nav-item" *ngIf="CheckIfRole(['Owner'])">
            <a class="nav-link" id="hstActiosUsers-tab" data-toggle="tab" href="#hstActiosUsers" role="tab"
              aria-controls="hstActiosUsers" (click)="carregarMeuHistoricoAtividades()" aria-selected="false">Histórico
              de atividades</a>
          </li>
        </ul>
        <div class="tab-content px-3 px-xl-5" id="myTabContent">
          <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab"
            *ngIf="carregadoDisponivel">
            <div class="tab-pane-content mt-5">
              <form ngNativeValidate [formGroup]="perfilForm" autocomplete="off" (submit)="OnSubmitEditPerfil()">
                <h3>Detalhes da conta</h3>
                <br />

                <div class="row mb-2">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="firstName">ID</label>
                      <input type="text" class="form-control" formControlName="Id" />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="lastName">Cliente</label>
                      <input type="text" class="form-control" formControlName="Cliente" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="lastName">Usúario</label>
                      <input type="text" class="form-control" formControlName="Usuario" />
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="firstName">Criado em</label>
                      <input type="text" class="form-control" formControlName="Criando_Em" />
                    </div>
                  </div>
                </div>
                <hr />

                <h3>Informações gerais</h3>
                <br />
                <div class="row mb-2">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label>Tipo pessoa</label>
                      <input type="text" class="form-control" formControlName="TipoPessoa" />
                    </div>
                  </div>

                  <div class="col-lg-4" *ngIf="perfilForm.get('TipoPessoa').value === 'Fisica'">
                    <div class="form-group">
                      <label>Cpf</label>
                      <input formControlName="Documento" placeholder="Seu Cpf" class="form-control" type="text" [mask]="
                            documentMask[perfilForm.get('TipoPessoa').value]
                          " />
                    </div>
                  </div>

                  <div class="col-lg-4" *ngIf="perfilForm.get('TipoPessoa').value === 'Juridica'">
                    <div class="form-group">
                      <label>Cnpj</label>
                      <input formControlName="Documento" placeholder="Seu Cnpj" class="form-control" type="text" [mask]="
                            documentMask[perfilForm.get('TipoPessoa').value]
                          " />
                    </div>
                  </div>

                  <div class="col-lg-4" *ngIf="perfilForm.get('TipoPessoa').value">
                    <div class="form-group">
                      <label *ngIf="
                            perfilForm.get('TipoPessoa').value === 'Juridica'
                          ">Nome fantasia
                      </label>
                      <label *ngIf="
                            perfilForm.get('TipoPessoa').value === 'Fisica'
                          ">Nome *</label>
                      <input formControlName="Nome" class="form-control" type="text" />
                    </div>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-4" *ngIf="perfilForm.get('TipoPessoa').value === 'Juridica'">
                    <div class="form-group">
                      <label>Razão social</label>
                      <input formControlName="RazaoSocial" class="form-control" type="text" />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Site</label>
                      <input formControlName="Site" class="form-control" type="text" />
                    </div>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>Endereço de e-mail</label>
                      <input formControlName="Email" class="form-control" type="text" />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>Telefone</label>
                      <input formControlName="Telefone" class="form-control" type="text" />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>Nome responsável</label>
                      <input formControlName="Responsavel" class="form-control" type="text" />
                    </div>
                  </div>
                </div>
                <hr />

                <h3>Endereço</h3>
                <br />
                <div class="row mb-2">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>País</label>
                      <select style="cursor: not-allowed" class="form-control" disabled>
                        <option selected>Brasil</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>Cep</label>
                      <input formControlName="Cep" class="form-control" type="text" />
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>Endereço</label>
                      <input formControlName="Endereco" class="form-control" type="text" />
                    </div>
                  </div>
                </div>


                <div class="row mb-2">
                  <div class="col-lg-2">
                    <div class="form-group">
                      <label>Número</label>
                      <input formControlName="Numero" class="form-control" type="text" />
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div class="form-group">
                      <label>Estado</label>
                      <input formControlName="Estado" class="form-control" type="text" />
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div class="form-group">
                      <label>Cidade</label>
                      <input formControlName="Cidade" class="form-control" type="text" />
                    </div>
                  </div>
                </div>

                <div class="row mb-2">

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Bairro</label>
                      <input formControlName="Bairro" class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Complemento</label>
                      <input formControlName="Complemento" class="form-control" type="text" />
                    </div>
                  </div>

                </div>

                <div class=" card-footer bg-white d-flex justify-content-end">
                  <button disabled type="submit" class="btn btn-primary mb-2 btn-pill">
                    Atualizar perfil
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">
            <div class="tab-widget mt-5">

              <!-- <div class="row mb-2"> -->

              <!-- <div class="col-lg-6"> -->
              <div class="form-group">
                <label>Receber notificação por sms &nbsp;</label>
                <label class="switch switch-icon switch-primary switch-pill form-control-label">
                  <input type="checkbox" (change)="alterarNotificacaoSMS()" [checked]="SmsNotification"
                    class="switch-input form-check-input">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>

              <!-- </div> -->
              <!-- <div class="col-lg-6"> -->
              <div class="form-group">
                <label>Receber notificação por e-mail &nbsp;</label>
                <label class="switch switch-icon switch-primary switch-pill form-control-label">
                  <input type="checkbox" (change)="alterarNotificacaoEmail()" [checked]="EmailNotification"
                    class="switch-input form-check-input">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </div>
              <div class="form-group">
                <p style="color: red;">Procedimento implicará no desconto de seus créditos</p>

              </div>
              <!-- </div> -->

              <!-- </div> -->
            </div>
          </div>

          <div class="tab-pane fade" id="historicoLogin" role="tabpanel" aria-labelledby="historicoLogin-tab">
            <div class="tab-widget mt-5">
              <div class="row">
                <div class="col-12">
                  <!-- Recent Order Table -->
                  <div class="input-group ml-0 mb-3" style="width:auto;flex: 1">
                    <input type="text" (input)="getPaginateLogin()" [(ngModel)]="searchQueryLogin" class="form-control"
                      placeholder="Busca rápida" />
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                        <i class=" mdi mdi-magnify font-size-20"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card card-default">

                    <div *ngIf="!loadLogin" class="card-body pt-0 pb-3">
                      <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
                        <thead>
                          <tr>
                            <!-- <th scope="col">Id</th> -->
                            <th scope="col">Data</th>
                            <th scope="col">Usuário</th>
                            <th scope="col">Tipo de login</th>
                            <!--<th scope="col" *ngIf="userService.CanAcessRole(['Owner'])">Ip</th>-->
                            <th scope="col">Dispositivo</th>
                            <!-- <th scope="col">Navegador</th> -->
                            <th scope="col">Local (aproximado)</th>

                          </tr>
                        </thead>
                        <tbody *ngIf="listaHistoricoLogin.length > 0">
                          <tr *ngFor="let item of listaHistoricoLogin">
                            <!-- <td scope="row">{{item.id}}</td> -->
                            <td scope="row">{{item.criado}}</td>
                            <td scope="row">{{item.usuario}}</td>
                            <td scope="row">E-mail</td>
                            <!--<td *ngIf="userService.CanAcessRole(['Owner'])" scope="row">{{item.ip}}</td>-->
                            <td scope="row">{{item.dispostivo}}</td>
                            <td scope="row">{{item.local}}</td>
                            <td scope="row"></td>

                          </tr>




                        </tbody>
                        <tfoot *ngIf="listaHistoricoLogin.length == 0">
                          <tr>
                            <td>Não encontrado registros</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div class="form-inline mb-3 justify-content-between" *ngIf="!loadLogin">
                      <ul class="pagination">
                        <li [ngClass]="
                            this.hasPreviousPageLogin
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                          <a class="page-link" style="cursor: pointer" aria-label="Previous">
                            <span aria-hidden="true" class="mdi mdi-chevron-left"
                              (click)="getPaginateLogin(pageLogin - 1)"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                        </li>
                        <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesLogin;"
                          [ngClass]="{'active': pageLogin == p }">
                          <a class="page-link" (click)="getPaginateLogin(p)">{{p}}</a>
                        </li>
                        <li [ngClass]="
                          this.hasNextPageLogin
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                          <a class="page-link" style="cursor: pointer" aria-label="Next">
                            <span aria-hidden="true" class="mdi mdi-chevron-right"
                              (click)="getPaginateLogin(pageLogin + 1)"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </li>
                      </ul>
                      <ul class="pagination">
                        <div class="page-link">
                          Itens por Pagina
                          <select (change)="getPaginateLogin()" [(ngModel)]="pageSizeLogin">
                            <option [value]="10">10</option>
                            <option [value]="25">25</option>
                            <option [value]="50">50</option>
                          </select>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" id="hstActiosUsers" role="tabpanel" aria-labelledby="hstActiosUsers-tab"
            *ngIf="historicoAtsDisponivel">
            <div class="tab-widget mt-5">
              <div class="row">
                <div class="col-12">
                  <!-- Recent Order Table -->
                  <div class="input-group ml-0 mb-3" style="width:auto;flex: 1">
                    <input type="text" (input)="getPaginateAtividade()" [(ngModel)]="searchQueryAtv"
                      class="form-control" placeholder="Busca rápida" />
                    <div class="input-group-append">
                      <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                        <i class=" mdi mdi-magnify font-size-20"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card card-default">
                    <div class="card-body pt-0 pb-3">
                      <table *ngIf="!loadAtv" class="table table-responsive table-responsive-large table-hover"
                        style="width: 100%">
                        <thead>
                          <tr>
                            <th scope="col">Data</th>
                            <th scope="col">Usúario</th>
                            <!-- <th scope="col">Login</th> -->
                            <th scope="col">Ação</th>
                            <th scope="col">Sistema</th>
                            <th scope="col">Login no sistema</th>
                            <th *ngIf="CheckIfRole(['Owner'])" scope="col">Tipo usúario</th>

                          </tr>
                        </thead>
                        <tbody *ngIf="listaHistoricoAtvs.length">
                          <tr *ngFor="let item of listaHistoricoAtvs">
                            <td scope="row">{{item.criado}}</td>
                            <td scope="row">{{item.usuario}}</td>
                            <!-- <td scope="row">{{item.login}}</td> -->
                            <td scope="row">{{item.log}}</td>
                            <td scope="row">{{item.sistema}}</td>
                            <td scope="row">Username</td>
                            <td *ngIf="CheckIfRole(['Owner'])" scope="row">{{item.tipo_user}}</td>

                          </tr>
                        </tbody>
                        <tfoot *ngIf="listaHistoricoAtvs.length == 0">
                          <tr>
                            <td>Não encontrado registros</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div class="form-inline mb-3 justify-content-between" *ngIf="!loadAtv">
                      <ul class="pagination">
                        <li [ngClass]="
                            this.hasPreviousPageActivity
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                          <a class="page-link" style="cursor: pointer" aria-label="Previous">
                            <span aria-hidden="true" class="mdi mdi-chevron-left"
                              (click)="getPaginateAtividade(pageActivity - 1)"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                        </li>
                        <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesActivity;"
                          [ngClass]="{'active': pageActivity == p }">
                          <a class="page-link" (click)="getPaginateAtividade(p)">{{p}}</a>
                        </li>
                        <li [ngClass]="
                          this.hasNextPage
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                          <a class="page-link" style="cursor: pointer" aria-label="Next">
                            <span aria-hidden="true" class="mdi mdi-chevron-right"
                              (click)="getPaginateAtividade(pageActivity + 1)"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </li>
                      </ul>
                      <ul class="pagination">
                        <div class="page-link">
                          Itens por Pagina
                          <select (change)="getPaginateAtividade()" [(ngModel)]="pageSizeActivity">
                            <option [value]="10">10</option>
                            <option [value]="25">25</option>
                            <option [value]="50">50</option>
                          </select>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
</div>