import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConversaoStatusSmsPipe } from '../../message/ConversaoStatusSms.pipe';
import { MessageService } from '../../message/menssage.service';
import { ListaSms } from '../../message/model_message/ListaSms.model';
import { UserService } from '../../shared/user.service';
import { RelatoriosService } from '../Relatorios.service';


@Component({
  selector: 'app-reports-details',
  templateUrl: './reports-details.component.html',
  styleUrls: ['./reports-details.component.scss']
})
export class ReportsDetailsComponent implements OnInit {

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private clientService: UserService, private toastr: ToastrService, private msgEnvidaService: MessageService, private relatorioService: RelatoriosService, public conversaoSMSStatus: ConversaoStatusSmsPipe) { }

  public load = false;

  mensagensEnviadas: ListaSms[];

  listaResultado = []
  listaResultadoBackup = []

  paramsKey = [];
  userInformation: any;
  type: any;
  client: any;
  status: any;
  format: any;
  period: any;
  startDate: any;
  endDate: any;
  finalStartDate: any;
  finalEndDate: any;

  searchQuery: any;

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.params.type;
    this.format = this.activatedRoute.snapshot.params.format;
    this.client = this.activatedRoute.snapshot.params.client;
    this.status = this.activatedRoute.snapshot.params.status;
    this.period = this.activatedRoute.snapshot.params.period;
    this.startDate = this.period.split("a")[0];
    this.endDate = this.period.split("a")[1];

    let startDateArray = this.startDate.split("-");
    this.finalStartDate = [startDateArray[2], startDateArray[1], startDateArray[0]].join("-");

    if (this.endDate != null) {
      let endDateArray = this.endDate.split("-");
      this.finalEndDate = [endDateArray[2], endDateArray[1], endDateArray[0]].join("-");
    }




    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res)


    this.listarRelatorio()

  }

  listarRelatorio() {
    this.load = true;

    this.relatorioService.buscarRelatorioNumero(this.type, this.client, this.finalStartDate, this.endDate != null ? this.finalEndDate : this.finalStartDate, this.format, this.searchQuery, this.status).subscribe((res: any) => {
      this.load = false;

      this.listaResultado = res;
      this.listaResultadoBackup = [...this.listaResultado];

      if (this.listaResultado.length > 150) {
        this.listaResultado.splice(150, this.listaResultado.length);
      }

      this.listaResultado = this.listaResultado.reduce((accumulator, value) => accumulator.concat(value), []);


    })
  }

  buscarQuery() {
    this.listaResultado = this.listaResultadoBackup.filter(x => x.number.includes(this.searchQuery) || x.content.toLowerCase().includes(this.searchQuery.toLowerCase()))
  }

  modalDetalharMensagem(messageId, status) {
    this.load = true;

    this.msgEnvidaService.getHistoricoMensagens(1, "", status == null ? status : '').subscribe(res => console.log(res));
    this.msgEnvidaService.DetalharMensagem(Number(this.activatedRoute.snapshot.params.status), this.searchQuery).subscribe(
      (res: any) => {
        if (res.length && res[0].paramsKey !== null) {
          this.paramsKey = res[0].paramsKey;
          //deletando o primeiro parametro q é o numero
          this.paramsKey.splice(0, 1);
        }
        this.mensagensEnviadas = res;
        //deleteando o primeiro parametro q é o numero
        this.mensagensEnviadas.forEach(msg => msg.paramsValues.splice(0, 1))
        this.load = false;
      },
      (erros) => {
        console.log(erros);
      }
    );
  }

  exportarMensagens(isCsv) {
    let dateFrom: string = this.period;

    dateFrom = dateFrom.replace("-", "");
    dateFrom = dateFrom.replace("-", "");

    //let dateFromArray = dateFrom.split("-");
    //let dateToArray = dateTo.split("-");
    //dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
    //dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

    this.relatorioService.exportarRelatorioNumero(this.type, this.client, this.finalStartDate, this.endDate != null ? this.finalEndDate : this.finalStartDate, this.format, this.searchQuery, this.status, true, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let filename = '';
      if (this.conversaoSMSStatus.transform(this.status.split(",")[0]) != null) {
        filename = "_" + this.conversaoSMSStatus.transform(this.status.split(",")[0])
      }
      if (!isCsv) {
        saveAs(blob, this.userInformation.clientName + "_" + this.period + filename + ".xlsx");
      } else {
        saveAs(blob, this.userInformation.clientName + "_" + this.period + filename + ".csv");
      }
    });

    //this.msgEnvidaService.loadExportExcelDetailsSms(Number(this.activatedRoute.snapshot.params.id)).subscribe(
    //  (res: any) => {
    //    this.msgEnvidaService.exportAsExcelFile(res, 'historico_detalhes_sms');
    //  },
    //  (erros) => {
    //  }
    //);
  }


}
