import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment'; import 'moment/locale/pt-br';
import { UserService } from '../shared/user.service';

declare let Chart: any;
declare let $: any;

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent implements OnInit {
  // @ViewChild('myTable') table: any;

  constructor(public userService: UserService) { }

  txtInitial = '';
  txtFinal = '';

  userBasicData: any;



  private InitializeTimeFields() {
    const initialDate = moment().startOf('month').format('DD/MM/YYYY');
    const finalDate = moment().endOf('month').format('DD/MM/YYYY');

    $('#txtInitialDate').datetimepicker({
      locale: moment.locale('pt-br'),
      format: 'DD/MM/YYYY',
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });

    $('#txtFinalDate').datetimepicker({
      locale: moment.locale('pt-br'),
      format: 'DD/MM/YYYY',
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      },
    });

    this.txtInitial = initialDate;
    this.txtFinal = finalDate;
  }


  ngOnInit() {


  }

  CheckIfRole(roles) {
    if (this.userBasicData == undefined) {
      return false;
    }
    return this.userBasicData.roleType ? roles.includes(this.userBasicData.roleType) : false;
  }

  onDetailToggle(event) {
  }
  toggleExpandGroup(group) {
    // this.table.groupHeader.toggleExpandGroup(group);
  }
}
