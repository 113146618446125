<!-- <h3>Alteração de senha</h3> -->

<div class="row">
  <div class="col-md-12">
    <div class="card card-default">
      <div class="card-header border-bottom  d-block justify-content-left">
        <h2 class="card-title" style="text-align: left">Alteração de senha</h2>
        <p style="font-size:small">
          Para sua segurança, a senha deve ter 6 caracteres, incluindo
          pelo menos uma letra maiúscula e uma letra minúscula, um número
          e caractere especial.
        </p>
      </div>

      <form ngNativeValidate
            [formGroup]="novaSenhaForm"
            autocomplete="off"
            (submit)="OnSubmitChangePassword()">
        <div class="card-body">
          <div class="row">
            <!--<h3>
              Insira sua senha atual e nova senha para realizar alteração
            </h3>-->

            <div class="col-lg-6 mt-2">
              <label class="text-dark font-weight-medium" for="">Senha atual:*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <!--<span class="input-group-text">
                    <i class="mdi mdi-form-textbox-password"></i>
                  </span>-->
                </div>
                <input class="form-control"
                       type="password"
                       formControlName="SenhaAtual" />

              </div>
              <span class="mt-2 d-block text-danger"
                    *ngIf="
                  novaSenhaForm.get('SenhaAtual').touched &&
                  novaSenhaForm.get('SenhaAtual').errors?.required
                ">Este campo é obrigatório!</span>

              <label class="text-dark font-weight-medium" for="">Nova Senha:*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <!--<span class="input-group-text">
                    <i class="mdi mdi-form-textbox-password"></i>
                  </span>-->
                </div>
                <input class="form-control"
                       type="password"
                       formControlName="NovaSenha" />


              </div>
              <span class="mt-2 d-block text-danger"
                    *ngIf="
                  novaSenhaForm.get('NovaSenha').touched &&
                  novaSenhaForm.get('NovaSenha').errors?.required
                ">Este campo é obrigatório!</span>
              <label class="text-dark font-weight-medium" for="">Confirma a nova senha:*</label>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <!--<span class="input-group-text">
                    <i class="mdi mdi-form-textbox-password"></i>
                  </span>-->
                </div>
                <input class="form-control"
                       type="password"
                       formControlName="ConfirmaNovaSenha" />


              </div>
              <span class="mt-2 d-block text-danger"
                    *ngIf="
                  novaSenhaForm.get('ConfirmaNovaSenha').touched &&
                  novaSenhaForm.get('ConfirmaNovaSenha').errors?.required
                ">Este campo é obrigatório!</span><span class="mt-2 d-block text-danger"
                                                        *ngIf="
                  novaSenhaForm.get('ConfirmaNovaSenha').touched &&
                  novaSenhaForm.get('ConfirmaNovaSenha').errors
                    ?.passwordMismatch
                ">As senhas não são iguais!</span>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div col-12 col-xs-6 col-md-4 col-lg-3>
              <input data-toggle="tooltip" title="Confirmar Alteração"
                     type="submit"
                     value="Alterar"
                     class="btn btn-block btn-primary"
                     [disabled]="!novaSenhaForm.valid" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- modal senha alterada -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
     id="modalSenhaAlterada" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Alteração de Senha
        </h5>
      </div>
      <div class="modal-body" style="white-space:pre-wrap">
        <p>Senha alterada com sucesso</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="redirectToDashboard()" class="btn btn-primary btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>

      </div>
    </div>
  </div>
</div>
