<div class="row">
    <div class="col-12">
      <div class="card card-default">
        <form
          ngNativeValidate
          [formGroup]="usuariosform"
          autocomplete="off"
          (submit)="OnSubmitEdit()"
          *ngIf="!load"
        >
          <div class="card">
              <div class="card-header card-header-border-bottom">
                  <h2>Atualizar Usuário</h2>
                </div>
            <br />
            <div class="card-body">

              <br />
              <div class="form-row">
                <div class="form-group col-sm-12 col-md-4">
                  <label for="exampleFormControlSelect3"
                    >Selecione cliente : *
                  </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect3"
                    formControlName="IdClienteSelecionado"
                    *ngIf="listaCliente"
                  >
                    <option
                      class="form-control"
                      *ngFor="let cliente of listaCliente"
                      [value]="cliente.id"
                    >
                      {{ cliente.name }}
                    </option>
                  </select>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('IdClienteSelecionado').touched &&
                      usuariosform.get('IdClienteSelecionado').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
  
                <div class="form-group col-sm-12 col-md-4">
                  <label>Nome:* </label>
  
                  <h3>
                    <input
                      class="form-control"
                      formControlName="Nome"
                    />
                  </h3>
                </div>
  
                <div class="form-group col-sm-12 col-md-4">
                  <label for="exampleFormControlSelect3">Tipo de conta: * </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect3"
                    formControlName="RoleSelecionada"
                  >
                    <option value="Owner">Owner</option>
                    <option value="Administrador">Administrador</option>
                    <option value="Comum">Comum</option>
                  </select>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('RoleSelecionada').touched &&
                      usuariosform.get('RoleSelecionada').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
              </div>
  
              <div class="form-row">
                <div class="form-group col-sm-12 col-md-4">
                  <label>Nome responsavel:* </label>
  
                  <h3>
                    <input
                      class="form-control"
                      formControlName="NomeResponsavel"
                    />
                  </h3>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('NomeResponsavel')
                        .touched &&
                        usuariosform.get('NomeResponsavel')
                        .errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
  
                <div class="form-group col-sm-12 col-md-4">
                  <label>Telefone do responsavel:* </label>
  
                  <h3>
                    <input
                      class="form-control"
                      formControlName="NomeContato"
                      mask="(00) 00000-0000"
                    />
                  </h3>
                </div>
  
                <div class="form-group col-sm-12 col-md-4">
                  <label for="txtFullName">Nome de Usuário: * </label>
                  <input
                    class="form-control"
                    id="txtUserName"
                    placeholder="Enter Username"
                    formControlName="Login"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Login').touched &&
                      usuariosform.get('Login').errors
                        ?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
              </div>
  
              <div class="form-row">
                <div class="form-group col-sm-12 col-md-4">
                  <label>E-mail:* </label>
  
                  <h3>
                    <input class="form-control" formControlName="Email" />
                  </h3>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Email')
                        .touched &&
                      usuariosform.get('Email').errors
                        ?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
  
                <div class="form-group col-sm-12 col-md-4">
                  <label for="txtPassword">Senha:</label>
                  <!-- <input
                    type="password"
                    class="form-control"
                    id="txtPassword"
                    placeholder="Enter Password"
                    formControlName="Password"
                  /> -->
  
                  <div class="input-group">
                    <input
                      type="{{ TypeOfPasswordInput }}"
                      class="form-control"
                      id="txtPassword"
                      placeholder="Enter Password"
                      formControlName="Senha"
                    />
                    <div class="input-group-append">
                      <button
                        data-toggle="tooltip" title="Exibir Senha"
                        class="btn btn-primary"
                        type="button"
                        (click)="TogglePasswordDisplay()"
                      >
                        <i
                          class="mdi mdi-eye"
                          [ngClass]="{
                            'mdi-eye': TypeOfPasswordInput === 'password',
                            'mdi-eye-off': TypeOfPasswordInput === 'text'
                          }"
                        ></i>
                      </button>
                      <button
                        data-toggle="tooltip" title="Gerar Senha Aleatória"
                        class="btn btn-primary"
                        type="button"
                        (click)="GeneratePassword()"
                      >
                        <i class="mdi mdi-auto-fix"></i>
                      </button>
                    </div>
                  </div>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Senha').touched &&
                      usuariosform.get('Senha').errors
                        ?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Senha').touched &&
                      usuariosform.get('Senha').errors
                        ?.minlength
                    "
                    >A senha precisa de pelo menos 8 caracteres</span
                  >
                </div>
                <div class="form-group col-sm-12 col-md-4">
                  <label for="txtConfirmPassword">Repita a Senha: </label>
                  <input
                    type="password"
                    class="form-control"
                    id="txtConfirmPassword"
                    placeholder="Enter Pumber"
                    formControlName="ConfirmaSenha"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('ConfirmaSenha')
                        .touched &&
                      usuariosform.get('ConfirmaSenha')
                        .errors?.required
                    "
                    >Este campo é obrigatório!</span
                  ><span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('ConfirmaSenha')
                        .touched &&
                      usuariosform.get('ConfirmaSenha')
                        .errors?.passwordMismatch
                    "
                    >As senhas não são iguais!</span
                  >
                </div>
              </div>
  

              <!-- <div class="form-row">
                <div class="form-group col-md-4">
                  <label>Nome: </label>
  
                  <input
                    class="form-control"
                    id="txtUserName"
                    placeholder="Enter nome"
                    formControlName="Nome"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Nome').touched &&
                      usuariosform.get('Nome').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
  
                <div class="form-group col-md-4">
                  <label for="txtFullName">Nome de Usuário: * </label>
                  <input
                    class="form-control"
                    id="txtUserName"
                    placeholder="Enter login"
                    formControlName="Login"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Login').touched &&
                      usuariosform.get('Login').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
                <div class="form-group col-md-4">
                  <label>E-mail*: </label>
                  <input
                    class="form-control"
                    id="txtUserName"
                    placeholder="Enter e-mail"
                    formControlName="Email"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Email').touched &&
                      usuariosform.get('Email').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                  <span
                  class="mt-2 d-block text-danger"
                  *ngIf="
                    usuariosform.get('Email')
                      .touched &&
                    usuariosform.get('Email').errors
                      ?.email
                  "
                  >Este email é inválido</span
                >
                </div>
  
              </div>
  
              <div class="form-row">
    
  
                <div class="form-group col-md-4">
                  <label for="txtPassword">Senha: *</label>
                  <input
                    type="password"
                    class="form-control"
                    id="txtPassword"
                    placeholder="Enter Password"
                    formControlName="Senha"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Senha').touched &&
                      usuariosform.get('Senha').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('Senha').touched &&
                      usuariosform.get('Senha').errors?.minlength
                    "
                    >A senha precisa de pelo menos 8 caracteres</span
                  >
                </div>
                <div class="form-group col-md-4">
                  <label for="txtConfirmPassword">Repita a Senha: * </label>
                  <input
                    type="password"
                    class="form-control"
                    id="txtConfirmPassword"
                    placeholder="Enter Pumber"
                    formControlName="ConfirmaSenha"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('ConfirmaSenha').touched &&
                      usuariosform.get('ConfirmaSenha').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  ><span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('ConfirmaSenha').touched &&
                      usuariosform.get('ConfirmaSenha').errors?.passwordMismatch
                    "
                    >As senhas não são iguais!</span
                  >
                </div>
              </div>
  
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="exampleFormControlSelect3">Tipo de conta: * </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect3"
                    formControlName="RoleSelecionada"
                  >
                    <option [selected]="usuario.role === 'Owner'" value="Owner">Owner</option>
                    <option [selected]="usuario.role === 'Administrador'" value="Administrador">Administrador</option>
                    <option [selected]="usuario.role === 'Comum'" value="Comum">Comum</option>
                  </select>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('RoleSelecionada').touched &&
                      usuariosform.get('RoleSelecionada').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
  
                <div class="form-group col-md-4">
                  <label for="exampleFormControlSelect3"
                    >Selecione cliente : *
                  </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect3"
                    formControlName="IdClienteSelecionado"
                    disabled
                    *ngIf="listaCliente"
                  >
                    <option
                    class="form-control"
                      *ngFor="let cliente of listaCliente"
                      [value]="cliente.id"
                    >
                      {{ cliente.name }}
                    </option>
                  </select>
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      usuariosform.get('IdClienteSelecionado').touched &&
                      usuariosform.get('IdClienteSelecionado').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
              </div> -->

            </div>
            <div class="card-footer">
              <div class="row">
                <div col-12 col-xs-6 col-md-4 col-lg-3 style="display:flex">
                  <button class="btn btn-danger" (click)="redirectToUserList()">
                    Cancelar
                  </button>
                  <input style="margin-left:2rem" type="submit"
                         value="Atualizar"
                         class="btn btn-block btn-primary"
                         [disabled]="!usuariosform.valid" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
