import { RelatoriosService } from './Relatorios.service';
import { UserService } from 'src/app/shared/user.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { isObservable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


//declare var Chart: any;
declare let $: any;


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit {


  constructor(private relatorioService: RelatoriosService, public userService: UserService, public router: Router, public FB: FormBuilder) {

    this.formulario = this.FB.group({
      formato: ['', Validators.required],
      periodoDe: ['', Validators.required],
      periodoAte: ['', Validators.required],
      tipoRelatorio: ['', Validators.required],
    });
  }


  public nomeRelatorio = 'Geral';
  public formulario: FormGroup;

  public ModalMissingInfoText = '';

  public TipoRelatorio = 'faturamento';

  public FormatoAux;

  public TipoRelatorioAux;

  public Formato = '';

  public PeriodoDe = '';

  public PeriodoAte = '';

  public PeriodoDeAux = '';

  public PeriodoAteAux = '';

  public IdCliente = '0';
  public ClienteNome = '';

  public listaCliente = [];

  public userInformation: any;

  public reportPasswordButton: any;

  public disablePeriodo = false;

  public disponivelRelatorio = false;

  public paramsCount = 0;

  public tipoFiltro: any;

  //acompanhamento
  public tipoListaDetalhesRelatorioAcompanhamento = '';

  public listaRelatorioAcompanhamentoEnvioMensagensDetalhado = [];

  public listaRelatorioAcompanhamentoEnvioMensagensConsolidado: any;

  public listaRelatorioAcompanhamentoEnvioNumeros = [];

  //mensagens enviadas
  public listaRelatorioMensagensEnviadasDetalhado = [];

  public listaRelatorioMensagensEnviadasConsolidado = [];

  public listaRelatorioMensagensEnviadasNumeros = [];

  public SomaMsgEnviadaConsolidado: any;;

  //credito
  public listaRelatorioCreditoConsolidado: any;

  public listaRelatorioCreditoDetalhado = [];

  //faturamento
  public listaRelatorioFaturamentoConsolidado: any;

  public listaRelatorioFatumentoDetalhado = [];

  //recebidas
  public listaRelatorioMsgRecebidasConsolidado = [];

  public listaRelatorioMsgRecebidasDetalhado = [];


  // public barChartOptions = {
  //   scaleShowVerticalLines: false,
  //   responsive: true
  // };

  // public barChartLabels = ['Não processado', 'Sendo processado', 'Não enviado', 'Enviado com confirmação', 'Total', 'Sucesso', 'Erro'];
  // public barChartType = 'bar';
  // public barChartLegend = true;
  // public barChartData = [
  //   { data: [] },

  // ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}], yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: { display: false },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['Não processado', 'Sendo processado', 'Não enviado', 'Enviado', 'Enviado com Confirmação'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public donutColors = [
    {
      backgroundColor: [
        '#17a2b8',
        '#EDFF0B',
        '#dc3545',
        '#28a745',
        '#28a745',
      ]
    }
  ];

  public barChartData: any = [
    { data: [], barPercentage: 0.8, },
  ];

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    // plugins: {
    //   datalabels: {
    //     formatter: (value, ctx) => {
    //       const label = ctx.chart.data.labels[ctx.dataIndex];
    //       return label;
    //     },
    //   },
    // }
  };
  public pieChartLabels: Label[] = ['Não Tarfado/Não Enviado', 'Não enviado', 'Enviado'];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [
        '#ffde0b',
        '#dc3545',
        '#28a745',
        '#17a2b8',
        '#28a745',
      ],
    },
  ];



  public barChartOptionsCredito: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}], yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: { display: false },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabelsCredito: Label[] = ['Saldo'];
  public barChartTypeCredito: ChartType = 'bar';
  public barChartLegendCredito = true;
  public donutColorsCredito = [
    {
      backgroundColor: [
        '#17a2b8',
      ]
    }
  ];

  public barChartDataCredito: any = [
    { data: [], barPercentage: 0.2, },
  ];

  public barChartOptionsFaturamento: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}], yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: { display: false },

    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabelsFaturamento: Label[] = ['Faturamento'];
  public barChartTypeFaturamento: ChartType = 'bar';
  public barChartLegendFaturamento = true;
  public donutColorsFaturamento = [
    {
      backgroundColor: [
        '#28a745',
      ]
    }
  ];

  public barChartDataFaturamento: any = [
    { data: [], barPercentage: 0.2, },
  ];


  public barChartOptionsMsgRecebidasPeriodo: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{}], yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: { display: false },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabelsMsgRecebidasPeriodo: Label[] = [];
  public barChartTypeMsgRecebidasPeriodo: ChartType = 'bar';
  public barChartLegendMsgRecebidasPeriodo = true;
  public donutColorsMsgRecebidasPeriodo = [
    {
      backgroundColor: [
        '#28a745',
      ]
    }
  ];

  public barChartDataMsgRecebidasPeriodo: any = [
    { data: [], barPercentage: 0.2, },
  ];



  ngOnInit() {
    this.carregaClientes();

    this.userService.GetUserInformation().subscribe((userInfo: any) => this.userInformation = userInfo)

  }

  ngAfterViewInit() {
    this.reportPasswordButton = document.getElementById('reportPasswordButton');
  }


  carregaClientes() {
    this.relatorioService.listaClientes().subscribe(
      (sucesso: any) => {
        this.listaCliente = sucesso;
        //this.IdCliente = sucesso[0].id
      },
      (erro: any) => { }
    );
  }

  selecionaTipoRelatorio(e: any) {
    this.TipoRelatorioAux = e.target.value;
    switch (this.TipoRelatorioAux) {
      case 'acompanhamento_envio':
        this.disablePeriodo = true;
        this.PeriodoDe = '';
        this.PeriodoAte = '';
        break;

      default:
        this.disablePeriodo = false;
        this.PeriodoDe = '';
        this.PeriodoAte = '';
        // alert('não foi repassado relatorio')
        break;
    }
  }
  selecionaCliente(event) {

    this.ClienteNome = event.target.options[event.target.options.selectedIndex].text;
    this.IdCliente = event.target.value;

  }
  selecionaFormato(value) {
    this.FormatoAux = value;

  }
  CheckIfRole(roles) {
    if (this.userInformation == undefined) {
      return false;
    }
    return this.userInformation.roleType ? roles.includes(this.userInformation.roleType) : false;
  }


  buscarRelatorioConsolidado() {
    this.reportPasswordButton.classList.add('button--loading');
    this.PeriodoDeAux = this.PeriodoDe;

    this.PeriodoAteAux = this.PeriodoAte;

    this.TipoRelatorio = this.TipoRelatorioAux;

    this.Formato = this.FormatoAux

    this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado = [];

    //Realizando busca
    this.relatorioService.buscarRelatorioConsolidado(this.TipoRelatorio, this.IdCliente, this.PeriodoDe, this.PeriodoAte, this.Formato, null, " ").subscribe((res: any) => {

      this.reportPasswordButton.classList.remove('button--loading');
      this.disponivelRelatorio = true;

      //Checando se há dados no relatorio
      if (res == null) {
        document.getElementById('botaoModalSemResultado').click();
        this.reportPasswordButton.classList.remove('button--loading');
        return;
      }
      if (this.TipoRelatorio != "credito_sms") {
        if (this.TipoRelatorio != "faturamento") {
          if (!res) {
            document.getElementById('botaoModalSemResultado').click();
            this.reportPasswordButton.classList.remove('button--loading');
            return;
          } else if (res.length == 0) {
            document.getElementById('botaoModalSemResultado').click();
            this.reportPasswordButton.classList.remove('button--loading');
            return;
          }

        }
      }
      //Formatando nome do relatorio(pra q?)
      this.nomeRelatorio = this.Formato.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      });

      switch (this.TipoRelatorio) {
        case 'acompanhamento_envio':

          //Tratando os dados
          this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado = res.map(function (item) {
            return {
              nao_processado: item.count_NaoProcessado,
              nao_tarifado: item.count_NaoTarifado,
              sendo_processado: item.count_SendoProcessado,
              nao_enviado: item.count_NaoEnviado,
              enviado: item.count_Enviado,
              enviado_confirmacao: item.count_EnviadoComConfirmacao,
              total: item.count,
              sucesso: item.count_Sucesso,
              //quantidade_E: item.quantidade_E,
              data_consolidado: item.createdOn
            };
          });
          console.log("TESTE REFATORANDO RELATORIO:", this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado);

          this.carregaChartConsolidado(this.TipoRelatorio, res);
          break;
        case 'mensagens_enviadas_periodo':


          this.listaRelatorioMensagensEnviadasDetalhado = res;

          this.tipoListaDetalhesRelatorioAcompanhamento = 'mensagens';


          this.listaRelatorioMensagensEnviadasConsolidado = res.map(function (item) {
            return {
              nao_processado: item.count_NaoProcessado,
              nao_tarifado: item.count_NaoTarifado,
              sendo_processado: item.count_SendoProcessado,
              nao_enviado: item.count_NaoEnviado,
              enviado: item.count_Enviado + item.count_EnviadoComConfirmacao,
              //enviado_confirmacao: item.count_EnviadoComConfirmacao,
              total: item.count,
              sucesso: item.count_Sucesso,
              creditos: item.credits,
              data_consolidado: item.createdOn
            };
          });


          this.carregaChartConsolidado(this.TipoRelatorio, res);

          break;
        case 'faturamento':

          this.listaRelatorioFatumentoDetalhado = res;

          this.listaRelatorioFaturamentoConsolidado = res.map(item => ({
            periodo: item.periodo,
            cliente: item.cliente,
            faturamento: item.faturamento
          }));


          this.listaRelatorioFaturamentoConsolidado = this.listaRelatorioFaturamentoConsolidado.reduce(function (previousValue, currentValue) {
            return {
              periodo: currentValue.periodo,
              cliente: currentValue.cliente,
              faturamento: previousValue.faturamento + currentValue.faturamento
            };
          });

          this.carregaChartConsolidado(this.TipoRelatorio, [this.listaRelatorioFaturamentoConsolidado.faturamento]);

          break;
        case 'credito_sms':
          this.listaRelatorioCreditoConsolidado = res;

          this.listaRelatorioCreditoDetalhado = res;

          this.carregaChartConsolidado(this.TipoRelatorio, [res.saldo]);

          break;
        case 'mensagens_recebidas_periodo':
          this.listaRelatorioMsgRecebidasDetalhado = res;

          this.listaRelatorioMsgRecebidasConsolidado = res;

          if (this.listaRelatorioMsgRecebidasConsolidado.length) {

            const mapLabelsDataMsgRecebidas = this.listaRelatorioMsgRecebidasConsolidado.map(function (item) {
              return [
                item.date
              ];
            });

            const mapDataMsgRecebidas = this.listaRelatorioMsgRecebidasConsolidado.map(item => item.totalMessages);

            this.carregaChartConsolidado(this.TipoRelatorio, [{ mapLabelsDataMsgRecebidas, mapDataMsgRecebidas }]);

          }

          break;

      }


    })
  }

  buscaDadosRelatorio() {

    if (this.FormatoAux == 'detalhado') {
      this.buscaDadosRelatorioDetalhado();
    } else {
      this.buscarRelatorioConsolidado();
    }
  }


  buscaDadosRelatorioDetalhado() {

    this.reportPasswordButton.classList.add('button--loading');

    this.PeriodoDeAux = this.PeriodoDe;

    this.PeriodoAteAux = this.PeriodoAte;

    this.TipoRelatorio = this.TipoRelatorioAux;

    this.Formato = this.FormatoAux

    this.ModalMissingInfoText = '';

    if (this.TipoRelatorio == '') {
      this.ModalMissingInfoText += 'Tipo de Relatório não definido \n';
    }
    if (this.IdCliente == '' && this.CheckIfRole(['Owner', 'Administrador'])) {
      this.ModalMissingInfoText += 'Cliente não definido \n';
    }
    if (this.PeriodoDe == '') {
      this.ModalMissingInfoText += 'Inicio de Período não definido \n';
    }
    if (this.PeriodoAte == '') {
      this.ModalMissingInfoText += 'Final de Período não definido \n';
    }
    if (this.Formato == '') {
      this.ModalMissingInfoText += 'Formato não definido \n';
    }

    if (this.ModalMissingInfoText != '' && this.TipoRelatorio != 'acompanhamento_envio') {
      document.getElementById('botaoModalInfoMissing').click();
      this.reportPasswordButton.classList.remove('button--loading');
      return null;
    }

    // this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado = [];
    // this.listaRelatorioFaturamentoConsolidado = null;
    // this.listaRelatorioCreditoConsolidado = [];
    // this.listaRelatorioMsgRecebidasConsolidado = [];
    // this.listaRelatorioMensagensEnviadasConsolidado = [];

    this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado = [];
    this.listaRelatorioCreditoDetalhado = [];
    this.listaRelatorioMensagensEnviadasDetalhado = [];
    this.listaRelatorioFatumentoDetalhado = [];
    this.listaRelatorioMsgRecebidasDetalhado = [];

    this.relatorioService.buscarRelatorioDetalhado(this.TipoRelatorio, this.IdCliente, this.PeriodoDe, this.PeriodoAte, this.Formato, null, " ").subscribe(
      (data: any) => {
        this.reportPasswordButton.classList.remove('button--loading');

        this.disponivelRelatorio = true;

        if (data == null) {
          document.getElementById('botaoModalSemResultado').click();
          this.reportPasswordButton.classList.remove('button--loading');
        }
        if (this.TipoRelatorio != "credito_sms") {
          if (this.TipoRelatorio != "faturamento") {
            if (!data) {
              document.getElementById('botaoModalSemResultado').click();
              this.reportPasswordButton.classList.remove('button--loading');
            } else if (data.length == 0) {
              document.getElementById('botaoModalSemResultado').click();
              this.reportPasswordButton.classList.remove('button--loading');
            }

          }
        }

        this.nomeRelatorio = this.Formato.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });



        switch (this.TipoRelatorio) {
          case 'acompanhamento_envio':



            this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado = data;

            this.tipoListaDetalhesRelatorioAcompanhamento = 'mensagens';

            this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado = data.map(function (item) {
              return {
                nao_processado: item.count_NaoProcessado,
                nao_tarifado: item.count_NaoTarifado,
                sendo_processado: item.count_SendoProcessado,
                nao_enviado: item.count_NaoEnviado,
                enviado: item.count_Enviado,
                enviado_confirmacao: item.count_EnviadoComConfirmacao,
                total: item.count,
                sucesso: item.count_Sucesso,
                //quantidade_E: item.quantidade_E,
                data_consolidado: item.createdOn
              };
            });


            this.carregaChartConsolidado(this.TipoRelatorio, data);



            break;
          case 'faturamento':

            this.listaRelatorioFatumentoDetalhado = data;

            this.listaRelatorioFaturamentoConsolidado = data.map(item => ({
              periodo: item.periodo,
              cliente: item.cliente,
              faturamento: item.faturamento
            }));


            this.listaRelatorioFaturamentoConsolidado = this.listaRelatorioFaturamentoConsolidado.reduce(function (previousValue, currentValue) {
              return {
                periodo: currentValue.periodo,
                cliente: currentValue.cliente,
                faturamento: previousValue.faturamento + currentValue.faturamento
              };
            });

            this.carregaChartConsolidado(this.TipoRelatorio, [this.listaRelatorioFaturamentoConsolidado.faturamento]);

            break;
          case 'mensagens_enviadas_periodo':


            this.listaRelatorioMensagensEnviadasDetalhado = data;

            this.tipoListaDetalhesRelatorioAcompanhamento = 'mensagens';


            this.listaRelatorioMensagensEnviadasConsolidado = data.map(function (item) {
              return {
                nao_processado: item.count_NaoProcessado,
                nao_tarifado: item.count_NaoTarifado,
                sendo_processado: item.count_SendoProcessado,
                nao_enviado: item.count_NaoEnviado,
                enviado: item.count_Enviado + item.count_EnviadoComConfirmacao,
                //enviado_confirmacao: item.count_EnviadoComConfirmacao,
                total: item.count,
                sucesso: item.count_Sucesso,
                creditos: item.credits,
                data_consolidado: item.createdOn
              };
            });


            this.listaRelatorioMensagensEnviadasDetalhado = data;

            this.carregaChartConsolidado(this.TipoRelatorio, data);

            break;
          case 'credito_sms':
            this.listaRelatorioCreditoConsolidado = data;

            this.listaRelatorioCreditoDetalhado = data;

            this.carregaChartConsolidado(this.TipoRelatorio, [data.saldo]);

            break;
          case 'mensagens_recebidas_periodo':
            this.listaRelatorioMsgRecebidasDetalhado = data;

            this.listaRelatorioMsgRecebidasConsolidado = data;

            if (this.listaRelatorioMsgRecebidasConsolidado.length) {

              const mapLabelsDataMsgRecebidas = this.listaRelatorioMsgRecebidasConsolidado.map(function (item) {
                return [
                  item.date
                ];
              });

              const mapDataMsgRecebidas = this.listaRelatorioMsgRecebidasConsolidado.map(item => item.totalMessages);

              this.carregaChartConsolidado(this.TipoRelatorio, [{ mapLabelsDataMsgRecebidas, mapDataMsgRecebidas }]);

            }

            break;

        }

      },
      (erro: any) => {
        console.log(erro);
        this.reportPasswordButton.classList.remove('button--loading');
      }
    );
  }

  mapeiaMensagensParaNumero() {
    this.Formato = 'detalhado';
    this.tipoListaDetalhesRelatorioAcompanhamento = 'numeros';
    this.disponivelRelatorio = true;
    this.nomeRelatorio = this.Formato.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
    this.paramsCount = Math.max.apply(Math, this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado.map(s => s.params.length));
    this.paramsCount = 8;

    if (this.listaRelatorioMensagensEnviadasDetalhado.length) {
      this.paramsCount = Math.max.apply(Math, this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado.map(s => s.params.length));
      this.paramsCount = 8;

    }
    // this.listaRelatorioAcompanhamentoEnvioNumeros = this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado.map(item => item.sms)

    this.listaRelatorioAcompanhamentoEnvioNumeros = this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado.map(item => item.sms);
    this.listaRelatorioMensagensEnviadasNumeros = this.listaRelatorioMensagensEnviadasDetalhado.map(item => item.sms);

    this.listaRelatorioAcompanhamentoEnvioNumeros = this.listaRelatorioAcompanhamentoEnvioNumeros.reduce((accumulator, value) => accumulator.concat(value), []);
    this.listaRelatorioMensagensEnviadasNumeros = this.listaRelatorioMensagensEnviadasNumeros.reduce((accumulator, value) => accumulator.concat(value), []);

    this.listaRelatorioAcompanhamentoEnvioNumeros.forEach(item => {
      if (item.paramValues[0]) {
        item.paramValues.splice(0, 1);
      }
    })
    this.listaRelatorioMensagensEnviadasNumeros.forEach(item => {
      if (item.paramValues[0]) {
        item.paramValues.splice(0, 1);
      }
    })

    if (this.tipoFiltro) {

      if (Array.isArray(this.tipoFiltro)) { // pendente ou agendado
        this.listaRelatorioAcompanhamentoEnvioNumeros = this.listaRelatorioAcompanhamentoEnvioNumeros.filter((el) => el.status === 0 || el.status === 2);
        this.listaRelatorioMensagensEnviadasNumeros = this.listaRelatorioMensagensEnviadasNumeros.filter((el) => el.status === 0 || el.status === 2);
      } else {
        this.listaRelatorioAcompanhamentoEnvioNumeros = this.listaRelatorioAcompanhamentoEnvioNumeros.filter((el) => el.status === this.tipoFiltro);
        this.listaRelatorioMensagensEnviadasNumeros = this.listaRelatorioMensagensEnviadasNumeros.filter((el) => el.status === this.tipoFiltro);

      }
    }


  }

  mapeiaMensagensConsolidadoParaDetalhado(tipo, data: string) {
    this.tipoFiltro = tipo;
    this.tipoListaDetalhesRelatorioAcompanhamento = 'numeros';
    data = data.replace("/", "-")
    data = data.replace("/", "-")
    data = data.replace("/", "-")
    data = data.replace("/", "-")
    if (this.IdCliente == '') {
      this.IdCliente = this.userInformation.clienteId;
    }
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/report-detail/${this.TipoRelatorio}/${this.Formato}/${this.IdCliente}/${tipo}/${data}`])
    );

    window.open(url.toString(), '_blank');
  }

  carregaChartConsolidado(tipo: string, listaDados: any) {

    switch (tipo) {
      case 'acompanhamento_envio':

        const listaItensMensagens = listaDados.map(function (item) {
          return {
            nao_processado: item.count_NaoProcessado,
            nao_tarifado: item.count_NaoTarifado,
            sendo_processado: item.count_SendoProcessado,
            nao_enviado: item.count_NaoEnviado,
            enviado: item.count_Enviado,
            enviado_confirmacao: item.count_EnviadoComConfirmacao,
            //total: item.count,
            //sucesso: item.count_Sucesso,
            //quantidade_E: item.quantidade_E,
          };
        });
        let somaEntreItensConsololidado = null;
        let somaEntreItensConsololidadoAux = null;
        if (!listaItensMensagens) {
        }
        somaEntreItensConsololidado = listaItensMensagens.reduce(function (previousValue, currentValue) {

          return {
            nao_processado: previousValue.nao_processado + currentValue.nao_processado,
            //nao_tarifado: previousValue.nao_tarifado + currentValue.nao_tarifado,
            sendo_processado: previousValue.sendo_processado + currentValue.sendo_processado,
            nao_enviado: previousValue.nao_enviado + currentValue.nao_enviado,
            enviado: previousValue.enviado + currentValue.enviado,
            enviado_confirmacao: previousValue.enviado_confirmacao + currentValue.enviado_confirmacao,
            //total: previousValue.total + currentValue.total,
            //sucesso: previousValue.sucesso + currentValue.sucesso,
            //quantidade_E: previousValue.quantidade_E + currentValue.quantidade_E,
          };
        });
        somaEntreItensConsololidadoAux = listaItensMensagens.reduce(function (previousValue, currentValue) {

          return {
            nao_processado: previousValue.nao_processado + currentValue.nao_processado,
            nao_tarifado: previousValue.nao_tarifado + currentValue.nao_tarifado,
            sendo_processado: previousValue.sendo_processado + currentValue.sendo_processado,
            nao_enviado: previousValue.nao_enviado + currentValue.nao_enviado,
            enviado: previousValue.enviado + currentValue.enviado,
            enviado_confirmacao: previousValue.enviado_confirmacao + currentValue.enviado_confirmacao,
            //total: previousValue.total + currentValue.total,
            //sucesso: previousValue.sucesso + currentValue.sucesso,
            //quantidade_E: previousValue.quantidade_E + currentValue.quantidade_E,
          };
        });
        somaEntreItensConsololidado.nao_enviado += somaEntreItensConsololidadoAux.nao_tarifado;
        somaEntreItensConsololidado.nao_tarifado = null;

        let FinalResultGrafico = {
          nao_processado: somaEntreItensConsololidado.nao_processado,
          sendo_processado: somaEntreItensConsololidado.sendo_processado,
          nao_enviado: somaEntreItensConsololidado.nao_enviado + somaEntreItensConsololidadoAux.nao_tarifado,
          enviado: somaEntreItensConsololidado.enviado,
          enviado_confirmacao: somaEntreItensConsololidado.enviado_confirmacao,
        }

        this.barChartData[0].data = Object.values(FinalResultGrafico);
        break;

      case 'faturamento':
        this.barChartDataFaturamento[0].data = listaDados;


        break;

      case 'mensagens_enviadas_periodo':
        const listaItensMensagens2 = listaDados.map(function (item) {
          return {
            nao_tarifado: item.count_NaoTarifado,
            nao_processado: item.count_NaoProcessado,
            //sendo_processado: item.count_SendoProcessado,
            nao_enviado: item.count_NaoEnviado,
            enviado: item.count_Enviado,
            enviado_confirmacao: item.count_EnviadoComConfirmacao,
            creditos: item.credits,
            //total: item.count,
            //sucesso: item.count_Sucesso,
            //quantidade_E: item.quantidade_E,
          };
        });

        const somaEntreItensConsololidado2 = listaItensMensagens2.reduce(function (previousValue, currentValue) {

          return {
            nao_processado: previousValue.nao_processado + currentValue.nao_processado,
            nao_tarifado: previousValue.nao_tarifado + currentValue.nao_tarifado,
            //sendo_processado: previousValue.sendo_processado + currentValue.sendo_processado,
            nao_enviado: previousValue.nao_enviado + currentValue.nao_enviado,
            enviado: previousValue.enviado + currentValue.enviado,
            enviado_confirmacao: previousValue.enviado_confirmacao + currentValue.enviado_confirmacao,
            creditos: previousValue.creditos + currentValue.creditos,
            //total: previousValue.total + currentValue.total,
            //sucesso: previousValue.sucesso + currentValue.sucesso,
            //quantidade_E: previousValue.quantidade_E + currentValue.quantidade_E,
          };
        });

        somaEntreItensConsololidado2.enviado += somaEntreItensConsololidado2.enviado_confirmacao;
        somaEntreItensConsololidado2.enviado_confirmacao = null;
        this.SomaMsgEnviadaConsolidado = somaEntreItensConsololidado2;


        let FinalResultGraficoPie = {
          nao_processado: somaEntreItensConsololidado2.nao_processado + somaEntreItensConsololidado2.nao_tarifado,
          nao_enviado: somaEntreItensConsololidado2.nao_enviado,
          enviado: somaEntreItensConsololidado2.enviado,
        }

        this.pieChartData = Object.values(FinalResultGraficoPie);

      case 'credito_sms':

        this.barChartDataCredito[0].data = listaDados;

        break;

      case 'mensagens_recebidas_periodo':

        let mapDataMsgRecebidas: number[] = listaDados[0].mapDataMsgRecebidas;

        let somaEntreItensConsolidado = 0;

        mapDataMsgRecebidas.forEach(elem => {
          somaEntreItensConsolidado += elem;
        });

        this.barChartDataMsgRecebidasPeriodo[0].data = [somaEntreItensConsolidado];

        this.barChartLabelsMsgRecebidasPeriodo = ["Total"];
        break;

    }

  }
  //EXPORTAÇÃO DE RELATORIO DE NUMEROS
  exportarNumeros(isCsv) {

    let dateFrom = this.PeriodoDeAux;
    let dateTo = this.PeriodoAteAux;
    dateFrom = dateFrom.replace("/", "");
    dateTo = dateTo.replace("/", "");

    switch (this.TipoRelatorio) {
      case 'acompanhamento_envio':
        this.relatorioService.exportarRelatorioNumero(this.TipoRelatorio, this.IdCliente, this.PeriodoDe, this.PeriodoAte, this.Formato, null, " ", true, isCsv).subscribe((res: any) => {
          const blob = new Blob([res]);

          let currentDate = new Date(Date.now());
          let currentDateFormat = currentDate.toLocaleDateString();
          currentDateFormat = currentDateFormat.replace("/", "");
          currentDateFormat = currentDateFormat.replace("/", "");
          if (isCsv) {
            saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_" + currentDateFormat + "_Numeros.csv");
          } else {
            saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_" + currentDateFormat + "_Numeros.xlsx");
          }
        });
        break;
      case 'mensagens_enviadas_periodo':
        let dateFrom = this.PeriodoDeAux;
        let dateTo = this.PeriodoAteAux;
        dateFrom = dateFrom.replace("/", "");
        dateTo = dateTo.replace("/", "");

        let dateFromArray = dateFrom.split("-");
        let dateToArray = dateTo.split("-");
        dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
        dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

        this.relatorioService.exportarRelatorioNumero(this.TipoRelatorio, this.IdCliente, this.PeriodoDe, this.PeriodoAte, this.Formato, null, " ", true, isCsv).subscribe((res: any) => {
          const blob = new Blob([res]);

          let currentDate = new Date(Date.now());
          let currentDateFormat = currentDate.toLocaleDateString();
          currentDateFormat = currentDateFormat.replace("/", "");
          currentDateFormat = currentDateFormat.replace("/", "");

          if (isCsv) {
            saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Numero.csv");
          } else {
            saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Numero.xlsx");
          }
        });
        break;
    }
  }

  //EXPORTAÇÃO DE RELATORIO
  exportarRelatorio(tipo, isCsv) {

    switch (this.TipoRelatorio) {
      case 'acompanhamento_envio':
        if (this.Formato === 'consolidado') {

          const dadosNovosconsilidado = {
            data_consolidado: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.data_consolidado,
            nao_processado: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.nao_processado,
            sendo_processado: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.sendo_processado,
            nao_enviado: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.nao_enviado + this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.nao_tarifado,
            enviado: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.enviado,
            enviado_confirmacao: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.enviado_confirmacao,
            total: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.total,
            sucesso: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.sucesso,
            erro: this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado.erro,
          };



          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          if (tipo == "EXCEL") {
            this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado, 'relatorio_acompanhamento_envio_consolidado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
              const blob = new Blob([res]);
              let currentDate = new Date(Date.now());
              let currentDateFormat = currentDate.toLocaleDateString();
              currentDateFormat = currentDateFormat.replace("/", "");
              currentDateFormat = currentDateFormat.replace("/", "");

              if (isCsv) {
                saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_" + currentDateFormat + "_Consolidado.csv");
              } else {
                saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_" + currentDateFormat + "_Consolidado.xlsx");
              }

            });
          } else {
            this.relatorioService.exportAsPDFFileRelatorio(this.listaRelatorioAcompanhamentoEnvioMensagensConsolidado, 'relatorio_acompanhamento_envio_consolidado', dateFrom, dateTo, parseInt(this.IdCliente)).subscribe((res: any) => {
              const blob = new Blob([res]);

              let currentDate = new Date(Date.now());
              let currentDateFormat = currentDate.toLocaleDateString();
              currentDateFormat = currentDateFormat.replace("/", "");
              currentDateFormat = currentDateFormat.replace("/", "");

              if (this.CheckIfRole(["Owner"])) {
                saveAs(blob, this.userInformation.clientName + "_" + this.ClienteNome + "_Acompanhamento_Envio_" + currentDateFormat + "_Consolidado.pdf");

              } else {
                saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_" + currentDateFormat + "_Consolidado.pdf");

              }
            });

          }

        }

        if (this.Formato === 'detalhado' && this.tipoListaDetalhesRelatorioAcompanhamento === 'numeros') {

          // let nrr = []
          // this.listaRelatorioAcompanhamentoEnvioNumeros.forEach(listaItens => {
          //   for (const iterator of listaItens) {
          //     nrr.push(iterator)
          //   }
          // })
          // console.log(nrr)

          const nrr = this.listaRelatorioAcompanhamentoEnvioNumeros.map(function (item) {
            return {
              id: item.messageId,
              destinatario: item.number,
              status: item.status,
              data_envio: item.scheduled,
              data_retorno: item.lastUpdateOn,
              prestadora: item.prestadora,
              operadora: item.operator,
              mensagem_enviada: item.content,
              usuario: item.user,
              cliente: item.client,
              tipo_sms: item.tipo,

            };
          });

          let dateFrom: string = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;

          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          if (tipo == "EXCEL") {
            this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioAcompanhamentoEnvioNumeros, 'relatorio_acompanhamento_envio_numeros', dateFrom, dateTo, isCsv).subscribe((res: any) => {
              const blob = new Blob([res]);
              if (isCsv) {
                saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_Numeros.csv");
              } else {
                saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_Numeros.xlsx");
              }
            });
          } else {

          }

        }

        if (this.Formato === 'detalhado' && this.tipoListaDetalhesRelatorioAcompanhamento === 'mensagens') {

          const dadosNovos = this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado.map(function (item) {
            const arr = {
              data_Envio: item.data_Envio,
              mensagem_Enviada: item.mensagem_Enviada,
              quantidade_N: item.quantidade_N,
              quantidade_s: item.quantidade_s,
              quantidade_E: item.quantidade_E,
              quantidade_cre: item.quantidade_cre,
              usuario: item.usuario,
              cliente: item.cliente,
              tipo_sms: item.tipo_sms
            };

            return arr;

          });

          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioAcompanhamentoEnvioMensagensDetalhado, 'relatorio_acompanhamento_envio_mensagens', dateFrom, dateTo, isCsv).subscribe((res: any) => {
            const blob = new Blob([res]);
            if (isCsv) {
              saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_Detalhado.csv");
            } else {
              saveAs(blob, this.userInformation.clientName + "_Acompanhamento_Envio_Detalhado.xlsx");
            }
          });

        }

        break;

      case 'faturamento':
        if (this.Formato === 'consolidado') {
          this.listaRelatorioFaturamentoConsolidado;
          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          let DateFromPDF = [dateFromArray[2], dateFromArray[1], dateFromArray[0]].join("/");
          let DateToPDF = [dateToArray[2], dateToArray[1], dateToArray[0]].join("/");

          if (tipo == "EXCEL") {
            this.relatorioService.exportAsExcelFileRelatorio([this.listaRelatorioFaturamentoConsolidado], 'relatorio_faturamento_consolidado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
              const blob = new Blob([res]);

              if (isCsv) {
                saveAs(blob, this.userInformation.clientName + "_Faturamento_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.csv");
              } else {
                saveAs(blob, this.userInformation.clientName + "_Faturamento_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.xlsx");
              }
            });
          } else {
            this.relatorioService.exportAsPDFFileRelatorio([this.listaRelatorioFaturamentoConsolidado], 'relatorio_faturamento_consolidado', DateFromPDF, DateToPDF, parseInt(this.IdCliente)).subscribe((res: any) => {
              const blob = new Blob([res]);
              if (this.CheckIfRole(["Owner"])) {

                saveAs(blob, this.userInformation.clientName + "_" + this.ClienteNome + "_Faturamento_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
              } else {

                saveAs(blob, this.userInformation.clientName + "_Faturamento_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
              }
            });

          }

        }

        if (this.Formato === 'detalhado') {
          this.listaRelatorioFatumentoDetalhado;
          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioFatumentoDetalhado, 'relatorio_faturamento_detalhado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
            const blob = new Blob([res]);
            if (isCsv) {
              saveAs(blob, this.userInformation.clientName + "_Faturamento_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.csv");
            } else {
              saveAs(blob, this.userInformation.clientName + "_Faturamento_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.xlsx");
            }
          });

        }
        break;
      case 'mensagens_enviadas_periodo':
        if (this.Formato === 'consolidado') {

          let dadosNovosconsilidado = this.listaRelatorioMensagensEnviadasConsolidado.map(function (item) {
            let arr = {
              CreatedOn: item.data_consolidado,
              Count_NaoEnviado: item.nao_enviado,
              Count_NaoProcessado: item.nao_processado + item.nao_tarifado,
              Count_Enviado: item.enviado,
              Credits: item.creditos,
              Count_EnviadoComConfirmacao: item.enviado_confirmacao,
              Count: item.total,
            }
            return arr
          });


          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          let DateFromPDF = [dateFromArray[2], dateFromArray[1], dateFromArray[0]].join("/");
          let DateToPDF = [dateToArray[2], dateToArray[1], dateToArray[0]].join("/");

          if (tipo == "EXCEL") {
            this.relatorioService.exportAsExcelFileRelatorio(dadosNovosconsilidado, 'relatorio_msg_enviadas_por_periodo_consolidado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
              const blob = new Blob([res]);
              if (isCsv) {
                saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.csv");
              } else {
                saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.xlsx");
              }
            })
          } else {
            this.relatorioService.exportAsPDFFileRelatorio(dadosNovosconsilidado, 'relatorio_msg_enviadas_por_periodo_consolidado', DateFromPDF, DateToPDF, parseInt(this.IdCliente)).subscribe((res: any) => {
              const blob = new Blob([res]);
              if (this.CheckIfRole(["Owner"])) {

                saveAs(blob, this.userInformation.clientName + "_" + this.ClienteNome + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
              } else {

                saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
              }
            })
          }

        }

        if (this.Formato === 'detalhado' && this.tipoListaDetalhesRelatorioAcompanhamento === 'numeros') {

          const nrr = this.listaRelatorioMensagensEnviadasNumeros.map(function (item) {
            return {
              id: item.messageId,
              destinatario: item.number,
              status: item.status,
              data_envio: item.scheduled,
              data_retorno: item.lastUpdateOn,
              prestadora: item.prestadora,
              operadora: item.operator,
              mensagem_enviada: item.content,
              usuario: item.user,
              cliente: item.client,
              tipo_sms: item.tipo,

            };
          });

          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioMensagensEnviadasNumeros, 'relatorio_msg_enviadas_por_periodo_numeros_por_periodo_detalhado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
            const blob = new Blob([res]);
            saveAs(blob, this.userInformation.clientName + "_Enviadas_Números_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.xlsx");
          });

        }

        if (this.Formato === 'detalhado' && this.tipoListaDetalhesRelatorioAcompanhamento === 'mensagens') {

          const dadosNovos = this.listaRelatorioMensagensEnviadasDetalhado.map(function (item) {
            const arr = {
              data_Envio: item.createdOn,
              mensagem_Enviada: item.messageContent,
              quantidade_N: item.countNumbers,
              quantidade_s: item.countSuccess,
              quantidade_E: item.countErros,
              quantidade_cre: item.credits,
              usuario: item.user,
              cliente: item.client,
              tipo_sms: item.smsType
            };

            return arr;

          });

          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioMensagensEnviadasDetalhado, 'relatorio_msg_enviadas_por_periodo_mensagens_detalhado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
            const blob = new Blob([res]);
            if (isCsv) {
              saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.csv");
            } else {
              saveAs(blob, this.userInformation.clientName + "_Enviadas_Mensagens_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.xlsx");
            }
          });

        }
        break;
      case 'credito_sms':
        if (this.Formato === 'consolidado') {
          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          let DateFromPDF = [dateFromArray[2], dateFromArray[1], dateFromArray[0]].join("/");
          let DateToPDF = [dateToArray[2], dateToArray[1], dateToArray[0]].join("/");

          if (tipo == "EXCEL") {
            this.relatorioService.exportAsExcelFileRelatorio([this.listaRelatorioCreditoConsolidado], 'relatorio_credito_sms_consolidado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
              const blob = new Blob([res],);
              if (isCsv) {
                saveAs(blob, this.userInformation.clientName + "_Credito_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.csv");
              } else {
                saveAs(blob, this.userInformation.clientName + "_Credito_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.xlsx");
              }
            });
          } else {
            this.relatorioService.exportAsPDFFileRelatorio([this.listaRelatorioCreditoConsolidado], 'relatorio_credito_sms_consolidado', DateFromPDF, DateToPDF, parseInt(this.IdCliente)).subscribe((res: any) => {
              const blob = new Blob([res],);
              if (this.CheckIfRole(["Owner"])) {

                saveAs(blob, this.userInformation.clientName + "_" + this.ClienteNome + "_Credito_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
              } else {

                saveAs(blob, this.userInformation.clientName + "_Credito_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
              }
            });

          }

        }

        if (this.Formato === 'detalhado') {
          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioCreditoDetalhado, 'relatorio_credito_sms_detalhado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
            const blob = new Blob([res],);
            if (isCsv) {
              saveAs(blob, this.userInformation.clientName + "_Credito_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.csv");
            } else {
              saveAs(blob, this.userInformation.clientName + "_Credito_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.xlsx");
            }
          });

        }
        break;
      case 'mensagens_recebidas_periodo':
        if (this.Formato === 'consolidado') {
          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");
          dateTo = dateTo.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];

          let DateFromPDF = [dateFromArray[2], dateFromArray[1], dateFromArray[0]].join("/");
          let DateToPDF = [dateToArray[2], dateToArray[1], dateToArray[0]].join("/");

          if (tipo == "EXCEL") {
            this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioMsgRecebidasConsolidado, 'relatorio_msg_recebidas_por_periodo_consolidado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
              const blob = new Blob([res],);
              if (isCsv) {
                saveAs(blob, this.userInformation.clientName + "_Recebidas_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.csv");
              } else {
                saveAs(blob, this.userInformation.clientName + "_Recebidas_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.xlsx");
              }
            });
          } else {
            this.relatorioService.exportAsPDFFileRelatorio(this.listaRelatorioMsgRecebidasConsolidado, 'relatorio_msg_recebidas_por_periodo_consolidado', DateFromPDF, DateToPDF, parseInt(this.IdCliente)).subscribe((res: any) => {
              const blob = new Blob([res],);
              saveAs(blob, this.userInformation.clientName + "_Recebidas_Periodo_" + dateFrom + "a" + dateTo + "_Consolidado.pdf");
            });

          }

        }

        if (this.Formato === 'detalhado') {
          let dateFrom = this.PeriodoDeAux;
          let dateTo = this.PeriodoAteAux;
          dateFrom = dateFrom.replace("/", "");

          let dateFromArray = dateFrom.split("-");
          let dateToArray = dateTo.split("-");
          dateFrom = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
          dateTo = dateToArray[2] + dateToArray[1] + dateToArray[0];


          dateTo = dateTo.replace("/", "");

          this.relatorioService.exportAsExcelFileRelatorio(this.listaRelatorioMsgRecebidasDetalhado, 'relatorio_msg_recebidas_por_periodo_detalhado', dateFrom, dateTo, isCsv).subscribe((res: any) => {
            const blob = new Blob([res],);
            if (isCsv) {
              saveAs(blob, this.userInformation.clientName + "_Recebidas_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.csv");
            } else {
              saveAs(blob, this.userInformation.clientName + "_Recebidas_Periodo_" + dateFrom + "a" + dateTo + "_Detalhado.xlsx");
            }
          });

        }
        break;

      default:
        alert('não');
        break;
    }
  }

  detailReport(id) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/message/sms/${id}`])
    );

    window.open(url, '_blank');
    //window.open('/message/sms/')
  }



}
