import { BlacklistService } from './blacklist.service';
import { Component, OnInit } from '@angular/core';
import { ListagemBlackList } from './models_blacklist/ListagemBlackList.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit {

  constructor(private blackListService: BlacklistService, private toastr: ToastrService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  TotalBlacklist: any;
  listaBlackList: any;

  contatoToBlackList: any;

  Numbers: string = null;

  selectedFile: File = null;





  ngOnInit() {
    this.listagemBlackList();
  }


  onSelectFile(fileInput: any) {
    this.selectedFile = <File>fileInput.target.files[0];
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.number.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listaBlackList = this.paginate(this.TotalBlacklist.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }


  listagemBlackList(page: number = 1) {
    this.load = true;

    this.page = page;


    this.blackListService.getAllBlackList(this.page, this.searchQuery).subscribe(
      (res: ListagemBlackList) => {
        this.TotalBlacklist = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalBlacklist.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }


  openModal(ct: any) {
    this.contatoToBlackList = ct;
    this.Numbers = null;

    this.selectedFile = <File>null;

  }

  closeModal() {
    this.contatoToBlackList = null;
  }



  ExcluiContatoBlackList(id: number) {
    this.blackListService.removeBlackList(id).subscribe(
      (sucesso) => {
        this.toastr.success('Removido com sucesso', 'Contato');
        this.listagemBlackList();
      },
      (erro) => {
        this.toastr.error('Não foi possivel remover', 'Contato');

      }
    );
  }


  enviarContatoBlackList() {
    const formData = new FormData();
    formData.append('Arquivo', this.selectedFile);
    formData.append('Numbers', this.Numbers);

    this.blackListService.addBlackList(formData).subscribe(
      (res) => {
        this.toastr.success('adicionaod contatos', 'Contato');
        this.listagemBlackList();
      },
      (errr) => {
        this.toastr.error('Não foi possivel adicionar contatos', 'Contato');

      }
    );

  }






















}
