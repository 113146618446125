<!-- <h3>Alteração de senha</h3> -->

<div class="row">
  <div class="col-md-12">
    <div class="card card-default ">
      <div class="card-header justify-content-left border-bottom">
        <h2 class="card-title text-dark" style="text-align: left">
          Cadastro de URL Suspeita
        </h2>
      </div>

      <div class="breadcrumb-wrapper d-flex justify-content-between align-items-center pl-3 pt-3 mb-3">
        <div>
          <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#cadastro_link_suspeita"
            style="cursor: pointer">
            <i class="mdi mdi-file-document mr-1" style="font-size: large"></i>
            Inserir novo link
          </button>
        </div>
      </div>
      <div class="input-group mx-3 " style="width:auto;flex:1">
        <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
          placeholder="Busca rápida" />
        <div class="input-group-append">
          <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
            <i class=" mdi mdi-magnify font-size-20"></i>
          </button>
        </div>
      </div>


      <div class="card card-default border-0" *ngIf="!load">
        <div class="card-body pt-0">
          <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Link</th>
                <th scope="col">Data de Criação</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody *ngIf="listagemLinkSuspeita">
              <tr *ngFor="let linkSusp of listagemLinkSuspeita">
                <td scope="row">{{ linkSusp.id }}</td>
                <td>{{ linkSusp.word }}</td>
                <td>{{ linkSusp.createdOn }}</td>
                <td>
                  <button data-toggle="tooltip" title="Deletar Link" data-toggle="modal"
                    data-target="#confirmaDeleteLinkusp" style="cursor: pointer" (click)="openModal(linkSusp.id)"
                    title="apagar" class="btn btn-sm btn-danger">
                    <span class="mdi mdi-delete font-size-20"></span>
                  </button>
                </td>
              </tr>
            </tbody>

            <tfoot *ngIf="!listagemLinkSuspeita">
              <tr>
                <td colspan="4" style="text-align: center">
                  Não encontrado registros
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="form-inline mb-3 mx-4 justify-content-between" *ngIf="!load">
        <ul class="pagination">
          <li [ngClass]="
              this.hasPreviousPage
                  ? 'page-item'
                  : 'page-item disabled'
              ">
            <a class="page-link" style="cursor: pointer" aria-label="Previous">
              <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;"
            [ngClass]="{'active': page == p }">
            <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
          </li>
          <li [ngClass]="
              this.hasNextPage
                  ? 'page-item'
                  : 'page-item disabled'
              ">
            <a class="page-link" style="cursor: pointer" aria-label="Next">
              <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
        <ul class="pagination">
          <div class="page-link">
            Itens por Pagina
            <select (change)="getPaginate()" [(ngModel)]="pageSize">
              <option [value]="10">10</option>
              <option [value]="25">25</option>
              <option [value]="50">50</option>
            </select>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- inserir link -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="cadastro_link_suspeita" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title center ">
          Novo link
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Informe a link</label>
          <input class="form-control" [(ngModel)]="link" type="text" />
        </div>


      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button type="button" class="btn btn-primary btn-pill" (click)="adicionarLink()" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>





<!-- modal excluir link -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDeleteLinkusp" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center " id="exampleModalLabel">
          Deseja deletar esse link ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="removerLink(linkSelecionado)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>