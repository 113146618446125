<div class="row">
  <div class="col-md-12">
    <div class="card card-default">
      <div class="card-header justify-content-left border-bottom">
        <h2 class="card-title text-black " style="text-align: left">
          Liberar mensagens - total: {{this.totalItemCount}}
        </h2>
      </div>

      <div class="breadcrumb-wrapper d-flex justify-content-between align-items-center" style="margin: 6px;">
        <!--<div>
          <label class="form-inline">Busca rápida</label>
          <div class="form-inline">
            <input type="text"
                   (input)="listagemMsgSuspeitas()"
                   [(ngModel)]="searchQuery"
                   class="form-control mb-4 mr-sm-4"
                   placeholder="messageId, conteudo" />
          </div>
        </div>-->
        <div class="input-group mx-3 mt-4" style="width:auto;flex:1">
          <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
            placeholder="Busca rápida" />
          <div class="input-group-append">
            <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
              <i class=" mdi mdi-magnify font-size-20"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row px-4 py-0" *ngIf="!load">
        <div class="col-12">
          <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Data envio</th>
                <th scope="col">Mensagem</th>
                <th scope="col">Qtn números</th>
                <th scope="col">Cliente</th>
                <th scope="col">Usúario</th>
                <th scope="col" style="text-align:center">Ações</th>
              </tr>
            </thead>
            <tbody *ngIf="listagemMgsSuspeita.length">
              <tr *ngFor="let msgSusp of listagemMgsSuspeita">
                <td scope="row">{{ msgSusp.messageId }}</td>
                <td>{{ msgSusp.createdOn }}</td>
                <td>{{ msgSusp.content }}</td>
                <td>{{ msgSusp.count }}</td>
                <td>{{ msgSusp.client }}</td>
                <td>{{ msgSusp.user }}</td>
                <td>
                  <div style="text-align: center;min-width:130px">
                    <div style="text-align: center;">
                      <button data-toggle="tooltip" title="Liberar Mensagem" class="btn btn-primary btn-sm"
                        (click)="openModal(msgSusp)" data-toggle="modal" data-target="#confirmaLiberarMessages">
                        <i class="mdi mdi-arrow-up font-size-20"></i>
                      </button>
                      <button data-toggle="tooltip" title="Bloquear Mensagem" class="btn btn-danger btn-sm ml-2"
                        (click)="openModal(msgSusp)" data-toggle="modal" data-target="#ModalBloqueio">
                        <i class="mdi mdi-block-helper font-size-20"></i>
                      </button>
                    </div>
                  </div>
                  <!--<button data-toggle="modal"
                          data-target="#confirmaLiberarMessages"
                          style="cursor: pointer"
                          (click)="openModal(msgSusp)"
                          title="liberar menssagens"
                          class="btn btn-success">
                    <span class="mdi mdi-lock-open-plus"></span>
                  </button>-->
                </td>
              </tr>
            </tbody>

            <tfoot *ngIf="!listagemMgsSuspeita.length">
              <tr>
                <td colspan="5" style="text-align: center">
                  Não encontrado registros
                </td>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
      <div class="form-inline mb-4 mx-4 justify-content-between" *ngIf="!load">
        <ul class="pagination">
          <li [ngClass]="
              this.hasPreviousPage
                  ? 'page-item'
                  : 'page-item disabled'
              ">
            <a class="page-link" style="cursor: pointer" aria-label="Previous">
              <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;"
            [ngClass]="{'active': page == p }">
            <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
          </li>
          <li [ngClass]="
              this.hasNextPage
                  ? 'page-item'
                  : 'page-item disabled'
              ">
            <a class="page-link" style="cursor: pointer" aria-label="Next">
              <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
        <ul class="pagination">
          <div class="page-link">
            Itens por Pagina
            <select (change)="getPaginate()" [(ngModel)]="pageSize">
              <option [value]="10">10</option>
              <option [value]="25">25</option>
              <option [value]="50">50</option>
            </select>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>



<!-- modal liberação de messages -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaLiberarMessages" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja liberar esta mensagem ?
        </h5>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Id:</strong></p>
            <p style="margin-left: 1rem;">{{suspeitoSelecionado ? suspeitoSelecionado.messageId : '' }}</p>

          </div>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Data:</strong></p>
            <p style="margin-left: 1rem;">{{suspeitoSelecionado ? suspeitoSelecionado.createdOn : '' }}</p>

          </div>
          <div class="form-group" style=" margin-top: 2rem;">
            <div style="display:flex">
              <p for="exampleInputPassword1"><strong>Mensagem Pendente:</strong></p>
              <p style="margin-left: 1rem;">{{suspeitoSelecionado ? suspeitoSelecionado.content : ''}}</p>

            </div>
          </div>
        </form>
        <div class="alert alert-danger alert-outlined" role="alert">
          <p> Mensagem pendente será liberada para envio!</p>
          <p class="mt-2"> <strong>Esta operação não poderá ser desfeita. Deseja continuar?</strong></p>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="liberarMensagens(suspeitoSelecionado.messageId)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal bloqueio de messages -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="ModalBloqueio" tabindex="-1" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center " id="exampleModalLabel">
          Deseja bloquear esta mensagem ?
        </h5>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Id:</strong></p>
            <p style="margin-left: 1rem;">{{suspeitoSelecionado ? suspeitoSelecionado.messageId : '' }}</p>

          </div>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Data:</strong></p>
            <p style="margin-left: 1rem;">{{suspeitoSelecionado ? suspeitoSelecionado.createdOn : '' }}</p>

          </div>
          <div class="form-group" style=" margin-top: 2rem;">
            <div style="display:flex">
              <p for="exampleInputPassword1"><strong>Mensagem Pendente:</strong></p>
              <p style="margin-left: 1rem;">{{suspeitoSelecionado ? suspeitoSelecionado.content : ''}}</p>

            </div>
          </div>
        </form>
        <div class="alert alert-danger alert-outlined" role="alert">
          <p> Mensagem pendente não será enviada, exibindo para o cliente o status <strong>NÃO ENVIADO</strong>.</p>
          <p class="mt-2"> <strong>Esta operação não poderá ser desfeita. Deseja continuar?</strong></p>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="liberarMensagens(suspeitoSelecionado.messageId)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>