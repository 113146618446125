<div class="row">
    <div class="col-12">
      <div class="card card-default">
        <form
          ngNativeValidate
          [formGroup]="fornecedorForm"
          autocomplete="off"
          (submit)="OnSubmitEdit()"
          *ngIf="!load"
        >
          <div class="card">
              <div class="card-header card-header-border-bottom">
                  <h2>atualizar fornecedor</h2>
                </div>
            <br />
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-md-2">
                  <label>Nome: </label>
  
                  <input
                    class="form-control"
                    id="txtUserName"
                    placeholder="Enter nome"
                    formControlName="Nome"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      fornecedorForm.get('Nome').touched &&
                      fornecedorForm.get('Nome').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
  
                <div class="form-group col-md-3">
                  <label for="txtFullName">CNPJ: * </label>
                  <label>CNPJ: * </label>
                  <input
                    formControlName="Documento"
                    placeholder="Seu Cnpj"
                    class="form-control"
                    type="text"
                    mask="00.000.000/0000-00"
                  />
                  <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      fornecedorForm.get('Documento').touched &&
                      fornecedorForm.get('Documento').errors?.required
                    "
                    >Este campo é obrigatório!</span
                  >
                </div>
 

                <div class="form-group col-md-2">
                    <label for="txtNumber">Telefone: * </label>
                    <input
                      class="form-control"
                      id="txtNumber"
                      placeholder="Enter Number"
                      formControlName="Telefone"
                      mask="(00) 00000-0000"
                    />
                    <span
                      class="mt-2 d-block text-danger"
                      *ngIf="
                        fornecedorForm.get('Telefone')
                          .touched &&
                          fornecedorForm.get('Telefone').errors
                          ?.required
                      "
                      >Este campo é obrigatório!</span
                    >
                  </div>

                  <div class="form-group col-md-3">
                    <label>E-mail*: </label>
                    <input
                      class="form-control"
                      id="txtUserName"
                      placeholder="Enter e-mail"
                      formControlName="Email"
                    />
                    <span
                      class="mt-2 d-block text-danger"
                      *ngIf="
                        fornecedorForm.get('Email').touched &&
                        fornecedorForm.get('Email').errors?.required
                      "
                      >Este campo é obrigatório!</span
                    >
                    <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      fornecedorForm.get('Email')
                        .touched &&
                      fornecedorForm.get('Email').errors
                        ?.email
                    "
                    >Este email é inválido</span
                  >
                  </div>

              </div>
  
              <div class="form-row">
                  <!-- <div class="form-group col-md-4">
                    <label for="txtNumber">Telefone: * </label>
                    <input
                      class="form-control"
                      id="txtNumber"
                      placeholder="Enter Number"
                      formControlName="Telefone"
                      mask="(00) 00000-0000"
                    />
                    <span
                      class="mt-2 d-block text-danger"
                      *ngIf="
                        fornecedorForm.get('Telefone')
                          .touched &&
                          fornecedorForm.get('Telefone').errors
                          ?.required
                      "
                      >Este campo é obrigatório!</span
                    >
                  </div>

                  <div class="form-group col-md-4">
                    <label>E-mail*: </label>
                    <input
                      class="form-control"
                      id="txtUserName"
                      placeholder="Enter e-mail"
                      formControlName="Email"
                    />
                    <span
                      class="mt-2 d-block text-danger"
                      *ngIf="
                        fornecedorForm.get('Email').touched &&
                        fornecedorForm.get('Email').errors?.required
                      "
                      >Este campo é obrigatório!</span
                    >
                    <span
                    class="mt-2 d-block text-danger"
                    *ngIf="
                      fornecedorForm.get('Email')
                        .touched &&
                      fornecedorForm.get('Email').errors
                        ?.email
                    "
                    >Este email é inválido</span
                  >
                  </div> -->
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div col-12 col-xs-6 col-md-4 col-lg-3 style="display:flex">
                  <button class="btn btn-danger" (click)="redirectToProviderList()">
                    Cancelar
                  </button>
                  <input type="submit"
                         value="Atualizar"
                         class="btn btn-block btn-primary ml-3"
                         [disabled]="!fornecedorForm.valid" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
