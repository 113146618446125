import { rootRouterConfig } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login.component';
import { ClientsComponent } from './clients/clients.component';
import { RegisterComponent } from './clients/register/register.component';

import { UserService } from './shared/user.service';

import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ListagemComponent } from './clients/listagem/listagem.component';



import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EdicaoComponent } from './clients/edicao/edicao.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localeBr from '@angular/common/locales/pt';
import { PagesCodeComponent } from './shared/pages-code/pages-code.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ListagemContatosComponent } from './contacts/listagem-contatos/listagem-contatos.component';
import { RegistroContatoComponent } from './contacts/registro-contato/registro-contato.component';
import { EdicaoContatoComponent } from './contacts/edicao-contato/edicao-contato.component';
import { GroupsComponent } from './groups/groups.component';
import { ListagemGruposComponent } from './groups/listagem-grupos/listagem-grupos.component';
import { CriacaoGruposComponent } from './groups/criacao-grupos/criacao-grupos.component';
import { EdicaoGruposComponent } from './groups/edicao-grupos/edicao-grupos.component';
import { LoadGruposContatosPipe } from './contacts/edicao-contato/loadGruposContatos.pipe';
import { UsersComponent } from './users/users.component';
import { ListagemUsuariosComponent } from './users/listagem-usuarios/listagem-usuarios.component';
import { CriacaoUsuariosComponent } from './users/criacao-usuarios/criacao-usuarios.component';
import { EdicaoUsuariosComponent } from './users/edicao-usuarios/edicao-usuarios.component';
import { ProvidersComponent } from './providers/providers.component';
import { ListagemFornecedoresComponent } from './providers/listagem-fornecedores/listagem-fornecedores.component';
import { EdicaoFornecedoresComponent } from './providers/edicao-fornecedores/edicao-fornecedores.component';
import { UsersForProvidersComponent } from './users-for-providers/users-for-providers.component';
import { ListagemUsersForProvidersComponent } from './users-for-providers/listagem-users-for-providers/listagem-users-for-providers.component';
import { MessageComponent } from './message/message.component';
import { SendmessageComponent } from './message/sendmessage/sendmessage.component';
import { HistoricsendmessageComponent } from './message/historicsendmessage/historicsendmessage.component';
import { HistoricSmsComponent } from './message/historic-sms/historic-sms.component';

import { ConversaoStatusSmsPipe } from './message/ConversaoStatusSms.pipe';
import { SendFileSmsComponent } from './message/send-file-sms/send-file-sms.component';
import { BlacklistComponent } from './contacts/blacklist/blacklist.component';
import { ReceivedSmsComponent } from './message/received-sms/received-sms.component';
import { ImportsComponent } from './contacts/imports/imports.component';


import { DatePipe } from '@angular/common';
import { ModelosTemplatesmsComponent } from './message/modelos-templatesms/modelos-templatesms.component';
import { ReportsComponent } from './reports/reports.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SaldoComponent } from './saldo/saldo.component';
import { TransferenciaComponent } from './saldo/transferencia/transferencia.component';
import { ConfiguracaoComponent } from './configuracao/configuracao.component';
import { SenhaComponent } from './configuracao/senhaConfiguracao/senha.component';
import { PerfilComponent } from './configuracao/perfilConfiguracao/perfil.component';
import { AuditoriaComponent } from './auditoria/auditoria.component';
import { AtividadesUsersComponent } from './auditoria/atividades-users/atividades-users.component';
import { PesquisaNumeroComponent } from './configuracao/pesquisa-numero/pesquisa-numero.component';
import { MsganiversariantesComponent } from './message/msganiversariantes/msganiversariantes.component';
import { MsgsuspeitasComponent } from './message/msgsuspeitas/msgsuspeitas.component';
import { PalavrasuspComponent } from './message/msgsuspeitas/palavrasusp/palavrasusp.component';
import { LinkssuspComponent } from './message/msgsuspeitas/linkssusp/linkssusp.component';
import { LiberacaosuspComponent } from './message/msgsuspeitas/liberacaosusp/liberacaosusp.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HelpComponent } from './help/help.component';

import { NgProgressModule } from 'ngx-progressbar';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ReportsDetailsComponent } from './reports/reports-details/reports-details.component';
import { DesenvolvedorComponent } from './configuracao/desenvolvedorConfiguracao/desenvolvedor.component';
import { HistoricoLoginComponent } from './auditoria/historico_login/historico-login.component';
registerLocaleData(localeBr, 'pt');


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    ClientsComponent,
    RegisterComponent,
    ListagemComponent,
    EdicaoComponent,
    ResetPasswordComponent,
    PagesCodeComponent,
    ContactsComponent,
    ListagemContatosComponent,
    RegistroContatoComponent,
    EdicaoContatoComponent,
    GroupsComponent,
    ListagemGruposComponent,
    CriacaoGruposComponent,
    EdicaoGruposComponent,
    LoadGruposContatosPipe,
    UsersComponent,
    ListagemUsuariosComponent,
    CriacaoUsuariosComponent,
    EdicaoUsuariosComponent,
    ProvidersComponent,
    ListagemFornecedoresComponent,
    EdicaoFornecedoresComponent,
    UsersForProvidersComponent,
    ListagemUsersForProvidersComponent,
    MessageComponent,
    SendmessageComponent,
    HistoricsendmessageComponent,
    HistoricSmsComponent,
    ConversaoStatusSmsPipe,
    SendFileSmsComponent,
    BlacklistComponent,
    ReceivedSmsComponent,
    ImportsComponent,
    ModelosTemplatesmsComponent,
    ReportsComponent,
    ReportsDetailsComponent,
    SaldoComponent,
    TransferenciaComponent,
    ConfiguracaoComponent,
    SenhaComponent,
    PerfilComponent,
    AuditoriaComponent,
    AtividadesUsersComponent,
    PesquisaNumeroComponent,
    MsganiversariantesComponent,
    MsgsuspeitasComponent,
    PalavrasuspComponent,
    LinkssuspComponent,
    LiberacaosuspComponent,
    HelpComponent,
    DesenvolvedorComponent,
    HistoricoLoginComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    [RouterModule.forRoot(rootRouterConfig, { relativeLinkResolution: 'legacy' })],
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    NgxDatatableModule,
    CommonModule,
    ChartsModule,
    NgxCaptchaModule,
    NgProgressModule.withConfig({
      spinner: false
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [UserService, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }, DatePipe, ConversaoStatusSmsPipe, { provide: LOCALE_ID, useValue: 'pt' }, ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
