<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-email text-blue mr-4 font-size-20"></i>
  <h1>Histórico de Mensagens</h1>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <!-- <div>
      <a type="button" class="btn btn-primary" [routerLink]="['/users/register']">
        <i class="mdi mdi-plus mr-1"></i> Novo usuário
      </a>
    </div> -->
  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="modal"
        data-target="#modalPeriodo" aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <!--<div class="dropdown-menu"
           aria-labelledby="dropdownMenuButton"
           style="right: 0; left: initial">-->
      <!--<a class="dropdown-item" (click)="exportaHistoricoMensagensEnviadas()">Exportar como Excel</a>-->
      <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
      <!--</div>-->
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header mb-0">
        <div class="row w-100 justify-content-between">
          <div class="dropdown d-inline-block mb-0">
            <button class="btn btn-primary dropdown-toggle" style="height:45px" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
              Filtrar por
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" style="cursor:pointer" (click)="listaMensagensEnviadas(1)">Todas</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listaMensagensEnviadas(1,1)">Agendadas</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listaMensagensEnviadas(1,0)">Pendente</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listaMensagensEnviadas(1,2)">Em Andamento</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listaMensagensEnviadas(1,3)">Finalizadas</a>
            </div>
          </div>
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate(1)" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="card-body pt-0 pb-5" *ngIf="!load">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Data do envio</th>
              <th scope="col">Usuario</th>
              <th scope="col">Mensagem</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Tipo</th>
              <th scope="col">Estado</th>
              <th scope="col">Visualizar</th>
            </tr>
          </thead>
          <tbody *ngFor="let msgEnviada of listamsgEnviadas">
            <tr>
              <td scope="row">{{ msgEnviada.message_id }}</td>
              <td>{{ msgEnviada.scheduled }}</td>
              <td>{{ msgEnviada.fullName }}</td>
              <td [title]="msgEnviada.content">{{ msgEnviada.content }}</td>
              <td>{{msgEnviada.qunt}}</td>
              <td>{{msgEnviada.tipo}}</td>
              <td style="vertical-align:middle">
                <div class="badge badge-pill"
                  [ngClass]="{'badge-warning':msgEnviada.estado === 'Em Andamento','badge-success': msgEnviada.estado === 'Finalizada','badge-primary': msgEnviada.estado === 'Agendada','badge-secondary': msgEnviada.estado === 'Pendente'}">
                  {{msgEnviada.estado}}
                </div>
              </td>
              <td>
                <div class="dropdown widget-dropdown">
                  <button data-toggle="tooltip" title="Detalhar Envio" class="btn btn-sm btn-primary"
                    style="text-decoration: none;" (click)="detalharMsg(msgEnviada.message_id)">
                    <span class="mdi mdi-magnify" style="font-size: 20px;"></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="form-inline justify-content-between" *ngIf="!load">
  <!-- <nav aria-label="Page navigation example right"> -->
  <ul class="pagination ">
    <li style="cursor: pointer" [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li style="cursor: pointer" [ngClass]="
        this.hasNextPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
  <!-- </nav> -->
</div>

<!-- detalhes do cliente -->
<div class="modal fade" id="modalDetalhesMensagensEnviadas" data-keyboard="false" data-backdrop="static" tabindex="-1"
  aria-labelledby="modalDetalhesMensagensEnviadas" style="display: none" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Detalhes de mensagens enviadas
        </h5>
        <button type="button" data-dismiss="modal" (click)="closeModal()">
          <span data-dismiss="modal" aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">

          <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Mensagem</th>
                <th scope="col">Números</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody *ngFor="let msg of mensagensEnviadas">
              <tr>
                <td scope="row">{{ msg.id }}</td>
                <td [title]="msg.content">{{ msg.content }}</td>
                <td>{{ msg.number }}</td>
                <td>{{ msg.statusName}}</td>

              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- escolha de periodo -->
<div class="modal fade" id="modalPeriodo" data-keyboard="false" data-backdrop="static" tabindex="-1"
  aria-labelledby="modalDetalhesMensagensEnviadas" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Escolha um periodo para a exportação do relatorio
        </h5>
        <button type="button" data-dismiss="modal" (click)="closeModal()">
          <span data-dismiss="modal" aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <div style="display: flex">
            <div class="form-group" style="flex: 1">
              <label>De</label>
              <!--<label *ngIf="formulario.invalid"><span style="color:red">Obrigatório</span></label>-->
              <input [(ngModel)]="PeriodoDe" class="form-control" type="date" [value]="PeriodoDe" />
            </div>
            <div class="form-group" style="margin: 40px 15px 0 15px">
              <span>à</span>
            </div>
            <div class="form-group" style="flex: 1">
              <label>Até</label>
              <input [(ngModel)]="PeriodoAte" class="form-control" type="date" [value]="PeriodoAte" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal"
          (click)="exportaHistoricoMensagensEnviadas(PeriodoDe, PeriodoAte)">
          Exportar
        </button>
      </div>
    </div>
  </div>
</div>