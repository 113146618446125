<div class="breadcrumb-wrapper">
  <h1>Gerenciamento de relatórios</h1>
</div>

<!-- <div class="content"> -->
<div class="row">
  <div class="col-md-12">
    <div class="card card-default">
      <div class="card-body" style="">
        <br /><br />

        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Tipo relatório</label>
            <select (change)="selecionaTipoRelatorio($event)" class="form-control">
              <option value="null">Selecione...</option>
              <option *ngIf="CheckIfRole(['Owner'])" value="faturamento">Faturamento</option>
              <option value="mensagens_enviadas_periodo">
                Mensagens enviadas por periodo
              </option>
              <option *ngIf="CheckIfRole(['Owner'])" value="credito_sms">Crédito</option>
              <option value="mensagens_recebidas_periodo">
                Mensagens recebidas por periodo
              </option>
              <option value="acompanhamento_envio">
                Acompanhamento de envio
              </option>
            </select>
          </div>

          <div class="form-group col-md-3" *ngIf="CheckIfRole(['Owner', 'Administrador'])">
            <label for="">Clientes</label>
            <select class="form-control" name="IdCliente" (change)="selecionaCliente($event)">
              <option value="null">Selecione...</option>
              <option *ngFor="let item of listaCliente" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-md-6">
            <div style="display: flex">
              <div class="form-group" style="flex: 1">
                <label>De</label>
                <!--<label *ngIf="formulario.invalid"><span style="color:red">Obrigatório</span></label>-->
                <input [(ngModel)]="PeriodoDe" [(disabled)]="disablePeriodo" class="form-control" type="date"
                  [value]="PeriodoDe" />
              </div>
              <div class="form-group" style="margin: 40px 15px 0 15px">
                <span>à</span>
              </div>
              <div class="form-group" style="flex: 1">
                <label>Até</label>
                <input [(ngModel)]="PeriodoAte" [(disabled)]="disablePeriodo" class="form-control" type="date"
                  [value]="PeriodoAte" />
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-3">
            <label for="">Formato</label>
            <select class="form-control" (change)="selecionaFormato($event.target.value)">
              <option selected value="null">Selecione...</option>
              <option value="consolidado">Consolidado</option>
              <option value="detalhado">Detalhado</option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <button type="button" id="reportPasswordButton" class="btn btn-block btn-primary button-report"
              (click)="buscaDadosRelatorio()">
              <span class="button__text">
                Gerar relátorio
              </span>
            </button>
          </div>
        </div>

        <!-- <div class="form-row">
          <div class="form-group col-md-6">
            <label>Tipo relatório</label>
            <select
              (change)="selecionaTipoRelatorio($event)"
              class="form-control"
            >
              <option value="faturamento">Faturamento</option>
              <option value="acompanhamento_envio">
                Acompanhamento de envio
              </option>
            </select>
          </div>

          <div
            class="form-group col-md-6"
            *ngIf="userService.CanAcessRole(['Owner', 'Administrador'])"
          >
            <label for="">Clientes</label>
            <select
              class="form-control"
              name="IdCliente"
              [(ngModel)]="IdCliente"
            >
              <option
                selected
                *ngFor="let item of listaCliente"
                [value]="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-5">
            <label>De</label>
            <input
              [(ngModel)]="Periodo"
              [(disabled)]="disablePeriodo"
              class="form-control"
              type="date"
              [value]="Periodo"
            />
          </div>
          <div class="form-group col-md-1" style="text-align: center; top:50px ;"><span>à</span></div>
          <div class="form-group col-md-5">
            <label>Até</label>
            <input
              [(ngModel)]="Periodo"
              [(disabled)]="disablePeriodo"
              class="form-control"
              type="date"
              [value]="Periodo"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="">Formato</label>
            <select class="form-control" [(ngModel)]="Formato">
              <option value="consolidado">Consolidado</option>
              <option value="detalhado">Detalhado</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <button
              style="text-align: center"
              type="button"
              style="margin-bottom: 10px"
              class="btn btn-block btn-primary"
              (click)="buscaDadosRelatorio()"
            >
              Gerar relátorio
            </button>
          </div>
        </div> -->
      </div>
    </div>

    <!-- relatorio acompanhamento de envio atual e por periodo (detalhado) mensagens e numeros -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && Formato === 'detalhado' && listaRelatorioAcompanhamentoEnvioMensagensDetalhado.length">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel(Mensagens)</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV(Mensagens)</a>
              <a class="dropdown-item" (click)="exportarNumeros(false)" style="cursor: pointer">Exportar como
                Excel(Números)</a>
              <a class="dropdown-item" (click)="exportarNumeros(true)" style="cursor: pointer">Exportar como
                CSV(Números)</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>
      <div class="card-body pt-0">
        <ul class="nav nav-pills mb-5 mt-5 nav-style-fill nav-justified" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link"
              [ngClass]="{active:tipoListaDetalhesRelatorioAcompanhamento === 'mensagens' && disponivelRelatorio}"
              id="pills-home-tab" data-toggle="pill" href="#pills-home" (click)="buscaDadosRelatorio()" role="tab"
              style="cursor: pointer">Mensagens</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" id="pills-profile-tab"
              [ngClass]="{active: tipoListaDetalhesRelatorioAcompanhamento === 'numeros' && disponivelRelatorio}"
              data-toggle="pill" href="#pills-profile" (click)="mapeiaMensagensParaNumero()" role="tab"
              style="cursor: pointer">Números</a>
          </li> -->
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"
            *ngIf="tipoListaDetalhesRelatorioAcompanhamento === 'mensagens' && disponivelRelatorio">
            <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Data do envio</th>
                  <th scope="col">Mensagem Enviada</th>
                  <th scope="col">Q. Numeros</th>
                  <th scope="col">Q. de Sucesso</th>
                  <th scope="col">Q. de Erros</th>
                  <th scope="col">Q. de Creditos</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Tipo Sms</th>
                </tr>
              </thead>
              <tbody *ngIf="listaRelatorioAcompanhamentoEnvioMensagensDetalhado.length">
                <tr *ngFor="let item of listaRelatorioAcompanhamentoEnvioMensagensDetalhado">
                  <td scope="row" style="text-decoration:underline;cursor:pointer"
                    (click)="detailReport(item.messageId)">{{ item.messageId }}</td>
                  <td>{{ item.createdOn }}</td>
                  <td>{{ item.messageContent }}</td>
                  <td>{{ item.countNumbers }}</td>
                  <td>{{ item.countSuccess }}</td>
                  <td>{{ item.countErros }}</td>
                  <td>{{ item.credits }}</td>
                  <td>{{ item.user }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item.smsType }}</td>
                  <!-- <td>
                    <div class="dropdown widget-dropdown">
                      <a
                      style="text-decoration: none;color: black;"
                      [routerLink]="['/message/sms', msgEnviada.message_id]"
                      >
                      <span class="mdi mdi-clipboard-text-search-outline" style="font-size: 20px;"></span>
                    </a>
                    </div>
                  </td> -->
                </tr>
              </tbody>
              <tfoot *ngIf="!listaRelatorioAcompanhamentoEnvioMensagensDetalhado.length">
                <tr>
                  <td>Não encontrado dados</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
            *ngIf="tipoListaDetalhesRelatorioAcompanhamento === 'numeros' && disponivelRelatorio"
            style="width: 100%; overflow-x: scroll">
            <table class="table table-responsive table-hover" style="width: 100%">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Número</th>
                  <th scope="col">Status</th>
                  <th scope="col" style="min-width:150px">Data do Envio</th>
                  <th scope="col" style="min-width:150px">Data do Retorno</th>
                  <!--<th scope="col">Prestadora</th>-->
                  <th scope="col">Operadora</th>
                  <th scope="col" style="min-width: 400px">Mensagem Enviada</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Cliente</th>
                  <th scope="col" style="min-width:150px">Tipo de Sms</th>
                  <ng-container *ngIf="paramsCount">
                    <th scope="col" style="min-width:150px" *ngFor="
                        let item of [].constructor(7);
                        let i = index
                      ">
                      Parâmetro {{ i + 2 }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <!-- <div *ngIf="listaRelatorioAcompanhamentoEnvioNumeros.length"> -->
              <tbody *ngIf="listaRelatorioAcompanhamentoEnvioNumeros.length">
                <tr *ngFor="let item of listaRelatorioAcompanhamentoEnvioNumeros">
                  <td scope="row">{{ item.messageId }}</td>
                  <td>{{ item.number }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.scheduled }}</td>
                  <td>{{ item.lastUpdateOn }}</td>
                  <!--<td>{{ item.mobileOperatorName }}</td>-->
                  <td>{{ item.operator }}</td>
                  <td>{{ item.content }}</td>
                  <td>{{ item.user }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item.smsType }}</td>
                  <td *ngFor="let x of [].constructor(paramsCount-1);
                        let i = index
                      ">
                    {{ item.paramValues[i]? item.paramValues[i]: "" }}
                  </td>

                  <!-- <td>
                    <div class="dropdown widget-dropdown">
                      <a
                      style="text-decoration: none;color: black;"
                      [routerLink]="['/message/sms', msgEnviada.message_id]"
                      >
                      <span class="mdi mdi-clipboard-text-search-outline" style="font-size: 20px;"></span>
                    </a>
                    </div>
                  </td> -->
                </tr>
              </tbody>
              <!-- </div> -->
              <tfoot *ngIf="!listaRelatorioAcompanhamentoEnvioNumeros.length">
                <tr>
                  <td>Não encontrado registros</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- relatorio acompanhamento de envio atual e por periodo (consolidado) -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && Formato === 'consolidado' && listaRelatorioAcompanhamentoEnvioMensagensConsolidado.length && this.TipoRelatorio === 'acompanhamento_envio'">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV</a>
              <a class="dropdown-item" (click)="exportarRelatorio('PDF')" style="cursor: pointer">Exportar como PDF</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>

      <div *ngIf="listaRelatorioAcompanhamentoEnvioMensagensConsolidado.length > 0"
        style="display: flex;align-self:center;width:50%">
        <canvas *ngIf="this.TipoRelatorio === 'acompanhamento_envio'" baseChart [datasets]="barChartData"
          [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType"
          [colors]="donutColors">
        </canvas>
      </div>
      <br /><br />

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Não Processado</th>
              <th scope="col">Sendo processado</th>
              <th scope="col">Não enviado</th>
              <th scope="col">Enviado</th>
              <th scope="col">Enviado com confirmação</th>
              <th scope="col">Total</th>
              <!-- <th scope="col">Sucesso</th>
              <th scope="col">Erro</th> -->
            </tr>
          </thead>
          <tbody *ngFor="let item of listaRelatorioAcompanhamentoEnvioMensagensConsolidado">
            <tr style="cursor: pointer">
              <td scope="row" style="text-decoration:underline;cursor:pointer">
                {{item.data_consolidado}}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([1,0],item.data_consolidado)">
                {{
                item.nao_processado
                }}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([2],item.data_consolidado)">
                {{
                item.sendo_processado
                }}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([4,3,-1],item.data_consolidado)">
                {{item.nao_enviado + item.nao_tarifado}}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([5],item.data_consolidado)">
                {{
                item.enviado
                }}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([6],item.data_consolidado)">
                {{
                item.enviado_confirmacao
                }}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([-1],item.data_consolidado)">
                {{
                item.total
                }}
              </td>
              <!-- <td>{{ item.sucesso }}</td>
              <td>{{ item.erro }}</td> -->
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioAcompanhamentoEnvioMensagensConsolidado">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio mensagens enviadas por periodo (detalhado) mensagens e numeros -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && Formato === 'detalhado' && listaRelatorioMensagensEnviadasDetalhado.length">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel(Mensagens)</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV(Mensagens)</a>
              <a class="dropdown-item" (click)="exportarNumeros(false)" style="cursor: pointer">Exportar como
                Excel(Números)</a>
              <a class="dropdown-item" (click)="exportarNumeros(true)" style="cursor: pointer">Exportar como
                CSV(Números)</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>
      <div class="card-body pt-0">
        <ul class="nav nav-pills mb-5 mt-5 nav-style-fill nav-justified" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link"
              [ngClass]="{active:tipoListaDetalhesRelatorioAcompanhamento === 'mensagens' && disponivelRelatorio}"
              id="pills-home-tab" data-toggle="pill" href="#pills-home" (click)="buscaDadosRelatorio()" role="tab"
              style="cursor: pointer">Mensagens</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" id="pills-profile-tab"
              [ngClass]="{active: tipoListaDetalhesRelatorioAcompanhamento === 'numeros' && disponivelRelatorio}"
              data-toggle="pill" href="#pills-profile" (click)="mapeiaMensagensParaNumero()" role="tab"
              style="cursor: pointer">Números</a>
          </li> -->
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"
            *ngIf="tipoListaDetalhesRelatorioAcompanhamento === 'mensagens' && disponivelRelatorio">
            <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Data do envio</th>
                  <th scope="col">Mensagem Enviada</th>
                  <th scope="col">Q. Numeros</th>
                  <th scope="col">Q. de Sucesso</th>
                  <th scope="col">Q. de Erros</th>
                  <th scope="col">Q. de Creditos</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Tipo Sms</th>
                </tr>
              </thead>
              <tbody *ngIf="listaRelatorioMensagensEnviadasDetalhado.length">
                <tr *ngFor="let item of listaRelatorioMensagensEnviadasDetalhado">
                  <td scope="row" style="text-decoration:underline; cursor:pointer"
                    (click)="detailReport(item.messageId)">{{ item.messageId }}</td>
                  <td>{{ item.createdOn }}</td>
                  <td>{{ item.messageContent }}</td>
                  <td>{{ item.countNumbers }}</td>
                  <td>{{ item.countSuccess }}</td>
                  <td>{{ item.countErros }}</td>
                  <td>{{ item.credits }}</td>
                  <td>{{ item.user }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item.smsType }}</td>
                  <!-- <td>
                    <div class="dropdown widget-dropdown">
                      <a
                      style="text-decoration: none;color: black;"
                      [routerLink]="['/message/sms', msgEnviada.message_id]"
                      >
                      <span class="mdi mdi-clipboard-text-search-outline" style="font-size: 20px;"></span>
                    </a>
                    </div>
                  </td> -->
                </tr>
              </tbody>
              <tfoot *ngIf="!listaRelatorioMensagensEnviadasDetalhado.length">
                <tr>
                  <td>Não encontrado dados</td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"
            *ngIf="tipoListaDetalhesRelatorioAcompanhamento === 'numeros' && disponivelRelatorio"
            style="width: 100%; overflow-x: scroll">
            <table class="table table-responsive table-hover" style="width: 100%">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Número</th>
                  <th scope="col">Status</th>
                  <th scope="col" style="min-width:150px">Data do Envio</th>
                  <th scope="col" style="min-width:150px">Data do Retorno</th>
                  <!--<th scope="col">Prestadora</th>-->
                  <th scope="col">Operadora</th>
                  <th scope="col" style="min-width: 400px">Mensagem Enviada</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Cliente</th>
                  <th scope="col" style="min-width:150px">Tipo de Sms</th>
                  <ng-container *ngIf="paramsCount">
                    <th scope="col" style="min-width:150px" *ngFor="
                        let item of [].constructor(7);
                        let i = index
                      ">
                      Parâmetro {{ i + 2 }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <!-- <div *ngIf="listaRelatorioAcompanhamentoEnvioNumeros.length"> -->
              <tbody *ngIf="listaRelatorioMensagensEnviadasNumeros.length">
                <tr *ngFor="let item of listaRelatorioMensagensEnviadasNumeros">
                  <td scope="row">{{ item.messageId }}</td>
                  <td>{{ item.number }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.scheduled }}</td>
                  <td>{{ item.lastUpdateOn }}</td>
                  <!--<td>{{ item.mobileOperatorName }}</td>-->
                  <td>{{ item.operator }}</td>
                  <td>{{ item.content }}</td>
                  <td>{{ item.user }}</td>
                  <td>{{ item.client }}</td>
                  <td>{{ item.smsType }}</td>
                  <td *ngFor="let x of [].constructor(paramsCount-1);
                        let i = index
                      ">
                    {{ item.paramValues[i]? item.paramValues[i]: "" }}
                  </td>

                  <!-- <td>
                    <div class="dropdown widget-dropdown">
                      <a
                      style="text-decoration: none;color: black;"
                      [routerLink]="['/message/sms', msgEnviada.message_id]"
                      >
                      <span class="mdi mdi-clipboard-text-search-outline" style="font-size: 20px;"></span>
                    </a>
                    </div>
                  </td> -->
                </tr>
              </tbody>
              <!-- </div> -->
              <tfoot *ngIf="!listaRelatorioMensagensEnviadasNumeros.length">
                <tr>
                  <td>Não encontrado registros</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- relatorio mensagem atual e por periodo (consolidado) -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && Formato === 'consolidado' && listaRelatorioMensagensEnviadasConsolidado.length > 0 && this.TipoRelatorio === 'mensagens_enviadas_periodo'">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV</a>
              <a class="dropdown-item" (click)="exportarRelatorio('PDF')" style="cursor: pointer">Exportar como PDF</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>

      <div *ngIf="listaRelatorioMensagensEnviadasConsolidado" style="display: flex;align-self:center;width:50%">

        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
          [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend">
        </canvas>
      </div>
      <br /><br />

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Não Tarifado/Não Enviado</th>
              <!--<th scope="col">Sendo processado</th>-->
              <th scope="col">Não enviado</th>
              <th scope="col">Enviado</th>
              <th scope="col">Crédito</th>
              <th scope="col">Total</th>
              <!-- <th scope="col">Sucesso</th>
              <th scope="col">Erro</th> -->
            </tr>
          </thead>
          <tbody *ngFor="let item of listaRelatorioMensagensEnviadasConsolidado">
            <tr style="cursor: pointer">
              <td scope="row">
                {{item.data_consolidado}}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([3,1,0,-1],item.data_consolidado)">
                {{
                item.nao_processado + item.nao_tarifado
                }}
              </td>
              <!--<td (click)="mapeiaMensagensConsolidadoParaDetalhado(3)">
                {{
                item.sendo_processado
                }}
              </td>-->
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([4],item.data_consolidado)">
                {{
                item.nao_enviado
                }}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([5,6],item.data_consolidado)">
                {{
                item.enviado
                }}
              </td>
              <td>
                {{
                item.creditos
                }}
              </td>
              <td style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([-1],item.data_consolidado)">
                {{
                item.total
                }}
              </td>
              <!-- <td>{{ item.sucesso }}</td>
              <td>{{ item.erro }}</td> -->
            </tr>
          </tbody>
          <tbody *ngIf="listaRelatorioAcompanhamentoEnvioMensagensConsolidado" style="background-color:gainsboro">
            <tr>
              <th scope="col">TOTAL</th>
              <th scope="col" style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([3,1],listaRelatorioMensagensEnviadasConsolidado[0].data_consolidado+'a'+listaRelatorioMensagensEnviadasConsolidado[listaRelatorioMensagensEnviadasConsolidado.length -1].data_consolidado)">
                {{SomaMsgEnviadaConsolidado.nao_processado + SomaMsgEnviadaConsolidado.nao_tarifado}}</th>
              <!--<th scope="col">Sendo processado</th>-->
              <th scope="col" style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([4],listaRelatorioMensagensEnviadasConsolidado[0].data_consolidado+'a'+listaRelatorioMensagensEnviadasConsolidado[listaRelatorioMensagensEnviadasConsolidado.length -1].data_consolidado)">
                {{SomaMsgEnviadaConsolidado.nao_enviado}}</th>
              <th scope="col" style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([5,6],listaRelatorioMensagensEnviadasConsolidado[0].data_consolidado+'a'+listaRelatorioMensagensEnviadasConsolidado[listaRelatorioMensagensEnviadasConsolidado.length -1].data_consolidado)">
                {{SomaMsgEnviadaConsolidado.enviado}}</th>
              <th scope="col">{{SomaMsgEnviadaConsolidado.creditos}}</th>
              <th scope="col" style="text-decoration:underline;cursor:pointer"
                (click)="mapeiaMensagensConsolidadoParaDetalhado([-1],listaRelatorioMensagensEnviadasConsolidado[0].data_consolidado+'a'+listaRelatorioMensagensEnviadasConsolidado[listaRelatorioMensagensEnviadasConsolidado.length -1].data_consolidado)">
                {{SomaMsgEnviadaConsolidado.nao_processado + SomaMsgEnviadaConsolidado.nao_enviado +
                SomaMsgEnviadaConsolidado.enviado + SomaMsgEnviadaConsolidado.nao_tarifado}}</th>
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioAcompanhamentoEnvioMensagensConsolidado">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio credito consolidado -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && TipoRelatorio === 'credito_sms' && Formato === 'consolidado' && listaRelatorioCreditoConsolidado">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV</a>
              <a class="dropdown-item" (click)="exportarRelatorio('PDF')" style="cursor: pointer">PDF</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>

      <div *ngIf="listaRelatorioCreditoConsolidado" style="display: flex;align-self:center;width:50%">
        <canvas *ngIf="this.TipoRelatorio === 'credito_sms' && listaRelatorioCreditoConsolidado" baseChart
          [datasets]="barChartDataCredito" [labels]="barChartLabelsCredito" [options]="barChartOptionsCredito"
          [legend]="barChartLegendCredito" [chartType]="barChartTypeCredito" [colors]="donutColorsCredito">
        </canvas>
      </div>
      <br /><br />

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Saldo</th>
              <th scope="col">Status</th>
              <!-- <th scope="col">Sucesso</th>
              <th scope="col">Erro</th> -->
            </tr>
          </thead>
          <tbody *ngIf="listaRelatorioCreditoConsolidado">
            <tr (click)="Formato = 'detalhado'" style="cursor: pointer">
              <td scope="row">
                {{ listaRelatorioCreditoConsolidado.cliente }}
              </td>
              <td>
                {{ listaRelatorioCreditoConsolidado.saldo }}
              </td>
              <td>
                {{ listaRelatorioCreditoConsolidado.status }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioCreditoConsolidado">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio detalhado credito -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && TipoRelatorio === 'credito_sms' && Formato === 'detalhado' && listaRelatorioCreditoDetalhado.length > 0">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório detalhado</h3>
      </div>

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Data de inclusão do crédito</th>
              <th scope="col">Quantidade de créditos</th>
              <th scope="col">Quantidade utilizada</th>
              <th scope="col">Data vencimento</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody *ngIf="listaRelatorioCreditoDetalhado.length >0">
            <tr style="cursor: pointer" *ngFor="let item of listaRelatorioCreditoDetalhado">
              <td scope="row">{{ item.cliente }}</td>
              <td>
                {{ item.dataCriacao }}
              </td>
              <td>
                {{ item.qtnCredito }}
              </td>
              <td>
                {{ item.qtnUtilizada }}
              </td>
              <td>
                {{ item.dataVencimento }}
              </td>
              <td>
                {{ item.status }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioCreditoDetalhado.length >0">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio consolidado faturamento -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && TipoRelatorio === 'faturamento' && Formato === 'consolidado' && listaRelatorioFaturamentoConsolidado">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL', false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL', true)" style="cursor: pointer">Exportar como
                CSV</a>
              <a class="dropdown-item" (click)="exportarRelatorio('PDF')" style="cursor: pointer">Exportar como PDF</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>

      <div *ngIf="listaRelatorioFaturamentoConsolidado" style="display: flex;align-self:center;width:50%">
        <canvas *ngIf="this.TipoRelatorio === 'faturamento' && listaRelatorioFaturamentoConsolidado" baseChart
          [datasets]="barChartDataFaturamento" [labels]="barChartLabelsFaturamento"
          [options]="barChartOptionsFaturamento" [legend]="barChartLegendFaturamento"
          [chartType]="barChartTypeFaturamento" [colors]="donutColorsFaturamento">
        </canvas>
      </div>
      <br /><br />

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Período</th>
              <th scope="col">Cliente</th>
              <th scope="col">Faturamento</th>
            </tr>
          </thead>
          <tbody *ngIf="listaRelatorioFaturamentoConsolidado">
            <tr (click)="Formato = 'detalhado'" style="cursor: pointer">
              <td scope="row">
                {{ listaRelatorioFaturamentoConsolidado.periodo }}
              </td>
              <td>
                {{ listaRelatorioFaturamentoConsolidado.cliente }}
              </td>
              <td>
                {{ listaRelatorioFaturamentoConsolidado.faturamento }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioFaturamentoConsolidado">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio detalhado faturamento -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && TipoRelatorio === 'faturamento' && Formato === 'detalhado' && listaRelatorioFatumentoDetalhado.length">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL', false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL', true)" style="cursor: pointer">Exportar como
                CSV</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório detalhado</h3>
      </div>

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Período</th>
              <th scope="col">Cliente</th>
              <th scope="col">Não enviado</th>
              <th scope="col">Não tarifado/Não enviado</th>
              <th scope="col">Enviado</th>
              <th scope="col">Mensagens recebidas</th>
              <th scope="col">Créditos</th>
              <th scope="col">Total de números</th>
              <th scope="col">Total tarifado</th>
              <th scope="col">Faturamento</th>
            </tr>
          </thead>
          <tbody *ngIf="listaRelatorioFatumentoDetalhado.length">
            <tr style="cursor: pointer" *ngFor="let item of listaRelatorioFatumentoDetalhado">
              <td scope="row">{{ item.periodo }}</td>
              <td>
                {{ item.cliente }}
              </td>
              <td>
                {{ item.naoEnviadas }}
              </td>
              <td>
                {{ item.naoTarifadas }}
              </td>
              <td>
                {{ item.enviadas }}
              </td>
              <td>
                {{ item.msgRecebidas }}
              </td>
              <td>
                {{ item.creditos }}
              </td>

              <td>
                {{ item.totalNumeros }}
              </td>
              <td>
                {{ item.totalTarifados }}
              </td>
              <td>
                {{ item.faturamento }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioFatumentoDetalhado.length">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio consolidado de mensagens recebidas por periodo -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && TipoRelatorio === 'mensagens_recebidas_periodo' && Formato === 'consolidado' && listaRelatorioMsgRecebidasConsolidado.length">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV</a>
              <a class="dropdown-item" (click)="exportarRelatorio('PDF')" style="cursor: pointer">PDF</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório {{ nomeRelatorio }}</h3>
      </div>

      <div *ngIf="listaRelatorioMsgRecebidasConsolidado.length" style="display: flex;align-self:center;width:50%">
        <canvas
          *ngIf="this.TipoRelatorio === 'mensagens_recebidas_periodo' && listaRelatorioMsgRecebidasConsolidado.length"
          baseChart [datasets]="barChartDataMsgRecebidasPeriodo" [labels]="barChartLabelsMsgRecebidasPeriodo"
          [options]="barChartOptionsMsgRecebidasPeriodo" [legend]="barChartLegendMsgRecebidasPeriodo"
          [chartType]="barChartTypeMsgRecebidasPeriodo" [colors]="donutColorsFaturamento">
        </canvas>
      </div>
      <br /><br />

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Mensagens recebidas</th>
            </tr>
          </thead>
          <tbody *ngIf="listaRelatorioMsgRecebidasConsolidado.length">
            <tr *ngFor="let item of listaRelatorioMsgRecebidasConsolidado" (click)="Formato = 'detalhado'"
              style="cursor: pointer">
              <td scope="row">
                {{ item.date }}
              </td>
              <td>
                {{ item.totalMessages }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioMsgRecebidasConsolidado.length">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- relatorio detalhado de mensagens recebidas por periodo -->
    <div class="card card-default"
      *ngIf="disponivelRelatorio && TipoRelatorio === 'mensagens_recebidas_periodo' && Formato === 'detalhado' && listaRelatorioMsgRecebidasDetalhado.length">
      <div class="card-header justify-content-left">
        <h2 style="text-align: left">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',false)" style="cursor: pointer">Exportar como
                Excel(Mensagens)</a>
              <a class="dropdown-item" (click)="exportarRelatorio('EXCEL',true)" style="cursor: pointer">Exportar como
                CSV(Mensagens)</a>
              <a class="dropdown-item" (click)="exportarNumeros(false)" style="cursor: pointer">Exportar como
                Excel(Números)</a>
              <a class="dropdown-item" (click)="exportarNumeros(true)" style="cursor: pointer">Exportar como
                CSV(Números)</a>

              <!-- <a class="dropdown-item" href="#">Csv</a> -->
            </div>
          </div>
        </h2>
      </div>
      <div class="card-header justify-content-center">
        <h3>Relatório detalhado</h3>
      </div>

      <div class="card-body pt-0">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Mensagem Recebida</th>
              <th scope="col">Número</th>
              <th scope="col">Operadora</th>
              <th scope="col">Mensagem Id</th>
              <th scope="col">Mensagem Enviada</th>
              <th scope="col">Usuario</th>
              <th scope="col">Cliente</th>
              <th scope="col">Tipo de SMS</th>

            </tr>
          </thead>
          <tbody *ngIf="listaRelatorioMsgRecebidasDetalhado.length">
            <tr style="cursor: pointer" *ngFor="let item of listaRelatorioMsgRecebidasDetalhado">
              <td scope="row">
                {{ item.data }}
              </td>
              <td>
                {{ item.msg_recebido }}
              </td>
              <td>
                {{ item.telefone }}
              </td>
              <td>
                {{ item.mobileOperatorName }}
              </td>
              <td>
                {{ item.messageId }}
              </td>
              <td>
                {{ item.msg_enviada }}
              </td>

              <td>
                {{ item.usuario }}
              </td>
              <td>
                {{ item.cliente }}
              </td>
              <td>{{ item.tipo }}</td>

            </tr>
          </tbody>
          <tfoot *ngIf="!listaRelatorioMsgRecebidasDetalhado.length">
            <tr>
              <td>Não encontrado registros</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <button class="btn btn-primary" id="botaoModalInfoMissing" data-toggle="modal" style="display:none"
    data-target="#ModalMissingInfo">
  </button>
  <button class="btn btn-primary" id="botaoModalSemResultado" data-toggle="modal" style="display:none"
    data-target="#modalSemResultado">
  </button>
</div>
<!-- </div> -->
<!-- modal excluir -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="ModalMissingInfo" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Há campos não preenchidos
        </h5>
      </div>
      <div class="modal-body" style="white-space:pre-wrap">
        <p>{{ModalMissingInfoText}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>

      </div>
    </div>
  </div>
</div>

<!-- modal sem resultados -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="modalSemResultado" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Sem Resultados
        </h5>
      </div>
      <div class="modal-body" style="white-space:pre-wrap">
        <p>O Relatório não trouxe resultados</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>

      </div>
    </div>
  </div>
</div>