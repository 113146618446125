import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'xlsx-style';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  listaClientes() {
    return this.http.get(`${this.baseUrl}reports/getcliente`);
  }

  buscarRelatorioConsolidado(tipoRelatorio: string, idCliente: string, peridoDe: string, peridoAte: string, formato: string, searchQuery?: string, status?: string, isCsv?: boolean) {
    return this.http.get(`${this.baseUrl}reports/GetConsolidatedReport?TipoRelatorio=${tipoRelatorio}&IdCliente=${idCliente}&PeriodoDe=${peridoDe}&PeriodoAte=${peridoAte}&Formato=${formato}&SearchQuery=${searchQuery == undefined || searchQuery == null ? "" : searchQuery}&Status=${status == undefined || status == null ? " " : status}&isCsv=${isCsv == undefined || isCsv == null ? false : isCsv}`);
  }
  buscarRelatorioDetalhado(tipoRelatorio: string, idCliente: string, peridoDe: string, peridoAte: string, formato: string, searchQuery?: string, status?: string, isCsv?: boolean) {
    return this.http.get(`${this.baseUrl}reports/GetDetailedReport?TipoRelatorio=${tipoRelatorio}&IdCliente=${idCliente}&PeriodoDe=${peridoDe}&PeriodoAte=${peridoAte}&Formato=${formato}&SearchQuery=${searchQuery == undefined || searchQuery == null ? "" : searchQuery}&Status=${status == undefined || status == null ? " " : status}&isCsv=${isCsv == undefined || isCsv == null ? false : isCsv}`);
  }
  buscarRelatorioNumero(tipoRelatorio: string, idCliente: string, peridoDe: string, peridoAte: string, formato: string, searchQuery?: string, status?: string, isCsv?: boolean) {
    return this.http.get(`${this.baseUrl}reports/GetNumbersReport?TipoRelatorio=${tipoRelatorio}&IdCliente=${idCliente}&PeriodoDe=${peridoDe}&PeriodoAte=${peridoAte}&Formato=${formato}&SearchQuery=${searchQuery == undefined || searchQuery == null ? "" : searchQuery}&Status=${status == undefined || status == null ? " " : status}&isCsv=${isCsv == undefined || isCsv == null ? false : isCsv}`);
  }
  exportarRelatorioNumero(tipoRelatorio: string, idCliente: string, peridoDe: string, peridoAte: string, formato: string, searchQuery?: string, status?: string, exportar?: boolean, isCsv?: boolean) {
    return this.http.get(`${this.baseUrl}reports/GetNumbersReport?TipoRelatorio=${tipoRelatorio}&IdCliente=${idCliente}&PeriodoDe=${peridoDe}&PeriodoAte=${peridoAte}&Formato=${formato}&SearchQuery=${searchQuery == undefined || searchQuery == null ? "" : searchQuery}&Status=${status == undefined || status == null ? " " : status}&export=${exportar}&isCsv=${isCsv == undefined || isCsv == null ? false : isCsv}`, { responseType: 'blob' });
  }
  exportReportAsPDF(data: any) {
    return this.http.post(`${this.baseUrl}reports/GetReportAsPDF`, data);
  }

  exportAsExcelFileRelatorio(json: any, tipo: string, dateFrom, dateTo, isCsv) {
    switch (tipo) {
      case "relatorio_msg_enviadas_por_periodo_consolidado":

        return this.http.post(`${this.baseUrl}reports/GetReportSentMessageAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_msg_enviadas_por_periodo_mensagens_detalhado":
        return this.http.post(`${this.baseUrl}reports/GetReportSentMessageDetailedAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_msg_enviadas_por_periodo_numeros_por_periodo_detalhado":
        return this.http.post(`${this.baseUrl}reports/GetReportSentMessageNumbersAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_credito_sms_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportCreditsConsolidatedAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_credito_sms_detalhado":
        return this.http.post(`${this.baseUrl}reports/GetReportCreditsDetailedAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_acompanhamento_envio_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportSentAcompanhamentoConsolidadoAsExcel?isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_acompanhamento_envio_numeros":
        return this.http.post(`${this.baseUrl}reports/GetReportSentMessageNumbersAsExcel?isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_acompanhamento_envio_mensagens":
        return this.http.post(`${this.baseUrl}reports/GetReportSentMessageDetailedAsExcel?&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_faturamento_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportFaturamentoAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_faturamento_detalhado":
        return this.http.post(`${this.baseUrl}reports/GetReportFaturamentoDetailedAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_msg_recebidas_por_periodo_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportReceivedMessageAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
      case "relatorio_msg_recebidas_por_periodo_detalhado":
        return this.http.post(`${this.baseUrl}reports/GetReportReceivedMessageDetailedAsExcel/?dateFrom=${dateFrom}&dateTo=${dateTo}&isCsv=${isCsv}`, json, { responseType: 'blob' });
        break;
    }
  }

  exportAsPDFFileRelatorio(json: any, tipo: string, dateFrom, dateTo, client = 0) {
    switch (tipo) {
      case "relatorio_msg_enviadas_por_periodo_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportSentMessageAsPDF/?dateFrom=${dateFrom}&dateTo=${dateTo}&ClientId=${client}`, json, { responseType: 'blob' });
        break;
      case "relatorio_msg_enviadas_por_periodo_mensagens_detalhado":
        break;
      case "relatorio_msg_enviadas_por_periodo_numeros_por_periodo_detalhado":
        break;
      case "relatorio_credito_sms_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportCreditsConsolidatedAsPDF/?dateFrom=${dateFrom}&dateTo=${dateTo}&ClientId=${client}`, json, { responseType: 'blob' });
        break;
      case "relatorio_credito_sms_detalhado":
        break;
      case "relatorio_acompanhamento_envio_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportSentAcompanhamentoConsolidadoAsPDF`, json, { responseType: 'blob' });
        break;
      case "relatorio_acompanhamento_envio_numeros":
        break;
      case "relatorio_acompanhamento_envio_mensagens":
        break;
      case "relatorio_faturamento_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportFaturamentoAsPDF/?dateFrom=${dateFrom}&dateTo=${dateTo}&ClientId=${client}`, json, { responseType: 'blob' });
        break;
      case "relatorio_faturamento_detalhado":
        break;
      case "relatorio_msg_recebidas_por_periodo_consolidado":
        return this.http.post(`${this.baseUrl}reports/GetReportReceivedMessageAsPDF/?dateFrom=${dateFrom}&dateTo=${dateTo}&ClientId=${client}`, json, { responseType: 'blob' });
        break;
      case "relatorio_msg_recebidas_por_periodo_detalhado":
        break;
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  renameKey(json) {
    let obj = json[0];
    let keys = Object.keys(obj);
    for (let j = 0; j < json.length; j++) {
      obj = json[j];
      for (let i = 0; i < keys.length; i++) {
        let selectedKey = keys[i];
        switch (selectedKey) {
          case "id":
            obj["Id"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "data_consolidado":
            obj["Data"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "nao_tarifado":
            obj["Não Tarifados/Não Enviados"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "sendo_processado":
            obj["Sendo Processado"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "nao_enviado":
            obj["Não Enviados"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "enviado":
            obj["Enviados"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "enviado_confirmacao":
            obj["Enviados com Confirmação"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "creditos":
            obj["Créditos"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
          case "total":
            obj["TOTAL"] = obj[keys[i]];
            delete obj[keys[i]];
            break;
        }
      }
    }
  }



}
