import { Saldo } from './saldo.model';
import { listagem } from './listagem.model';
import { ListagemUsersService } from './listagem-users.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment'; import 'moment/locale/pt-br';
import { UserService } from 'src/app/shared/user.service';
import { User } from '../edicao/User.model';
import { SaldoService } from '../../saldo/Saldo.service';
import { UsersForProvidersService } from '../../users-for-providers/usersForProviders.service';
import { MessageService } from '../../message/menssage.service';


declare let $: any;

@Component({
  selector: 'app-listagem',
  templateUrl: './listagem.component.html',
  styleUrls: ['./listagem.component.css']
})
export class ListagemComponent implements OnInit {

  constructor(private listagemService: ListagemUsersService, private usersForProvidersService: UsersForProvidersService, private saldoService: SaldoService, private toastr: ToastrService, private spinner: NgxSpinnerService, private fb: FormBuilder, private ref: ChangeDetectorRef, public userService: UserService, public messageService: MessageService) {
    // this.criarFormSaldo()

  }

  searchQuery = '';

  descricao = '';

  page = 1;
  neighborPages = [];
  pageHistoric = 1;
  neighborPagesHistoric = [];
  maxPagesOnPagination = 5;

  totalItems = 0;
  pageSize = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  totalItemsHistoric = 0;
  pageSizeHistoric = 10;
  pageCountHistoric = 0
  hasPreviousPageHistoric = false;
  hasNextPageHistoric = false;

  load = false;

  usuariosDados: any;
  TotalClientes: {
    ativos: 0,
    inativos: 0,
    lista: []
  };


  user: any;
  userInformation: any;

  usuario: User;

  saldoForm: FormGroup;

  isIndetermined = false;

  TotalClientLogs: any;

  clientLogs: any;

  currentClientId: any;

  quantidade = 1;

  fornecedor = 0;
  idfornecedorSelecionado = 0;

  validade = 0;

  listaRemocoes = [];

  public listaFornecedores = [];

  concatenadoValue: number = 0;

  documentMask: object = {
    1: '000.000.000-00',
    2: '00.000.000/0000-00'
  };


  ngOnInit() {
    this.listagemUsers();
    this.carregaListagemFornecedores();
    this.userService.GetUserInformation().subscribe(res => this.userInformation = res);

  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    this.page = page;
    this.usuariosDados = this.paginate(this.TotalClientes.lista, this.pageSize, this.page);
    this.UpdatePages();
  }
  getPaginateHistoric(page: number = 1) {
    this.pageHistoric = page;
    this.clientLogs = this.paginate(this.TotalClientLogs.lista, this.pageSizeHistoric, this.pageHistoric);
    this.UpdatePagesHistoric();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  UpdatePagesHistoric() {
    var actualPageCount = this.pageCountHistoric;
    this.neighborPagesHistoric = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesHistoric.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageHistoric - 1) - ratioLeft < 0) {
        let result = ((this.pageHistoric - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageHistoric) + ratioRight > actualPageCount) {
        let result = ((this.pageHistoric) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageHistoric - ratioLeft; i <= this.pageHistoric; i++) {
        this.neighborPagesHistoric.push(i);
      }
      for (let i = this.pageHistoric + 1; i <= this.pageHistoric + ratioRight; i++) {
        this.neighborPagesHistoric.push(i);

      }

    }
  }


  listagemUsers(page: number = 1, type?) {
    this.load = true;
    // this.spinner.show();
    this.page = page;


    this.listagemService.getClientesGeral(this.page, this.searchQuery, type).subscribe(
      (res: any) => {
        this.TotalClientes = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalClientes.lista.length;
        this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

        // console.log(this.neighborPages);
        // this.spinner.hide();
      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }
  carregaListagemFornecedores() {
    this.saldoService.listaFornedoresByCredito().subscribe(
      (sucesso: any) => {
        this.listaFornecedores = sucesso;
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar fornecedores');
        this.listaFornecedores = [];
      }
    );
  }

  deletarClient(id: number) {
    this.listagemService.deleteClient(id).subscribe(
      (res: object) => {
        // console.log(res);
        this.closeModal();
        this.listagemUsers();
        this.toastr.success('Exclusão de usuario!', 'Deletado com sucesso');

      },
      (erro: any) => {
        console.log('Erro ao apagar usuario', erro);
        this.toastr.error('Exclusão de usuario', 'Erro ao apagar usuario');


      }
    );
  }
  liberarFlash(id: number) {
    this.listagemService.liberarFlash(id).subscribe(
      (res: object) => {
        // console.log(res);
        this.closeModal();
        this.listagemUsers();
        this.toastr.success('SMS Flash liberado com sucesso para esse liente');

      },
      (erro: any) => {
        console.log('Erro liberar Sms Flash', erro);
        this.toastr.error('Erro ao liberar SMS Flash');


      }
    );
  }
  liberarConcatenado(id: number) {
    this.listagemService.liberarConcatenado(id, this.concatenadoValue).subscribe(
      (res: object) => {
        // console.log(res);
        this.closeModal();
        this.listagemUsers();
        this.toastr.success('SMS Concatenado liberado com sucesso!');

      },
      (erro: any) => {
        console.log('Erro ao apagar usuario', erro);
        this.toastr.error('Erro ao liberar SMS Concatenado.');


      }
    );
  }

  openModal(usuario: Object) {
    this.user = usuario;

  }

  closeModal() {
    this.user = null;

    this.listaRemocoes = [];
    this.quantidade = 1;
    this.fornecedor = 0;
    this.usuario = null;

  }

  changeStatus(id: number, status: boolean) {

    this.listagemService.alteraStatusUser(id, status).subscribe(
      (res: object) => {
        // console.log(res);
        this.closeModal();
        this.listagemUsers();
        this.toastr.success('Alteração de status!', 'Concluido');
      },
      (erro: any) => {
        console.log('Erro ao alterar status usuario', erro);
        this.toastr.error('Erro de status', 'Erro ao alterar status usuario');
      }
    );

  }

  addBalance(id: number) {

    this.spinner.show();
    const saldo: Saldo = { IdCliente: id, QuantityBalance: this.quantidade, ValidityBalance: this.validade.toString(), Transference: false, ProviderId: this.fornecedor, Descricao: this.descricao, isIndetermined: this.isIndetermined };

    this.listagemService.transferirSaldo(saldo).subscribe(
      (sucesso: any) => {
        console.log(sucesso);
        this.spinner.hide();
        this.toastr.success('Adicionaldo saldo com sucesso', 'Saldo');
        this.listagemUsers();


      },
      (erro) => {
        this.toastr.error('Ocorreu um erro ao adicionar saldo', 'Saldo');

        this.spinner.hide();

      }
    );


  }

  modalSaldo(usuario: Object) {
    this.user = usuario;

    $('#datetimepicker1').datetimepicker({
      locale: moment.locale('ru'),
      format: 'DD/MM/YYYY HH:mm:ss',
      date: moment(),
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });
  }
  modalFlash(usuario: Object) {
    this.user = usuario;
  }

  modalTransferencia(usuario: Object) {
    this.user = usuario;

    $('#datetimepicker2').datetimepicker({
      locale: moment.locale('ru'),
      format: 'DD/MM/YYYY HH:mm:ss',
      date: moment(),
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });
  }

  transferenceSaldo(id: number) {

    this.spinner.show();
    const saldo: Saldo = { IdCliente: id, QuantityBalance: this.quantidade, ValidityBalance: $('#datetimepicker2').datetimepicker('viewDate').toJSON(), Transference: true, ProviderId: this.fornecedor, Descricao: '', isIndetermined: this.isIndetermined };

    this.listagemService.transferirSaldo(saldo).subscribe(
      (sucesso: any) => {
        console.log(sucesso);
        this.spinner.hide();
        this.toastr.success('Saldo transferido com sucesso', 'Saldo');
        this.listagemUsers();
      },
      (erro: any) => {
        if (typeof erro.error === 'string') {

          this.toastr.error(erro.error, 'Saldo');
        } else {

          this.toastr.error('Ocorreu um erro ao transferir saldo', 'Saldo');
        }

        this.spinner.hide();

      }
    );
  }

  modalRemocaoSaldo(id: number) {

    this.listagemService.getRemocoes(id).subscribe(
      (sucesso: any) => {
        this.listaRemocoes = sucesso;
      },
      (erro: any) => {
        this.toastr.error('Ocorreu um erro ao carregar saldos', 'Saldo');

      }

    );

    // $('#datetimepicker3').datetimepicker({
    //   locale: moment.locale('ru'),
    //   format: 'DD/MM/YYYY HH:mm:ss',
    //   date: moment(),
    //   icon: {
    //     up: "mdi mdi-arrow-down-bold",
    //     down: "mdi mdi-arrow-up-bold"
    //   }
    // })
  }

  excluirSaldoSelecionado(date: string) {

    this.spinner.show();

    this.listagemService.removeSaldo(date).subscribe(
      (res: object) => {

        this.spinner.hide();

        this.toastr.success('Saldo removido!', 'Saldo');
        this.listagemUsers();

      },
      (erro: any) => {
        this.toastr.error('Erro ao remover saldo', 'Saldo');
      }
    );

  }

  modalValiditySaldo(usuario: Object) {
    this.user = usuario;

    $('#datetimepicker3').datetimepicker({
      locale: moment.locale('ru'),
      format: 'DD/MM/YYYY HH:mm:ss',
      date: moment(),
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });
  }
  setIndetermined(flag) {
    this.isIndetermined = flag;
  }

  addValidity(id: number) {

    this.spinner.show();
    const saldo: Saldo = { IdCliente: id, QuantityBalance: this.quantidade, ValidityBalance: $('#datetimepicker3').datetimepicker('viewDate').toJSON(), Transference: false, ProviderId: this.fornecedor, Descricao: '', isIndetermined: this.isIndetermined };

    this.listagemService.adicionarValidadeSaldo(saldo).subscribe(
      (sucesso: any) => {
        console.log(sucesso);
        this.spinner.hide();
        this.toastr.success('Adicionaldo validade com sucesso', 'Validade');
        this.listagemUsers();


      },
      (erro) => {
        this.toastr.error('Ocorreu um erro ao adicionar validade', 'Validade');

        this.spinner.hide();

      }
    );


  }

  criarFormSaldo() {
    this.saldoForm = this.fb.group({
      IdUser: ['', [Validators.email, Validators.required]],
      QuantityBalance: ['', Validators.required],
      ValidityBalance: ['', Validators.required],
    }, { validator: this.ValidSaldo });

  }

  modalDetalhes(id: number) {
    this.listagemService.detalhesClienteById(id).subscribe(
      (sucesso: User) => {
        this.usuario = sucesso;
        // console.log(this.usuario);
        $('#modalDetalhesCliente').modal('show');


      },
      (erro: any) => {
        this.toastr.error('Ocorreu um erro ao carregar dados', 'Cliente');

      }

    );
  }

  ValidSaldo(fb: FormGroup) {
    const Quantity = fb.get('QuantityBalance');
    if (Quantity.errors == null) {
      if (fb.get('QuantityBalance').value != Quantity.value) {
        Quantity.setErrors({ passwordMismatch: true });
      } else {
        Quantity.setErrors(null);
      }
    }
  }

  selecionaFornecedores(usuario: any) {
    this.user = usuario;

    //this.idfornecedorSelecionado = this.listaFornecedores.filter(a => a.label === this.user.provider).map(a => a.id)[0];
  }

  trocaFornecedor(clientId: number) {


    this.usersForProvidersService.AlteraFornecedor(clientId, this.idfornecedorSelecionado).subscribe(
      (res: any) => {
        console.log(res);
        this.closeModal();
        this.listagemUsers();
        this.toastr.success('Alteração de fornecedor!', 'Concluido');
      },
      (erro: any) => {
        console.log('Erro ao alterar fornecedor usuario', erro);
        this.toastr.error('Erro ao alterar fornecedor usuario', 'Fornecedor');
      }
    );
  }

  gerarAniversariantes(clientId) {
    this.messageService.gerarNivers(clientId).subscribe(res => console.log(res));
  }

  getClientLogHistoric(clientId) {
    this.currentClientId = clientId;
    this.listagemService.getClientLogHistoric(clientId).subscribe((res: any) => {
      this.TotalClientLogs = res

      //DADOS PAGINAÇÃO
      this.totalItemsHistoric = this.TotalClientLogs.lista.length;
      this.pageCountHistoric = Math.floor((this.totalItemsHistoric + this.pageSizeHistoric) / this.pageSizeHistoric);
      this.hasPreviousPageHistoric = this.pageHistoric == 1 ? false : true;
      this.hasNextPageHistoric = this.pageHistoric == this.pageCountHistoric ? false : true;

      this.getPaginateHistoric();
    });
  }

  exportClientLogHistoric(isCsv) {
    this.listagemService.exportClientLogHistoric(this.TotalClientLogs.lista, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let currentDate = new Date(Date.now());
      let currentDateFormat = currentDate.toLocaleDateString();
      currentDateFormat = currentDateFormat.replace("/", "");
      currentDateFormat = currentDateFormat.replace("/", "");
      if (isCsv) {
        saveAs(blob, this.clientLogs[0].client + "_Historico" + ".csv");
      } else {
        saveAs(blob, this.clientLogs[0].client + "_Historico" + ".xlsx");
      }
    });
  }
  exportClientsExcel(isCsv) {
    this.listagemService.exportClientsExcel(this.TotalClientes.lista, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let currentDate = new Date(Date.now());
      let currentDateFormat = currentDate.toLocaleDateString();
      currentDateFormat = currentDateFormat.replace("/", "");
      currentDateFormat = currentDateFormat.replace("/", "");

      if (isCsv) {
        saveAs(blob, this.userInformation.clientName + "_Clientes" + ".csv");
      } else {
        saveAs(blob, this.userInformation.clientName + "_Clientes" + ".xlsx");
      }
    });
  }

}
