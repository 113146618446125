<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="pacman" [fullScreen]="true">
  <p style="color: white">Carregando...</p>
</ngx-spinner>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <a type="button" class="btn btn-primary" [routerLink]="['/clients/register']">
      <i class="mdi mdi-plus mr-1"></i> Novo cliente
    </a>
  </div>
  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
        <a class="dropdown-item" style="cursor:pointer" (click)="exportClientsExcel(false)">Exportar como Excel</a>
        <a class="dropdown-item" style="cursor:pointer" (click)="exportClientsExcel(true)">Exportar como CSV</a>
        <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <!-- Recent Order Table -->
    <div class="card card-default">
      <div class="card-header mb-1 ">
        <div class="row w-100 justify-content-between">
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0 pb-5" *ngIf="TotalClientes">
        <ul class="nav nav-pills nav-justified nav-style-fill nav-pill-table" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active d-flex" id="active-tab" (click)="listagemUsers(1,true)" data-toggle="tab"
              href="#active" role="tab" aria-controls="active" aria-selected="true">
              <div class=" d-flex ml-auto mr-auto">
                Ativos
                <p>
                  <!--Ativos-->
                </p>
                <span class="ml-2 text-success font-size-15">
                  {{TotalClientes.ativos}}
                </span>

              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" id="inactive-tab" data-toggle="tab" href="#inactive"
              (click)="listagemUsers(1,false)" role="tab" aria-controls="inactive" aria-selected="false">
              <div class=" d-flex ml-auto mr-auto">
                Inativos
                <span class="ml-2 text-secondary font-size-15">
                  {{ TotalClientes.inativos }}
                </span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" id="all-tab" data-toggle="tab" href="#all" role="tab" (click)="listagemUsers(1)"
              aria-controls="all" aria-selected="false">
              <div class=" d-flex ml-auto mr-auto">
                Todos
                <span class="ml-2 text-primary font-size-15">
                  {{
                  TotalClientes.ativos + TotalClientes.inativos
                  }}
                </span>
              </div>
            </a>
          </li>
        </ul>
        <table *ngIf="!load" class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">CPF/CNPJ</th>
              <!-- <th scope="col">Cliente</th> -->
              <th scope="col">E-mail</th>
              <th scope="col">Celular</th>
              <!-- <th scope="col">Crédito</th> -->
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody *ngFor="let usuario of usuariosDados">
            <tr>
              <td scope="row">{{ usuario.fullName }}</td>
              <td>{{ usuario.documento }}</td>
              <!-- <td>{{ usuario.cliente }}</td> -->
              <td>{{ usuario.email }}</td>
              <td>{{ usuario.phoneNumber }}</td>

              <!-- <td>{{ client.credito }}</td> -->
              <td>
                <div *ngIf="usuario.isActive">
                  <span class="badge badge-pill badge-success">Ativo</span>
                </div>
                <div *ngIf="!usuario.isActive">
                  <span class="badge badge-pill badge-warning">Inativo</span>
                </div>
              </td>
              <td>
                <div class="dropdown widget-dropdown">
                  <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"></a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">
                    <li class="dropdown-item">
                      <a [routerLink]="['/clients/edit', usuario.id]" data-toggle="modal" style="cursor: pointer">
                        Editar
                        cliente
                      </a>
                    </li>
                    <li class="dropdown-item" *ngIf="usuario.isActive">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Desativar cliente</a>
                    </li>
                    <li class="dropdown-item" *ngIf="!usuario.isActive">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Ativar cliente</a>
                    </li>
                    <li *ngIf="!usuario.isActive" class="dropdown-item">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaDelete"
                        style="cursor: pointer">Deletar cliente</a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="selecionaFornecedores(usuario)" data-toggle="modal" data-target="#alteraFornecedor"
                        style="cursor: pointer">Alterar Fornecedor</a>
                    </li>
                    <!--<li class="dropdown-item">
                      <a title="Adicionar saldo" (click)="modalSaldo(usuario)" data-toggle="modal"
                         style="cursor: pointer" data-target="#addSaldo">Adicionar saldo</a>
                    </li>-->

                    <li class="dropdown-item">
                      <a data-toggle="modal" style="cursor: pointer" data-target="#historicModal"
                        (click)="getClientLogHistoric(usuario.id)">Histórico</a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="modalSaldo(usuario)" data-toggle="modal" style="cursor: pointer"
                        data-target="#modalConcatenado">Sms concatenado</a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="modalFlash(usuario)" data-toggle="modal" style="cursor: pointer"
                        data-target="#modalFlash">Sms Flash</a>
                    </li>
                    <li class="dropdown-item">
                      <a title="Detalhes do cliente" (click)="modalDetalhes(usuario.id)" data-toggle="modal"
                        style="cursor: pointer" data-target="#DetalhesCliente">Detalhes</a>
                    </li>
                    <!--<li class="dropdown-item">
                      <a title="Gerar Aniversariantes" (click)="gerarAniversariantes(usuario.id)" data-toggle="modal"
                         style="cursor: pointer" data-target="#modalDetalhesCliente">Gerar Aniversariantes</a>
                    </li>-->
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="form-inline justify-content-between" *ngIf="!load">
  <!-- <nav aria-label="Page navigation example right"> -->
  <ul class="pagination pagination-flat">
    <li style="cursor: pointer" [ngClass]="
        this.hasPreviousPageHistoric
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li style="cursor: pointer" [ngClass]="
        this.hasNextPageHistoric ? 'page-item' : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
  <!-- </nav> -->
</div>

<!-- detalhes do cliente -->
<div class="modal fade" id="DetalhesCliente" data-keyboard="false" data-backdrop="static" tabindex="-1"
  aria-labelledby="modalDetalhesCliente" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content " *ngIf="usuario">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Detalhes de {{ usuario.fullName }}
        </h5>
        <button type="button" data-dismiss="modal">
          x
        </button>
      </div>
      <div class="modal-body" *ngIf="usuario">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="dados-tab" data-toggle="tab" href="#dados" role="tab" aria-controls="dados"
              aria-selected="true">Dados Gerais</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" id="contatos-tab" data-toggle="tab" href="#contatos" role="tab" aria-controls="contatos"
              aria-selected="false">Outros Contatos</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" id="endereco-tab" data-toggle="tab" href="#endereco" role="tab" aria-controls="endereco"
              aria-selected="false">Endereço</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" id="usuario-tab" data-toggle="tab" href="#usuario" role="tab" aria-controls="usuario"
              aria-selected="false">Informações do Usuario</a>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent1">
          <div class="tab-pane pt-3 fade show active" id="dados" role="tabpanel" aria-labelledby="dados-tab">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="exampleFormControlSelect3">Tipo de pessoa: * </label>
                <select class="form-control" id="exampleFormControlSelect3" disabled>
                  <option [selected]="usuario.tipoCliente === 1" value="1">
                    Fisica
                  </option>
                  <option [selected]="usuario.tipoCliente === 2" value="2">
                    Jurídica
                  </option>
                </select>
              </div>

              <div class="form-group col-md-4" *ngIf="usuario.tipoCliente === 1">
                <label>CPF: * </label>
                <input disabled [value]="usuario.documento" class="form-control" type="text"
                  [mask]="documentMask[usuario.tipoCliente]" />
              </div>

              <div class="form-group col-md-4" *ngIf="usuario.tipoCliente === 2">
                <label>CNPJ: * </label>
                <input disabled [value]="usuario.documento" placeholder="Seu Cnpj" class="form-control" type="text" />
              </div>

              <div *ngIf="usuario.tipoCliente" class="form-group col-md-4">
                <label for="txtFullName" *ngIf="usuario.tipoCliente === 2">
                  Razão social: *
                </label>
                <label for="txtFullName" *ngIf="usuario.tipoCliente === 1">
                  Nome: *
                </label>
                <input disabled class="form-control" id="txtFullName" [value]="usuario.razaoSocial" />
              </div>

              <div class="form-group col-md-4" *ngIf="usuario.tipoCliente === 2">
                <label for="txtFullName" *ngIf="usuario.tipoCliente === 2">
                  Nome fantasia: *
                </label>

                <label *ngIf="usuario.tipoCliente === 1" for="txtFullName">Nome *</label>

                <input disabled class="form-control" id="txtFullName" [value]="usuario.fullName" />
              </div>

              <div class="form-group col-md-4">
                <label for="txtNumber">Telefone: * </label>
                <input disabled class="form-control" id="txtNumber" [value]="usuario.number" />
              </div>

              <div class="form-group col-md-4">
                <label>Nome responsável * </label>

                <input disabled class="form-control" id="ResponsibleName" [value]="usuario.responsibleName"
                  [value]="usuario.responsibleName" />
              </div>

            </div>
            <div class="form-row">
              <div class="form-group col-md-8">
                <label for="txtEmail">Endereço de Email: *</label>
                <input disabled type="email" class="form-control" id="txtEmail" [value]="usuario.email" />
              </div>



            </div>
          </div>

          <div class="tab-pane pt-3 fade" id="contatos" role="tabpanel" aria-labelledby="contatos-tab">
            <div *ngFor="let contato of usuario.otherContats">
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label>Full name: * </label>
                  <input class="form-control" disabled [value]="contato.fullName" />
                </div>
                <div class="form-group col-md-4">
                  <label>Email: * </label>
                  <input class="form-control" disabled [value]="contato.email" />
                </div>

                <div class="form-group col-md-2">
                  <label>Phone 1: * </label>
                  <input class="form-control" disabled [value]="contato.phoneOne" mask="(00) 00000-0000" />
                </div>

                <!--<div class="form-group col-md-2">
                  <label>Phone 2: * </label>
  
                  <input class="form-control" disabled [value]="contato.phoneTwo" mask="(00) 00000-0000" />
                </div>-->

                <div class="form-group col-md-3">
                  <label>Cargo: * </label>

                  <input class="form-control" disabled [value]="contato.cargo" />
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane pt-3 fade" id="endereco" role="tabpanel" aria-labelledby="endereco-tab">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Cep: *</label>
                <input type="txt" class="form-control" disabled [value]="usuario.cep" />
              </div>

              <div class="form-group col-md-4">
                <label>Endereco: *</label>
                <input type="txt" class="form-control" disabled [value]="usuario.endereco" />
              </div>

              <div class="form-group col-md-4">
                <label>Numero: *</label>
                <input type="number" class="form-control" disabled [value]="usuario.numero" />
              </div>
            </div>

            <div class="form-row" *ngIf="">
              <div class="form-group col-md-3">
                <label for="exampleFormControlSelect3">Estado: * </label>
                <input type="text" disabled [value]="usuario.estado" />
              </div>

              <div class="form-group col-md-3">
                <label for="exampleFormControlSelect3">Cidade: * </label>
                <input type="text" disabled [value]="usuario.cidade" />
              </div>

              <div class="form-group col-md-3">
                <label for="txtFullName">Bairro: * </label>
                <input class="form-control" id="" [value]="usuario.bairro" />
              </div>

              <div class="form-group col-md-3">
                <label for="txtFullName">Complemento: * </label>
                <input class="form-control" id="" [value]="usuario.companyName" />

              </div>
            </div>
          </div>
          <div class="tab-pane pt-3 fade" id="usuario" role="tabpanel" aria-labelledby="usuario-tab">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Nome responsável: </label>

                <h3>
                  <input class="form-control" [value]="usuario.responsibleName" disabled />
                </h3>
              </div>

              <div class="form-group col-md-4">
                <label for="txtFullName">Nome de Usuário: * </label>
                <input class="form-control" id="" disabled [value]="usuario.userName" />

              </div>

              <div class="form-group col-md-4">
                <label for="exampleFormControlSelect3">
                  Tipo de conta: *
                </label>
                <input type="text" disabled class="form-control" [value]="usuario.role">

              </div>
              <div class="form-group col-md-8">
                <label>E-mail: </label>

                <h3>
                  <input class="form-control" disabled [value]="usuario.email" />
                </h3>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Historic -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="historicModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Histórico
        </h5>
      </div>
      <div class="modal-body">
        <div class="dropdown d-inline-block mb-3">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" data-display="static">
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
            <a class="dropdown-item" style="cursor:pointer" (click)="exportClientLogHistoric(false)">Exportar como
              Excel</a>
            <a class="dropdown-item" style="cursor:pointer" (click)="exportClientLogHistoric(true)">Exportar como
              CSV</a>
            <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
          </div>
        </div>
        <div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Cliente</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of clientLogs">
              <tr>
                <td scope="row">{{ item.createdOn }}</td>
                <td scope="row">{{ item.client }}</td>
                <td scope="row">{{ item.log }}</td>

              </tr>
            </tbody>
          </table>
          <div class="form-inline" *ngIf="!load">
            <!-- <nav aria-label="Page navigation example right"> -->
            <ul class="pagination">
              <li style="cursor: pointer"
                [ngClass]=" this.hasPreviousPageHistoric ? 'page-item' : 'page-item disabled' ">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true" class="mdi mdi-chevron-left"
                    (click)="getPaginateHistoric(currentClientId,pageHistoric - 1)"></span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesHistoric;"
                [ngClass]="{'active': pageHistoric == p }">
                <a class="page-link" (click)="getPaginateHistoric(currentClientId,p)">{{p}}</a>
              </li>
              <li style="cursor: pointer" [ngClass]=" this.hasNextPageHistoric ? 'page-item' : 'page-item disabled'">
                <a class="page-link" aria-label="Next">
                  <span aria-hidden="true" class="mdi mdi-chevron-right"
                    (click)="getPaginateHistoric(currentClientId,pageHistoric + 1)"></span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
            <!-- </nav> -->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- alterar status -->
<div class="modal fade" *ngIf="user" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaStatus" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar status deste Cliente?
        </h5>
      </div>
      <!-- <div class="modal-body">
        Deseja alterar status
      </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="changeStatus(user.id, user.isActive)"
          data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- deletar usuarios -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDelete" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar este Cliente?
        </h5>
      </div>
      <!-- <div class="modal-body">
        Deseja alterar status
      </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="deletarClient(user.id)" data-dismiss="modal">
          Deletar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- liberar SMS Flash -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="modalFlash" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja liberar o SMS Flash para esse usuário ?
        </h5>
      </div>
      <!-- <div class="modal-body">
        Deseja alterar status
      </div> -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="liberarFlash(user.id)" data-dismiss="modal">
          Liberar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- liberar Concatenado -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="modalConcatenado" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja liberar o SMS Concatenado para esse usuário?
        </h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Novo Limite:</label>
          <select class="form-control" [(ngModel)]="concatenadoValue">
            <option value="0">Selecione...</option>
            <option value="1">160</option>
            <option value="2">300</option>
            <option value="3">450</option>
            <option value="4">600</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="liberarConcatenado(user.id)"
          data-dismiss="modal">
          Liberar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- adicionar saldo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static" id="addSaldo"
  tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja adicionar saldo ?
        </h5>
      </div>
      <div class="modal-body">
        <!-- <form
          ngNativeValidate
          [formGroup]="saldoForm"
          autocomplete="off"
          (submit)="addBalance()"
        > -->
        <div class="form-group">
          <div class="form-group">
            <label>Fornecedor: *</label>
            <select class="form-control" [(ngModel)]="fornecedor">
              <option value="0">Selecione...</option>
              <option *ngFor="let item of listaFornecedores" [ngValue]="item.id">
                {{ item.fornecedor }}
              </option>
            </select>
          </div>
          <label>Quantidade: * </label>
          <input placeholder="Sua quantidade" class="form-control" [(ngModel)]="quantidade" type="text" />

          &nbsp;&nbsp;
          <!-- <span
            class="mt-2 d-block text-danger"
            *ngIf="
              saldoForm.get('QuantityBalance').touched &&
              saldoForm.get('QuantityBalance').errors?.required
            "
            >Este campo é obrigatório!</span
          > -->
          <div class="form-group">
            <label>Descrição:</label>
            <div class="input-group date">
              <input type="text" class="form-control " [(ngModel)]="descricao" />

            </div>
          </div>
          <div class="form-group col-6 px-0">
            <label class="ml-2">Validade:</label>
            <div class="row">
              <div class="d-flex col-12">
                <div class="input-group date">
                  <input type="date" class="form-control" [disabled]="isIndetermined" [(ngModel)]="validade" />
                </div>
                <label class="control outlined control-checkbox ml-2 mt-3" style="min-width:200px">
                  Validade Indeterminada
                  <input [(ngModel)]="isIndetermined" type="checkbox"
                    (change)="setIndetermined($event.target.checked)" />
                  <div class="control-indicator"></div>
                </label>
              </div>

            </div>
          </div>
        </div>
        <!-- </form> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="addBalance(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- transferir saldo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="transferenceSaldo" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja transferir saldo ?
        </h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Quantidade: * </label>
          <input placeholder="Sua quantidade" class="form-control" [(ngModel)]="quantidade" type="text" />

          <div class="form-group">
            <div class="input-group date" id="datetimepicker2" data-target-input="nearest">
              <input type="text" class="form-control datetimepicker-input" data-target="#datetimepicker2" />
              <div class="input-group-append" data-target="#datetimepicker2" data-toggle="datetimepicker">
                <div class="input-group-text">
                  <i class="mdi mdi-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </form> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="transferenceSaldo(user.id)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Remocao de Saldo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="remocaoSaldo" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja remover qual saldo ?
        </h5>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <table class="table">
            <thead>
              <th scope="col">Saldo</th>
              <th scope="col">Validade</th>
              <th scope="col">Excluir ?</th>
            </thead>
            <tbody *ngFor="let item of listaRemocoes">
              <tr>
                <td>{{ item.consumed }}</td>
                <td>{{ item.validity | date: "dd/MM/yyyy HH:mm:ss" }}</td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" title="Excluir saldo"
                    (click)="excluirSaldoSelecionado(item.id)" data-dismiss="modal">
                    <i class="mdi mdi-trash-can"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot *ngIf="listaRemocoes.length <= 0">
              <td colspan="3"><b>Não posuem saldos a serem removidos</b></td>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Adicionar Validade -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="addValidity" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja adicionar validade ?
        </h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Fornecedor: *</label>
          <select class="form-control" [(ngModel)]="fornecedor">
            <option value="0">Selecione...</option>
            <option *ngFor="let item of listaFornecedores" [ngValue]="item.id">
              {{ item.fornecedor }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Quantidade: * </label>
          <input placeholder="Sua quantidade" class="form-control" [(ngModel)]="quantidade" type="text" />

          <div class="form-group">
            <div class="input-group date" id="datetimepicker3" data-target-input="nearest">
              <input type="text" class="form-control datetimepicker-input" data-target="#datetimepicker3" />
              <div class="input-group-append" data-target="#datetimepicker3" data-toggle="datetimepicker">
                <div class="input-group-text">
                  <i class="mdi mdi-calendar"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </form> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="addValidity(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- alterar fornecedor -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="alteraFornecedor" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar fornecedor ?
          <div class="model-title center">
            <p class="alert alert-danger alert-outlined mt-3">
              Ao alterar, o tráfego de SMS da conta será realizado com o fornecedor
              selecionado.
            </p>
          </div>
        </h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Fornecedores: </label>
          <select class="form-control" [(ngModel)]="idfornecedorSelecionado">
            <option value="0">Selecione...</option>
            <option *ngFor="let item of listaFornecedores" [value]="item.id">{{item.fornecedor}}</option>
          </select>
        </div>

        <!-- </form> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="trocaFornecedor(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>