import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { UserService } from '../shared/user.service';

declare let $: any;
declare let window: any

/**
 * Componente da Dashboard
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  /**
   * Barra de Progresso
   */
  progressRef: NgProgressRef;
  /**
   * Tempo Max de Sessao
   */
  maxSessionTime: Date;
  /**
   * Flag para liberação de modal referente à renovação de token
   */
  canRenewToken = true

  /**
   * 
   */
  _MS_PER_DAY = 1000;

  /**
   * Informações do Usuário
   */
  public UserInformation: any = {
    Initials: '',
    FirstName: '',
    credits: 0
  };

  /**
   * Construtor do DashboardComponent
   *
   * @param userService Serviço do Usuário
   */
  constructor(public userService: UserService, private progress: NgProgress, private router: Router) {
    router.events.subscribe((val) => {
      //console.log(val)
      if (val instanceof NavigationStart) {
        if (localStorage.getItem('UniqMobile_Message_Token') != null) {
          userService.GetUserInformation().subscribe((userInfo: any) => {
            let lastUserInfo = this.UserInformation;
            //lastUserInfo.credits = userInfo.credits;
            //this.UserInformation = lastUserInfo;
            this.UserInformation = userInfo;
            this.UserInformation.FirstName = lastUserInfo.FirstName;
            this.UserInformation.Initials = lastUserInfo.Initials;
          });

        }
      }
    });
  }

  ngAfterViewInit() {
    // this.progressRef.start();
  }

  /**
   * Make ProfilePicture
   *
   * @param UserInformation Informações do Usuário
   */
  private GenerateProfileStatus(UserInformation) {
    if (UserInformation != null) {
      const fullName: String = UserInformation.clientName;
      if (fullName != null && fullName != undefined) {
      const split = fullName.split(' ');
      const firstName = split[0];
      let initials = '';
      split.forEach(element => {
        initials = initials + element.toUpperCase()[0];
      });
      this.UserInformation.FirstName = firstName;
      this.UserInformation.Initials = initials;
      this.UserInformation.credits = this.UserInformation.credits;

      }
    }
  }

  /**
   * Ao Clicar, realiza o procedimento de logout do sistema
   */
  OnClickLogout() {
    this.userService.Logout();
  }

  /**
   *  Personalização da Sidebar
   */
  private ApplyLayout() {
    $(document).ready(function () {

      /*======== 1. SCROLLBAR SIDEBAR ========*/
      var sidebarScrollbar = $(".sidebar-scrollbar");
      if (sidebarScrollbar.length != 0) {
        sidebarScrollbar.slimScroll({
          opacity: 0,
          height: "100%",
          color: "#808080",
          size: "5px",
          touchScrollStep: 50
        })
          .mouseover(function () {
            $(this)
              .next(".slimScrollBar")
              .css("opacity", 0.5);
          });
      }
  
      /*======== 2. MOBILE OVERLAY ========*/
      if ($(window).width() < 768) {
        $(".sidebar-toggle").on("click", function () {
          $("body").css("overflow", "hidden");
          $('body').prepend('<div class="mobile-sticky-body-overlay"></div>');
          $("#body").removeClass("sidebar-mobile-out").addClass("sidebar-mobile-in");
        });
  
        $(document).on("click", '.mobile-sticky-body-overlay', function (e) {
          $(this).remove();
          $("#body").removeClass("sidebar-mobile-in").addClass("sidebar-mobile-out");
          $("body").css("overflow", "auto");
        });
      }
  
      /*======== 3. SIDEBAR MENU ========*/
      var sidebar = $(".sidebar");
      if (sidebar.length != 0) {
        $(".sidebar .nav > .has-sub > a").click(function () {
          $(this).parent().siblings().removeClass('expand');
          $(this).parent().toggleClass('expand');
        });
  
        $(".sidebar .nav > .has-sub .has-sub > a").click(function () {
          $(this).parent().toggleClass('expand');
        });
      }
  
  
      /*======== 4. SIDEBAR TOGGLE FOR MOBILE ========*/
      if ($(window).width() < 768) {
        $(document).on("click", ".sidebar-toggle", function (e) {
          e.preventDefault();
          var min = "sidebar-mobile-in",
            min_out = "sidebar-mobile-out",
            body = "#body";
          $(body).hasClass(min)
            ? $(body)
              .removeClass(min)
              .addClass(min_out)
            : $(body)
              .addClass(min)
              .removeClass(min_out);
        });
      }
  
      /*======== 5. SIDEBAR TOGGLE FOR VARIOUS SIDEBAR LAYOUT ========*/
      var body = $("#body");
      if ($(window).width() >= 768) {
  
        if (typeof window.isMinified === "undefined") {
          window.isMinified = false;
        }
        if (typeof window.isCollapsed === "undefined") {
          window.isCollapsed = false;
        }
  
        $("#sidebar-toggler").on("click", function () {
          if (
            body.hasClass("sidebar-fixed-offcanvas") ||
            body.hasClass("sidebar-static-offcanvas")
          ) {
            $(this)
              .addClass("sidebar-offcanvas-toggle")
              .removeClass("sidebar-toggle");
            if (window.isCollapsed === false) {
              body.addClass("sidebar-collapse");
              window.isCollapsed = true;
              window.isMinified = false;
            } else {
              body.removeClass("sidebar-collapse");
              body.addClass("sidebar-collapse-out");
              setTimeout(function () {
                body.removeClass("sidebar-collapse-out");
              }, 300);
              window.isCollapsed = false;
            }
          }
  
          if (
            body.hasClass("sidebar-fixed") ||
            body.hasClass("sidebar-static")
          ) {
            $(this)
              .addClass("sidebar-toggle")
              .removeClass("sidebar-offcanvas-toggle");
            if (window.isMinified === false) {
              body
                .removeClass("sidebar-collapse sidebar-minified-out")
                .addClass("sidebar-minified");
              window.isMinified = true;
              window.isCollapsed = false;
            } else {
              body.removeClass("sidebar-minified");
              body.addClass("sidebar-minified-out");
              window.isMinified = false;
            }
          }
        });
      }
  
      // if ($(window).width() >= 768 && $(window).width() < 992) {
      //   if (
      //     body.hasClass("sidebar-fixed") ||
      //     body.hasClass("sidebar-static")
      //   ) {
      //     body
      //       .removeClass("sidebar-collapse sidebar-minified-out")
      //       .addClass("sidebar-minified");
      //     window.isMinified = true;
      //   }
      // }
  
      /*======== 7. RIGHT SIDEBAR ========*/
  
      var rightSidebarIn = 'right-sidebar-in';
      var rightSidebarOut = 'right-sidebar-out';
  
      $('.nav-right-sidebar .nav-link').on('click', function () {
  
        if (!body.hasClass(rightSidebarIn)) {
          body.addClass(rightSidebarIn).removeClass(rightSidebarOut);
  
        } else if ($(this).hasClass('show')) {
          body.addClass(rightSidebarOut).removeClass(rightSidebarIn);
        }
      });
  
      $('.card-right-sidebar .close').on('click', function () {
        body.removeClass(rightSidebarIn).addClass(rightSidebarOut);
      });
  
      if ($(window).width() <= 1024) {
  
        var togglerInClass = "right-sidebar-toggoler-in";
        var togglerOutClass = "right-sidebar-toggoler-out";
  
        body.addClass(togglerOutClass);
  
        $('.btn-right-sidebar-toggler').on('click', function () {
          if (body.hasClass(togglerOutClass)) {
            body.addClass(togglerInClass).removeClass(togglerOutClass);
          } else {
            body.addClass(togglerOutClass).removeClass(togglerInClass);
          }
        });
      }
  
    });
  }

  /**
   * Checa se pode acessar determinado conteúdo
   * @param roles Tipo de Usuários
   * @returns Verdadeiro, se acessível
   */
  CheckIfRole(roles) {
    if (this.UserInformation == undefined) {
      return false;
    }
    return this.UserInformation.roleType ? roles.includes(this.UserInformation.roleType) : false;
  }

  /**
   * Ao Inicializar este componente
   */
  async ngOnInit() {
    //Referencia a Barra de Progresso
    this.progressRef = this.progress.ref('dashboardProgress');

    //Obtem o tempo max de sessão em cache
    this.maxSessionTime = new Date(localStorage.getItem('UniqMobile_Message_SessionTime'));


    //SessionLoop
    setInterval(() => {
    const a = new Date(localStorage.getItem('UniqMobile_Message_SessionTime')),b = new Date();
      if (this.canRenewToken) {
        if ((Math.abs(this.dateDiffInDays(a, b) / 60) < 5)) {
          $('#ModalRenovarToken').modal('show');
          this.canRenewToken = false;
        }
      }
      this.GenerateProfileStatus(this.UserInformation);
    }, 1000);


    //So pode renderizar se o Usuário estiver Logado
    await this.userService.IsLoggedIn;
    this.ApplyLayout();
    this.userService.GetUserInformation().subscribe(userInfo => {
      this.UserInformation = userInfo
      this.GenerateProfileStatus(this.UserInformation);
    });
  }
  // a and b are javascript Date objects
  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes(), a.getSeconds(), a.getMilliseconds());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds(), b.getMilliseconds());

  return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
}

  public HasToBeActive(route) {
    return this.router.url.includes(route);
  }

  renewToken() {
    this.userService.RenewToken().subscribe((res:any) => {
      localStorage.setItem('UniqMobile_Message_Token', res.token);
      localStorage.setItem('UniqMobile_Message_SessionTime', res.sessionTime);
      $('#ModalTokenRenovado').modal('show');
      this.canRenewToken = true;
    })
  }

}
