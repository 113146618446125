import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../menssage.service';
import { SuspiciousMessageListing } from '../../model_message/ListagemMsgSuspeitas.model';

@Component({
  selector: 'app-liberacaosusp',
  templateUrl: './liberacaosusp.component.html',
  styleUrls: ['./liberacaosusp.component.scss']
})
export class LiberacaosuspComponent implements OnInit {

  constructor(private msgService: MessageService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;
  totalItemCount = 0;

  suspeitoSelecionado: any = {
    content: '',
    messageId: '',
    createdOn: '',
    count: '',
    client: '',
    user: '',
  };



  TotalSuspeitas: any;
  listagemMgsSuspeita: any = [];


  ngOnInit() {
    this.listagemMsgSuspeitas();
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.content.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listagemMgsSuspeita = this.paginate(this.TotalSuspeitas.paged, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemMsgSuspeitas(page: number = 1) {
    this.load = true;
    this.page = page;


    this.msgService.listarMgsSuspeitas(this.page, this.searchQuery).subscribe(
      (res: SuspiciousMessageListing) => {
        this.TotalSuspeitas = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalSuspeitas.paged.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }


  openModal(sms: any) {
    this.suspeitoSelecionado = sms;
  }

  closeModal() {
    this.suspeitoSelecionado = null;
  }

  liberarMensagens(id: number) {
    this.msgService.liberarMessages(id).subscribe(
      (res: any) => {
        this.listagemMsgSuspeitas();
      },
      (erros) => {
        console.log(erros);

      }
    );
  }
  blockMessage(id) {
    console.log(id);
    this.msgService.bloquearMessages(id).subscribe((res: any) => {
      this.listagemMsgSuspeitas();
    })
  }


}
