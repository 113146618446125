import { query } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EdicaoUser } from './models_users/edicaoUser.model';
import { ListagemClientes } from './models_users/listagemClientes.model';
import { ListagemUsuarios } from './models_users/listagemUsuarios.model';

@Injectable({
  providedIn: 'root'
})
export class UsersInClientService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;

  GetallUsers(page: number = 1, query: string = '', type?, active?): Observable<ListagemUsuarios> {
    if ((type == undefined || type == null) && (active == undefined || active == null)) {
      return this.http.get<ListagemUsuarios>(`${this.baseUrl}usersinclients/listagemusuarios?page=${page}&query=${query}`);

    } else if ((active != undefined && active != null) && (type == undefined || type == null)) {
      return this.http.get<ListagemUsuarios>(`${this.baseUrl}usersinclients/listagemusuarios?page=${page}&query=${query}&active=${active}`);

    } else if ((active == undefined || active == null) && (type != undefined && type != null)) {
      return this.http.get<ListagemUsuarios>(`${this.baseUrl}usersinclients/listagemusuarios?page=${page}&query=${query}&type=${type}`);

    }
    return this.http.get<ListagemUsuarios>(`${this.baseUrl}usersinclients/listagemusuarios?page=${page}&query=${query}&type=${type}&active=${active}`);
  }


  GetallCliente(): Observable<ListagemClientes[]> {
    return this.http.get<ListagemClientes[]>(`${this.baseUrl}usersinclients/ListClientes`);
  }

  GetUserById(id: number): Observable<EdicaoUser> {
    return this.http.get<EdicaoUser>(`${this.baseUrl}usersinclients/Edit/${id}`);
  }

  alteraStatusUser(id: number, status: boolean) {
    return this.http.get(`${this.baseUrl}usersinclients/analisestatus/${id}/${status}`);
  }

  deleteUser(id: number) {
    return this.http.delete(`${this.baseUrl}usersinclients/delete/${id}`);
  }

  register(usuario: any) {
    return this.http.post(`${this.baseUrl}usersinclients/create`, usuario);
  }

  updateUser(id: number, usuario: any) {
    return this.http.put(`${this.baseUrl}usersinclients/Edit/${id}`, usuario);
  }

  getUserLogHistoric(userId: number, page: number = 1) {
    return this.http.get(`${this.baseUrl}usersinclients/GetUserLogHistoric?userId=${userId}&page=${page}`);
  }

  exportUserLogHistoric(userLog, isCsv) {
    return this.http.post(`${this.baseUrl}usersinclients/ExportUserLogHistoric?isCsv=${isCsv}`, userLog, { responseType: 'blob' });
  }
  exportUsers(data, isCsv) {
    return this.http.post(`${this.baseUrl}reports/GetUsersAsExcel?isCsv=${isCsv}`, data, { responseType: 'blob' });
  }
}
