import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment'; import 'moment/locale/pt-br';
import { NgProgress, NgProgressComponent, NgProgressRef } from 'ngx-progressbar';
import { ToastrService } from 'ngx-toastr';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { ListagemAtividadesUsuarios } from '../auditoria/models_auditoria/ListagemAtividadesUsuarios.model';
import { MessageService } from '../message/menssage.service';
import { Mensagem } from '../message/model_message/Mensagem_model';
import { UserService } from '../shared/user.service';

declare let Chart: any;
declare let $: any;
declare let progressBar: NgProgressComponent;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements AfterViewInit, OnInit {
  // @ViewChild('myTable') table: any;

  constructor(public userService: UserService, private progress: NgProgress, private messageService: MessageService, private toastr: ToastrService, public auditoriaService: AuditoriaService, public msgService: MessageService) { }


  public userBasicData: any;
  public userData = {};
  public TotalSentChartDataLabels: string[] = [];
  public TotalSentChartDataTotalAmount: number[] = [];
  public TotalSentChartDataSuccess: number[] = [];
  public TotalSentChartDataFail: number[] = [];
  public chartLoaded = false;
  public totalMessagesChart = 0;

  neighborPagesReplys = [];
  neighborPagesScheduled = [];
  neighborPagesSuspicious = [];
  neighborPagesActivities = [];
  maxPagesOnPagination = 5;
  totalItemsReplys = 0;
  pageSizeReplys = 10;
  pageCountReplys = 0
  hasPreviousPageReplys = false;
  hasNextPageResplys = false;

  totalItemsScheduled = 0;
  pageSizeScheduled = 10;
  pageCountScheduled = 0
  hasPreviousPageScheduled = false;
  hasNextPageScheduled = false;

  totalItemsSuspicious = 0;
  pageSizeSuspicious = 10;
  pageCountSuspicious = 0
  hasPreviousPageSuspicious = false;
  hasNextPageSuspicious = false;

  totalItemsActivities = 0;
  pageSizeActivities = 10;
  pageCountActivities = 0
  hasPreviousPageActivities = false;
  hasNextPageActivities = false;

  public ReplyContent: string;

  public currentActivity: any = {
    criado: '',
    client: '',
    login: '',
    log: '',
    sistema: '',
  };
  public currentPendingMessage: any = {
    content: '',
    messageId: '',
    createdOn: '',
    count: '',
    client: '',
    user: '',
  };
  public currentScheduledMessage: any = {
    id: '',
    content: '',
    createdOn: '',
    qunt: ''
  };
  public currentReplyMessage: any = {
    messageId: '',
    mtContent: '',
    content: '',
    number: '',
    count: '',
    client: '',
    user: '',
    receivedOn: '',
  };

  progressRef: NgProgressRef;

  txtInitial = '';
  txtFinal = '';

  Scheduled = '';
  ScheduledTime = '';

  loadHistorico: boolean = true;
  loadPendentes: boolean = true;
  loadAgendado: boolean = true;
  loadRespostas: boolean = true;

  pageAtividade: number = 1;
  pagePendentes: number = 1;
  pageAgendadas: number = 1;
  pageSuspeitas: number = 1;
  pageRespostas: number = 1;

  TotalHistorico: any;
  TotalPendentes: any;
  TotalAgendadas: any;
  TotalRecebidas: any;
  HistoricoAtividade: any = [];
  MensagensPendentes: any = [];
  MensagensAgendadas: any = [];
  MensagensRecebidas: any = [];

  private InitializeTimeFields() {
    const initialDate = moment().startOf('month').format('DD/MM/YYYY');
    const finalDate = moment().endOf('month').format('DD/MM/YYYY');

    $('#txtInitialDate').datetimepicker({
      locale: moment.locale('pt-br'),
      format: 'DD/MM/YYYY',
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      }
    });

    $('#txtFinalDate').datetimepicker({
      locale: moment.locale('pt-br'),
      format: 'DD/MM/YYYY',
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      },
    });

    //console.log(initialDate, " -> ", finalDate)
    this.txtInitial = initialDate;
    this.txtFinal = finalDate;
    //console.log(this.txtInitial, " -> ", this.txtFinal)
  }

  inicializarChart1() {
    const bar1 = document.getElementById('ultimosEnviosChart');

    if (bar1 !== null) {
      const myChart = new Chart(bar1, {
        type: 'bar',
        data: {
          labels: this.TotalSentChartDataLabels,

          datasets: [
            {
              label: 'Envios',
              backgroundColor: '#4c84ff',
              borderColor: '#4c84ff',
              data: this.TotalSentChartDataTotalAmount,
              lineTension: 0.3,
              pointBackgroundColor: '#4c84ff',
              pointHoverBackgroundColor: 'rgba(76, 132, 255,1)',
              pointHoverRadius: 3,
              pointHitRadius: 30,
              pointBorderWidth: 2,
              barPercentage: 1.0,
              categoryPercentage: 0.3,
              pointStyle: 'rectRounded'
            },
            {
              label: 'Sucesso',
              backgroundColor: '#29cc97',
              borderColor: '#29cc97',
              data: this.TotalSentChartDataSuccess,
              lineTension: 0.3,
              pointBackgroundColor: '#29cc97',
              pointHoverBackgroundColor: 'rgba(254, 196, 0,1)',
              pointHoverRadius: 3,
              pointHitRadius: 30,
              pointBorderWidth: 2,
              barPercentage: 1.0,
              categoryPercentage: 0.3,
              pointStyle: 'rectRounded'
            },
            {
              label: 'Falhas',
              backgroundColor: '#fe5461',
              borderColor: '#fe5461',
              data: this.TotalSentChartDataFail,
              lineTension: 0.3,
              pointBackgroundColor: '#fe5461',
              pointHoverBackgroundColor: 'rgba(254, 196, 0,1)',
              pointHoverRadius: 3,
              pointHitRadius: 30,
              pointBorderWidth: 2,
              barPercentage: 1.0,
              categoryPercentage: 0.3,
              pointStyle: 'rectRounded'
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  display: false
                },
                ticks: {
                  display: true, // hide main x-axis line
                  beginAtZero: true
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  drawBorder: false, // hide main y-axis line
                  display: false
                },
                ticks: {
                  display: true,
                  beginAtZero: true
                }
              }
            ]
          },
          tooltips: {
            enabled: true
          }
        }
      });

    } else {
    };
  }

  //NG INIT
  ngOnInit(): void {
    //INICIALIZA A BARRA DE PROGRESSO
    this.progressRef = this.progress.ref('dashboardProgress');

    this.userService.GetUserInformation().subscribe((userInfo: any) => {
      this.userBasicData = userInfo;
      this.listagemRespostas(1);
      this.listagemMsgsAgendadas(1);
      if (this.CheckIfRole(['Owner'])) {
        this.listagemMsgsPendentes(1);
      }
    });


  }
  CheckIfRole(roles) {
    if (this.userBasicData == undefined) {
      return false;
    }
    return this.userBasicData.roleType ? roles.includes(this.userBasicData.roleType) : false;
  }

  BlockMessage(id) {
    console.log(id);
    this.messageService.bloquearMessages(id).subscribe((res: any) => {
      this.toastr.success("Mensagem Bloqueada com Sucesso!")
      this.listagemMsgsPendentes(1);
    })
  }
  LiberarMessage(id) {
    console.log(id);
    this.messageService.liberarMessages(id).subscribe((res: any) => {
      this.toastr.success("Mensagem Liberada com Sucesso!")
      this.listagemMsgsPendentes(1);
    })
  }

  async ngAfterViewInit() {
    //DA START NA PROGRESSAO
    this.progressRef.start();
    this.messageService.GetStatusOfLastMessages().subscribe(res => {

      for (const x of res) {
        this.TotalSentChartDataLabels.push(x.label);
        this.TotalSentChartDataTotalAmount.push(x.total);
        this.TotalSentChartDataSuccess.push(x.success);
        this.TotalSentChartDataFail.push(x.errors);
        this.totalMessagesChart += x.total;
      }

      setTimeout(() => { this.inicializarChart1(); }, 1000);
    });


    this.InitializeTimeFields();
    await this.userService.GetUserAdditionalInformation().subscribe(res => {
      this.userData = res;
      //VERIFICAR SE TUDO JA FOI CARREGADO ANTES DE DAR O COMPLETE
      this.progressRef.complete();
      this.listagemAtividadeUsuarios(1);
    });

    //console.log( )
  }
  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginateReplys(page: number = 1) {
    this.pageRespostas = page;
    this.MensagensRecebidas = this.paginate(this.TotalRecebidas.lista, this.pageSizeReplys, this.pageRespostas);
    this.UpdatePagesReplys();
  }
  getPaginateScheduled(page: number = 1) {
    this.pageAgendadas = page;
    this.MensagensAgendadas = this.paginate(this.TotalAgendadas.lista, this.pageSizeScheduled, this.pageAgendadas);
    this.UpdatePagesScheduleds();
  }

  getPaginateActivities(page: number = 1) {
    this.pageAtividade = page;
    this.HistoricoAtividade = this.paginate(this.TotalHistorico.lista, this.pageSizeActivities, this.pageAtividade);
    this.UpdatePagesActivites();
  }
  getPaginateSuspicious(page: number = 1) {
    this.pagePendentes = page;
    this.MensagensPendentes = this.paginate(this.TotalPendentes.paged, this.pageSizeSuspicious, this.pagePendentes);
    this.UpdatePagesSuspicous();
    console.log(this.MensagensPendentes);
  }
  UpdatePagesReplys() {
    var actualPageCount = this.pageCountReplys;
    this.neighborPagesReplys = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesReplys.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageRespostas - 1) - ratioLeft < 0) {
        let result = ((this.pageRespostas - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageRespostas) + ratioRight > actualPageCount) {
        let result = ((this.pageRespostas) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageRespostas - ratioLeft; i <= this.pageRespostas; i++) {
        this.neighborPagesReplys.push(i);
      }
      for (let i = this.pageRespostas + 1; i <= this.pageRespostas + ratioRight; i++) {
        this.neighborPagesReplys.push(i);

      }

    }
  }

  UpdatePagesActivites() {
    var actualPageCount = this.pageCountActivities;
    this.neighborPagesActivities = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesActivities.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageAtividade - 1) - ratioLeft < 0) {
        let result = ((this.pageAtividade - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageAtividade) + ratioRight > actualPageCount) {
        let result = ((this.pageAtividade) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageAtividade - ratioLeft; i <= this.pageAtividade; i++) {
        this.neighborPagesActivities.push(i);
      }
      for (let i = this.pageAtividade + 1; i <= this.pageAtividade + ratioRight; i++) {
        this.neighborPagesActivities.push(i);

      }

    }
  }

  UpdatePagesScheduleds() {
    var actualPageCount = this.pageCountScheduled;
    this.neighborPagesScheduled = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesScheduled.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageAgendadas - 1) - ratioLeft < 0) {
        let result = ((this.pageAgendadas - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageAgendadas) + ratioRight > actualPageCount) {
        let result = ((this.pageAgendadas) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageAgendadas - ratioLeft; i <= this.pageAgendadas; i++) {
        this.neighborPagesScheduled.push(i);
      }
      for (let i = this.pageAgendadas + 1; i <= this.pageAgendadas + ratioRight; i++) {
        this.neighborPagesScheduled.push(i);

      }

    }
  }

  UpdatePagesSuspicous() {
    var actualPageCount = this.pageCountSuspicious;
    this.neighborPagesSuspicious = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPagesSuspicious.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.pageSuspeitas - 1) - ratioLeft < 0) {
        let result = ((this.pageSuspeitas - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.pageSuspeitas) + ratioRight > actualPageCount) {
        let result = ((this.pageSuspeitas) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.pageSuspeitas - ratioLeft; i <= this.pageSuspeitas; i++) {
        this.neighborPagesSuspicious.push(i);
      }
      for (let i = this.pageSuspeitas + 1; i <= this.pageSuspeitas + ratioRight; i++) {
        this.neighborPagesSuspicious.push(i);

      }

    }
  }

  listagemRespostas(page: number = 1) {
    this.loadRespostas = true;

    this.pageRespostas = page;


    this.messageService.ListaRespostaRecebidadas(this.pageRespostas, '').subscribe(
      (res) => {
        //console.log(res);
        this.TotalRecebidas = res;
        this.loadRespostas = false;
        //this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItemsReplys = this.TotalRecebidas.lista.length;
        this.pageCountReplys = Math.floor((this.totalItemsReplys + this.pageSizeReplys) / this.pageSizeReplys);
        this.hasPreviousPageReplys = this.pageRespostas == 1 ? false : true;
        this.hasNextPageResplys = this.pageRespostas == this.pageCountReplys ? false : true;

        this.getPaginateReplys();
        this.UpdatePagesReplys();


      },
      (erros) => {
        console.log(erros);

      }
    );
  }
  listagemMsgsPendentes(page: number = 1) {
    this.loadPendentes = true;

    this.pageSuspeitas = page;


    this.messageService.listarMgsSuspeitas(this.pageSuspeitas, '').subscribe(
      (res: any) => {
        //console.log(res);
        this.TotalPendentes = res;
        this.loadPendentes = false;

        //DADOS PAGINAÇÃO
        this.totalItemsSuspicious = this.TotalPendentes.paged.length;
        this.pageCountSuspicious = Math.floor((this.totalItemsSuspicious + this.pageSizeSuspicious) / this.pageSizeSuspicious);
        this.hasPreviousPageSuspicious = this.pagePendentes == 1 ? false : true;
        this.hasNextPageSuspicious = this.pagePendentes == this.pageCountSuspicious ? false : true;

        this.getPaginateSuspicious();
        this.UpdatePagesSuspicous();

      },
      (erros) => {
        console.log(erros);
        //this.load = false;

      }
    );
  }
  listagemMsgsAgendadas(page: number = 1) {
    this.loadAgendado = true;

    this.pageAgendadas = page;


    this.messageService.getHistoricoMensagens(this.pageAgendadas, '', 1).subscribe(
      (res) => {
        //console.log(res);
        this.TotalAgendadas = res;
        this.loadAgendado = false;
        //this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItemsScheduled = this.TotalAgendadas.lista.length;
        this.pageCountScheduled = Math.floor((this.totalItemsScheduled + this.pageSizeScheduled) / this.pageSizeScheduled);
        this.hasPreviousPageScheduled = this.pageAgendadas == 1 ? false : true;
        this.hasNextPageScheduled = this.pageAgendadas == this.pageCountScheduled ? false : true;

        this.getPaginateScheduled();
        this.UpdatePagesScheduleds();


      },
      (erros) => {
        console.log(erros);
        //this.load = false;

      }
    );
  }

  adicionarAblackList(numero: string) {
    this.messageService.addNumberBlackList(numero).subscribe(
      (res) => {
        this.listagemRespostas();

        this.toastr.success('Adicionado a lista negra', 'Resposta');

      },
      (erros) => {
        console.log(erros);


      }
    );
  }

  selectMessage(id) {
    this.currentReplyMessage = this.MensagensRecebidas.find(x => x.id == id);
  }
  selectMessageAgendada(id) {
    console.log("ID: ", id)
    this.currentScheduledMessage = this.MensagensAgendadas.find(x => x.message_id == id);
    console.log("currentScheduledMessage: ", this.currentScheduledMessage)
  }

  selectActivity(id) {

    this.currentActivity = this.HistoricoAtividade.find(x => x.id == id);

  }

  selectMessagePendente(id) {

    this.currentPendingMessage = this.MensagensPendentes.find(x => x.messageId == id);

  }

  excluirResposta(id: number) {
    this.messageService.deleteResposta(id).subscribe(
      (res) => {
        this.listagemRespostas();

        this.toastr.success('Excluido com sucesso', 'Resposta');

      },
      (erros) => {
        console.log(erros);

      }
    );
  }

  listagemAtividadeUsuarios(page: number = 1) {
    this.loadHistorico = true;

    this.pageAtividade = page;

    if (this.CheckIfRole(['Owner'])) {
      this.auditoriaService.carregaListagemAtivdadesUsuarios(this.pageAtividade, '').subscribe(
        (res: any) => {
          this.TotalHistorico = res;
          this.loadHistorico = false;

          //DADOS PAGINAÇÃO
          this.totalItemsActivities = this.TotalHistorico.lista.length;
          this.pageCountActivities = Math.floor((this.totalItemsActivities + this.pageSizeActivities) / this.pageSizeActivities);
          this.hasPreviousPageActivities = this.pageAtividade != 1 ? false : true;
          this.hasNextPageActivities = this.pageAtividade != this.pageCountActivities ? false : true;

          this.getPaginateActivities();
          this.UpdatePagesActivites();

        },
        (erros) => {
          console.log(erros);
          this.loadHistorico = false;

        }
      );
    }
  }

  onDetailToggle(event) {
  }
  toggleExpandGroup(group) {
  }

  CheckScheduledMessage() {

    if ((this.Scheduled !== undefined && this.Scheduled !== null && this.Scheduled != '') && (this.ScheduledTime !== undefined && this.ScheduledTime !== null && this.ScheduledTime != '')) {

      //gambiarra braba. mudar dps
      let hour = this.ScheduledTime[0] + this.ScheduledTime[1];
      let min = this.ScheduledTime[2] + this.ScheduledTime[3];
      this.ScheduledTime = hour + ":" + min;

    }
  }

  reagendarMsg(id, novaData, novaHora) {

    let novoHorario = novaData + " " + novaHora;
    this.messageService.reagendarMensagem(id, novoHorario).subscribe((res: any) => {

      this.listagemMsgsAgendadas();
      this.toastr.success("Mensagem Reagendada com Sucesso!");

    });
  }
  cancelarAgendamento(id) {
    this.messageService.cancelarMensagemAgendada(id).subscribe((res: any) => {

      this.listagemMsgsAgendadas();
      this.toastr.success("Mensagem Agendada cancelada com Sucesso!");
    });
  }

  SubmitMessage() {
    //const isErro = this.validaCampos();
    //if (isErro) {
    //  return isErro;
    //}

    document.getElementById('botaoConfirmacaoEnvio').click();

    const msg: Mensagem = { numbers: this.currentReplyMessage.number, Content: this.ReplyContent, isFlash: false, Sheduled: null, From: "", GrupoIds: [] };
    this.ReplyContent = null;
    this.msgService.SendMessagem(msg).subscribe(
      (data: any) => {
        //this.toastr.success("Enviado com sucesso", "Mensagem")

        //this.clientService.GetUserInformation().subscribe((userInfo) => u;
      },
      (erro: any) => {
        this.toastr.error('Favor preencha os campos', 'Mensagem');
        UserService;
      }
    );
  }
}
