import { Saldo } from './saldo.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { listagem } from './listagem.model';
import { User } from '../edicao/User.model';

@Injectable({
  providedIn: 'root'
})
export class ListagemUsersService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;

  getClientesGeral(page: number = 1, query: string = '', type?): Observable<listagem> {
    if (type == undefined) {
      return this.http.get<listagem>(`${this.baseUrl}Users/getclients?page=${page}&query=${query}`);
    }
    return this.http.get<listagem>(`${this.baseUrl}Users/getclients?page=${page}&query=${query}&active=${type}`);
  }


  deleteClient(id: number) {
    return this.http.delete(`${this.baseUrl}Users/DeleteClient/${id}`);
  }


  alteraStatusUser(id: number, status: boolean) {
    return this.http.get(`${this.baseUrl}Users/ChangeStatus/${id}/${status}`);
  }


  adicionarSaldo(dados: Saldo) {
    return this.http.post(`${this.baseUrl}Users/AddBalance`, dados);
  }

  transferirSaldo(dados: Saldo) {
    return this.http.post(`${this.baseUrl}Users/TransferenciaSaldo`, dados);
  }

  getRemocoes(id: number) {
    return this.http.get(`${this.baseUrl}Users/Remocao?id=${id}`);
  }

  removeSaldo(date: string) {
    return this.http.delete(`${this.baseUrl}Users/RemocaoSaldo?id=${date}`);
  }

  adicionarValidadeSaldo(cadastro: Saldo) {
    return this.http.post(`${this.baseUrl}Users/AddValidityBalance`, cadastro);
  }

  detalhesClienteById(id: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}users/edit/${id}`);
  }
  liberarFlash(id: number) {
    return this.http.get(`${this.baseUrl}Users/ActivateCanFlashForClient/${id}`);
  }
  liberarConcatenado(Id: number, concatValue: number) {
    return this.http.get(`${this.baseUrl}Users/IncreaseLimitForClient?id=${Id}&concatValue=${concatValue}`);
  }
  getClientLogHistoric(clientId: number) {
    return this.http.get(`${this.baseUrl}Users/getClientLogHistoric?clientId=${clientId}`);
  }
  exportClientLogHistoric(clientLog, isCsv) {
    return this.http.post(`${this.baseUrl}Users/ExportClientLogHistoric?isCsv=${isCsv}`, clientLog, { responseType: 'blob' });
  }
  exportClientsExcel(data, isCsv) {
    return this.http.post(`${this.baseUrl}reports/GetClientsAsExcel?isCsv=${isCsv}`, data, { responseType: 'blob' });
  }

}
