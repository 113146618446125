import { ListagemContatos } from './listagemContatos.model';
import { ListagemContatosService } from './listagemContatos.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-listagem-contatos',
  templateUrl: './listagem-contatos.component.html',
  styleUrls: ['./listagem-contatos.component.scss']
})

export class ListagemContatosComponent implements OnInit {

  constructor(private listageContatoService: ListagemContatosService, private clientService: UserService, private toastr: ToastrService, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute) { }


  searchQuery = '';

  grupoNome = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  listagemContato: any;
  TotalContatos: any;

  user: any;
  userInformation: any;

  selectedFile: File = null;

  linksModelo = `${document.getElementsByTagName('base')[0].href}templatesUploads/teste_contato_excelImporta.xlsx`;

  public idGrupoJaSelecionado: any;


  ngOnInit() {
    this.allContatoOrContatoByGrupo();
    this.idGrupoJaSelecionado = this.activatedRoute.snapshot.params.id;
    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);
  }

  allContatoOrContatoByGrupo(page: number = 1) {
    const id = this.activatedRoute.snapshot.params.id;
    if (this.activatedRoute.snapshot.params.id) {
      this.carregaContatosGroup(Number(id), page);
    } else {
      this.carregaContatos(page);

    }
  }



  openModal(usuario: any) {

    this.user = usuario;

  }

  closeModal() {
    this.user = null;
    this.selectedFile = <File>null;

  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    let arrayAux = array.map(x => { return { name: x.name != null ? x.name : '', criadoEm: x.criadoEm,phone: x.phone, niver: x.niver,id: x.id, genero: x.genero, estado: x.estado,email: x.email, cidade: x.cidade, bairro: x.bairro } });
    return arrayAux.filter(x => x.name.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    this.page = page;
    this.listagemContato = this.paginate(this.TotalContatos.lista, this.pageSize, this.page);
    console.log("PAGECOUNT: ", this.pageCount);
    console.log("PAGESIZE: ", this.pageSize);
    console.log("PAGE: ", this.page);
    console.log("CONTATO: ", this.listagemContato);
    console.log("TOTAL: ", this.TotalContatos.lista);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  carregaContatosGroup(idGrupo: number, page: number = 1) {
    this.load = true;
    // this.spinner.show();
    this.page = page;


    this.listageContatoService.getAllContatosByGrupo(idGrupo, this.page, this.searchQuery).subscribe(
      (res: any) => {
        this.TotalContatos = res;
        this.load = false;
        this.grupoNome = res.lista[0].group;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalContatos.lista.length;
        this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();




        // this.spinner.hide();
      },
      (erros) => {
        console.log(erros);
        this.load = false;
        // this.spinner.hide();

      }
    );
  }


  carregaContatos(page: number = 1) {
    this.load = true;
    // this.spinner.show();
    this.page = page;


    this.listageContatoService.getAllContatos(this.page, this.searchQuery).subscribe(
      (res: ListagemContatos) => {
        this.TotalContatos = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalContatos.lista.length;
        this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();


        // this.spinner.hide();
      },
      (erros) => {
        console.log(erros);
        this.load = false;
        // this.spinner.hide();

      }
    );
  }

  excluirContato(id: number) {
    this.listageContatoService.deleteContato(id).subscribe(
      (sucesso: any) => {
        this.toastr.success('Excluido com sucesso!', 'Contato');
        this.carregaContatos();
      },
      (erro: any) => {
        this.toastr.error('Erro ao Excluir!', 'Contato');

      }
    );

  }

  onSelectFile(fileInput: any) {
    this.selectedFile = <File>fileInput.target.files[0];
  }

  onSubmit(dados) {


    const formData = new FormData();
    formData.append('Arquivo', this.selectedFile);

    this.listageContatoService.ImportExcel(formData).subscribe(
      (body: any) => {
        this.toastr.success('Listagem de contatos será atualizada!', 'Contato', { timeOut: 1000, progressBar: true });
        setTimeout(() => {
          this.carregaContatos();
        }, 2000);

      },
      (erro: any) => {
        // console.log("ERRO =>", erro)
        if (typeof erro.error === 'string') {

          this.toastr.error(erro.error, 'Contato');
        } else {

          this.toastr.error('Ocorreu um erro ao importar', 'Contato');
        }

      }

    );

  }

  gerarExcelContatosGrupos(isCsv) {
    // this.toastr.info("Esta sendo gerado excel de contatos e grupos")
    const id = this.activatedRoute.snapshot.params.id || 0;
    this.listageContatoService.PegarContatosGrupos(id, this.searchQuery, isCsv).subscribe(
      (res: any) => {
        const blob = new Blob([res]);
        if (isCsv) {
          saveAs(blob, this.userInformation.clientName + "_Grupo_" + this.grupoNome + ".csv");
        } else {
          saveAs(blob, this.userInformation.clientName + "_Grupo_" + this.grupoNome + ".xlsx");
        }
        //this.listageContatoService.gerarExcelContatosGrupos(res, 'contatos_grupos');
      },
      (erros) => {
        console.log(erros);

      }
    );
  }


}
