import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

  constructor() { }

  tittle = 'Gerenciamento de Fornecedores';

  ngOnInit() {
  }

}
