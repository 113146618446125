import { User } from './../../clients/edicao/User.model';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UsersForProvidersService } from '../usersForProviders.service';
import { ListagemUsersForProviders } from '../model_users_for_providers/ListagemUsersForProviders.model';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-listagem-users-for-providers',
  templateUrl: './listagem-users-for-providers.component.html',
  styleUrls: ['./listagem-users-for-providers.component.css']
})
export class ListagemUsersForProvidersComponent implements OnInit {

  constructor(private usersForProvidersService: UsersForProvidersService, private toastr: ToastrService, private clientService: UserService) { }

  usuario: User;

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  TotalUsuarios: any;
  usuariosDados: any;

  user: any;
  userInformation: any;

  idfornecedorSelecionado: any = null;

  listaFornecedoresCadastrado: [] = [];

  documentMask: object = {
    1: '000.000.000-00',
    2: '00.000.000/0000-00'
  };


  ngOnInit() {
    this.listagemusersForproviders();
    this.idfornecedorSelecionado = null;
    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.usuariosDados = this.paginate(this.TotalUsuarios.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listagemusersForproviders(page: number = 1, type?, active?) {
    this.load = true;
    this.page = page;


    this.usersForProvidersService.GetallUsersForProviders(this.page, this.searchQuery, type, active).subscribe(
      (res: ListagemUsersForProviders) => {
        this.TotalUsuarios = res;
        this.load = false;
        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalUsuarios.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();


      },
      (erros) => {
        console.log(erros);
        this.load = false;

      }
    );
  }

  openModal(usuario: any) {
    this.user = usuario;

  }

  closeModal() {
    this.user = null;
    this.idfornecedorSelecionado = null;
    this.listaFornecedoresCadastrado = [];
  }

  changeStatus(id: number, status: boolean) {

    this.usersForProvidersService.AlteraStatusUserForProvider(id, status).subscribe(
      (res: object) => {
        this.closeModal();
        this.listagemusersForproviders();
        this.toastr.success('Alteração de status!', 'Concluido');
      },
      (erro: any) => {
        this.toastr.error('Erro de status', 'Erro ao alterar status usuario');
      }
    );

  }

  modalDetalhes(id: number) {
    this.usersForProvidersService.detalhesUsersProviderById(id).subscribe(
      (sucesso: User) => {
        this.usuario = sucesso;

      },
      (erro: any) => {
        this.toastr.error('Ocorreu um erro ao carregar dados', 'Cliente');

      }

    );
  }

  deletarUsuario(id: number) {
    this.usersForProvidersService.deleteUserForProvider(id).subscribe(
      (res: object) => {
        this.closeModal();
        this.listagemusersForproviders();
        this.toastr.success('Exclusão de usuario!', 'Deletado com sucesso');

      },
      (erro: any) => {
        this.toastr.error('Exclusão de usuario', 'Erro ao apagar usuario');


      }
    );
  }

  trocaFornecedor(clientId: number) {


    this.usersForProvidersService.AlteraFornecedor(clientId, this.idfornecedorSelecionado).subscribe(
      (res: any) => {
        this.closeModal();
        this.listagemusersForproviders();
        this.toastr.success('Alteração de fornecedor!', 'Concluido');
      },
      (erro: any) => {
        this.toastr.error('Erro ao alterar fornecedor usuario', 'Fornecedor');
      }
    );
  }


  listaFornecedores(usuario: any) {
    this.user = usuario;

    this.usersForProvidersService.GetallProviders().subscribe(
      (res: any) => {
        this.idfornecedorSelecionado = res.filter(a => a.label === this.user.provider).map(a => a.id)[0];
        this.listaFornecedoresCadastrado = res;
      },
      (erro: any) => {
        this.toastr.error('Erro ao carregar fornecedores', 'Lista de fornecedores');
      }
    );
  }

  ExportProvidersExcel(isCsv) {
    this.usersForProvidersService.ExportUsersProvidersExcel(this.usuariosDados.lista, isCsv).subscribe((res: any) => {
      const blob = new Blob([res]);
      let currentDate = new Date(Date.now());
      let currentDateFormat = currentDate.toLocaleDateString();
      currentDateFormat = currentDateFormat.replace("/", "");
      currentDateFormat = currentDateFormat.replace("/", "");

      if (isCsv) {
        saveAs(blob, this.userInformation.clientName + "_Usuarios_Fornecedores" + ".csv");
      } else {
        saveAs(blob, this.userInformation.clientName + "_Usuarios_Fornecedores" + ".xlsx");
      }
    })
  }


}
