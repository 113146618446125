import { User } from './User.model';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UsersEditService {

  constructor(private http: HttpClient, private fb: FormBuilder) { }


  baseUrl = `${environment.baseUrl}`;


  getById(id: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}users/edit/${id}`);
  }

  editUser(id: number, user: User) {
    return this.http.put<User>(`${this.baseUrl}users/edit/${id}`, user);
  }



}
