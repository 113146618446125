import { ImportacaContatoService } from './importacaContato.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit {

  constructor(private importContatoService: ImportacaContatoService, private toastr: ToastrService, private route: Router) { }

  selectedFile: File = null;

  linksModelo = `${document.getElementsByTagName('base')[0].href}templatesUploads/UNIQ_MESSAGE__MODELO_EXCEL_IMPORTACAO_DE_CONTATOS.xlsx`;

  isExistenteGrupo = false;

  isNovoGrupo = false;

  grupoIdSelecionado: any = null;

  inputNomeGrupo = '';

  listaGrupos = [];

  ngOnInit() {
    this.carregarGrupos();
  }

  onSelectFile(fileInput: any) {
    this.selectedFile = <File>fileInput.target.files[0];
  }


  tipoImportacao(tipo = 'existente', status = false) {
    switch (tipo) {
      case 'new_grupo':
        this.grupoIdSelecionado = null;
        this.isExistenteGrupo = false;
        this.isNovoGrupo = status;
        break;

      case 'existente':
        this.inputNomeGrupo = '';
        this.isNovoGrupo = false;
        this.isExistenteGrupo = status;
        break;

      default:
        this.grupoIdSelecionado = null;
        this.isExistenteGrupo = false;
        this.inputNomeGrupo = '';
        this.isNovoGrupo = false;
        break;
    }
  }

  carregarGrupos() {
    this.importContatoService.pegaGruposParaImportacao().subscribe(
      (sucesso: any) => {
        this.listaGrupos = sucesso;
      },
      (erro: any) => { }

    );

  }

  processarImportacao() {
    if (this.selectedFile === null) {
      this.toastr.error('Anexe o arquivo');
      return;
    }
    if (this.isExistenteGrupo && this.grupoIdSelecionado == null ) {
      this.toastr.error('Selecione um grupo para vincular os usuários');
      return;
    }
    if (this.isNovoGrupo && this.inputNomeGrupo == '') {
      this.toastr.error('Digite um nome para o grupo a ser criado');
      return;
    }

    const formData = new FormData();
    formData.append('Arquivo', this.selectedFile);
    formData.append('GrupoId', this.grupoIdSelecionado || 0);
    formData.append('NomeGrupo', this.inputNomeGrupo);



    this.importContatoService.importaDadosContato(formData).subscribe(
      (data: any) => {

        console.log(data);
        $('#ModalImportacao').modal('show');
      },
      (errr: any) => {
        console.log(errr);
      }
    );

  }
  redirectToDashboard() {
    this.route.navigateByUrl('/contacts/list');
  }

}
