import { ListaGroupsToContato } from '../listaGroupsToContato.model';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistroContatoService } from './registroContato.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { GruposService } from '../../groups/Grupos.service';

@Component({
  selector: 'app-registro-contato',
  templateUrl: './registro-contato.component.html',
  styleUrls: ['./registro-contato.component.scss']
})
export class RegistroContatoComponent implements OnInit {

  constructor(private fb: FormBuilder, public groupService: GruposService, private registroService: RegistroContatoService, private toastr: ToastrService, private route: Router, private activatedRoute: ActivatedRoute) {
    this.criarForm();
  }

  contatoForm: FormGroup;
  grupoForm: FormGroup;
  listaGrupos: ListaGroupsToContato[];
  isExistenteGrupo = false;

  isNovoGrupo = false;

  IdSelecionado: any[] = [];
  load: boolean;
  gruposSelecionados: any[] = [];
  public idGrupoJaSelecionado: any;


  ngOnInit() {
    this.criarForm();
    this.gruposSelecionados = [];
    this.carregaListaGrupos();
    this.idGrupoJaSelecionado = this.activatedRoute.snapshot.params.id;

    if (this.idGrupoJaSelecionado) {
      this.gruposSelecionados = [Number(this.idGrupoJaSelecionado)];
    }
  }

  tipoImportacao(tipo = 'existente', status = false) {
    switch (tipo) {
      case 'new_grupo':
        this.IdSelecionado = null;
        this.isExistenteGrupo = false;
        this.isNovoGrupo = status;
        break;

      case 'existente':
        //this.inputNomeGrupo = ""
        this.isNovoGrupo = false;
        this.isExistenteGrupo = status;
        break;

      default:
        this.IdSelecionado = null;
        this.isExistenteGrupo = false;
        //this.inputNomeGrupo = ""
        this.isNovoGrupo = false;
        break;
    }
    console.log(this.isNovoGrupo);
  }



  criarForm() {
    this.contatoForm = this.fb.group({
      Telefone: ['', Validators.required],
      Nome: [''],
      Email: [''],
      Aniversario: [''],
      Genero: ['0'],
      Bairro: [''],
      Cidade: [''],
      Estado: [''],

      IdGrupos: this.activatedRoute.snapshot.params.id ? new FormArray([new FormControl(this.activatedRoute.snapshot.params.id)]) : new FormArray([]),

    });
    this.grupoForm = this.fb.group({
      NomeGroup: ['', Validators.required],
      Descricao: ['', Validators.required]
    });

  }


  carregaListaGrupos() {
    this.load = true;
    this.registroService.listaGroups().subscribe(
      (data: ListaGroupsToContato[]) => {
        this.listaGrupos = data;
        console.log(this.listaGrupos);
        this.load = false;

      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar grupos!', 'Grupos');
        this.load = false;

      }
    );
  }

  SelectAllGroups(flag) {
    if (flag) {
      this.gruposSelecionados = this.listaGrupos.map(g => g.id);
    } else {
      this.gruposSelecionados = [];
    }
    console.log(this.gruposSelecionados)
  }

  SelectGroup(id) {
    if (this.gruposSelecionados.find(x => x == id)) {
      let index = this.gruposSelecionados.indexOf(id);
      this.gruposSelecionados.splice(index, 1);
    } else {
      this.gruposSelecionados.push(id);
    }
    console.log(this.gruposSelecionados)
  }

  OnSubmitCreate() {
    this.contatoForm.value.IdGrupos = this.gruposSelecionados;

    this.registroService.cadastroContato(this.contatoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Cadastro de contato!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/contacts/list');
        }, 2000);
      },
      (erro: any) => {
        this.toastr.error(erro.error.mensagem, 'Contato');

      }
    );

  }
  OnSubmitCreateGroup() {

    this.groupService.createGrupo(this.grupoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Está sendo redirecionado!', 'Sucesso', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/groups/list');
        }, 2000);
      },
      (erro: any) => {
        this.toastr.error('Erro ao cadastrar!', 'Error');

      }
    );



  }

  onCheckChange(event) {
    const formArray: FormArray = this.contatoForm.get('IdGrupos') as FormArray;


    if (event.target.checked) {

      formArray.push(new FormControl(event.target.value));
    } else {

      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {

          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }





}
