import { GruposService } from './../Grupos.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-criacao-grupos',
  templateUrl: './criacao-grupos.component.html',
  styleUrls: ['./criacao-grupos.component.scss']
})
export class CriacaoGruposComponent implements OnInit {

  constructor(private fb: FormBuilder, private grupoService: GruposService, private toastr: ToastrService, private route: Router) {
    this.criarForm();

  }

  grupoForm: FormGroup;
  load: boolean;


  ngOnInit() {
  }





  criarForm() {
    this.grupoForm = this.fb.group({
      NomeGroup: ['', Validators.required],
      Descricao: ['']
    });
  }


  OnSubmitCreate() {

    this.grupoService.createGrupo(this.grupoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Está sendo redirecionado!', 'Sucesso', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/groups/list');
        }, 2000);
      },
      (erro: any) => {
        this.toastr.error('Erro ao cadastrar!', 'Error');

      }
    );



  }


}
