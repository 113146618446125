<!-- <h3>Alteração de senha</h3> -->
<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-email text-blue mr-4 font-size-20"></i>
  <h1>Mensagens para Aniversariantes</h1>
</div>
<div class="row">
  <div class="col-md-12">
    <!--<h2 class="card-title text-white" style="text-align: left">
      Cadastro de mensagens para aniversariantes
    </h2>-->

    <div class="breadcrumb-wrapper d-flex justify-content-between align-items-center" style="margin: 6px;">
      <div>
        <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#cadastro_edit_niver"
          style="cursor: pointer">
          <i class="mdi mdi-file-document mr-1" style="font-size: large"></i>
          Inserir nova mensagem
        </button>
        <br />
        <br />
        <div class="card-footer">
          <div class="row">
            <div col-12 col-xs-6 col-md-4 col-lg-3>
              <span class="mdi mdi-alert"></span> As mensagens cadastradas serão criadas a partir da próxima virada do
              dia
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>

    <div class="card card-default" *ngIf="!load">
      <div class="card-header mb-0">
        <div class="row w-100">
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="card-body pt-0 pb-5">
        <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Remetente</th>
              <th scope="col" style="max-width: 300px">Mensagem</th>
              <th scope="col">Hora de envio</th>
              <th scope="col">Qtde de dias de antecedência</th>
              <th scope="col" style="text-align:center">Ações</th>
            </tr>
          </thead>
          <tbody *ngIf="listagemNiverMsg">
            <tr *ngFor="let niver of listagemNiverMsg">
              <td scope="row">{{ niver.remetente }}</td>
              <td>{{ niver.content }}</td>
              <td>{{ niver.hora }}</td>
              <td>{{ niver.dias }}</td>
              <td style="text-align:center">
                <button data-toggle="tooltip" title="Editar Mensagem" data-toggle="modal"
                  data-target="#cadastro_edit_niver" style="cursor: pointer" (click)="openModalDados(niver.id)"
                  title="editar" class="btn btn-primary btn-sm">
                  <span class="mdi mdi-pencil font-size-20"></span>
                </button> &nbsp;
                <button data-toggle="tooltip" title="Deletar Mensagem" data-toggle="modal"
                  data-target="#confirmaDeleteNiver" style="cursor: pointer" (click)="openModalDados(niver.id)"
                  title="editar" class="btn btn-danger btn-sm font">
                  <span class="mdi mdi-delete font-size-20"></span>
                </button>
              </td>
            </tr>
          </tbody>

          <tfoot *ngIf="!listagemNiverMsg">
            <tr>
              <td colspan="4" style="text-align: center">
                Não encontrado registros
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="form-inline" *ngIf="!load">
      <ul class="pagination">
        <li [ngClass]="
              thishasPreviousPage
                ? 'page-item'
                : 'page-item disabled'
            ">
          <a class="page-link" style="cursor: pointer" aria-label="Previous">
            <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;"
          [ngClass]="{'active': page == p }">
          <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
        </li>
        <li [ngClass]="
            thishasNextPage
                ? 'page-item'
                : 'page-item disabled'
            ">
          <a class="page-link" style="cursor: pointer" aria-label="Next">
            <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </div>

  </div>
</div>

<!-- inserir/editar aniversariante -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="cadastro_edit_niver" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" *ngIf="!aniversarianteSelecionado?.id">
          Novo mensagem
        </h5>
        <h5 class="modal-title center" *ngIf="aniversarianteSelecionado?.id">
          Editar mensagem
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Remetente</label>
          <input class="form-control" [(ngModel)]="remetente" type="text" />
        </div>
        <div class="col-12 px-0">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Dias de antecedência</label>
                <select class="form-control" [(ngModel)]="diasAntecedencia">
                  <option value="0">00 Dia(s)</option>
                  <option value="1">01 Dia(s)</option>
                  <option value="2">02 Dia(s)</option>
                  <option value="3">03 Dia(s)</option>
                  <option value="4">04 Dia(s)</option>
                  <option value="5">05 Dia(s)</option>
                  <option value="6">06 Dia(s)</option>
                  <option value="7">07 Dia(s)</option>
                  <option value="8">08 Dia(s)</option>
                  <option value="9">09 Dia(s)</option>
                  <option value="10">10 Dia(s)</option>
                  <option value="11">11 Dia(s)</option>
                  <option value="12">12 Dia(s)</option>
                  <option value="13">13 Dia(s)</option>
                  <option value="14">14 Dia(s)</option>
                  <option value="15">15 Dia(s)</option>
                  <option value="16">16 Dia(s)</option>
                  <option value="17">17 Dia(s)</option>
                  <option value="18">18 Dia(s)</option>
                  <option value="19">19 Dia(s)</option>
                  <option value="20">20 Dia(s)</option>
                  <option value="21">21 Dia(s)</option>
                  <option value="22">22 Dia(s)</option>
                  <option value="23">23 Dia(s)</option>
                  <option value="24">24 Dia(s)</option>
                  <option value="25">25 Dia(s)</option>
                  <option value="26">26 Dia(s)</option>
                  <option value="27">27 Dia(s)</option>
                  <option value="28">28 Dia(s)</option>
                  <option value="29">29 Dia(s)</option>
                  <option value="30">30 Dia(s)</option>
                  <option value="31">31 Dia(s)</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label>Hora do envio</label>
                <input class="form-control" [(ngModel)]="horaEnvio" type="time" />
              </div>
            </div>
          </div>
        </div>




        <div class="form-group">
          <label>Mensagem:</label>

          <textarea class="form-control" rows="5" id="contentMessage" [(ngModel)]="mensagem"
            (input)="CountCharSMS()"></textarea>
          <span style="font-size:small;color:#808080">Quantidade de caracteres: {{charCount}}</span>
        </div>
        <div class="form-group">
          <select class="form-control" (change)="AddParameter($event.target.value)" [(ngModel)]="personalizado">
            <option value="">Campo personalizado</option>
            <option value="[Nome]">[Nome]</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button *ngIf="aniversarianteSelecionado?.id" type="button" class="btn btn-primary btn-pill"
          (click)="editarMsgAniversariantes(aniversarianteSelecionado?.id)" data-dismiss="modal">
          Editar
        </button>
        <button *ngIf="!aniversarianteSelecionado?.id" type="button" class="btn btn-primary btn-pill"
          (click)="gravarMsgAniversariantes()" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" id="botaoAvisoMessageLimit" data-toggle="modal" style="display:none"
  data-target="#avisoMessageLimit"> ok</button>

<!-- modal excluir -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDeleteNiver" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar essa Mensagem de Aniversário ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="excluirNiver(aniversarianteSelecionado?.id)"
          data-dismiss="modal">
          Deletar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- aviso de limite execedio -->
<div class="modal fade" id="avisoMessageLimit" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Tamanho da mensagem excedida</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Você excedeu o limite de {{limitMessage}} caracteres
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Ok</button>

      </div>
    </div>
  </div>
</div>

<!-- aviso de confirmacao -->
<div class="modal fade" id="avisoConfirmacao" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none;"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Mensagem de Aniversário</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Mensagem de aniversário criada com sucesso!
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Ok</button>

      </div>
    </div>
  </div>
</div>