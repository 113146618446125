import { ConfiguracaoService } from '../configuracao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-desenvolvedor',
  templateUrl: './desenvolvedor.component.html',
  styleUrls: ['./desenvolvedor.component.scss']
})
export class DesenvolvedorComponent implements OnInit {

  constructor(private fb: FormBuilder, private configService: ConfiguracaoService, private toastr: ToastrService, private userService: UserService, private router: Router) {
  }

  novaSenhaForm: FormGroup;
  developerInfo: any;
  userInfo: any;

  ngOnInit() {
    this.userService.GetUserInformation().subscribe(res => {
      this.userInfo = res;
      this.getDeveloperInfo();
    });
  }

  getDeveloperInfo() {
    this.configService.getDeveloperInfo().subscribe((res:any) => this.developerInfo = res.apiKey);
  }

}
