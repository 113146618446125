<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <!-- <div>
      <a type="button" class="btn btn-primary" [routerLink]="['/users/register']">
        <i class="mdi mdi-plus mr-1"></i> Novo usuário
      </a>
    </div> -->
  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
        <a class="dropdown-item" style="cursor:pointer" (click)="ExportProvidersExcel(false)">Exportar como Excel</a>
        <a class="dropdown-item" style="cursor:pointer" (click)="ExportProvidersExcel(true)">Exportar como CSV</a>
        <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header justify-content-between mb-1">
        <h2>
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Filtrar por
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemusersForproviders(1)">Todos</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemusersForproviders(1,4)">Owner</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemusersForproviders(1,2)">Administrador</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemusersForproviders(1,1)">Comum</a>
            </div>
          </div>
        </h2>
        <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
          <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
            placeholder="Busca rápida" />
          <div class="input-group-append">
            <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
              <i class=" mdi mdi-magnify font-size-20"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0 pb-5" *ngIf="TotalUsuarios">
        <ul class="nav nav-pills nav-justified nav-style-fill nav-pill-table" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active d-flex" id="active-tab" data-toggle="tab" href="#active"
              (click)="listagemusersForproviders(1,null,true)" role="tab" aria-controls="active" aria-selected="true">
              <div class="d-flex ml-auto mr-auto">
                Ativos
                <span class="ml-2 text-success font-size-15">{{TotalUsuarios.ativos}}</span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" id="inactive-tab" data-toggle="tab" href="#inactive"
              (click)="listagemusersForproviders(1,null,false)" role="tab" aria-controls="inactive"
              aria-selected="false">
              <div class="d-flex ml-auto mr-auto">
                Inativos
                <span class="ml-2 text-secondary font-size-15">
                  {{ TotalUsuarios.inativos }}</span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" id="all-tab" data-toggle="tab" href="#all" (click)="listagemusersForproviders(1)"
              role="tab" aria-controls="all" aria-selected="false">
              <div class="d-flex ml-auto mr-auto">
                Todos
                <span class="ml-2 text-primary font-size-15">{{TotalUsuarios.ativos + TotalUsuarios.inativos}}</span>

              </div>
            </a>
          </li>
        </ul>
        <table class="table table-responsive table-responsive-large table-hover" *ngIf="!load" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Tipo</th>
              <th scope="col">Cliente</th>
              <th scope="col">Fornecedor</th>
              <th scope="col">Crédito</th>
              <th scope="col">Status</th>
              <!--<th scope="col">Ações</th>-->
            </tr>
          </thead>
          <tbody *ngFor="let usuario of usuariosDados">
            <tr>
              <td scope="row">{{ usuario.fullName }}</td>
              <td>{{ usuario.role }}</td>
              <td>{{ usuario.cliente }}</td>
              <td>{{ usuario.provider }}</td>
              <!-- <td>{{ usuario.email }}</td> -->
              <td>{{ usuario.credito }}</td>
              <td>
                <div *ngIf="usuario.isActive">
                  <span class="badge badge-pill badge-success">Ativo</span>
                </div>
                <div *ngIf="!usuario.isActive">
                  <span class="badge badge-pill badge-warning">Inativo</span>
                </div>
              </td>
              <!--<td>
                <div class="dropdown widget-dropdown">
                  <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"></a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">
                    <li class="dropdown-item">
                      <a [routerLink]="['/users/edit', usuario.id]" data-toggle="modal"
                        style="cursor: pointer">Editar usuario</a>
                    </li>-->

              <!--<li class="dropdown-item">
                      <a (click)="listaFornecedores(usuario)" data-toggle="modal" data-target="#alteraFornecedor"
                        style="cursor: pointer">Alterar Fornecedor</a>
                    </li>-->
              <!--<li class="dropdown-item">
                      <a data-toggle="modal" (click)="modalDetalhes(usuario.clienteId)" data-toggle="modal"
                        data-target="#modalDetalhesUserForProvider" style="cursor: pointer">Detalhes</a>
                    </li>
                    <li class="dropdown-item" *ngIf="usuario.isActive">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Desativar usuario</a>
                    </li>
                    <li class="dropdown-item" *ngIf="!usuario.isActive">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Ativar usuario</a>
                    </li>-->
              <!--                    <li *ngIf="!usuario.status" class="dropdown-item">-->
              <!--                      <a-->
              <!--                        (click)="openModal(usuario)"-->
              <!--                        data-toggle="modal"-->
              <!--                        data-target="#confirmaDelete"-->
              <!--                        style="cursor: pointer"-->
              <!--                        >Deletar usuario</a-->
              <!--                      >-->
              <!--                    </li>-->
              <!-- <li *ngIf="!usuario.isActive" class="dropdown-item">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaDelete"
                        style="cursor: pointer">Deletar usuario</a>
                    </li> -->
              <!-- <li class="dropdown-item">
                      <a title="Adicionar saldo" (click)="modalSaldo(usuario)" data-toggle="modal"
                        style="cursor: pointer" data-target="#addSaldo">Adicionar saldo</a>
                    </li> -->
              <!--</ul>
                </div>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="form-inline" *ngIf="!load">
  <!-- <nav aria-label="Page navigation example right"> -->
  <ul class="pagination pagination-flat ">
    <li style="cursor: pointer" [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li style="cursor: pointer" [ngClass]="
        this.hasNextPage ? 'page-item' : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <!-- </nav> -->
</div>

<!-- alterar status -->
<div class="modal fade" *ngIf="user" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaStatus" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar status ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="changeStatus(user.id, user.status)"
          data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- deletar usuarios -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDelete" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse usuário ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="deletarUsuario(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- detalhes do cliente -->
<div class="modal fade" *ngIf="usuario" id="modalDetalhesUserForProvider" data-keyboard="false" data-backdrop="static"
  tabindex="-1" aria-labelledby="modalDetalhesUserForProvider" style="display: none" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Detalhes de {{ usuario.fullName }}
        </h5>
        <button type="button" data-dismiss="modal" (click)="closeModal()">
          <span data-dismiss="modal" aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <!-- daddos gerais -->
          <h3>Dados gerais</h3>
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="exampleFormControlSelect3">Tipo de pessoa: * </label>
              <select class="form-control" id="exampleFormControlSelect3" disabled>
                <option [selected]="usuario.tipoCliente === 1" value="1">
                  Fisica
                </option>
                <option [selected]="usuario.tipoCliente === 2" value="2">
                  Jurídica
                </option>
              </select>
            </div>

            <div class="form-group col-md-3" *ngIf="usuario.tipoCliente === 1">
              <label>CPF: * </label>
              <input disabled [value]="usuario.documento" class="form-control" type="text"
                [mask]="documentMask[usuario.tipoCliente]" />
            </div>

            <div class="form-group col-md-3" *ngIf="usuario.tipoCliente === 2">
              <label>CNPJ: * </label>
              <input disabled [value]="usuario.documento" placeholder="Seu Cnpj" class="form-control" type="text"
                [mask]="documentMask[usuario.tipoCliente]" />
            </div>

            <div *ngIf="usuario.tipoCliente" class="form-group col-md-3">
              <label for="txtFullName" *ngIf="usuario.tipoCliente === 2">Razão social: *
              </label>
              <label for="txtFullName" *ngIf="usuario.tipoCliente === 1">Nome: *
              </label>
              <input disabled class="form-control" id="txtFullName" [value]="usuario.razaoSocial" />
            </div>

            <div class="form-group col-md-4" *ngIf="usuario.tipoCliente === 2">
              <label for="txtFullName" *ngIf="usuario.tipoCliente === 2">Nome fantasia: *
              </label>

              <label *ngIf="usuario.tipoCliente === 1" for="txtFullName">Nome *</label>

              <input disabled class="form-control" id="txtFullName" [value]="usuario.fullName" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="txtEmail">Endereço de Email: *</label>
              <input disabled type="email" class="form-control" id="txtEmail" [value]="usuario.email" />
            </div>

            <div class="form-group col-md-3">
              <label for="txtNumber">Telefone: * </label>
              <input disabled class="form-control" id="txtNumber" [value]="usuario.numero" />
            </div>

            <div class="form-group col-md-3">
              <label>Nome responsável * </label>

              <input disabled class="form-control" id="ResponsibleName" [value]="usuario.responsibleName"
                [value]="usuario.responsibleName" />
            </div>
          </div>

          <!-- fim daddos gerais -->

          <!-- outros contato -->
          <h3>Outros contatos</h3>
          <div *ngFor="let contato of usuario.otherContats">
            <div class="form-row">
              <div class="form-group col-md-2">
                <label>Full nam: * </label>
                <input class="form-control" disabled [value]="contato.fullName" />
              </div>
              <div class="form-group col-md-2">
                <label>Email: * </label>
                <input class="form-control" disabled [value]="contato.email" />
              </div>

              <div class="form-group col-md-2">
                <label>Phone 1: * </label>
                <input class="form-control" disabled [value]="contato.phoneOne" mask="(00) 00000-0000" />
              </div>

              <div class="form-group col-md-2">
                <label>Phone 2: * </label>

                <input class="form-control" disabled [value]="contato.phoneTwo" mask="(00) 00000-0000" />
              </div>

              <div class="form-group col-md-1">
                <label>Cargo: * </label>

                <input class="form-control" disabled [value]="contato.cargo" />
              </div>
            </div>
          </div>
          <!-- fim outros contato -->

          <!-- endereco -->

          <h3>Endereço</h3>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Cep: *</label>
              <input type="txt" class="form-control" disabled [value]="usuario.cep" mask="00.000-000" />
            </div>

            <div class="form-group col-md-3">
              <label>Endereco: *</label>
              <input type="txt" class="form-control" disabled [value]="usuario.endereco" />
            </div>

            <div class="form-group col-md-3">
              <label>Numero: *</label>
              <input type="number" class="form-control" disabled [value]="usuario.numero" />
            </div>
          </div>

          <div class="form-row" *ngIf="">
            <div class="form-group col-md-3">
              <label for="exampleFormControlSelect3">Estado: * </label>
              <input type="text" disabled [value]="usuario.estado" />
            </div>

            <div class="form-group col-md-3">
              <label for="exampleFormControlSelect3">Cidade: * </label>
              <input type="text" disabled [value]="usuario.cidade" />
            </div>

            <div class="form-group col-md-3">
              <label for="txtFullName">Bairro: * </label>
              <input class="form-control" id="" [value]="usuario.bairro" />
            </div>

            <div class="form-group col-md-3">
              <label for="txtFullName">Complemento: * </label>
              <input class="form-control" id="" [value]="usuario.companyName" />
            </div>
          </div>

          <!-- fim endereco -->

          <!-- dados de user -->

          <h3>Informações de usuário</h3>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label>Nome responsável: </label>

              <h3>
                <input class="form-control" [value]="usuario.responsibleName" disabled />
              </h3>
            </div>

            <div class="form-group col-md-4">
              <label for="txtFullName">Nome de Usuário: * </label>
              <input class="form-control" id="" disabled [value]="usuario.userName" />
            </div>

            <div class="form-group col-md-1">
              <label for="exampleFormControlSelect3">Tipo de conta: * </label>
              <input type="text" disabled class="form-control" [value]="usuario.role" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label>E-mail: </label>

              <h3>
                <input class="form-control" disabled [value]="usuario.email" />
              </h3>
            </div>
          </div>

          <!-- fim dados de user -->
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- alterar fornecedor -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="alteraFornecedor" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar fornecedor ?
          <div class="model-title center">
            <p class="alert alert-info">Ao alterar, o tráfego de SMS da conta será realizado com o fornecedor
              selecionado.</p>
          </div>
        </h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Fornecedores: </label>
          <select class="form-control" [(ngModel)]="idfornecedorSelecionado">
            <option *ngFor="let item of listaFornecedoresCadastrado" [value]="item.id">{{item.label}}</option>
          </select>
        </div>

        <!-- </form> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="trocaFornecedor(user.clienteId)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>