import { ConfiguracaoService } from './../configuracao.service';
import { Component, OnInit } from '@angular/core';

declare let $: any;

@Component({
  selector: 'app-pesquisa-numero',
  templateUrl: './pesquisa-numero.component.html',
  styleUrls: ['./pesquisa-numero.component.scss']
})
export class PesquisaNumeroComponent implements OnInit {

  constructor(private configuracaoService: ConfiguracaoService) { }

  public numero = '';

  public listaSmsNumero = [];

  public numeroDetalhado: any;

  ngOnInit() {
  }


  carregaListaDados() {
    this.listaSmsNumero = [];
    this.configuracaoService.buscarDadosTelefone(this.numero).subscribe(
      (sucesso: any) => {

        this.listaSmsNumero = sucesso;
        $('#operadorSmsNumero').modal('show');
       },
      (err: any) => {
        alert('erro ao carregar');
      }
    );
  }

  detalharPhone(smsId: number){
    this.configuracaoService.buscaDadosDetalhesPhone(smsId).subscribe(
      (sucesso: any) => {

        this.numeroDetalhado = sucesso;
        $('#operadorSmsNumeroDetalhadoSms').modal('show');
       },
      (err: any) => {
        alert('erro ao carregar');
      }
    );
  }

  closeModal(){
    this.numeroDetalhado = null;
  }

}
