import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../../menssage.service';
import { ListagemSuspeitasLinks } from '../../model_message/ListagemSuspeitasLinks.model';

@Component({
  selector: 'app-linkssusp',
  templateUrl: './linkssusp.component.html',
  styleUrls: ['./linkssusp.component.scss']
})
export class LinkssuspComponent implements OnInit {

  constructor(private msgService: MessageService, private toastr: ToastrService) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  linkSelecionado: any;

  TotalLinks: any;
  listagemLinkSuspeita: any;

  link = '';

  ngOnInit() {
    this.listagemLinks();
  }
  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.word.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listagemLinkSuspeita = this.paginate(this.TotalLinks.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }


  listagemLinks(page: number = 1) {
    this.load = true;
    this.page = page;


    this.msgService.listarLinksSuspeitas(this.page, this.searchQuery).subscribe(
      (res: ListagemSuspeitasLinks) => {
        this.TotalLinks = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalLinks.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }

  adicionarLink() {
    this.msgService.addPalavraOuLinkSuspeito(this.link, 1).subscribe(
      (res: any) => {
        this.toastr.success("Link salvo com sucesso")
        this.listagemLinks();
      },
      (erros) => {
        this.toastr.success("Nao foi possivel salvar o link")
        console.log(erros);

      }
    );
  }

  removerLink(id: any) {
    this.msgService.removerItemSuspeito(id).subscribe(
      (res: any) => {
        this.listagemLinks();

      },
      (erros) => {
        console.log(erros);

      }
    );
  }

  openModal(dados: any) {
    this.linkSelecionado = dados;
  }

  closeModal() {
    this.linkSelecionado = null;
    this.link = null;
  }

}
