import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-pages-code',
  templateUrl: './pages-code.component.html',
  styleUrls: ['./pages-code.component.scss']
})
export class PagesCodeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private route: Router, public userService: UserService) { }

  code: number;
  msg: string;
  ngOnInit() {
    this.carregarTela();
  }

  carregarTela() {
    this.code = this.activatedRoute.snapshot.params.code;

    switch (Number(this.code)) {
      case 401:
        this.msg = 'Desculpe, você precisa está logado';
        break;

      case 403:
        this.msg = 'Desculpe, você precisa de permissão';
        break;

      case 404:
        this.msg = 'Desculpe, end point não localizado';
        break;

        case 500:
          this.msg = 'Desculpe, ocorreu erro no nosso servidor';
          break;

      default:
        this.code = 404;
        this.msg = 'Desculpe, end point não localizado';

        break;
    }



  }

}
