<div class="row">
  <div class="col-12" *ngIf="!load">
    <form
      ngNativeValidate
      [formGroup]="grupoForm"
      autocomplete="off"
      (submit)="OnSubmitCreate()"
    >
      <div class="card card-default card-form-register">
        <div class="card-header card-header-border-bottom ">
          <h2>Adicionar grupo</h2>
        </div>
        <div class="card-body pb-1">
          <div class="row">
            <div class="col-12 mt-3">
              <div class="form-group">
                <label for="exampleFormControlSelect3">Nome do grupo: * </label>
                <input
                  class="form-control"
                  placeholder="Digite nome do grupo"
                  formControlName="NomeGroup"
                />
                <span
                  class="mt-2 d-block text-danger"
                  *ngIf="
                    grupoForm.get('NomeGroup').touched &&
                    grupoForm.get('NomeGroup').errors?.required
                  "
                  >Este campo é obrigatório!</span
                >
              </div>

              <div class="form-group">
                <label>Descricao: </label>
                <textarea
                  class="form-control"
                  placeholder="Digite descricao"
                  formControlName="Descricao"
                  cols="30"
                  rows="10"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div col-12 col-xs-6 col-md-4 col-lg-3>
              <input
                type="submit"
                value="Cadastrar"
                class="btn btn-block btn-primary"
                [disabled]="!grupoForm.valid"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
