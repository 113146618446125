import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ListaGroupsToContato } from '../listaGroupsToContato.model';
import { RegistroContatoService } from '../registro-contato/registroContato.service';
import { EdicaContatoService } from './EdicaContato.service';

@Component({
  selector: 'app-edicao-contato',
  templateUrl: './edicao-contato.component.html',
  styleUrls: ['./edicao-contato.component.scss']
})
export class EdicaoContatoComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private registroService: RegistroContatoService,
    private editContatoService: EdicaContatoService,
    private toastr: ToastrService, private route: Router,
    private activatedRoute: ActivatedRoute, private datepipe: DatePipe) {
    //  this.criarForm()

  }

  contatoForm: FormGroup;
  load: boolean;
  listaGrupos: ListaGroupsToContato[];
  gruposCadastrados: any;
  ngOnInit() {
    // this.carregarContato()
    this.carregaListaGrupos();
  }


  carregaListaGrupos() {
    this.load = true;
    this.registroService.listaGroups().subscribe(
      (data: ListaGroupsToContato[]) => {
        this.listaGrupos = data;
        this.load = false;
        this.carregarContato();
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar grupos!', 'Grupos');
        this.load = false;
        this.carregarContato();

      }
    );
  }

  carregarContato() {
    this.load = true;
    this.editContatoService.carregarContatoById(this.activatedRoute.snapshot.params.id).subscribe(
      (data: any) => {
        this.load = false;

        this.contatoForm = this.fb.group({
          Telefone: [{ value: data.contato.phone, disabled: false }, Validators.required],
          Nome: [{ value: data.contato.name, disabled: false }],
          Email: [{ value: data.contato.email, disabled: false }],
          Aniversario: [{ value: this.datepipe.transform(data.contato.birthday, 'yyyy-MM-dd'), disabled: false }],
          Genero: [{ value: data.contato.genero, disabled: false }],
          Bairro: [{ value: data.contato.bairro, disabled: false }],
          Cidade: [{ value: data.contato.cidade, disabled: false }],
          Estado: [{ value: data.contato.estado, disabled: false }],
          IdGrupos: data.contato.grupoId ? new FormArray([new FormControl(data.contato.grupoId)]) : new FormArray([]),
        });



        this.gruposCadastrados = data.grupos;

      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar contato!', 'Contato');
        this.load = false;

      }
    );
  }

  OnSubmitEdit() {
    this.contatoForm.value.IdGrupos = this.gruposCadastrados;

    this.editContatoService.editContato(Number(this.activatedRoute.snapshot.params.id), this.contatoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Atualizado de contato!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/contacts/list');
        }, 2000);
      },
      (erro: any) => {
        this.toastr.error('Erro ao atualizar!', 'Contato');

      }
    );



  }
  SelectAllGroups(flag) {
    if (flag) {
      this.gruposCadastrados = this.listaGrupos.map(g => g.id);
    } else {
      this.gruposCadastrados = [];
    }
  }

  SelectGroup(id) {
    if (this.gruposCadastrados.find(x => x == id)) {
      let index = this.gruposCadastrados.indexOf(id);
      this.gruposCadastrados.splice(index, 1);
    } else {
      this.gruposCadastrados.push(id);
    }
  }

  onCheckChange(event) {
    const formArray: FormArray = this.contatoForm.get('IdGrupos') as FormArray;


    if (event.target.checked) {

      formArray.push(new FormControl(event.target.value));
    } else {

      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {

          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

}
