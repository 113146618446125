<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <a type="button" class="btn btn-primary" [routerLink]="['/users/register']">
      <i class="mdi mdi-plus mr-1"></i> Novo usuário
    </a>
  </div>
  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
        <a class="dropdown-item" style="cursor:pointer" (click)="exportUsers(false)">Exportar como Excel</a>
        <a class="dropdown-item" style="cursor:pointer" (click)="exportUsers(true)">Exportar como CSV</a>
        <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header mb-1">
        <div class="row w-100 justify-content-between">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Filtrar por
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemUsersCadastrado(1)">Todos</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemUsersCadastrado(1,2)">Owner</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemUsersCadastrado(1,1)">Administrador</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemUsersCadastrado(1,0)">Comum</a>
            </div>
          </div>
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0 pb-5" *ngIf="TotalUsuarios">
        <ul class="nav nav-pills nav-justified nav-style-fill nav-pill-table" id="myTab" role="tablist">
          <li class="nav-item" (click)="listagemUsersCadastrado(1,null,true)">
            <a class="nav-link active d-flex" id="active-tab" data-toggle="tab" href="#active" role="tab"
              aria-controls="active" aria-selected="true">
              <div class=" d-flex ml-auto mr-auto">
                Ativos
                <span class="ml-2 text-success font-size-15">
                  {{TotalUsuarios.ativos}}
                </span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" id="inactive-tab" data-toggle="tab"
              (click)="listagemUsersCadastrado(1,null,false)" href="#inactive" role="tab" aria-controls="inactive"
              aria-selected="false">
              <div class=" d-flex ml-auto mr-auto">
                Inativos
                <span class="ml-2 text-secondary font-size-15">
                  {{ TotalUsuarios.inativos }}
                </span>
              </div>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex" id="all-tab" data-toggle="tab" (click)="listagemUsersCadastrado(1)" href="#all"
              role="tab" aria-controls="all" aria-selected="false">
              <div class=" d-flex ml-auto mr-auto">
                Todos
                <span class="ml-2 text-primary font-size-15">
                  {{TotalUsuarios.ativos + TotalUsuarios.inativos}}
                </span>
              </div>
            </a>
          </li>
        </ul>
        <table *ngIf="!load" class="table table-responsive table-responsive-large table-hover" style="width: 100%">
          <thead>
            <tr>
              <th scope="col">Username</th>
              <th scope="col">Tipo</th>
              <th scope="col">Cliente</th>
              <th scope="col">Celular</th>
              <th scope="col">Email</th>
              <th scope="col">Crédito</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody *ngFor="let usuario of usuariosDados">
            <tr>
              <td scope="row">{{ usuario.login }}</td>
              <td>{{ usuario.role }}</td>
              <td>{{ usuario.cliente }}</td>
              <td>{{ usuario.phoneNumber }}</td>
              <td>{{ usuario.email }}</td>
              <td>{{ usuario.credito }}</td>
              <td>
                <div *ngIf="usuario.isActive">
                  <span class="badge badge-pill badge-success">Ativo</span>
                </div>
                <div *ngIf="!usuario.isActive">
                  <span class="badge badge-pill badge-warning">Inativo</span>
                </div>
              </td>
              <td>
                <div class="dropdown widget-dropdown">
                  <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"></a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">
                    <li class="dropdown-item">
                      <a [routerLink]="['/users/edit', usuario.id]" data-toggle="modal" style="cursor: pointer">
                        Editar
                        usuario
                      </a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="detalharUser(usuario.id)" data-toggle="modal" data-target="#DetalhesUsuario"
                        style="cursor: pointer">Detalhes</a>
                    </li>
                    <li class="dropdown-item" *ngIf="usuario.isActive">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Desativar usuario</a>
                    </li>
                    <li class="dropdown-item" *ngIf="!usuario.isActive">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaStatus"
                        style="cursor: pointer">Ativar usuario</a>
                    </li>
                    <li *ngIf="!usuario.isActive" class="dropdown-item">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaDelete"
                        style="cursor: pointer">Deletar usuario</a>
                    </li>
                    <li *ngIf="CheckIfRole(['Owner'])" class="dropdown-item">
                      <a (click)="getUserLogHistoric(usuario.id,1)" data-toggle="modal" data-target="#HistoricModal"
                        style="cursor: pointer">Histórico</a>
                    </li>
                    <li *ngIf="usuario.isActive" class="dropdown-item">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#confirmaTrocarAPIKEY"
                        style="cursor: pointer">Trocar APIKEY</a>
                    </li>
                    <li *ngIf="usuario.isActive && CheckIfRole(['Owner'])" class="dropdown-item">
                      <a (click)="openModal(usuario)" data-toggle="modal" data-target="#logarComoUsuario"
                        style="cursor: pointer">Logar como este Usuário</a>
                    </li>
                    <!-- <li class="dropdown-item">
                      <a title="Adicionar saldo" (click)="modalSaldo(usuario)" data-toggle="modal"
                        style="cursor: pointer" data-target="#addSaldo">Adicionar saldo</a>
                    </li> -->
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="form-inline justify-content-between" *ngIf="!load">
  <!-- <nav aria-label="Page navigation example right"> -->
  <ul class="pagination">
    <li style="cursor: pointer" [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li style="cursor: pointer" [ngClass]="
        this.hasNextPage ? 'page-item' : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
  <!-- </nav> -->
</div>

<!-- detalhes do cliente -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="DetalhesUsuario" tabindex="-1" style="display: none" aria-hidden="true">

  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" *ngIf="usuario">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Detalhes de {{ usuario.login }}
        </h5>
        <button type="button" data-dismiss="modal" (click)="closeModal()">
          <span data-dismiss="modal" aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <br />
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4">
              <label>
                Selecione cliente : *
              </label>
              <select class="form-control" *ngIf="listaCliente" disabled>
                <option [selected]="usuario.clienteId" *ngFor="let cliente of listaCliente" [value]="cliente.id">
                  {{ cliente.name }}
                </option>
              </select>

              <!-- <input type="text" disabled [value]="usuario.clienteId"> -->
            </div>

            <div class="form-group col-sm-12 col-md-4">
              <label>Nome:* </label>

              <h3>
                <input class="form-control" disabled [value]="usuario.fullName" />
              </h3>
            </div>

            <div class="form-group col-sm-12 col-md-4">
              <label for="exampleFormControlSelect3">Tipo de conta: * </label>
              <input type="text" class="form-control" disabled [value]="usuario.role" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4">
              <label>Nome responsavel:* </label>

              <h3>
                <input class="form-control" disabled [value]="usuario.responsibleName" />
              </h3>
            </div>

            <div class="form-group col-sm-12 col-md-4">
              <label>Telefone do responsavel:* </label>

              <h3>
                <input class="form-control" [value]="usuario.responsibleContact" mask="(00) 00000-0000" disabled />
              </h3>
            </div>

            <div class="form-group col-sm-12 col-md-4">
              <label for="txtFullName">Nome de Usuário: * </label>
              <input class="form-control" id="txtUserName" placeholder="Enter Username" disabled
                [value]="usuario.login" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4">
              <label>E-mail:* </label>

              <h3>
                <input class="form-control" disabled [value]="usuario.email" />
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- alterar status -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="openDetalhesUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar status ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="changeStatus(user.id, user.isActive)"
          data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Historic -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="HistoricModal" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Histórico
        </h5>
      </div>
      <div class="modal-body">
        <div class="dropdown d-inline-block mb-3">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" data-display="static">
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
            <a class="dropdown-item" style="cursor:pointer" (click)="exportUserLogHistoric(false)">Exportar como
              Excel</a>
            <a class="dropdown-item" style="cursor:pointer" (click)="exportUserLogHistoric(true)">Exportar como
              Excel</a>
            <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
          </div>
        </div>
        <div>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Cliente</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of userLog">
              <tr>
                <td scope="row">{{ item.createdOn }}</td>
                <td scope="row">{{ item.client }}</td>
                <td scope="row">{{ item.log }}</td>

              </tr>
            </tbody>
          </table>
          <div class="form-inline" *ngIf="!load">
            <!-- <nav aria-label="Page navigation example right"> -->
            <ul class="pagination">
              <li style="cursor: pointer"
                [ngClass]=" this.hasPreviousPageHistoric ? 'page-item' : 'page-item disabled' ">
                <a class="page-link" aria-label="Previous">
                  <span aria-hidden="true" class="mdi mdi-chevron-left"
                    (click)="getPaginateHistoric(pageHistoric - 1)"></span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesHistoric;"
                [ngClass]="{'active': pageHistoric == p }">
                <a class="page-link" (click)="getPaginateHistoric(p)">{{p}}</a>
              </li>
              <li style="cursor: pointer" [ngClass]="this.hasNextPageHistoric ? 'page-item' : 'page-item disabled'">
                <a class="page-link" aria-label="Next">
                  <span aria-hidden="true" class="mdi mdi-chevron-right"
                    (click)="getPaginateHistoric(pageHistoric + 1)"></span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
            <!-- </nav> -->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- alterar status -->
<div class="modal fade" *ngIf="user" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaStatus" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar status ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="changeStatus(user.id, user.isActive)"
          data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- deletar usuarios -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDelete" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse usuário ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="deletarUsuario(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Trocar APIKEY do usuario -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaTrocarAPIKEY" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja realmente trocar a APIKey desse usuário ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="trocarAPIKeyUsuario(user.id)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- logar como o usuario -->
<div class="modal fade" data-keyboard="false" *ngIf="user" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="logarComoUsuario" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Logar como Usuário
        </h5>
      </div>
      <div class="modal-body">
        Deseja realmente logar como o usuario {{user.fullName}}?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="loginAsUser(user.id)" data-dismiss="modal">
          Fazer Login
        </button>
      </div>
    </div>
  </div>
</div>