import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EdicaContatoService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  carregarContatoById(idContato: Number){
    return this.http.get(`${this.baseUrl}contacts/getcontatoid/${idContato}`);
  }

  editContato(id: number, contato: any) {
    return this.http.put(`${this.baseUrl}contacts/edit/${id}`, contato);
  }

}
