import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { MessageService } from '../menssage.service';
import { ListagenModeloMessages } from '../model_message/ListagenModeloMessages.model';
declare let $: any;


@Component({
  selector: 'app-modelos-templatesms',
  templateUrl: './modelos-templatesms.component.html',
  styleUrls: ['./modelos-templatesms.component.scss']
})
export class ModelosTemplatesmsComponent implements OnInit {

  constructor(private msgEnvidaService: MessageService, private toastr: ToastrService, public clientService: UserService, public FB: FormBuilder) {
  }
  formulario;
  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;

  TotalModelos: any;
  listaModelos: any;

  modeloSelecionado: any;

  listaParamentros = ['Campo Customizado', '[Nome]', '[Parâmetro 2]'];

  descricao = '';

  conteudo = '';

  limitMessage = 0;

  charCount = 0;


  ngOnInit() {
    this.carregarListaModelo();

    this.clientService.GetUserInformation().subscribe((userInfo: any) => this.limitMessage = userInfo.limitMessage)
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.content.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listaModelos = this.paginate(this.TotalModelos.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  carregarListaModelo(page: number = 1) {
    this.load = true;
    this.page = page;


    this.msgEnvidaService.carregaListaModelos(this.page, this.searchQuery).subscribe(
      (res: ListagenModeloMessages) => {
        this.TotalModelos = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalModelos.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();
      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );

  }

  CountCharSMS() {
    let count = 0;
    if (count > 0) {
      count += 2;
    }
    let conteudo = this.conteudo;

    count += conteudo.length;
    if (count > this.limitMessage - 1) {
      this.conteudo = this.conteudo.substring(0, this.limitMessage - 1);
      //this.Content = conteudo;

      $('#avisoMessageModeloLimit').modal('show');
      $('#contentMessage').val(this.conteudo);

    }
    this.charCount = count;

  }
  SelectParameter(value) {
    if (value == '[Nome]') {
      if (!this.conteudo.includes('[Nome]')) {
        this.conteudo += '[Nome]';
      }
    } else {
      this.conteudo = this.conteudo.replace('[Nome]', '');
      $('#contentMessage').val(this.conteudo);
    }
    if (value == '[Parâmetro 2]') {
      if (!this.conteudo.includes('[Parâmetro 2]')) {
        this.conteudo += '[Parâmetro 2]';
      }
    } else {
      this.conteudo = this.conteudo.replace('[Parâmetro 2]', '');
      $('#contentMessage').val(this.conteudo);
    }
    this.CountCharSMS();
  }

  openModal(modelo: any) {
    //this.listaParamentros = []
    this.modeloSelecionado = modelo;

  }

  closeModal() {
    this.listaParamentros = ['Campo Customizado', '[Nome]', '[Parâmetro 2]'];
    this.descricao = '';
    this.conteudo = '';
    this.charCount = 0;

  }

  //leituraParamentro(mensagem: string) {
  //  this.contadorMensagem();
  //  this.msgEnvidaService.leituraParamentroModelo(mensagem).subscribe(
  //    (res: any) => {
  //      this.listaParamentros = res.paramentros;

  //    },
  //    (erros) => {
  //      console.log(erros);
  //      this.load = false;
  //    }
  //  );
  //}

  carregaDadosModeloId(id: number) {
    this.listaParamentros = ['Campo Customizado', '[Nome]', '[Parâmetro 2]'];

    this.msgEnvidaService.carregarModeloById(id).subscribe(
      (res: any) => {
        this.modeloSelecionado = res.modelo;
        this.descricao = res.modelo.description;
        this.conteudo = res.modelo.content;
        //this.listaParamentros = res.paramentros;
        $('#cadastroNovoModelo').modal('show');
        this.CountCharSMS();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }

  excluirModeloSelecionado(id: number) {
    this.msgEnvidaService.excluirModelo(id).subscribe(
      (res: any) => {
        this.toastr.success('Excluido com  sucesso');

        this.carregarListaModelo();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }

  gravarModelo() {
    if (!this.descricao || !this.conteudo) {
      return this.toastr.info('descrição e conteudo são obrigatorios');
    }

    const data = {
      descricao: this.descricao,
      conteudo: this.conteudo,
      paramentros: this.listaParamentros
    };

    this.msgEnvidaService.inserirModelo(data).subscribe(
      (res: any) => {
        this.toastr.success('gravado com  sucesso');
        this.carregarListaModelo();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );


  }


  contadorMensagem() {

    let count = this.conteudo.length;
    let conteudo = this.conteudo;

    if (conteudo.length > this.limitMessage - count) {


      conteudo = conteudo.substring(0, this.limitMessage);

      $('#avisoMessageModeloLimit').modal('show');

    }


    count += conteudo.length;

    this.conteudo = conteudo;

    this.charCount = count;

    // console.log(this.limitMessage)

    $('#contentMessage').val(conteudo);
    // console.log(count);

  }

  editarModelo(modelo) {
    if (!this.descricao || !this.conteudo) {
      return this.toastr.info('descrição e conteudo são obrigatorios');
    }

    const data = {
      descricao: this.descricao,
      conteudo: this.conteudo,
      paramentros: this.listaParamentros
    };

    this.msgEnvidaService.editarModelo(Number(modelo.id), data).subscribe(
      (res: any) => {
        this.toastr.success('atualizado com  sucesso');
        this.carregarListaModelo();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }



}
