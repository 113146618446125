<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="pacman" [fullScreen]="true">
  <p style="color: white">Carregando...</p>
</ngx-spinner>

<div class="row">
  <div class="col-md-6 col-lg-6 col-xl-3">
    <div class="media widget-media p-4 bg-white border border-light rounded">
      <i class="mdi mdi-account-outline text-blue mr-4"></i>
      <div class="media-body align-self-center">
        <h4 class="text-primary mb-2" *ngIf="!load">
          {{ this.totalItems }}
        </h4>
        <p>Cadastrados</p>
      </div>
    </div>
  </div>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <!--<a
      type="button"
      class="btn btn-primary"
      [routerLink]="['/groups/register']"
    >
      <i class="mdi mdi-plus mr-1"></i> Novo grupo
    </a>-->
    <button class="btn btn-primary" data-toggle="modal" data-target="#CriarGrupoModal">
      <i class="mdi mdi-plus mr-1"></i> Novo grupo
    </button>
  </div>

  <!-- <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
        Importar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial;">
        <a class="dropdown-item"  data-toggle="modal" data-target="#importaExcel" style="cursor: pointer">Importar como Excel</a>
        <a class="dropdown-item" href="#">Importar como CSV</a>
      </div>
    </div>
  </div> -->
</div>

<div class="row">
  <div class="col-12">
    <div class="card card-default">
      <div class="card-header mb-0">
        <div class="row w-100 justify-content-between">
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="getPaginate()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button data-toggle="tooltip" title="Visualizar Grupo" class="btn btn-sm btn-primary"
                style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0 pb-5" *ngIf="!load">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Descrição</th>
              <th scope="col">Status</th>
              <th scope="col">Criado em</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of listagemGrupos">
            <tr>
              <td scope="row">{{ item.name }}</td>
              <td scope="row">{{ item.quantidade }}</td>
              <td scope="row" [title]="item.description">{{ item.description != null? item.description.substring(0, 20):
                '' }}</td>
              <td>
                <div *ngIf="item.isActive">
                  <span class="badge badge-pill badge-success">Ativo</span>
                </div>
                <div *ngIf="!item.isActive">
                  <span class="badge badge-pill badge-warning">Inativo</span>
                </div>
              </td>
              <td scope="row">{{ item.criadoEm }}</td>
              <td>
                <div class="dropdown widget-dropdown">
                  <a class="dropdown-toggle icon-burger-mini" href="#" role="button" id="dropdown-notification"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                  <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-notification">

                    <li class="dropdown-item" *ngIf="item.isActive">
                      <a (click)="openModal(item)" data-toggle="modal" data-target="#confirmaStatusGrupoAltera"
                        style="cursor: pointer">Desativar grupo</a>
                    </li>
                    <li class="dropdown-item" *ngIf="!item.isActive">
                      <a (click)="openModal(item)" data-toggle="modal" data-target="#confirmaStatusGrupoAltera"
                        style="cursor: pointer">Ativar grupo</a>
                    </li>

                    <li class="dropdown-item">
                      <a data-target="#EditarGrupoModal" (click)="carregarGrupoSelecionado(item.id)" data-toggle="modal"
                        style="cursor: pointer">Editar</a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="openModal(item)" data-toggle="modal" data-target="#confirmaDelete"
                        style="cursor: pointer">Excluir</a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="gerarExcelContatoUmGrupo(item.id,false)" style="cursor: pointer">Exportar</a>
                    </li>
                    <li class="dropdown-item">
                      <a (click)="ListContactsFromGroup(item.id)"
                        style="cursor: pointer;text-decoration:underline">Contatos</a>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <div class="btn-group"></div>
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!listagemGrupos">
            <td style="text-align: center" colspan="3">Não encontrado itens</td>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Paginação -->
<div class="form-inline justify-content-between" *ngIf="!load">
  <ul class="pagination ">
    <li style="cursor: pointer" [ngClass]="
        this.hasPreviousPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li style="cursor: pointer" [ngClass]="
        this.hasNextPage
          ? 'page-item'
          : 'page-item disabled'
      ">
      <a class="page-link" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
</div>

<!-- modal excluir -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDelete" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse grupo ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="excluirGrupo(user.id)" data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>


<!-- Modal Criar Grupo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="CriarGrupoModal" tabindex="-1" aria-labelledby="CriarGrupoModal" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="CriarGrupoModal">
          Adicionar Grupo
        </h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12" *ngIf="!load">
            <form ngNativeValidate [formGroup]="grupoForm" autocomplete="off" (submit)="OnSubmitCreate()">
              <div class="card-body pb-1">
                <div class="row">
                  <div class="col-12 ">
                    <div class="form-group">
                      <label for="exampleFormControlSelect3">Nome do grupo: * </label>
                      <input class="form-control" placeholder="Digite nome do grupo" formControlName="NomeGroup" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                    grupoForm.get('NomeGroup').touched &&
                    grupoForm.get('NomeGroup').errors?.required
                  ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group">
                      <label>Descrição: </label>
                      <textarea class="form-control" placeholder="Digite descricao" formControlName="Descricao"
                        cols="30" rows="5">
                            </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button type="submit" (click)="OnSubmitCreate()" data-dismiss="modal" class="btn btn-pill btn-primary"
          [disabled]="!grupoForm.valid">
          Cadastrar
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal Editar Grupo -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="EditarGrupoModal" tabindex="-1" aria-labelledby="CriarGrupoModal" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="CriarGrupoModal">
          Editar Grupo
        </h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12" *ngIf="!load">
            <form ngNativeValidate [formGroup]="grupoForm" autocomplete="off" (submit)="OnSubmitCreate()">
              <div class="card-body pb-1">
                <div class="row">
                  <div class="col-12 ">
                    <div class="form-group">
                      <label for="exampleFormControlSelect3">Nome do grupo: * </label>
                      <input class="form-control" placeholder="Digite nome do grupo" formControlName="NomeGroup" />
                      <span class="mt-2 d-block text-danger" *ngIf="
                    grupoForm.get('NomeGroup').touched &&
                    grupoForm.get('NomeGroup').errors?.required
                  ">Este campo é obrigatório!</span>
                    </div>

                    <div class="form-group">
                      <label>Descrição: </label>
                      <textarea class="form-control" placeholder="Digite descricao" formControlName="Descricao"
                        cols="30" rows="5">
                            </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()">
          Fechar
        </button>

        <button type="submit" (click)="OnSubmitEdit()" data-dismiss="modal" class="btn btn-pill btn-primary"
          [disabled]="!grupoForm.valid">
          Editar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- alterar status -->
<div class="modal fade" *ngIf="user" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaStatusGrupoAltera" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja alterar status ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="changeStatusGroup(user.id, user.isActive)"
          data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>