<div class="breadcrumb-wrapper d-flex" *ngIf="mensagensEnviadas.length">
  <i class="mdi mdi-chart-bar text-blue mr-4 font-size-20"></i>
  <h3 class="ml-0 mb-3">
    {{this.userInformation.clientName}}_{{activatedRoute.snapshot.params.id}}_Total:{{mensagensEnviadasBackup.length}}
  </h3>
</div>

<div class="breadcrumb-wrapper d-flex justify-content-between align-items-center">
  <div>
    <div class="dropdown d-inline-block mb-1">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" data-display="static">
        Exportar
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
        <a class="dropdown-item" style="cursor:pointer" (click)="exportarMensagens(false)">Exportar como Excel</a>
        <a class="dropdown-item" style="cursor:pointer" (click)="exportarMensagens(true)">Exportar como CSV</a>
        <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
      </div>
    </div>
  </div>
</div>
<div class="card card-default">
  <div class="col-12">
    <div class="card-header mb-0">
      <div class="row w-100" style="flex:1; flex-direction:column">
        <div class="dropdown d-inline-block mb-0">
          <div class="input-group ml-0 mb-0">
            <input type="text" (input)="buscarQuery()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pl-0" style="width: 100%; overflow-x: scroll;max-height:400px" *ngIf="!load">
      <table class="table table-responsive table-responsive-large table-hover" style="width: 100%;">
        <thead>
          <tr>
            <th scope="col" style="min-width:550px">Mensagem</th>
            <th scope="col">Status</th>
            <th scope="col">Número</th>
            <th scope="col" style="min-width:150px">Data do envio</th>
            <th scope="col" style="min-width:150px">Data do retorno</th>
            <!--<th scope="col">Prestadora</th>-->
            <th scope="col">Operadora</th>
            <th scope="col">Tipo</th>
            <th scope="col" *ngFor="let key of [].constructor(7); let i = index">
              Parâmetro {{ i + 2 }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="mensagensEnviadas.length">
          <tr *ngFor="let msg of mensagensEnviadas">
            <td style="font-weight:100">{{ msg.content }}</td>
            <td>{{ msg.status }}</td>
            <td>{{ msg.number }}</td>
            <td>{{ msg.scheduled }}</td>
            <td>{{ msg.lastUpdateOn }}</td>
            <!--<td>{{ msg.mobileOperatorName msg.mobileOperatorName?.toUpperCase() }}</td>-->
            <td>{{ msg.operator?.toUpperCase() }}</td>
            <td>{{ msg.tipo }}</td>
            <!--<td *ngFor="let pvalue of msg.paramsValues">{{ pvalue? pvalue: "" }}</td>-->
            <td *ngFor="let x of [].constructor(7);let i = index ">
              {{ msg.paramsValues[i]? msg.paramsValues[i]: "" }}
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="!mensagensEnviadas.length">
          <tr>
            <td colspan="4" style="text-align: center">
              Não encontrado registros
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>