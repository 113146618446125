import { GruposService } from './../Grupos.service';
import { Component, OnInit } from '@angular/core';
import { ListagemGrupos } from './listagemGrupos.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { ListagemContatosService } from '../../contacts/listagem-contatos/listagemContatos.service';

@Component({
  selector: 'app-listagem-grupos',
  templateUrl: './listagem-grupos.component.html',
  styleUrls: ['./listagem-grupos.component.scss']
})
export class ListagemGruposComponent implements OnInit {

  constructor(private fb: FormBuilder, private listageContatoService: ListagemContatosService, private clientService: UserService, private groupService: GruposService, private toastr: ToastrService, private route: Router) {
    this.criarForm();
  }

  grupoForm: FormGroup;

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize: any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  selectedGroup = 0;

  load = false;

  TotalGrupos: any;
  listagemGrupos: any;

  user: any;

  userInformation: any;

  selectedFile: File = null;

  linksModelo = `${document.getElementsByTagName('base')[0].href}templatesUploads/teste_grupo_excelImpo.xlsx`;



  ngOnInit() {
    this.carregaGrupos();
    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);

  }

  openModal(usuario: any) {

    this.user = usuario;

  }

  changeStatusGroup(id: number, status: boolean) {
    this.groupService.alterarStatusGrupo(id, status).subscribe(
      (sucesso: any) => {
        this.toastr.success('Atualizado com sucesso!', 'Grupo');
        this.carregaGrupos();
      },
      (erro: any) => {
        this.toastr.error('Erro ao Atualizar!', 'Grupo');

      }
    );
  }

  closeModal() {
    this.user = null;
    this.selectedFile = <File>null;
    this.selectedGroup = 0;
    this.criarForm();

  }
  criarForm() {
    this.grupoForm = this.fb.group({
      NomeGroup: ['', Validators.required],
      Descricao: ['']
    });
  }


  OnSubmitCreate() {

    this.groupService.createGrupo(this.grupoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Está sendo redirecionado!', 'Sucesso', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          //this.route.navigateByUrl("/groups/list")
        }, 2000);
        this.carregaGrupos();
      },
      (erro: any) => {
        this.toastr.error('Erro ao cadastrar!', 'Error');

      }
    );
  }

  carregarGrupoSelecionado(id) {
    this.load = true;
    this.selectedGroup = id;
    this.groupService.getGrupoId(this.selectedGroup).subscribe(
      (data: any) => {
        this.load = false;

        this.grupoForm = this.fb.group({
          NomeGroup: [{ value: data.name, disabled: false }, Validators.required],
          Descricao: [{ value: data.description, disabled: false }],

        });
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar grupo!', 'Grupo');
        this.load = false;
      }
    );
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.name.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:", this.totalItems)
    console.log("PAGECOUNT", this.pageCount)
    this.page = page;
    this.listagemGrupos = this.paginate(this.TotalGrupos.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }


  carregaGrupos(page: number = 1) {
    this.load = true;
    this.page = page;


    this.groupService.getContatcsGeral(this.page, this.searchQuery).subscribe(
      (res: ListagemGrupos) => {
        this.TotalGrupos = res;
        this.load = false;


        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalGrupos.lista.length;
        this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.getPaginate();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }


  excluirGrupo(id: number) {
    this.groupService.delGrupo(id).subscribe(
      (sucesso: any) => {
        this.toastr.success('Excluido com sucesso!', 'Grupo');
        this.carregaGrupos();
      },
      (erro: any) => {
        this.toastr.error('Erro ao Excluir!', 'Grupo');

      }
    );

  }


  onSelectFile(fileInput: any) {
    this.selectedFile = <File>fileInput.target.files[0];
  }

  onSubmit(dados) {


    const formData = new FormData();
    formData.append('Arquivo', this.selectedFile);

    this.groupService.ImportExcel(formData).subscribe(
      (body: any) => {
        console.log(body);

        this.toastr.success('Listagem de grupos será atualizada!', 'Grupo', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.carregaGrupos();
        }, 2000);

      },
      (erro: any) => {
        console.log('ERRO =>', erro);
        if (typeof erro.error === 'string') {

          this.toastr.error(erro.error, 'Grupo');
        } else {

          this.toastr.error('Ocorreu um erro ao importar', 'Grupo');
        }

      }

    );

  }

  OnSubmitEdit() {
    this.groupService.editGrupo(this.selectedGroup, this.grupoForm.value).subscribe(
      (sucesso: any) => {
        this.toastr.success('Você está sendo redirecionado!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      },
      (erro: any) => {
        this.toastr.error('Erro ao atualizar!', 'Grupo');

      }
    );
  }

  gerarExcelContatoUmGrupo(id: number, isCsv) {
    this.listageContatoService.PegarContatosGrupos(id, '', isCsv).subscribe(
      (res: any) => {
        const blob = new Blob([res]);
        let grupo = this.listagemGrupos.find(g => g.id == id);
        saveAs(blob, this.userInformation.clientName + "_Grupo_" + grupo.name + ".xlsx");
      },
      (erros) => {
        console.log(erros);

      }
    );
  }

  ListContactsFromGroup(id) {
    const url = this.route.serializeUrl(
      this.route.createUrlTree([`/contacts/list/${id}`])
    );
    window.open(url.toString(), '_blank');
  }



}
