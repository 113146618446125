import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Saldo } from '../clients/listagem/saldo.model';

@Injectable({
  providedIn: 'root'
})
export class SaldoService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  carregaClientes() {
    return this.http.get(`${this.baseUrl}balances/getclientesbysaldo`);
  }

  adicionarByTransferirSaldo(dados: Saldo) {
    return this.http.post(`${this.baseUrl}balances/adicionarsaldobytransferencia`, dados);
  }

  carregaHistoricoCliete(idcliente: number) {
    return this.http.get(`${this.baseUrl}balances/CarregaHstSaldoCliente?IdCliente=${idcliente}`);
  }

  listaFornedoresByCredito() {
    return this.http.get(`${this.baseUrl}balances/listagemfornecedorbyucredito`);

  }

  revogarCredito(idbalance: number){
    return this.http.get(`${this.baseUrl}balances/revogarcreditoselecionado?id=${idbalance}`);

  }

}
