<div id="toaster"></div>
<ng-progress id="dashboardProgress"></ng-progress>
<div class="wrapper">
  <aside class="left-sidebar bg-sidebar">
    <div id="sidebar" class="sidebar sidebar-with-footer">
      <!-- Aplication Brand -->
      <div class="app-brand">
        <div class="app-brand-container" [routerLink]="" onclick="return false;">
          <div class="brand-icon">
            <div id="profileImage">{{ UserInformation.Initials }}</div>
          </div>
          <span class="brand-name text-truncate brand-mutiple-values">
            <div>{{ UserInformation.FirstName }}</div>
            <div class="brand-credits">
              Créditos: {{ UserInformation.credits }}
            </div>
          </span>
          <span title="Sair" class="brand-name text-truncate text-right">
            <span class="mdi mdi-logout" (click)="OnClickLogout()"></span>
          </span>
        </div>
      </div>
      <!-- begin sidebar scrollbar -->
      <div class="sidebar-scrollbar">
        <!-- sidebar menu -->
        <ul class="nav sidebar-inner" id="sidebar-menu">
          <!-- Visão Geral-->
          <li class="has-sub" [routerLink]="['/']" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }">
            <a class="sidenav-item-link" [routerLink]="['/']" id="LateralMenuItemIndex">
              <i class="mdi mdi-home"></i>
              <span class="nav-text">Visão Geral</span>
            </a>
          </li>
          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador', 'Comum'])"
            [ngClass]="{ active: HasToBeActive('/contacts') }">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#contacts"
              aria-expanded="false" aria-controls="contacts">
              <i class="mdi mdi-contacts"></i>
              <span class="nav-text">Contatos</span>
            </a>
            <ul class="collapse" id="contacts" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li [routerLink]="['/groups/list']" [routerLinkActive]="['active']">
                  <a class="sidenav-item-link" [routerLink]="['/groups/list']">
                    <span class="nav-text">Grupos</span>
                  </a>
                </li>
                <li [routerLink]="['/contacts/register']" [routerLinkActive]="['active']">
                  <a class="sidenav-item-link" [routerLink]="['/contacts/register']">
                    <span class="nav-text">Novo Contato</span>
                  </a>
                </li>
                <li [routerLink]="['/contacts/list']" [routerLinkActive]="['active']">
                  <a class="sidenav-item-link" [routerLink]="['/contacts/list']">
                    <span class="nav-text">Listagem de Contatos</span>
                  </a>
                </li>
                <li [routerLink]="['/contacts/import']" [routerLinkActive]="['active']">
                  <a class="sidenav-item-link" [routerLink]="['/contacts/import']">
                    <span class="nav-text">Importar Contatos</span>
                  </a>
                </li>
                <li [routerLink]="['/contacts/black-list']" [routerLinkActive]="['active']">
                  <a class="sidenav-item-link" [routerLink]="['/contacts/black-list']">
                    <span class="nav-text">Lista Negra</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>

          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador', 'Comum'])"
            [ngClass]="{ active: HasToBeActive('/messages') }">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#messages"
              aria-expanded="false" aria-controls="messages">
              <i class="mdi mdi-email"></i>
              <span class="nav-text">Mensagens</span>
            </a>
            <ul class="collapse" id="messages" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/message/send']">
                    <span class="nav-text">Nova Mensagem</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/message/send-file']">
                    <span class="nav-text">Envio por Arquivo</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/message/historic']">
                    <span class="nav-text">Histórico de Mensagens</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/message/reiceiveds']">
                    <span class="nav-text">Respostas</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/message/birthdays']">
                    <span class="nav-text">Aniversariantes</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/message/models']">
                    <span class="nav-text">Modelos</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <!-- Apenas Owner, Administrador e Comum (Todos)-->
          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador', 'Comum'])" [routerLink]="['/reports']"
            [routerLinkActive]="['active']">
            <a class="sidenav-item-link" [routerLink]="['/reports']" id="LateralMenuItemIndex">
              <i class="mdi mdi-chart-bar"></i>
              <span class="nav-text">Relatórios</span>
            </a>
          </li>
          <hr class="separator" *ngIf="CheckIfRole(['Owner', 'Administrador'])" />
          <!-- Apenas Owner e Administrador -->
          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador'])" [routerLink]="['/clients/list']"
            [routerLinkActive]="['active']">
            <a class="sidenav-item-link" [routerLink]="['/clients/list']" id="LateralMenuItemIndex">
              <i class="mdi mdi-card-account-details"></i>
              <span class="nav-text">Clientes</span>
            </a>
          </li>
          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador'])" [routerLink]="['/users/list']"
            [routerLinkActive]="['active']">
            <a class="sidenav-item-link" [routerLink]="['/users/list']" id="LateralMenuItemIndex">
              <i class="mdi mdi-account-circle"></i>
              <span class="nav-text">Usuários</span>
            </a>
          </li>

          <hr class="separator" *ngIf="CheckIfRole(['Owner', 'Administrador'])" />
          <!-- Apenas Owner e Administrador -->
          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador'])" [routerLinkActive]="['active']">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#saldo"
              aria-expanded="false" aria-controls="saldo">
              <i class="mdi mdi-currency-usd-circle"></i>
              <span class="nav-text">Saldo</span>
            </a>
            <ul class="collapse" id="saldo" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/balances/add']">
                    <span class="nav-text">Transferência de Saldo</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <!-- Apenas Comum-->
          <!-- <li class="has-sub" *ngIf="CheckIfRole('Owner')" [routerLink]="['/balance/']" [routerLinkActive]="['active']">
            <a class="sidenav-item-link" [routerLink]="['/balance/list']" id="LateralMenuItemIndex">
              <i class="mdi mdi-coin"></i>
              <span class="nav-text">Saldo</span>
            </a>
          </li> -->
          <!-- Apenas Owner -->
          <li class="has-sub" *ngIf="CheckIfRole(['Owner'])">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#fornecedor"
              aria-expanded="false" aria-controls="saldo">
              <i class="mdi mdi-cube-outline"></i>
              <span class="nav-text">Fornecedores</span>
            </a>
            <!-- <a class="sidenav-item-link" [routerLink]="['/providers/list']" id="LateralMenuItemIndex">
              <i class="mdi mdi-cellphone-wireless"></i>
              <span class="nav-text">Fornecedores</span>
            </a> -->
            <ul class="collapse" id="fornecedor" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/providers/list']" [routerLinkActive]="['active']">
                    <span class="nav-text">Geral</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/users-providers/list']" [routerLinkActive]="['active']">
                    <span class="nav-text">Por usuários</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>

          <!-- <hr
            class="separator"
            *ngIf="CheckIfRole(['Owner', 'Administrador'])"
          /> -->
          <!-- Apenas Owner -->
          <li class="has-sub" *ngIf="CheckIfRole(['Owner'])">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#audit"
              aria-expanded="false" aria-controls="saldo">
              <i class="mdi mdi-history"></i>
              <span class="nav-text">Histórico</span>
            </a>
            <ul class="collapse" id="audit" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/historic/active-users']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Atividades</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/historic/login']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Login</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>

          <!-- Apenas Owner e Administrador -->
          <!-- <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador'])" [routerLink]="['/balance/']"
            [routerLinkActive]="['active']">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#saldo"
              aria-expanded="false" aria-controls="saldo">
              <i class="mdi mdi-coin"></i>
              <span class="nav-text">Saldo</span> <b class="caret"></b>
            </a>
            <ul class="collapse" id="saldo" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li>
                  <a class="sidenav-item-link" href="material-icon.html">
                    <span class="nav-text">Transferência de Saldo</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" href="flag-icon.html">
                    <span class="nav-text">Extrato</span>
                  </a>
                </li>
              </div>
            </ul>
          </li> -->
          <!-- Apenas Owner -->
          <li class="has-sub" *ngIf="CheckIfRole(['Owner'])">
            <a class="sidenav-item-link" href="javascript:void(0)" data-toggle="collapse" data-target="#msg-suspeitas"
              aria-expanded="false" aria-controls="saldo">
              <i class="mdi mdi-email-alert"></i>
              <span class="nav-text">Mensagens suspeitas</span>
            </a>
            <ul class="collapse" id="msg-suspeitas" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/msg-suspicious/liberation']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Liberação</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/msg-suspicious/word']" [routerLinkActive]="['active']">
                    <span class="nav-text">Palavras</span>
                  </a>
                </li>
                <li>
                  <a class="sidenav-item-link" [routerLink]="['/msg-suspicious/links']" [routerLinkActive]="['active']">
                    <span class="nav-text">Links</span>
                  </a>
                </li>
              </div>
            </ul>
          </li>

          <!-- <hr
            class="separator"
            *ngIf="CheckIfRole(['Owner', 'Administrador', 'Comum'])"
            [routerLink]="['/provider/']"
          /> -->

          <li class="has-sub" *ngIf="CheckIfRole(['Owner', 'Administrador', 'Comum'])">
            <a class="sidenav-item-link" id="LateralMenuItemIndex" href="javascript:void(0)" data-toggle="collapse"
              data-target="#configsPerfil" aria-expanded="false" aria-controls="configsPerfil">
              <i class="mdi mdi-cogs"></i>
              <span class="nav-text">Configurações</span>
            </a>
            <ul class="collapse" id="configsPerfil" data-parent="#sidebar-menu">
              <div class="sub-menu">
                <li [routerLink]="['/configuration/profile']">
                  <a class="sidenav-item-link" [routerLink]="['/configuration/profile']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Sua Conta</span>
                  </a>
                </li>
                <li [routerLink]="['/configuration/change-password']">
                  <a class="sidenav-item-link" [routerLink]="['/configuration/change-password']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Alterar senha</span>
                  </a>
                </li>

                <li [routerLink]="['/configuration/search-phone']">
                  <a class="sidenav-item-link" [routerLink]="['/configuration/search-phone']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Pesquisa número</span>
                  </a>
                </li>
                <!--<li [routerLink]="['/configuration/developer']">
                  <a class="sidenav-item-link" [routerLink]="['/configuration/developer']"
                    [routerLinkActive]="['active']">
                    <span class="nav-text">Desenvolvedor</span>
                  </a>
                </li>-->
              </div>
            </ul>
          </li>

          <li class="has-sub" [routerLink]="['/help']" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }">
            <a class="sidenav-item-link" [routerLink]="['/help']" id="LateralMenuItemIndex">
              <i class="mdi mdi-help"></i>
              <span class="nav-text">Ajuda</span>
            </a>
          </li>

          <!-- <li class="has-sub"  *ngIf="CheckIfRole('Owner')" [routerLink]="['/groups/list']" [routerLinkActive]="['active']">
            <a class="sidenav-item-link"  [routerLink]="['/groups/list']" id="LateralMenuItemIndex">
              <i class="mdi mdi-account-group"></i>
              <span class="nav-text">Grupos</span>
            </a>
          </li> -->
        </ul>
      </div>
      <!-- Footer -->
      <div class="sidebar-footer">
        <div class="sidebar-footer-content py-0">
          <img src="assets/svg/logotipo_white_r_reduced.svg" />
        </div>
      </div>
      <!-- ./Footer -->
    </div>
  </aside>

  <div class="page-wrapper">
    <header class="main-header d-block d-md-none" id="header">
      <nav class="navbar navbar-static-top navbar-expand-lg d-flex">
        <!-- Sidebar toggle button -->
        <button id="sidebar-toggler" class="sidebar-toggle">
          <i class="mdi mdi-menu"></i>
        </button>

        <div style="flex:1 "></div>

        <span class="mr-2">Créditos: {{ UserInformation.credits }} </span>
        <div class="navbar-right ">
          <ul class="nav navbar-nav">
            <!-- User Account -->
            <li class="user-menu">
              <a href="#" class="nav-link "
                style="display:flex; flex-direction: row;align-items: center;align-content: center;">
                <div class="brand-icon user-image">
                  <div id="profileImage">{{ UserInformation.Initials }}</div>
                </div>
                <span class="text-black ml-2" style="flex: 1; color: black;font-size: initial;">{{
                  UserInformation.FirstName }}</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>


    </header>
    <div class="content-wrapper">
      <div class="content " style="padding-left: 25px; padding-right: 25px">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</div>

<!-- modal renovar Token -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="ModalRenovarToken" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Sua sessão está acabando
        </h5>
      </div>
      <div class="modal-body">
        <p>Deseja renovar sua sessão?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" (click)="OnClickLogout()" data-dismiss="modal">Sair do
          Sistema</button>
        <button type="button" class="btn btn-primary btn-pill" (click)="renewToken()" data-dismiss="modal">
          Renovar Sessão
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal token renovado -->
<div class="modal fade" id="ModalTokenRenovado" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Sessão renovada com sucesso!
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>
