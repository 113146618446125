<!-- <h3>Alteração de senha</h3> -->

<div class="row">
  <div class="col-md-12">
    <div class="card card-default">
      <div class="card-header border-bottom  d-block justify-content-left">
        <h2 class="card-title" style="text-align: left">Desenvolvedor</h2>
        <p style="font-size:small">
          Utilize a Chave de API abaixo para integrar com a nosso sistema
        </p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="col-6 mt-3">
              <label class="text-dark font-weight-medium" for="">Sua Chave de API:</label>
              <div class="input-group mb-2">
                <input class="form-control" style="width:450px; max-width: 450px" type="text" [(ngModel)]="developerInfo" readonly/>
              </div>
            </div>
            <div class="col-12 mt-3">
              <p>Para mais informações sobre como integrar como a nossa API acesse nossa <a href="https://docs.uniqmobile.com.br/" target="_blank" style="">documentação <i class="mdi mdi-open-in-new"></i></a>.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
