import { FormArray } from '@angular/forms';
import { ListaEstadosCidade } from './listaEstadosCidade.model';
import { DadosCep } from './dadosCep.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/user.service';
import { ListaProviders } from './listaProviders.model';
import * as moment from 'moment'; import 'moment/locale/pt-br';

declare let $: any;


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

  constructor(public userService: UserService, private route: Router, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  documentMask: object = {
    1: '000.000.000-00',
    2: '00.000.000/0000-00'
  };

  /**
   * Informa o tipo do campo do formulário. Considero o seguinte: caso esteja em 'password' a senha estará oculta, em 'text' a senha é exibida.
   *
   * @type String
   */
  TypeOfPasswordInput = 'password';

  Estados: ListaEstadosCidade;
  Cidades: string[];
  load: boolean;
  ResponsibleName: string = null;
  Email: string;
  providers: ListaProviders[];
  userInformation: any;

  ngOnInit() {
    this.userService.UserRegistrationModel.reset();
    this.userService.UserRegistrationModel.get("TipoCliente").setValue('1');
    this.carregaEstados();
    // console.log(this.userService.UserRegistrationModel.controls.OtherContats);
    this.carregarFornecedores();
    this.carregarDatePicker();
    // console.log(this.userService.UserRegistrationModel);
    //buscando informações do usuario
    this.userService.GetUserInformation().subscribe((userInfo: any) => {
      this.userInformation = userInfo;
    });

  }
  printValidade() {
    console.log(this.userService.UserRegistrationModel.get("Validade").value)
  }

  private dec2hex(number): string {
    const base = 16;
    const digito = new Array();
    let i = 0;

    while (number != 0) {
      i++;
      digito[i] = number % base;
      number = Math.floor(number / base);
    }
    let value = '';

    while (i >= 1) {
      switch (digito[i]) {
        case 10: { value += 'A'; break; }
        case 11: { value += 'B'; break; }
        case 12: { value += 'C'; break; }
        case 13: { value += 'D'; break; }
        case 14: { value += 'E'; break; }
        case 15: { value += 'F'; break; }
        default: { value += digito[i]; break; }
      }
      i--;
    }
    return value;
  }

  CheckIfRole(roles) {
    if (this.userInformation == undefined) {
      return false;
    }
    return this.userInformation.roleType ? roles.includes(this.userInformation.roleType) : false;
  }

  GeneratePassword(): void {
    const min = 32;
    const max = 126;
    let pass = '';
    for (let i = 1; i <= 10; i++) {
      const numberAsChar = min + Math.floor((Math.random() * (max - min)));  // 32 a 126
      let caracter = '%' + this.dec2hex(numberAsChar);
      caracter = unescape(caracter);
      pass += caracter;
    }
    // this.userService.UserRegistrationModel.controls.Cidade.setValue(this.selecionaCidade(res.uf, "sigla"));
    this.userService.UserRegistrationModel.controls.Password.setValue(pass);
    this.userService.UserRegistrationModel.controls.ConfirmPassword.setValue(pass);
  }

  /**
   * Altera a visibilidade do campo de senha
   */
  TogglePasswordDisplay(): void {
    this.TypeOfPasswordInput = this.TypeOfPasswordInput === 'text' ? 'password' : 'text';
  }

  carregarDatePicker(): void {
    $('#datetimepicker1').datetimepicker({
      locale: moment.locale('ru'),
      format: 'DD/MM/YYYY HH:mm:ss',
      date: moment(),
      icon: {
        up: 'mdi mdi-arrow-down-bold',
        down: 'mdi mdi-arrow-up-bold'
      },
    });
  }

  carregarFornecedores() {
    this.load = true;
    this.userService.getProvidersList().subscribe(
      (sucesso: ListaProviders[]) => {
        this.providers = sucesso;
        this.userService.UserRegistrationModel.controls.IdFornecedorPreferencial.setValue('0');
        this.load = false;
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar listagem', 'Fornecedores');
        this.load = false;

      }
    );
  }

  OnSubmitRegister() {
    // this.userService.UserRegistrationModel.patchValue({
    //   Validade: $("#datetimepicker1").datetimepicker("viewDate").toJSON()
    // })
    this.userService.Register().subscribe(
      (res: any) => {
        if (res.sucesso) {
          this.toastr.success('Você está sendo redirecionado!', 'Concluido', { timeOut: 2000, progressBar: true });
          setTimeout(() => {
            this.route.navigateByUrl('/clients/list');
          }, 2000);
        }
      },
      err => {
        console.log(err);
        if (typeof err.error === 'string') {

          this.toastr.error(err.error, 'Cliente');
        } else {

          this.toastr.error('Não foi possivel realizar cadastro', 'Cliente');
        }
      }
    );
  }


  carregaEstados() {
    this.userService.buscaEstados().subscribe(
      (retorno: ListaEstadosCidade) => {
        this.Estados = retorno;
        this.load = true;
      },
      err => {
        this.toastr.error('Não encontrado estados!', 'Estados');

      }
    );
  }

  selecionaCidade(estado: any, atributo = 'nome') {
    this.Cidades = this.Estados.estados[this.buscaArrayComAtributo(this.Estados.estados, atributo, estado)].cidades;
  }

  buscaArrayComAtributo(array: any, attr: string, value: any) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  buscaCep(cep: string) {
    if (/\d{2}\.\d{3}\-\d{3}/.test(cep)) {
      // alert('Cep é válido!');
      const cepFormatado = cep.replace(/\.|\-/g, '');

      this.userService.getAddressCep(cepFormatado).subscribe(
        (res: DadosCep) => {
          this.userService.UserRegistrationModel.patchValue({
            Bairro: res.bairro,
            Endereco: res.logradouro,
            Estado: res.uf,
            Cidade: res.localidade
            // Cidade: this.selecionaCidade(res.uf, "sigla")
          });
          // this.userService.UserRegistrationModel.controls.Cidade.setValue(this.selecionaCidade(res.uf, "sigla"));
          this.selecionaCidade(res.uf, 'sigla');
          // this.userService.UserRegistrationModel.controls.Cidade.setValue(this.selecionaCidade(res.uf, "sigla"));
        },
        (erro: any) => {
          this.toastr.error('Não encontrado dados baseado no cep informado, preencha-os!', 'Cep');
        }

      );
    } else {
      this.toastr.error('Informe um cep válido!', 'Cep');

    }


  }

  addContact() {
    const controls = <FormArray>this.userService.UserRegistrationModel.controls['OtherContats'];
    controls.push(this.userService.initOtherContact());

  }


  removeContact(i: number) {
    const controlContacts = <FormArray>this.userService.UserRegistrationModel.controls['OtherContats'];
    controlContacts.removeAt(i);
  }

  getControls() {
    return (this.userService.UserRegistrationModel
      .controls.OtherContats as FormArray).controls;
  }

}
