import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListaGroupsToContato } from '../listaGroupsToContato.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistroContatoService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;




  listaGroups(): Observable<ListaGroupsToContato[]> {
    return this.http.get<ListaGroupsToContato[]>(`${this.baseUrl}contacts/getgoups`);
  }

  cadastroContato(contato: any) {
    return this.http.post(`${this.baseUrl}contacts/Create`, contato);
  }

}
