import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { UserService } from '../shared/user.service';

declare let $: any;

/**
 * Barra de Progresso do Sistema
 *
 * @type {Object}
 */

/**
 * Componente de Login do Sistema
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit {

  /**
   * Barra de Progresso
   */
  progressRef: NgProgressRef;

  /**
   * Botão de Login
   */
  loginButton = null;

  /**
   * Deve mostrar o erro
   *
   * @type boolean
   */
  public hasToShowError = false;

  /**
   * Texto do Erro
   *
   * @type string
   */
  public ErrorMessage = '';

  /**
   * Constrututor da Classe
   *
   * @param userService Serviço do Usuário
   * @param router Serviço de Rota do Angular
   */
  constructor(private userService: UserService, private router: Router,  private progress: NgProgress) {
    this.UserLoginModel = userService.UserLoginModel;
  }

  ngAfterViewInit() {
    this.progressRef.complete();
  }

  /**
   * Modelo do Formulário de Login
   *
   * @type {FormBuilder}
   */
  UserLoginModel = null;

  /**
   * Ao alterar os campos
   */
  onChangeFields(){
    this.hasToShowError = false;
    this.ErrorMessage = '';
  }
  /**
   * Quando o Usuário clicar em Logar, realiza o processo de Login
   */
  onSubmitLogin() {
    if (!this.UserLoginModel.get("ReCaptcha").valid) {
      this.hasToShowError = true;
      this.ErrorMessage = 'Preencha o Captcha.';
      return null;
    }
    
    this.loginButton.classList.add('button--loading');

    this.userService.Login().subscribe(
      (res: any) => {
        localStorage.setItem('UniqMobile_Message_Token', res.token);
        localStorage.setItem('UniqMobile_Message_SessionTime', res.sessionTime);
        if (res.erros != null && res.erros.length > 0) {
          this.hasToShowError = true;
          this.ErrorMessage = res.erros[0];
          this.loginButton.classList.remove('button--loading');
        } else{
          this.router.navigate(['/']);
          this.UserLoginModel.reset();
        }

      },
      err => {
        if (err.status == 500) {
          this.hasToShowError = true;
          this.ErrorMessage = 'Tente novamente mais tarde.';
        }
        if (err.status == 401) {
          this.hasToShowError = true;
          this.ErrorMessage = 'Tente novamente mais tarde.';
        }
        this.loginButton.classList.remove('button--loading');
      }
    );
  }

  /**
   * Ao Inicializar este componente
   */
  ngOnInit() {
    this.progressRef = this.progress.ref('dashboardProgress');
    this.progressRef.start();

    ;

    if (localStorage.getItem('UniqMobile_Message_Token') != null) {
      this.router.navigate(['/']);
    }

    this.loginButton = document.querySelector('#loginButton');
    let ip = null;
    this.userService.getIpInfo().subscribe((res: any) => {
      ip = res.ip;
      this.userService.getLocationInfo(ip).subscribe((resLoc: any) => {
        this.UserLoginModel.get("Location").setValue(resLoc.city + "/" + resLoc.region);
        console.log(resLoc.city + "/" + resLoc.region)
      })
    })


  }

  handleSuccess(event) {
    if (event) {
      this.UserLoginModel.get("ReCaptcha").setValue("true");
    }
  }
  handleError() {
    console.log("erro")
  }
}
