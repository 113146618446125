import { LiberacaosuspComponent } from './message/msgsuspeitas/liberacaosusp/liberacaosusp.component';
import { LinkssuspComponent } from './message/msgsuspeitas/linkssusp/linkssusp.component';
import { PalavrasuspComponent } from './message/msgsuspeitas/palavrasusp/palavrasusp.component';
import { MsgsuspeitasComponent } from './message/msgsuspeitas/msgsuspeitas.component';
import { MsganiversariantesComponent } from './message/msganiversariantes/msganiversariantes.component';
import { AuditoriaComponent } from './auditoria/auditoria.component';
import { PerfilComponent } from './configuracao/perfilConfiguracao/perfil.component';
import { SenhaComponent } from './configuracao/senhaConfiguracao/senha.component';
import { ConfiguracaoComponent } from './configuracao/configuracao.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsDetailsComponent } from './reports/reports-details/reports-details.component';
import { ModelosTemplatesmsComponent } from './message/modelos-templatesms/modelos-templatesms.component';
import { ImportsComponent } from './contacts/imports/imports.component';
import { ReceivedSmsComponent } from './message/received-sms/received-sms.component';
import { BlacklistComponent } from './contacts/blacklist/blacklist.component';
import { HistoricSmsComponent } from './message/historic-sms/historic-sms.component';
import { SendmessageComponent } from './message/sendmessage/sendmessage.component';
import { MessageComponent } from './message/message.component';
import { ListagemUsersForProvidersComponent } from './users-for-providers/listagem-users-for-providers/listagem-users-for-providers.component';
import { UsersForProvidersComponent } from './users-for-providers/users-for-providers.component';
import { EdicaoFornecedoresComponent } from './providers/edicao-fornecedores/edicao-fornecedores.component';
import { ProvidersComponent } from './providers/providers.component';
import { EdicaoUsuariosComponent } from './users/edicao-usuarios/edicao-usuarios.component';
import { CriacaoUsuariosComponent } from './users/criacao-usuarios/criacao-usuarios.component';
import { ListagemUsuariosComponent } from './users/listagem-usuarios/listagem-usuarios.component';
import { UsersComponent } from './users/users.component';
import { EdicaoGruposComponent } from './groups/edicao-grupos/edicao-grupos.component';
import { CriacaoGruposComponent } from './groups/criacao-grupos/criacao-grupos.component';
import { RegistroContatoComponent } from './contacts/registro-contato/registro-contato.component';
import { ListagemComponent } from './clients/listagem/listagem.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ListagemContatosComponent } from './contacts/listagem-contatos/listagem-contatos.component';
import { PagesCodeComponent } from './shared/pages-code/pages-code.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EdicaoComponent } from './clients/edicao/edicao.component';
import { RegisterComponent } from './clients/register/register.component';
import { ClientsComponent } from './clients/clients.component';
import { EdicaoContatoComponent } from './contacts/edicao-contato/edicao-contato.component';
import { GroupsComponent } from './groups/groups.component';
import { ListagemGruposComponent } from './groups/listagem-grupos/listagem-grupos.component';
import { ListagemFornecedoresComponent } from './providers/listagem-fornecedores/listagem-fornecedores.component';
import { HistoricsendmessageComponent } from './message/historicsendmessage/historicsendmessage.component';
import { SendFileSmsComponent } from './message/send-file-sms/send-file-sms.component';
import { SaldoComponent } from './saldo/saldo.component';
import { TransferenciaComponent } from './saldo/transferencia/transferencia.component';
import { PesquisaNumeroComponent } from './configuracao/pesquisa-numero/pesquisa-numero.component';
import { AtividadesUsersComponent } from './auditoria/atividades-users/atividades-users.component';
import { HelpComponent } from './help/help.component';
import { DesenvolvedorComponent } from './configuracao/desenvolvedorConfiguracao/desenvolvedor.component';
import { HistoricoLoginComponent } from './auditoria/historico_login/historico-login.component';

export const rootRouterConfig: Routes = [
    {
    path: '', component: DashboardComponent, canActivate: [AuthGuard], children: [
      // path: '', component: DashboardComponent, children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'erro/:code', component: PagesCodeComponent },
      {
        path: 'clients', component: ClientsComponent, children: [
          { path: 'list', component: ListagemComponent },
          { path: 'register', component: RegisterComponent },
          { path: 'edit/:id', component: EdicaoComponent },
        ]
      },
      {
        path: 'contacts', component: ContactsComponent, children: [
          { path: 'list', component: ListagemContatosComponent },
          { path: 'list/:id', component: ListagemContatosComponent },
          { path: 'register', component: RegistroContatoComponent },
          { path: 'register/:id', component: RegistroContatoComponent },
          { path: 'edit/:id', component: EdicaoContatoComponent },
          { path: 'black-list', component: BlacklistComponent },
          { path: 'import', component: ImportsComponent },
        ]
      },
      {
        path: 'groups', component: GroupsComponent, children: [
          { path: 'list', component: ListagemGruposComponent },
          { path: 'register', component: CriacaoGruposComponent },
          { path: 'edit/:id', component: EdicaoGruposComponent },
        ]
      },
      {
        path: 'users', component: UsersComponent, children: [
          { path: 'list', component: ListagemUsuariosComponent },
          { path: 'register', component: CriacaoUsuariosComponent },
          { path: 'edit/:id', component: EdicaoUsuariosComponent },
        ]
      },
      {
        path: 'providers', component: ProvidersComponent, children: [
          { path: 'list', component: ListagemFornecedoresComponent },
          { path: 'edit/:id', component: EdicaoFornecedoresComponent },
        ]
      },
      {
        path: 'msg-suspicious', component: MsgsuspeitasComponent, children: [
          { path: 'word', component: PalavrasuspComponent },
          { path: 'links', component: LinkssuspComponent },
          { path: 'liberation', component: LiberacaosuspComponent },
        ]
      },
      {
        path: 'message', component: MessageComponent, children: [
          { path: 'send', component: SendmessageComponent },
          { path: 'historic', component: HistoricsendmessageComponent },
          { path: 'sms/:id', component: HistoricSmsComponent },
          { path: 'send-file', component: SendFileSmsComponent },
          { path: 'reiceiveds', component: ReceivedSmsComponent },
          { path: 'models', component: ModelosTemplatesmsComponent },
          { path: 'birthdays', component: MsganiversariantesComponent },
        ]
      },
      {
        path: 'users-providers', component: UsersForProvidersComponent, children: [
          { path: 'list', component: ListagemUsersForProvidersComponent },
        ]
      }, {
        path: 'balances', component: SaldoComponent, children: [
          { path: 'add', component: TransferenciaComponent },
        ]
      },
      {
        path: 'configuration', component: ConfiguracaoComponent, children: [
          { path: 'change-password', component: SenhaComponent },
          { path: 'profile', component: PerfilComponent },
          { path: 'search-phone', component: PesquisaNumeroComponent },
          { path: 'developer', component: DesenvolvedorComponent },
        ]
      },
      {
        path: 'historic', component: AuditoriaComponent, children: [
          { path: 'active-users', component: AtividadesUsersComponent },
          { path: 'login', component: HistoricoLoginComponent },

        ]
        },
        { path: 'report-detail/:type/:format/:client/:status/:period', component: ReportsDetailsComponent },
        { path: 'reports', component: ReportsComponent},
        { path: 'counter', component: ClientsComponent },
        { path: 'fetch-data', component: FetchDataComponent },
        { path: 'help', component: HelpComponent },
        ],
    },
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'reset-password/:id', component: ResetPasswordComponent },


];
