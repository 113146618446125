import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loadGruposContatos'
})
export class LoadGruposContatosPipe implements PipeTransform {

  transform(value: any, args?: any): boolean {
    // console.log(value, args);

    // return args ? args.some(item => item.id === value.id) : false;
    return args ? args.includes(value.id) : false;
  }

}
