import { DadosCep } from '../clients/register/dadosCep.model';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ListaEstadosCidade } from '../clients/register/listaEstadosCidade.model';
import { ArrayType } from '@angular/compiler';
import { ListaProviders } from '../clients/register/listaProviders.model';

/**
 * Serviço que possui funções de Globais para cada usuário do Sistema
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Construtor do UserService
   *
   * @param fb Gerador de Formulário
   * @param http Http do Anguar
   * @param baseUrl Base URL
   * @param router Rotas do Angular
   */
  constructor(private fb: FormBuilder, private http: HttpClient, @Inject('API_BASE_URL') baseUrl: string, private router: Router) {
    this.baseUrl = baseUrl;
  }


  @Input() inputArray: ArrayType[];


  /**
   * URL da API do Sistema
   *
   * @type string
   */
  private baseUrl = '';

  /**
   * Informações sobre o usuário logado
   *
   * @type Object
   */
  private UserInformation = null;

  /**
   * Informa se o serviço está pronto
   *
   * @type boolean
   */
  public IsReady = false;

  /**
   * Modelo do Formulário de Registro
   */
  UserRegistrationModel = this.fb.group({
    Email: ['', [Validators.email, Validators.required]],
    Number: ['', Validators.required],
    FullName: ['', Validators.required],
    UserName: ['', Validators.required],
    Password: ['', [Validators.required, Validators.minLength(6)]],
    ConfirmPassword: ['', Validators.required],
    Role: ['', Validators.required],
    TipoCliente: ['1', Validators.required],
    Documento: ['', Validators.required],
    RazaoSocial: [''],
    CompanyName: [''],
    ResponsibleName: [''],
    OtherContats: new FormArray(
      [this.initOtherContact()]
    ),
    Bairro: ['', Validators.required],
    Cep: ['', Validators.required],
    Endereco: ['', Validators.required],
    Numero: ['', Validators.required],
    Estado: ['', Validators.required],
    Cidade: ['', Validators.required],
    Site: [''],
    ResponsibleNameGeral: ['', Validators.required],
    ResponsibleNameContato: ['', Validators.required],
    NomeFullUsuario: ['', Validators.required],
    EmailUsuario: ['', [Validators.email, Validators.required]],
    Complemento: [''],
    IdFornecedorPreferencial: [''],
    Quantidade: [''],
    DescricaoSaldo: [''],
    Validade: ['']
  }, { validator: this.ValidRegistration });


  public initOtherContact() {
    return this.fb.group({

      FullName: new FormControl(''),
      Email: new FormControl(''),
      PhoneOne: new FormControl(''),
      PhoneTwo: new FormControl(''),
      Cargo: new FormControl(''),
    });
  }


  /**
   *
   *
   * Validação do Formulário de Registro
   *
   *
   *
   * @param fb Modelo do Formulário de Registro
   */
  ValidRegistration(fb: FormGroup) {

    if (fb.get('TipoCliente')) {


      if (fb.get('TipoCliente').value === '2') {
        if (!fb.get('RazaoSocial').value) {
          fb.get('RazaoSocial').setErrors({ required: true });
        } else {
          fb.get('RazaoSocial').setErrors(null);

        }

        // if (!fb.get('ResponsibleName').value) {
        //   fb.get('ResponsibleName').setErrors({ required: true })
        // } else {
        //   fb.get('ResponsibleName').setErrors(null)

        // }

      }
    }

    const ConfirmPassword = fb.get('ConfirmPassword');
    if (ConfirmPassword.errors == null || 'passwordMismatch' in ConfirmPassword.errors) {
      if (fb.get('Password').value != ConfirmPassword.value) {
        ConfirmPassword.setErrors({ passwordMismatch: true });
      } else {
        ConfirmPassword.setErrors(null);
      }
    }
  }

  /**
   * Modelo do Formulário de Login
   */
  UserLoginModel = this.fb.group({
    UserName: ['', Validators.required],
    Password: ['', Validators.required],
    ReCaptcha: ['', Validators.required],
    Device: [''],
    Location: ['']
  });

  /**
   * Modelo do Formulário de Recuperação de Senha
   */
  UserResetModel = this.fb.group({
    Email: ['', [Validators.email, Validators.required]]
  });

  /**
   * Modelo de Formulário para alteração de senha
   */
  UserResetPasswordModel = this.fb.group({
    Password: ['', [Validators.required, Validators.minLength(6)]],
    ConfirmPassword: ['', Validators.required],
  }, { validator: this.ValidRegistration });

  /**
   * Criação da ModelView do Registro do Usuário
   */
  private CreateUserRegistration() {
    return {

      Email: this.UserRegistrationModel.value.Email,
      Number: this.UserRegistrationModel.value.Number,
      FullName: this.UserRegistrationModel.value.FullName,
      Password: this.UserRegistrationModel.value.Password,
      UserName: this.UserRegistrationModel.value.UserName,
      Role: this.UserRegistrationModel.value.Role,
      Documento: this.UserRegistrationModel.value.Documento,
      TipoCliente: this.UserRegistrationModel.value.TipoCliente,
      CompanyName: this.UserRegistrationModel.value.CompanyName,
      ResponsibleName: this.UserRegistrationModel.value.ResponsibleName,
      OtherContats: this.UserRegistrationModel.value.OtherContats,
      Bairro: this.UserRegistrationModel.value.Bairro,
      Cep: this.UserRegistrationModel.value.Cep,
      Endereco: this.UserRegistrationModel.value.Endereco,
      Numero: this.UserRegistrationModel.value.Numero,
      Estado: this.UserRegistrationModel.value.Estado,
      Cidade: this.UserRegistrationModel.value.Cidade,
      IdFornecedorPreferencial: this.UserRegistrationModel.value.IdFornecedorPreferencial,
      Quantidade: this.UserRegistrationModel.value.Quantidade,
      Validade: this.UserRegistrationModel.value.Validade,
      RazaoSocial: this.UserRegistrationModel.value.RazaoSocial,
      Complemento: this.UserRegistrationModel.value.Complemento,
      DescricaoSaldo: this.UserRegistrationModel.value.DescricaoSaldo,
      Site: this.UserRegistrationModel.value.Site,
      ResponsibleNameGeral: this.UserRegistrationModel.value.ResponsibleNameGeral,
      ResponsibleNameContato: this.UserRegistrationModel.value.ResponsibleNameContato,
      NomeFullUsuario: this.UserRegistrationModel.value.NomeFullUsuario,
      EmailUsuario: this.UserRegistrationModel.value.EmailUsuario,
    };
  }

  /**
   * Criação da ModelView do Login do Usuário
   */
  private CreateUserLogin() {

    let Name = 'Unknown OS';
    let BrowserName = 'Desconhecido';
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      Name = "Mobile"
    } else {
      Name = "Desktop"

    }

    //if (navigator.userAgent.indexOf('Win') != -1) {
    //  Name = 'Windows OS';
    //}
    //if (navigator.userAgent.indexOf('Mac') != -1) {
    //  Name = 'Macintosh';
    //}
    //if (navigator.userAgent.indexOf('Linux') != -1) {
    //  Name = 'Linux OS';
    //}
    //if (navigator.userAgent.indexOf('Android') != -1) {
    //    Name = 'Android OS';
    //}
    //if (navigator.userAgent.indexOf('like Mac') != -1) {
    //    Name ='iOS';
    //}
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      BrowserName = 'Opera';
    }
    else if (navigator.userAgent.indexOf("Chrome") != -1) {
      BrowserName = 'Chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1) {
      BrowserName = 'Safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
      BrowserName = 'Firefox';
    }
    else {
      BrowserName = 'Desconhecido';
    }

    return {
      UserName: this.UserLoginModel.value.UserName,
      Password: this.UserLoginModel.value.Password,
      Device: Name,
      Browser: BrowserName,
      Location: this.UserLoginModel.value.Location
    };
  }

  /**
   * Criação da ModelView de Recuperação de Senha
   */
  private CreateUserReset() {
    return {
      Email: this.UserResetModel.value.Email
    };
  }

  /**
   * Criação da ModelView de Recuperação de Senha com Token
   */
  private CreateUserResetPassword(token: string) {
    return {
      Token: token,
      Password: this.UserResetPasswordModel.value.Password,
    };
  }

  /**
   * Função de Login do Sistema
   */
  Login() {
    const body = this.CreateUserLogin();
    return this.RequestLogin(body);
  }
  /**
   * Função de Renovar Sessao
   */
  RenewToken() {
    return this.RequestRenewToken();
  }

  /**
   * Função de Registro de Usuário do Sistema
   */
  Register() {
    const body = this.CreateUserRegistration();
    return this.RequestRegister(body);
  }

  /**
   * Função de Logout do Sistema
   */
  Logout() {
    localStorage.removeItem('UniqMobile_Message_Token');
    this.UserInformation = null;
    this.router.navigate(['/login']);
  }

  /**
   * Função de Redefinição de Senha
   */
  ResetPassword() {
    const body = this.CreateUserReset();
    return this.RequestResetPassoword(body);
  }

  /**
   * Valida um Token de Recuperação do Sistema
   *
   * @param token Token de Redefinição
   */
  ValidResetPasswordToken(token: string) {
    return this.RequestValidResetPassowordToken({ Token: token });
  }

  /**
   * Função de Login do Sistema
   *
   * @param token Token de Redefinição
   */
  ResetPasswordWithToken(token: string) {
    const body = this.CreateUserResetPassword(token);
    return this.RequestResetPassowordWithToken(body);
  }

  /**
   * Função de Troca de APIKEY do Usuário do Sistema
   *
   * @param id Id do Usuário
   */
  ChangeUserAPIKEY(id: number) {
    return this.RequestNewApiKeyForUser(id);
  }
  /**
   * Função de reliza login pelo Usuário Especificado
   *
   * @param id Id do Usuário
   */
  loginAsUser(id: number) {
    return this.RequestLoginAsUser(id);
  }

  /**
   * Requisita o Registro do Usuário
   *
   * @param UserRegistration ModelView do Registro do Usuário
   */
  private RequestRegister(UserRegistration) {
    return this.http.post(this.baseUrl + 'Users/Register', UserRegistration);
  }

  /**
   * Requisita o Login do Usuário
   *
   * @param UserLogin ModelView do Login do Usuário
   */
  private RequestLogin(UserLogin) {
    return this.http.post(this.baseUrl + 'Users/Login', UserLogin);
  }
  /**
   * Requisita a renovação da sessão do usuario
   *
   */
  private RequestRenewToken() {
    return this.http.get(this.baseUrl + 'Users/RenewToken');
  }

  /**
   * Requisita a redefinição de Senha de um usuário
   *
   * @param body ModelView de Redefinição de Senha
   */
  private RequestResetPassoword(body) {
    return this.http.post(this.baseUrl + 'Users/ResetPassword', body);
  }

  /**
   * Requisita a redefinição de Senha de um usuário
   *
   * @param UserLogin ModelView de Redefinição de Senha
   */
  private RequestValidResetPassowordToken(body) {
    return this.http.post(this.baseUrl + 'Users/ValidResetPasswordToken', body);
  }

  /**
   * Requisita a redefinição de Senha de um usuário
   *
   * @param body ModelView de Redefinição de Senha
   */
  private RequestResetPassowordWithToken(body) {
    return this.http.post(this.baseUrl + 'Users/ResetPasswordToken', body);
  }

  /**
 * Requisita a troca da APIKEY do Usuário
 *
 * @param id Id do Usuário
 */
  private RequestNewApiKeyForUser(id: number) {
    return this.http.get(`${this.baseUrl}Users/GenerateKeyForUserById/${id}`);
  }

  /**
 * Requisita o login para o Usuário especificado
 *
 * @param id Id do Usuário
 */
  private RequestLoginAsUser(id: number) {
    return this.http.get(`${this.baseUrl}Users/LoginAsUserById/${id}`);
  }

  /**
   * Recupera as informações do Usuário
   */
  public GetUserInformation() {
    if (localStorage.getItem('UniqMobile_Message_Token') != null) {
      return this.RequestGetUserInformation();
    }
  }


  /**
   * Requisita as informações do Usuário
   */
  private RequestGetUserInformation() {
    return this.http.post(this.baseUrl + 'Users/GetUserInformation', {});
  }

  /**
   * Recupera as informações do Usuário
   */
  public GetUserAdditionalInformation() {
    return this.RequestGetUserAdditionalInformation();
  }

  /**
   * Requisita as informações Adicionais do Usuário
   */
  private RequestGetUserAdditionalInformation() {
    return this.http.post(this.baseUrl + 'Users/GetUserAdditionalInformation', {});
  }

  /**
   * Verifica se o usuário está logado
   */
  public async IsLoggedIn() {
    if (localStorage.getItem('UniqMobile_Message_Token') != null) {
      if (this.UserInformation == null) {
        await this.http.post(this.baseUrl + 'Users/isLoggedIn', {}).toPromise();
        return true;
      }
    }
    return false;
  }

  public CanAcessRole(papeis): boolean {
    return this.UserInformation.roleType ? papeis.includes(this.UserInformation.roleType) : false;
  }

  public getAddressCep(cep: string): Observable<DadosCep> {
    return this.http.get<DadosCep>(`https://viacep.com.br/ws/${cep}/json`);
  }

  public buscaEstados(): Observable<ListaEstadosCidade> {
    return this.http.get<ListaEstadosCidade>('https://gist.githubusercontent.com/letanure/3012978/raw/2e43be5f86eef95b915c1c804ccc86dc9790a50a/estados-cidades.json');
  }

  public getProvidersList(): Observable<ListaProviders[]> {
    return this.http.get<ListaProviders[]>(`${this.baseUrl}Users/listproviders`);
  }

  public getIpInfo() {
    return this.http.get('https://api.ipify.org?format=json');
  }
  public getLocationInfo(ip) {
    return this.http.get(`https://ipapi.co/${ip}/json/`);
  }

}
