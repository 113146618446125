<div class="">
  <section class="comum-section" *ngIf="CheckIfRole(['Comum','Administrador'])">
    <div class="row mx-0">
      <div class="row">
        <!-- Título-->
        <div class="col-xl-12">
          <h3 class="mb-3 d-flex ">
            <div>
              <i class="mdi mdi-home-circle font-size-20 mr-2"></i>
            </div>
            <div>Bem vindo</div>

            <!-- Seleção de Módulo de Envio -->
            <div>
              <!--<div class="btn-group">
                <button class="btn btn-primary">
                  SMS
                </button>
                <button class="btn btn-primary">
                  RCS
                </button>
                <button class="btn btn-primary">
                  VOZ
                </button>
              </div>-->
            </div>
          </h3>

        </div>

        <!-- Banner -->
        <div class="col-xl-12">
          <div style="width: 100%; height:100px;border-radius:5px;background-color:#005fc9"
            class=" mb-4 d-flex justify-content-flex">
            <img height="100" style="width: 200px" class="card-img-top" src="assets/svg/Banner 1 - Opcao 3.svg"
              alt="Card image cap">
            <div class="d-flex vertical-allign-center">
              <h4 class="text-white my-auto">
                ALCANCE!
                <p class="text-white">
                  Você sabia que o SMS é entregue mesmo quando o destinatário não possui internet?
                </p>
              </h4>
              <br />
              <div>
              </div>
            </div>
          </div>
        </div>

        <!-- Links Rápidos -->
        <div class="col-xl-12">
          <div class="d-flex">
            <div>
              <i class="mdi mdi-link font-size-20 mr-2"></i>
            </div>
            <h4>
              Links Rápidos
            </h4>

          </div>
          <div class="row mt-3">
            <div class="col-xl-6 ">
              <div [routerLink]="['/message/send']" class="widget-block rounded bg-white  d-flex link-rapido">
                <div class="widget-info align-self-center">
                  <div class="d-flex ">
                    <div style="vertical-align:central; display: flex; justify-content:space-around">
                      <i class="mdi mdi-email-multiple mb-1" style="color:#3063e9"></i>
                      <p class="ml-4 mt-2"><strong>Enviar Mensagem para Grupo ou Contato</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 ">
              <div [routerLink]="['/message/send-file']" class="widget-block rounded bg-white  d-flex link-rapido">
                <div class="widget-info align-self-center">
                  <div class="d-flex ">
                    <div style="vertical-align:central; display: flex; justify-content:space-around">
                      <i class="mdi mdi-email-multiple mb-1" style="color:#3063e9"></i>
                      <p class="ml-4 mt-2"><strong>Enviar Mensagem através de Arquivo</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 ">
              <div [routerLink]="['/message/reiceiveds']" class="widget-block rounded bg-white  d-flex link-rapido">
                <div class="widget-info align-self-center">
                  <div class="d-flex ">
                    <div style="vertical-align:central; display: flex; justify-content:space-around">
                      <i class="mdi mdi-email-receive mb-1" style="color:#3063e9"></i>
                      <p class="ml-4 mt-2"><strong>Acessar Respostas Recebidas</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 ">
              <div [routerLink]="['/message/historic']" class="widget-block rounded bg-white  d-flex link-rapido">
                <div class="widget-info align-self-center">
                  <div class="d-flex ">
                    <div style="vertical-align:central; display: flex; justify-content:space-around">
                      <i class="mdi mdi-history mb-1" style="color:#3063e9"></i>
                      <p class="ml-4 mt-2"><strong>Acessar Histórico de Mensagens</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-12" style="overflow-x:auto">
          <!-- Sales Graph -->
          <div class="card card-default table-overflow">
            <div class="card-header justify-content-center">
              <h2>Últimos Envios</h2>
            </div>
            <div class="card-body" style="height:30vh">
              <!--<div class="chartjs-size-monitor"
                   style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                <div class="chartjs-size-monitor-expand"
                     style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                  <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                </div>
                <div class="chartjs-size-monitor-shrink"
                     style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                  <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                </div>
              </div>-->
              <canvas id="ultimosEnviosChart" class="chartjs-render-monitor" style="display: block;"></canvas>
            </div>
            <div class="card-footer d-flex flex-wrap bg-white p-0">
              <div class="col-12 px-0">
                <div class="text-center p-4 border-left">
                  <h4>{{totalMessagesChart}}</h4>
                  <p class="mt-2">Total de envios dos ultimos 7 dias</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 col-12 " style="overflow-x:auto">
          <!-- Notification Table -->
          <div class="row">
            <div class="col-xl-12 col-12 " style="overflow-x:auto">
              <!-- Notification Table -->
              <div class="card card-default table-overflow">
                <div class="card-header ">
                  <h2>Respostas Recebidas</h2>
                  <div>
                    <button data-toggle="tooltip" title="Ir para Mensagens Recebidas"
                      [routerLink]="['/message/reiceiveds']" class="text-black-50 ml-2 font-size-20">
                      <i class="mdi mdi-email-receive" style="font-size: 30px; color: #005fc9"></i>
                    </button>
                  </div>
                </div>

                <!--<div style="text-align:center" class="mb-4" *ngIf="MensagensRecebidas.metadados.pageCount == 0">
                  <p>Você não possui Mensagens Recebidas</p>
                </div>-->

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Data</th>
                      <th scope="col">Id</th>
                      <th scope="col" style="max-width:400px">Mensagem</th>
                      <th scope="col">Resposta</th>
                      <th scope="col">Enviada Por</th>
                      <th scope="col" style="text-align:center">Ação</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of MensagensRecebidas">
                    <tr>
                      <td scope="row">
                        {{item.receivedOn}}
                      </td>
                      <td scope="row">
                        {{item.messageId}}
                      </td>
                      <td scope="row" style="max-width:500px">
                        <div style="text-wrap:normal;">
                          {{ item.mtContent }}

                        </div>
                      </td>
                      <td scope="row">
                        {{item.content}}
                      </td>
                      <td scope="row">
                        {{item.number}}
                      </td>
                      <td scope="row">
                        <div style="text-align: center;min-width:200px">
                          <div style="text-align: center;">
                            <button data-toggle="tooltip" title="Responder Mensagem" data-toggle="modal"
                              (click)="selectMessage(item.id)" data-target="#ModalRecebida"
                              class="btn btn-sm btn-primary">
                              <i class="mdi mdi-reply font-size-20"></i>
                            </button>
                            <button data-toggle="tooltip" title="Adicionar contato à Lista Negra"
                              style="margin-left: 1rem;" (click)="selectMessage(item.id)"
                              data-target="#confirmaAddListaNegra" data-toggle="modal" class="btn btn-sm btn-secondary">
                              <i class="mdi mdi-alpha-b-box font-size-20"></i>
                            </button>
                            <button data-toggle="tooltip" title="Deletar Resposta" style="margin-left: 1rem;"
                              (click)="selectMessage(item.id)" data-target="#confirmaDeleteResposta" data-toggle="modal"
                              class="btn btn-sm btn-danger">
                              <i class="mdi mdi-delete font-size-20"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="card-footer">
                  <div class="form-inline" *ngIf="!loadRespostas">
                    <ul class="pagination">
                      <li style="cursor: pointer" [ngClass]="
                            this.hasPreviousPageReplys
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                        <a class="page-link" style="cursor: pointer" aria-label="Previous">
                          <span aria-hidden="true" class="mdi mdi-chevron-left"
                            (click)="getPaginateReplys(pageRespostas - 1)"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesReplys;"
                        [ngClass]="{'active': pageRespostas == p }">
                        <a class="page-link" (click)="getPaginateReplys(p)">{{p}}</a>
                      </li>
                      <li style="cursor: pointer" [ngClass]="
                            this.hasNextPageReplys
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                        <a class="page-link" style="cursor: pointer" aria-label="Next">
                          <span aria-hidden="true" class="mdi mdi-chevron-right"
                            (click)="getPaginateReplys(pageRespostas + 1)">
                          </span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mt-3"></div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12 col-12" style="overflow-x:auto">
              <!-- Notification Table -->
              <div class="card card-default table-overflow">
                <div class="card-header">
                  <h2>Mensagens Agendadas</h2>
                  <div>
                    <button class="text-black-50 ml-3 font-size-20">
                      <i class="mdi mdi-calendar-clock" style="font-size: 30px; color: #005fc9"></i>
                    </button>
                  </div>
                </div>

                <!--<div style="text-align:center" class="mb-4" *ngIf="MensagensAgendadas.metadados.pageCount == 0">
                  <p>Você não possui Mensagens Agendadas</p>
                </div>-->

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Data</th>
                      <th scope="col">Mensagem</th>
                      <th scope="col">Quantidade</th>
                      <th scope="col">Agendada para</th>
                      <th scope="col" style="text-align:center">Ação</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of MensagensAgendadas">
                    <tr>
                      <td scope="row">
                        {{item.message_id}}
                      </td>
                      <td scope="row">
                        {{item.createdOn}}
                      </td>
                      <td scope="row">
                        <div style="text-wrap:normal;">
                          {{ item.content }}

                        </div>
                      </td>
                      <td scope="row">
                        {{ item.qunt }}
                      </td>
                      <td scope="row">
                        {{ item.scheduled }}
                      </td>
                      <td scope="row">
                        <div style="text-align: center;min-width:130px">
                          <div style="text-align: center;">
                            <button data-toggle="Visualizar Mensagem" title="Hooray!" data-target="#ModalAgendadas"
                              (click)="selectMessageAgendada(item.message_id)" data-toggle="modal"
                              class="btn btn-sm btn-primary">
                              <i class="mdi mdi-pencil font-size-20"></i>
                            </button>
                            <button data-toggle="tooltip" title="Cancelar Agendamento" style="margin-left: 1rem;"
                              (click)="selectMessageAgendada(item.message_id)"
                              data-target="#confirmaCancelarAgendamento" data-toggle="modal"
                              class="btn btn-sm btn-danger">
                              <i class="mdi mdi-delete font-size-20"></i>
                            </button>

                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="card-footer">
                  <div class="form-inline" *ngIf="!loadAgendado">
                    <ul class="pagination">
                      <li style="cursor: pointer" [ngClass]="
                            this.hasPreviousPageScheduled
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                        <a class="page-link" style="cursor: pointer" aria-label="Previous">
                          <span aria-hidden="true" class="mdi mdi-chevron-left"
                            (click)="getPaginateScheduled(page - 1)"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesScheduled;"
                        [ngClass]="{'active': pageAgendadas == p }">
                        <a class="page-link" (click)="getPaginateScheduled(p)">{{p}}</a>
                      </li>
                      <li style="cursor: pointer" [ngClass]="
                            this.hasNextPageScheduled
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                        <a class="page-link" style="cursor: pointer" aria-label="Next">
                          <span aria-hidden="true" class="mdi mdi-chevron-right">
                          </span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div class="mt-3"></div>
        </div>

      </div>
      <div class="row" style="width: 100%">
        <div class="col-xl-12 col-12">
          <!-- Sales Graph -->
          <!--<div class="card card-default">
            <div class="card-header justify-content-center">
              <h2>Últimos Envios</h2>
            </div>
            <div class="card-body" style="height:30vh">
              <div class="chartjs-size-monitor"
                   style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                <div class="chartjs-size-monitor-expand"
                     style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                  <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                </div>
                <div class="chartjs-size-monitor-shrink"
                     style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                  <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                </div>
              </div>
              <canvas id="barChart1" class="chartjs-render-monitor" style="display: block;"></canvas>
            </div>
            <div class="card-footer d-flex flex-wrap bg-white p-0">
              <div class="col-12 px-0">
                <div class="text-center p-4 border-left">
                  <h4>$70,506</h4>
                  <p class="mt-2">Total de envios dos ultimos 7 dias</p>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>

  </section>

  <!-- Owner Only-->
  <section class="owner-section" *ngIf="CheckIfRole(['Owner'])">
    <h3 class="d-flex justify-content-between">
      <div>Visão Geral</div>
      <div>
        <div class="input-group">
          <div class="btn-group">
            <button class="btn btn-primary">
              SMS
            </button>
            <button class="btn btn-primary">
              RCS
            </button>
            <button class="btn btn-primary">
              VOZ
            </button>
          </div>
        </div>
      </div>
    </h3>

    <!--Informações Rápidas-->
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-3 col-md-6 d-flex">
            <div class="widget-block rounded bg-white border d-block">
              <div class="widget-info align-self-center justify-content-between d-flex w-100">
                <div>
                  <h4 class="text-black mb-2">{{userData.lastLogin ? userData.lastLogin : "Não Possui"}}</h4>
                  <p>Último Acesso</p>
                </div>
                <div>
                  <i class="mdi mdi-clock-outline" style="font-size:30px"></i>
                </div>
              </div>
              <!--<div class="widget-chart w-50">
              </div>-->
            </div>
          </div>
          <div class="col-xl-3 col-md-6 d-flex">
            <div class="widget-block rounded border bg-white">
              <div class="widget-info align-self-center justify-content-between d-flex w-100">
                <div>
                  <h4 class="text-black mb-2"> {{userData.credits}}</h4>
                  <p>Estoque</p>
                </div>
                <div>
                  <i class="mdi mdi-archive" style="font-size:30px"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 d-flex">
            <div class="widget-block rounded border bg-white ">
              <div class="widget-info align-self-center justify-content-between d-flex w-100">
                <div>
                  <h4 class="text-black mb-2"> {{userData.totalMessages}}</h4>
                  <p>Mensagens Totais</p>
                </div>
                <div>
                  <i class="mdi mdi-email-multiple" style="font-size:30px"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6 d-flex">
            <div class="widget-block rounded border bg-white">
              <div class="widget-info align-self-center justify-content-between d-flex w-100">
                <div>
                  <h4 class="text-black mb-2">{{userData.stream ? userData.stream : "Não Possui"}}</h4>
                  <p>Maior fluxo de envio</p>
                </div>
                <div>
                  <i class="mdi mdi-radio-tower" style="font-size:30px"></i>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="row mb-4">
          <div class="col-xl-5 col-12" style="height:550px;">
            <!-- Notification Table -->
            <div class="card card-default d-flex flex-column h-100">
              <div class="card-header">
                <h2>Relatório de Atividades</h2>
                <div>
                  <button data-toggle="tooltip" title="Ir para Histórico de Atividade"
                    [routerLink]="['/historic/active-users']" class="text-black-50 ml-3 font-size-20">
                    <i class="mdi mdi-file-chart" style="font-size: 30px; color: #005fc9"></i>
                  </button>
                </div>
              </div>
              <div style="overflow-x: auto;flex:1" class="table-overflow">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Sistema</th>
                      <th scope="col">Atividade</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of HistoricoAtividade">
                    <tr>
                      <td scope="row">
                        <div
                          class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 bg-primary text-white">
                          <i class="mdi mdi-monitor-dashboard font-size-20"></i>
                        </div>
                      </td>
                      <td scope="row">
                        <div data-toggle="tooltip" title="Visualizar Atividade" data-toggle="modal"
                          (click)="selectActivity(item.id)" data-target="#modalAtividade">
                          <p style="color:#000; cursor:pointer;text-decoration: underline;">
                            {{ item.login}} - {{item.client}}
                          </p>
                          <p style="color:#000;cursor:pointer">{{ item.log }}</p>

                        </div>
                      </td>
                      <td scope="row">
                        {{item.criado}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="form-inline ">
                  <ul class="pagination">
                    <li style="cursor: pointer" [ngClass]="
                            this.hasPreviousPageActivities
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Previous">
                        <span aria-hidden="true" class="mdi mdi-chevron-left"
                          (click)="getPaginateActivities(pageAtividade - 1)"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesActivities;"
                      [ngClass]="{'active': pageAtividade == p }">
                      <a class="page-link" (click)="getPaginateActivities(p)">{{p}}</a>
                    </li>
                    <li style="cursor: pointer" [ngClass]="
                            this.hasNextPageActivities
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" (click)="getPaginateActivities(pageAtividade + 1)" style="cursor: pointer"
                        aria-label="Next">
                        <span aria-hidden="true" class="mdi mdi-chevron-right">
                        </span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>

          <div class="col-xl-7 col-12" style="height:550px;">
            <!-- Notification Table -->
            <div class="card card-default d-flex flex-column h-100">
              <div class="card-header ">
                <h2>Mensagens Pendentes</h2>
                <div>
                  <button data-toggle="tooltip" title="Ir para Mensagens Pendentes"
                    class="text-black-50 ml-3 font-size-20">
                    <i [routerLink]="['/msg-suspicious/liberation']" class="mdi mdi-email-alert"
                      style="font-size: 30px; color: #005fc9"></i>
                  </button>
                </div>
              </div>
              <!--<div style="text-align:center" class="mb-4" *ngIf="MensagensPendentes.metadados.pageCount == 0">
                <p>Você não possui Mensagens Pendentes</p>
              </div>-->
              <div style="overflow-x:auto;flex:1" class="table-overflow">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Data</th>
                      <th scope="col">Mensagem</th>
                      <th scope="col" style="text-align:center">Ação</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let item of MensagensPendentes">
                    <tr>
                      <td scope="row">
                        <p (click)="selectMessagePendente(item.messageId)" data-toggle="modal"
                          data-target="#ModalPendentes" style="text-decoration:underline;cursor:pointer">
                          {{item.messageId}}</p>
                      </td>
                      <td scope="row">
                        {{item.createdOn}}
                      </td>
                      <td scope="row">
                        <div style="text-wrap:normal;">
                          {{ item.content }}

                        </div>
                      </td>
                      <td scope="row">
                        <div style="text-align: center;min-width:130px">
                          <div style="text-align: center;">
                            <button data-toggle="tooltip" title="Liberar Mensagem" class="btn btn-primary btn-sm"
                              (click)="selectMessagePendente(item.messageId)" data-toggle="modal"
                              data-target="#ModalLiberacao">
                              <i class="mdi mdi-arrow-up font-size-20"></i>
                            </button>
                            <button data-toggle="tooltip" title="Bloqueiar Mensagem" class="btn btn-danger btn-sm ml-2"
                              (click)="selectMessagePendente(item.messageId)" data-toggle="modal"
                              data-target="#ModalBloqueio">
                              <i class="mdi mdi-block-helper font-size-20"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="form-inline">
                  <ul class="pagination">
                    <li style="cursor: pointer" [ngClass]="
                            this.hasPreviousPageSuspicious
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Previous">
                        <span aria-hidden="true" class="mdi mdi-chevron-left"
                          (click)="getPaginateSuspicious(pagePendentes - 1)"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesSuspicious;"
                      [ngClass]="{'active': pagePendentes == p }">
                      <a class="page-link" (click)="getPaginateSuspicious(p)">{{p}}</a>
                    </li>
                    <li style="cursor: pointer" [ngClass]="
                            this.hasNextPageSuspicious
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Next">
                        <span aria-hidden="true" class="mdi mdi-chevron-right">
                        </span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-xl-12 col-12" style="overflow-x:auto">
            <!-- Sales Graph -->
            <div class="card card-default table-overflow">
              <div class="card-header justify-content-center">
                <h2>Últimos Envios</h2>
              </div>
              <div class="card-body" style="height:30vh">
                <!--<div class="chartjs-size-monitor"
                     style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;">
                  <div class="chartjs-size-monitor-expand"
                       style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                    <div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div>
                  </div>
                  <div class="chartjs-size-monitor-shrink"
                       style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;">
                    <div style="position:absolute;width:200%;height:200%;left:0; top:0"></div>
                  </div>
                </div>-->
                <canvas id="ultimosEnviosChart" class="chartjs-render-monitor" style="display: block;"></canvas>
              </div>
              <div class="card-footer d-flex flex-wrap bg-white p-0">
                <div class="col-12 px-0">
                  <div class="text-center p-4 border-left">
                    <h4>{{totalMessagesChart}}</h4>
                    <p class="mt-2">Total de envios dos ultimos 7 dias</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12 col-12" style="overflow-x:auto">
            <!-- Notification Table -->
            <div class="card card-default table-overflow">
              <div class="card-header">
                <h2>Mensagens Agendadas</h2>
                <div>
                  <button class="text-black-50 ml-3 font-size-20">
                    <i class="mdi mdi-calendar-clock" style="font-size: 30px; color: #005fc9"></i>
                  </button>
                </div>
              </div>

              <!--<div style="text-align:center" class="mb-4" *ngIf="MensagensAgendadas.metadados.pageCount == 0">
                <p>Você não possui Mensagens Agendadas</p>
              </div>-->

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Data</th>
                    <th scope="col">Mensagem</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Agendada para</th>
                    <th scope="col" style="text-align:center">Ação</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of MensagensAgendadas">
                  <tr>
                    <td scope="row">
                      {{item.message_id}}
                    </td>
                    <td scope="row">
                      {{item.createdOn}}
                    </td>
                    <td scope="row">
                      <div style="text-wrap:normal;">
                        {{ item.content }}

                      </div>
                    </td>
                    <td scope="row">
                      {{ item.qunt }}
                    </td>
                    <td scope="row">
                      {{ item.scheduled }}
                    </td>
                    <td scope="row">
                      <div style="text-align: center;min-width:130px">
                        <div style="text-align: center;">
                          <button data-toggle="tooltip" title="Visualizar Mensagem" data-target="#ModalAgendadas"
                            (click)="selectMessageAgendada(item.message_id)" data-toggle="modal"
                            class="btn btn-sm btn-primary">
                            <i class="mdi mdi-pencil font-size-20"></i>
                          </button>
                          <button style="margin-left: 1rem;" data-toggle="tooltip" title="Cancelar Agendamento"
                            (click)="selectMessageAgendada(item.message_id)" data-target="#confirmaCancelarAgendamento"
                            data-toggle="modal" class="btn btn-sm btn-danger">
                            <i class="mdi mdi-delete font-size-20"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="card-footer">
                <div class="form-inline">
                  <ul class="pagination">
                    <li style="cursor: pointer" [ngClass]="
                            this.hasPreviousPageScheduled
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Previous">
                        <span aria-hidden="true" class="mdi mdi-chevron-left"
                          (click)="getPaginateScheduled(page - 1)"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesScheduled;"
                      [ngClass]="{'active': pageAgendadas == p }">
                      <a class="page-link" (click)="getPaginateScheduled(p)">{{p}}</a>
                    </li>
                    <li style="cursor: pointer" [ngClass]="
                            this.hasNextPageScheduled
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Next">
                        <span aria-hidden="true" class="mdi mdi-chevron-right">
                        </span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="row">
          <div class="col-xl-12 col-12 " style="overflow-x:auto">
            <!-- Notification Table -->
            <div class="card card-default table-overflow">
              <div class="card-header ">
                <h2>Respostas Recebidas</h2>
                <div>
                  <button data-toggle="tooltip" title="Ir para Respostas Recebidas!"
                    [routerLink]="['/message/reiceiveds']" class="text-black-50 ml-2 font-size-20">
                    <i class="mdi mdi-email-receive" style="font-size: 30px; color: #005fc9"></i>
                  </button>
                </div>
              </div>

              <!--<div style="text-align:center" class="mb-4" *ngIf="MensagensRecebidas.metadados.pageCount == 0">
                <p>Você não possui Mensagens Recebidas</p>
              </div>-->

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Id</th>
                    <th scope="col" style="max-width:400px">Mensagem</th>
                    <th scope="col">Resposta</th>
                    <th scope="col">Enviada Por</th>
                    <th scope="col" style="text-align:center">Ação</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of MensagensRecebidas">
                  <tr>
                    <td scope="row">
                      {{item.receivedOn}}
                    </td>
                    <td scope="row">
                      {{item.messageId}}
                    </td>
                    <td scope="row" style="max-width:500px">
                      <div style="text-wrap:normal;">
                        {{ item.mtContent }}

                      </div>
                    </td>
                    <td scope="row">
                      {{item.content}}
                    </td>
                    <td scope="row">
                      {{item.number}}
                    </td>
                    <td scope="row">
                      <div style="text-align: center;min-width:200px">
                        <div style="text-align: center;">
                          <button data-toggle="tooltip" title="Responder Mensagem" data-toggle="modal"
                            (click)="selectMessage(item.id)" data-target="#ModalRecebida"
                            class="btn btn-sm btn-primary">
                            <i class="mdi mdi-reply font-size-20"></i>
                          </button>
                          <button data-toggle="tooltip" title="Adicionar número à Lista Negra"
                            style="margin-left: 1rem;" (click)="selectMessage(item.id)"
                            data-target="#confirmaAddListaNegra" data-toggle="modal" class="btn btn-sm btn-secondary">
                            <i class="mdi mdi-alpha-b-box font-size-20"></i>
                          </button>
                          <button data-toggle="tooltip" title="Deletar resposta" style="margin-left: 1rem;"
                            (click)="selectMessage(item.id)" data-target="#confirmaDeleteResposta" data-toggle="modal"
                            class="btn btn-sm btn-danger">
                            <i class="mdi mdi-delete font-size-20"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="card-footer">
                <div class="form-inline">
                  <ul class="pagination">
                    <li style="cursor: pointer" [ngClass]="
                            this.hasPreviousPageReplys
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Previous">
                        <span aria-hidden="true" class="mdi mdi-chevron-left"
                          (click)="getPaginateReplys(page - 1)"></span>
                        <span class="sr-only">Previous</span>
                      </a>
                    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPagesReplys;"
                      [ngClass]="{'active': pageRespostas == p }">
                      <a class="page-link" (click)="getPaginateReplys(p)">{{p}}</a>
                    </li>
                    <li style="cursor: pointer" [ngClass]="
                            this.hasNextPageReplys
                              ? 'page-item'
                              : 'page-item disabled'
                          ">
                      <a class="page-link" style="cursor: pointer" aria-label="Next">
                        <span aria-hidden="true" class="mdi mdi-chevron-right">
                        </span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="mt-3"></div>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div class="modal fade" id="modalAtividade" tabindex="-1" role="dialog" aria-labelledby="modalAtividade"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle2">Atividade Usuário: {{currentActivity.login}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-hover ">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Cliente</th>
              <th scope="col">Usuario</th>
              <th scope="col">Ação</th>
              <!-- <th scope="col">Sistema</th> -->
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">{{currentActivity.criado}}</td>
              <td>{{currentActivity.client}}</td>
              <td>{{currentActivity.login}}</td>
              <td>{{currentActivity.log}}</td>
              <!-- <td>{{currentActivity.sistema == 0 ? "Dashboard": "API"}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- Modal Pendentes-->
<div class="modal fade" id="ModalPendentes" tabindex="-1" role="dialog" aria-labelledby="ModalPendentes"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle2">Mensagem Pendente - Nº {{currentPendingMessage.messageId}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="currentPendingMessage">
        <form>
          <table class="table table-hover ">
            <thead>
              <tr>
                <th scope="col">Mensagem</th>
                <th scope="col">Id</th>
                <th scope="col">Data</th>
                <th scope="col">Quantidade</th>
                <th scope="col">Cliente</th>
                <th scope="col">Usuario</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">{{currentPendingMessage.content}}</td>
                <td>{{currentPendingMessage.messageId}}</td>
                <td>{{currentPendingMessage.createdOn}}</td>
                <td>{{currentPendingMessage.count}}</td>
                <td>{{currentPendingMessage.client}}</td>
                <td>{{currentPendingMessage.user}}</td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal Agendadas -->
<div class="modal fade" id="ModalAgendadas" tabindex="-1" role="dialog" aria-labelledby="ModalRecebida"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle2">Alterar Agendamento</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="currentScheduledMessage != undefined">
        <div class="form-group" style=" margin-top: 2rem;">
          <div style="display:flex">
            <p for="exampleInputPassword1"><strong>Data:</strong></p>
            <p style="margin-left: 1rem;">{{currentScheduledMessage.createdOn}}</p>
          </div>
          <div class="form-group" style=" margin-top: 2rem;">
            <div style="display:flex">
              <p for="exampleInputPassword1"><strong>Quantidade:</strong></p>
              <p style="margin-left: 1rem;">{{currentScheduledMessage.qunt}}</p>
            </div>
          </div>
          <div class="form-group" style=" margin-top: 2rem;">
            <div style="display:flex">
              <p for="exampleInputPassword1"><strong>Mensagem:</strong></p>
              <p style="margin-left: 1rem;">{{currentScheduledMessage.content}}</p>

            </div>
          </div>

          <div class="form-group">
            <label for="exampleFormControlInput3">Nova Data</label>
            <input type="date" class="form-control" [(ngModel)]="Scheduled" (change)="CheckScheduledMessage()"
              id="exampleFormControlInput3" placeholder="">
          </div>
          <div class="form-group">
            <label for="exampleFormControlInput3">Novo Horario</label>
            <input type="text" class="form-control" [(ngModel)]="ScheduledTime" (change)="CheckScheduledMessage()"
              id="exampleFormControlInput3" mask="00:00" placeholder="00:00">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill"
          (click)="reagendarMsg(currentScheduledMessage.message_id,Scheduled,ScheduledTime)" data-dismiss="modal">
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal cancelar Agendamento -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaCancelarAgendamento" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja cancelar esse agendamento ?
        </h5>
      </div>
      <table class="table table-hover ">
        <thead>
          <tr>
            <th scope="col">Data</th>
            <th scope="col">Mensagem</th>
            <th scope="col">Quantidade</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{currentScheduledMessage.createdOn}}</td>
            <td>{{currentScheduledMessage.content}}</td>
            <td>{{currentScheduledMessage.qunt}}</td>
          </tr>
        </tbody>
      </table>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill"
          (click)="cancelarAgendamento(currentScheduledMessage.message_id)" data-dismiss="modal">
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Recebidas -->
<div class="modal fade" id="ModalRecebida" tabindex="-1" role="dialog" aria-labelledby="ModalRecebida"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle2">Resposta à mensagem recebida</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group" style="display:flex">
          <p for="exampleInputEmail1"><strong>Id:</strong></p>
          <p style="margin-left: 1rem;">{{currentReplyMessage ? currentReplyMessage.messageId : '' }}</p>

        </div>
        <div class="form-group" style=" margin-top: 2rem;">
          <div style="display:flex">
            <p for="exampleInputPassword1"><strong>Mensagem Enviada:</strong></p>
            <p style="margin-left: 1rem;">{{currentReplyMessage ? currentReplyMessage.mtContent : ''}}</p>

          </div>
        </div>
        <div class="form-group" style=" margin-top: 2rem;">
          <div style="display:flex">
            <p for="exampleInputPassword1"><strong>Resposta:</strong></p>
            <p style="margin-left: 1rem;">{{currentReplyMessage ? currentReplyMessage.content : ''}}</p>

          </div>
        </div>
        <div class="form-group" style="margin-top: 2rem;">
          <div style="display:flex">
            <p for="exampleInputPassword1"><strong>Recebido por:</strong></p>
            <p style="margin-left: 1rem;">{{currentReplyMessage ? currentReplyMessage.number : ''}}</p>

          </div>
        </div>
        <div class="form-group" style=" margin-top: 2rem;">
          <div style="display:flex">
            <p for="exampleInputPassword1"><strong>Data:</strong></p>
            <p style="margin-left: 1rem;">{{currentReplyMessage ? currentReplyMessage.receivedOn : ''}}</p>

          </div>
        </div>
        <div class="form-group my-0" style=" margin-top: 2rem;">
          <div style="display:block">
            <p for="exampleInputPassword1"><strong>Responder:</strong></p>
            <input class="form-control mt-2" [(ngModel)]="ReplyContent" placeholder="Resposta" />

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Cancelar
        </button>
        <button type="button" (click)="SubmitMessage()" class="btn btn-primary btn-pill" data-dismiss="modal">
          Responder
        </button>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" id="botaoConfirmacaoEnvio" data-toggle="modal" style="display:none"
  data-target="#ModalEnvioConfirmado"> ok</button>

<!-- modal envio confirmado -->
<div class="modal fade" id="ModalEnvioConfirmado" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Envio de mensagem confirmada
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>

<!-- modal adicionar a black list -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaAddListaNegra" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja adicionar esse contato a lista negra ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="adicionarAblackList(currentReplyMessage.number)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal liberar -->
<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="ModalLiberacao" tabindex="-1" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Liberação de Mensagem
        </h5>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Id:</strong></p>
            <p style="margin-left: 1rem;">{{currentPendingMessage ? currentPendingMessage.messageId : '' }}</p>

          </div>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Data:</strong></p>
            <p style="margin-left: 1rem;">{{currentPendingMessage ? currentPendingMessage.createdOn : '' }}</p>

          </div>
          <div class="form-group" style=" margin-top: 2rem;">
            <div style="display:flex">
              <p for="exampleInputPassword1"><strong>Mensagem Pendente:</strong></p>
              <p style="margin-left: 1rem;">{{currentPendingMessage ? currentPendingMessage.content : ''}}</p>

            </div>
          </div>
        </form>
        <div class="alert alert-danger alert-outlined" role="alert">
          <p> Mensagem pendente será liberada para envio!</p>
          <p class="mt-2"> <strong>Esta operação não poderá ser desfeita. Deseja continuar?</strong></p>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="LiberarMessage(currentPendingMessage.messageId)"
          data-dismiss="modal">
          Liberar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal bloquear -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="ModalBloqueio" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Não Liberação de Mensagem
        </h5>
      </div>
      <div class="modal-body">

        <form>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Id:</strong></p>
            <p style="margin-left: 1rem;">{{currentPendingMessage ? currentPendingMessage.messageId : '' }}</p>

          </div>
          <div class="form-group" style="display:flex">
            <p for="exampleInputEmail1"><strong>Data:</strong></p>
            <p style="margin-left: 1rem;">{{currentPendingMessage ? currentPendingMessage.createdOn : '' }}</p>

          </div>
          <div class="form-group" style=" margin-top: 2rem;">
            <div style="display:flex">
              <p for="exampleInputPassword1"><strong>Mensagem Pendente:</strong></p>
              <p style="margin-left: 1rem;">{{currentPendingMessage ? currentPendingMessage.content : ''}}</p>

            </div>
          </div>
        </form>
        <!--<hr />-->
        <div class="alert alert-danger alert-outlined" role="alert">
          <p> Mensagem pendente não será enviada, exibindo para o cliente o status <strong>NÃO ENVIADO</strong>.</p>
          <p class="mt-2"> <strong>Esta operação não poderá ser desfeita. Deseja continuar?</strong></p>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Cancelar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="BlockMessage(currentPendingMessage.messageId)"
          data-dismiss="modal">
          Bloquear
        </button>
      </div>
    </div>
  </div>
</div>

<!-- modal excluir -->

<div class="modal fade" data-keyboard="false" aria-labelledby="staticBackdropLabel" data-backdrop="static"
  id="confirmaDeleteResposta" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja deletar esse resposta ?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">
          <!--(click)="closeModal()">-->
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="excluirResposta(currentReplyMessage.id)"
          data-dismiss="modal">
          Salvar
        </button>
      </div>
    </div>
  </div>
</div>