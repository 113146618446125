<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-history text-blue mr-4 font-size-20"></i>
  <h1>Histórico de Atividades</h1>
</div>
<div class="row">
  <div class="col-12">
    <div class="mb-3">
      <div class="dropdown d-inline-block mb-1">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" data-display="static">
          Exportar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="right: 0; left: initial">
          <a class="dropdown-item" style="cursor:pointer" (click)="exportActivityHistoric()">Exportar como Excel
            Completo</a>
          <a class="dropdown-item" style="cursor:pointer" data-toggle="modal" data-target="#modalPeriodo">Exportar como
            Excel Por Período</a>
          <!-- <a class="dropdown-item" href="#">Exportar como PDF</a> -->
        </div>
      </div>
    </div>
    <div class="card card-default">
      <div class="card-header mb-0">
        <div class="row w-100 justify-content-between">
          <div class="dropdown d-inline-block mb-1">
            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" data-display="static">
              Filtrar por
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemAtividadeUsuarios(1)">Todos</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemAtividadeUsuarios(1,4)">Owner</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemAtividadeUsuarios(1,2)">Administrador</a>
              <a class="dropdown-item" style="cursor:pointer" (click)="listagemAtividadeUsuarios(1,1)">Comum</a>
            </div>
          </div>
          <div class="input-group ml-3 mb-0" style="width:auto;flex: 1">
            <input type="text" (input)="listagemAtividadeUsuarios()" [(ngModel)]="searchQuery" class="form-control"
              placeholder="Busca rápida" />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" style="height:45px;width:45px">
                <i class=" mdi mdi-magnify font-size-20"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Cliente</th>
              <th scope="col">Usúario</th>
              <th scope="col">Ação</th>
              <!-- <th scope="col">Sistema</th>
              <th scope="col">Login no sistema</th>
              <th scope="col">Tipo</th> -->
            </tr>
          </thead>
          <tbody *ngFor="let item of listAtividadesUsuarios">
            <tr>
              <td scope="row">{{ item.criado }}</td>
              <td scope="row">{{ item.client }}</td>
              <td scope="row">{{ item.nome }}</td>
              <td scope="row">{{ item.log }}</td>
              <!-- <td scope="row">{{ item.sistema }}</td>
              <td scope="row">E-mail</td>
              <td scope="row">{{ item.tipo_user }}</td> -->

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="form-inline justify-content-between" *ngIf="!load">
  <ul class="pagination">
    <li [ngClass]="
          this.hasPreviousPage
            ? 'page-item'
            : 'page-item disabled'
        ">
      <a class="page-link" style="cursor: pointer" aria-label="Previous">
        <span aria-hidden="true" class="mdi mdi-chevron-left" (click)="getPaginate(page - 1)"></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li class="page-item" style="cursor: pointer" *ngFor="let p of neighborPages;" [ngClass]="{'active': page == p }">
      <a class="page-link" (click)="getPaginate(p)">{{p}}</a>
    </li>
    <li [ngClass]="
        this.hasNextPage
            ? 'page-item'
            : 'page-item disabled'
        ">
      <a class="page-link" style="cursor: pointer" aria-label="Next">
        <span aria-hidden="true" class="mdi mdi-chevron-right" (click)="getPaginate(page + 1)"></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
  <ul class="pagination">
    <div class="page-link">
      Itens por Pagina
      <select (change)="getPaginate()" [(ngModel)]="pageSize">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select>
    </div>
  </ul>
</div>

<!-- escolha de periodo -->
<div class="modal fade" id="modalPeriodo" data-keyboard="false" data-backdrop="static" tabindex="-1"
  aria-labelledby="modalDetalhesMensagensEnviadas" style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">
          Escolha um periodo para a exportação do relatorio
        </h5>
        <button type="button" data-dismiss="modal">
          <span data-dismiss="modal" aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <div style="display: flex">
            <div class="form-group" style="flex: 1">
              <label>De</label>
              <!--<label *ngIf="formulario.invalid"><span style="color:red">Obrigatório</span></label>-->
              <input [(ngModel)]="PeriodoDe" class="form-control" type="date" [value]="PeriodoDe" />
            </div>
            <div class="form-group" style="margin: 40px 15px 0 15px">
              <span>à</span>
            </div>
            <div class="form-group" style="flex: 1">
              <label>Até</label>
              <input [(ngModel)]="PeriodoAte" class="form-control" type="date" [value]="PeriodoAte" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal"
          (click)="exportActivityHistoric(PeriodoDe, PeriodoAte)">
          Exportar
        </button>
      </div>
    </div>
  </div>
</div>