import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListagemContatos } from './listagemContatos.model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ListagemContatosService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;

  getAllContatos(page: number = 1, query: string = ''): Observable<ListagemContatos> {
    return this.http.get<ListagemContatos>(`${this.baseUrl}contacts/ListagemContatos?page=${page}&query=${query}`);
  }


  getAllContatosByGrupo(idgrupo: number, page: number = 1, query: string = ''): Observable<ListagemContatos> {
    return this.http.get<ListagemContatos>(`${this.baseUrl}groups/listagemcontantosingroup/${idgrupo}?page=${page}&query=${query}`);
  }

  deleteContato(id: number) {
    return this.http.delete(`${this.baseUrl}contacts/delete/${id}`);
  }

  ImportExcel(Arquivo: FormData) {
    return this.http.post(`${this.baseUrl}contacts/importcontactfileexcel/excel`, Arquivo);
  }

  PegarContatosGrupos(idGrupo: any, query: string, isCsv) {
    return this.http.get(`${this.baseUrl}contacts/exportcontatcsgroup?idGrupo=${idGrupo}&query=${query}&isCsv=${isCsv}`, { responseType: "blob" });
  }



  public gerarExcelContatosGrupos(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelContatosGrupos(excelBuffer, excelFileName);
  }
  private saveAsExcelContatosGrupos(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }





}
