import { Component, OnInit } from '@angular/core';
import { AuditoriaService } from '../auditoria.service';

@Component({
  selector: 'app-historico-login',
  templateUrl: './historico-login.component.html',
  styleUrls: ['./historico-login.component.scss']
})
export class HistoricoLoginComponent implements OnInit {

  constructor(private auditoriaService: AuditoriaService) { }

  load = false;
  listLogin: any = []
  TotalLogin: any = []
  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize:any = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  ngOnInit() {
    this.load = true;
    this.listarLogin(1,'');
  }

  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.usuario.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    console.log("TOTAL:",this.totalItems)
    console.log("PAGECOUNT",this.pageCount)
    this.page = page;
    this.listLogin = this.paginate(this.TotalLogin.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);

      }

    }
  }

  listarLogin(page: number = 1, query: string = null) {
    this.page = page;
    this.auditoriaService.getLoginHistoric(page, query).subscribe((res: any) => {
      this.TotalLogin = res;
      this.load = false;

      //DADOS PAGINAÇÃO
      this.totalItems = this.TotalLogin.lista.length;
      this.pageCount = (this.totalItems + this.pageSize) / this.pageSize;
      this.hasPreviousPage = this.page == 1 ? false : true;
      this.hasNextPage = this.page == this.pageCount ? false : true;

      this.getPaginate();

    });
  }

  exportLogin() {
    this.auditoriaService.exportLoginExcel(this.listLogin.lista).subscribe((res: any) => {
      const blob = new Blob([res]);

      saveAs(blob,"Uniq_Mobile_Historico_Login" + ".xlsx");
    });
  }

}
