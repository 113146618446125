import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Provider } from '../model_providers/provider.model';
import { ProviderService } from '../provider.service';

@Component({
  selector: 'app-edicao-fornecedores',
  templateUrl: './edicao-fornecedores.component.html',
  styleUrls: ['./edicao-fornecedores.component.scss']
})
export class EdicaoFornecedoresComponent implements OnInit {

  constructor(private fb: FormBuilder, private router: Router, private providerService: ProviderService, private route: Router, private activatedRoute: ActivatedRoute, private toastr: ToastrService) { }

  fornecedorForm: FormGroup;
  fornecedor: Provider;
  load: boolean;



  ngOnInit() {
    this.carregaFornecedor();
  }





  carregaFornecedor() {
    this.load = true;

    this.providerService.getProviderById(Number(this.activatedRoute.snapshot.params.id)).subscribe(
      (data: any) => {
        this.load = false;

        this.fornecedorForm = this.fb.group({
          Nome: [data.label, Validators.required],
          Email: [data.email, [Validators.email, Validators.required]],
          Telefone: [data.telefone, Validators.required],
          Documento: [data.document, Validators.required],

        });
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar!', 'Fornecedor');
        this.load = false;

      },
    );
  }



  OnSubmitEdit() {
    this.providerService.updateProvider(this.activatedRoute.snapshot.params.id, this.fornecedorForm.value).subscribe(
      (res: any) => {
        this.toastr.success('Você está sendo redirecionado!', 'Concluido', { timeOut: 2000, progressBar: true });
        setTimeout(() => {
          this.route.navigateByUrl('/providers/list');
        }, 2000);
      },
      err => {
        console.log(err);
        if (typeof err.error === 'string') {

          this.toastr.error(err.error, 'Fornecedor');
        } else {

          this.toastr.error('Não foi possivel realizar cadastro', 'Fornecedor');
        }
      }
    );
  }

  redirectToProviderList() {
    this.router.navigateByUrl('/providers/list');
  }


}
