<!-- <div class="container"> -->
<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-email text-blue mr-4 font-size-20"></i>
  <h1>Envio de Mensagem</h1>
</div>
<div class="row">
  <div class="col-xl-8 col-md-8 col-12 pr-0">
    <div class="col-xl-12 pl-0">
      <div class="card card-default">
        <div class="card-header card-header-border-bottom">
          <h4>
            Nova Mensagem
          </h4>
          <!--Nova Mensagem<span style="color: red">(Separado por ",")</span>-->
        </div>
        <div class="card-body">
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" style="min-height:275px;">
                <div class="d-flex flex-column h-100">
                  <div class="mt-3">
                    <label>Remetente</label>
                    <input type="text" class="form-control" [(ngModel)]="from" (input)="MaisSmg()">

                  </div>
                  <div style="display:flex; flex-direction:column; flex:1">
                    <label class="mt-2">Corpo da mensagem</label>
                    <textarea style="flex:1" class="form-control "
                              id="contentMessage"
                              [(ngModel)]="Content"
                              (input)="MaisSmg()"
                              rows="5"></textarea>
                    <span style="font-size:small;color:#808080">Quantidade de caracteres: {{charCount}}</span>
                  </div>
                </div>
              </div>
              <div class="col-6" style="min-height:275px;">
                <div class="d-flex flex-column h-100">
                  <div style="display:flex; flex-direction:column; flex:1">
                    <label class="mt-2">Números de celular</label>
                    <p class="mt-0 mb-2" style="font-size:small;color:#808080"> Insira os números separados por vírgula ou quebra de linha. modelo: DDI + DDD + Celular. exemplo: 5598999999999,5598999999999</p>
                    <textarea (change)="CountNumbers()" style="flex:1" class="form-control "
                              [(ngModel)]="numbers"
                              rows="5"></textarea>
                    <p style="font-size:small;color:#808080"> Os números repetidos serão excluídos</p>
                    <!--<p class="mt-0 mb-2" style="font-size:small;"> números inseridos: 0</p>-->
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="canFlash">
              <label class="control outlined control-checkbox">
                SMS Flash
                <input class="form-check-input"
                       type="checkbox"
                       [(ngModel)]="isFlash" />
                <div class="control-indicator"></div>
              </label>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-6">
              <div class="form-group col-12 ">
                <label>Modelo</label>
                <div class="d-flex">
                  <select (change)="SelectModelo($event.target.value)" class="form-control">
                    <option value="none">Selecione...</option>
                    <option *ngFor="let item of listaModelos" [value]="item.id">{{item.description}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-row">
                <div class="form-group col-12 ">
                  <label>Mensagem agendada(opcional)</label>
                  <div class="d-flex justify-content-around">
                    <div class="col-8 pl-0">
                      <input (blur)="CheckScheduledMessage()" type="date"
                             class="form-control"
                             [(ngModel)]="Sheduled" />

                    </div>
                    <div class="col-4 pl-0">
                      <input (blur)="CheckScheduledMessage()" type="text"
                             class="form-control"
                             placeholder="00:00"
                             mask="00:00"
                             [(ngModel)]="SheduledTime" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row col-6">
            <div class="form-group col-12 ">
              <label>Campo Personalizado</label>
              <div class="d-flex">
                <select (change)="SelectParameter($event.target.value)" class="form-control">
                  <option selected value="none">Selecione...</option>
                  <option *ngFor="let item of listaParamentros; let i = index"
                          [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>



          <div class="form-row">
            <div class="form-group col-12">
              <label class="mb-0">Grupos</label>
              <p class="mt-0 mb-2" style="font-size:small;color:#808080"> Selecione o(s) grupo(s) de contato para os quais deseja enviar a mensagem</p>
              <div>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">
                        <label class="control outlined control-checkbox ml-2">
                          <!-- <input [value]=""
              type="checkbox"
              [checked]="item | loadGruposContatos: gruposSelecionaodos" /> -->
                          <div class="control-indicator"></div>
                        </label>
                      </th>
                      <th scope="col">Nome</th>
                      <th scope="col">Qtd. de Contatos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of listaGrupos">
                      <td scope="row">
                        <label class="control outlined control-checkbox ml-2">


                          <input [value]="item.id"
                                 type="checkbox"
                                 (change)="SelectGrupo(item, $event.target.checked)"
                                 [checked]="item | loadGruposContatos: gruposSelecionados" />
                          <div class="control-indicator"></div>
                        </label>
                      </td>
                      <td scope="row">{{ item.name }}</td>
                      <td scope="row">{{ item.quantidade }}</td>

                    </tr>
                  </tbody>
                  <tfoot *ngIf="listaGrupos.length <= 0">
                  <td style="text-align: center" colspan="3">Não encontrado itens</td>
                  </tfoot>
                </table>
              </div>
              <label class="mb-2">Grupos Selecionados</label>
              <div class="d-flex">
                <p *ngIf="gruposSelecionados.length == 0">Nenhum grupo selecionado</p>
                <span class="tag-card" *ngFor="let grupo of gruposSelecionados">
                  <div class="tag-body pl-1">
                    <!--<i class="mdi mdi-close" style="cursor:pointer" (click)="SelectGrupo(grupo, false)" role="presentation"></i>-->
                    <span class="mr-1">{{grupo.name}}</span>
                  </div>
                </span>
              </div>
              <hr />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="form-group ">
                <button data-target="#ConfirmarEnvioModal" data-toggle="modal" style="float: left;" class="btn btn-primary">
                  Enviar Mensagem
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-12 pl-0">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">Pré-visualização</div>
        <div class="card-body">
          <div class="form-row">
            <div class="smartphone">
              <div class="content">
                <div style="width:100%;min-height:20%;height:auto;background:white;border-radius:15px;padding:1rem">
                  <p style="white-space: pre-wrap;">{{GetHTMLFromContent()}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" id="botaoConfirmacaoEnvio" data-toggle="modal" style="display:none" data-target="#ModalEnvioConfirmado"> ok</button>
<button class="btn btn-primary" id="botaoAvisoMessageLimit" data-toggle="modal" style="display:none" data-target="#avisoMessageLimit"> ok</button>
<!-- </div> -->
<!-- aviso de limite execedio -->
<div class="modal fade" id="avisoMessageLimit" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Tamanho da mensagem excedida</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Você excedeu o limite de {{limitMessage}} caracteres
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Confirmar</button>

      </div>
    </div>
  </div>
</div>

<!-- Confirmar Envio Modal -->
<div class="modal fade " id="ConfirmarEnvioModal" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

        <p class="mb-2">Deseja prosseguir com a operação?</p>
        <p class="font-size-12 my-2">Total de Mensagens: {{totalDestinatarios}}</p>
        <p class="font-size-12 ">Total de Destinatários: {{totalDestinatarios}}</p>
        <p class=" my-2">Enviar em: {{isSheduledMsg ? SheduledDate + " às " + SheduledTime : "Agora - Envio Imediato"}}</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="SubmitMensage()" class="btn btn-primary btn-pill" data-dismiss="modal">Confirmar</button>

      </div>
    </div>
  </div>
</div>

<!-- modal envio confirmado -->
<div class="modal fade" id="ModalEnvioConfirmado"  tabindex="-1"  aria-labelledby="exampleModalLabel" #modalConfirmacao aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Envio de mensagem confirmada
      </div>
      <div class="modal-footer">
        <button type="button" (click)="RedirectToDashboard()" class="btn btn-primary btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>
