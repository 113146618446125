<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="pacman" [fullScreen]="true">
  <p style="color: white">Carregando...</p>
</ngx-spinner>

<div class="row">
  <div class="col-12" *ngIf="!load">
    <form ngNativeValidate [formGroup]="contatoForm" autocomplete="off" (submit)="OnSubmitEdit()">
      <div class="card card-default card-form-register">
        <div class="card-body pb-1">
          <div class="row mt-3">
            <div class="col-sm-6">
              <div class="">
                <h4>Editar Contato</h4>
                <p style="color:#808080; font-size:small">Os campos marcados com * são obrigatórios</p>
                <hr />
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect3">
                  Nome do contato:
                </label>
                <input class="form-control" placeholder="Digite nome do contato" formControlName="Nome" />
                <span class="mt-2 d-block text-danger" *ngIf="
                    contatoForm.get('Nome').touched &&
                    contatoForm.get('Nome').errors?.required
                  ">Este campo é obrigatório!</span>
              </div>

              <div class="form-group">
                <label for="txtNumber">Telefone: * </label>
                <input class="form-control" id="txtNumber" placeholder="Digite telefone" formControlName="Telefone" />
                <span class="mt-2 d-block text-danger" *ngIf="
                    contatoForm.get('Telefone').touched &&
                    contatoForm.get('Telefone').errors?.required
                  ">Este campo é obrigatório!</span>
              </div>


              <div class="form-group">
                <label for="txtNumber">Email: </label>
                <input class="form-control" id="txtNumber" placeholder="Digite seu email" formControlName="Email" />
                <span class="mt-2 d-block text-danger" *ngIf="
                    contatoForm.get('Email').touched &&
                    contatoForm.get('Email').errors?.required
                  ">Este campo é obrigatório!</span>
              </div>

              <div class="form-group">
                <label for="txtNumber">Aniversario: </label>
                <input class="form-control" placeholder="Digite seu aniversario" formControlName="Aniversario"
                  type="date" />
              </div>


              <div class="form-group">
                <label>Gênero: </label>
                <select class="form-control" formControlName="Genero">
                  <option value="0">Selecione...</option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                  <option value="O">Outro</option>
                </select>
              </div>

              <div class="form-group">
                <label>Bairro: </label>
                <input class="form-control" placeholder="Digite seu bairro" formControlName="Bairro" />
              </div>

              <div class="form-group">
                <label>Cidade: </label>
                <input class="form-control" placeholder="Digite sua cidade" formControlName="Cidade" />
              </div>

              <div class="form-group">
                <label>Estado: </label>
                <input class="form-control" placeholder="Digite seu estado" formControlName="Estado" />
              </div>

            </div>

            <div class="col-sm-6">
              <div class="">
                <h4>Associar a Grupo(s)</h4>
                <p style="color:#808080; font-size:small">Associe o contato criado com um ou mais grupos.</p>
                <hr />
              </div>
              <label for="formGroupExampleInput">Grupos</label>

              <div style="max-height: 400px; overflow-y:auto">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">
                        <label class="control outlined control-checkbox ml-2">
                          <input [(NgModel)]="isAllChecked" type="checkbox"
                            (change)="SelectAllGroups($event.target.checked)"
                            [checked]="item | loadGruposContatos: gruposSelecionaodos" />
                          <div class="control-indicator"></div>
                        </label>
                      </th>
                      <th scope="col">Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr formArrayName="IdGrupos" *ngFor="let item of listaGrupos">
                      <td scope="row">
                        <label class="control outlined control-checkbox ml-2">


                          <input [value]="gruposCadastrados.indexOf(item.id) > -1" type="checkbox"
                            (change)="SelectGroup(item.id)" [checked]="gruposCadastrados.indexOf(item.id) > -1" />
                          <div class="control-indicator"></div>
                        </label>
                      </td>
                      <td scope="row">{{ item.name }}</td>

                    </tr>
                  </tbody>
                  <tfoot *ngIf="listaGrupos.length <= 0">
                    <td style="text-align: center" colspan="3">Não encontrado itens</td>
                  </tfoot>
                </table>
              </div>

            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div col-12 col-xs-6 col-md-4 col-lg-3>
              <input type="submit" value="Salvar alterações" class="btn btn-block btn-primary"
                [disabled]="!contatoForm.valid" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>