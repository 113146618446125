<div class="card card-default">
  <div class="row">
    <div class="col-lg-12">
      <div class="card-header card-header-border-bottom">
        <h2>Adicionar Créditos para Clientes - Saldo = {{ UserInformation.credits }}</h2>
      </div>
      <div class="card-body col-12">
        <div class="row mt-2">
          <div class="col-6">
            <label class="text-dark font-weight-medium" for="">Cliente:*</label>
            <!--<div class="mb-2" *ngIf="listagemCliente">-->
            <div class="mb-2">
              <select (change)="carregaHistoricoCredito(idCliente)" class="form-control" [(ngModel)]="idCliente">
                <option value="0">Selecione...</option>
                <option *ngFor="let item of listagemCliente" [ngValue]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>

          </div>
          <div class="col-6">
            <label *ngIf="CheckIfRole(['Owner'])" class="text-dark font-weight-medium">Fornecedor:*</label>
            <div class=" mb-2" *ngIf="CheckIfRole(['Owner'])">
              <select class="form-control" [(ngModel)]="FornecedorCredito">
                <option value="0">Selecione...</option>
                <option *ngFor="let item of listaFornecedores" [ngValue]="item.id">
                  {{ item.fornecedor }} - {{ item.credito }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="text-dark font-weight-medium" for="">Quantidade de créditos:*</label>
            <div class=" mb-2">
              <div class="">
              </div>
              <input class="form-control" [(ngModel)]="QuantidadeCredito" type="number" />
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label class="text-dark font-weight-medium" for="">Validade:*</label>
                <div class="d-flex mb-2">
                  <input class="form-control" [disabled]="isIndetermined" [(ngModel)]="ValidadeCredito" type="date" />
                  <label class="control outlined control-checkbox ml-2 mt-2" style="min-width:250px">
                    Validade Indeterminada
                    <input [(ngModel)]="isIndetermined" type="checkbox"
                      (change)="setIndetermined($event.target.checked)" />
                    <div class="control-indicator"></div>
                  </label>
                </div>
              </div>
              <!--<div class="col-4">

              </div>-->

            </div>
          </div>
        </div>
        <div class="col-6 pl-0">
          <label class="text-dark font-weight-medium" for="">Descrição:*</label>
          <div class="">
            <textarea class="form-control" [(ngModel)]="DescricaoCredito" cols="30" rows="10"></textarea>
          </div>
        </div>
        <button (click)="adicionarCredito()" class="btn btn-primary my-3">
          Adicionar Crédito
        </button>
      </div>
    </div>

    <div class="col-lg-12" *ngIf="listaSaldoUtizadoFromClienteSelecionaod.length">
      <div class="card card-default">
        <div class="card-header card-header-border-bottom">
          <h2 style="color: black">
            Ultimos créditos -
          </h2>
          <h2 class="text-primary">Saldo Atual:{{listaSaldoUtizadoFromClienteSelecionaod.length ? ListTotalCredit : 0}}
          </h2>
        </div>
        <div class="card-body pt-0 px-0 pb-2">
          <table class="table table-responsive table-responsive-large table-hover" style="width: 100%">
            <thead>
              <tr>
                <th scope="col">Data Crédito</th>
                <th scope="col">Quantidade</th>
                <th scope="col">Quantidade utilizada</th>
                <th scope="col">Validade</th>
                <th scope="col">Descricão</th>
                <th scope="col">Status</th>
                <th scope="col" style="text-align:center">Ação</th>
              </tr>
            </thead>
            <tbody *ngIf="listaSaldoUtizadoFromClienteSelecionaod.length">
              <tr *ngFor="let cred of listaSaldoUtizadoFromClienteSelecionaod">
                <td scope="row">{{ cred.data_credito }}</td>
                <td>{{ cred.quantidade }}</td>
                <td>{{ cred.utilizado }}</td>
                <td>{{ cred.validade }}</td>
                <td>{{ cred.descricao }}</td>
                <td style="vertical-align:middle">
                  <div *ngIf="cred.status">
                    <span class="badge badge-pill badge-success">Ativo</span>
                  </div>
                  <div *ngIf="!cred.status">
                    <span class="badge badge-pill badge-warning">Inativo</span>
                  </div>
                </td>
                <td>
                  <button data-toggle="tooltip" title="Revogar Créditos" style="margin-left: 1rem;"
                    [disabled]="!cred.status" (click)="openModal(cred)" data-target="#confirmaCreditoRevogado"
                    data-toggle="modal" class="btn btn-sm btn-danger">
                    <i class="mdi mdi-delete font-size-20"></i>
                  </button>
                </td>
              </tr>
            </tbody>

            <tfoot *ngIf="!listaSaldoUtizadoFromClienteSelecionaod.length">
              <tr>
                <td colspan="4" style="text-align: center">
                  Não encontrado registros
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- revogar credito -->
<div class="modal fade" *ngIf="creditoSelecionado" data-keyboard="false" aria-labelledby="staticBackdropLabel"
  data-backdrop="static" id="confirmaCreditoRevogado" tabindex="-1" aria-labelledby="exampleModalLabel"
  style="display: none" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title center" id="exampleModalLabel">
          Deseja regovar o crédito?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal" (click)="closeModal()"
          data-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary btn-pill" (click)="revogar(creditoSelecionado.id)"
          data-dismiss="modal">
          Alterar
        </button>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-primary" id="botaoConfirmacao" data-toggle="modal" style="display:none"
  data-target="#ModalConfirmacao"> ok</button>
<!-- modal envio confirmado -->
<div class="modal fade" id="ModalConfirmacao" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Saldo</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Transferência de Saldo realizada com sucesso!
      </div>
      <div class="modal-footer">
        <button type="button" (click)="reloadPage()" class="btn btn-primary btn-pill" data-dismiss="modal">Ok</button>

      </div>
    </div>
  </div>
</div>