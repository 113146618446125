<div class="container d-flex align-items-center vh-100" style="flex-direction:column">
    <div class="login-logo-banner d-flex justify-content-center">
        <a href="https://uniqmobile.com.br">
            <img src="assets/png/logotipo_h_black_124.png">
        </a>
    </div>
    <div class="login-box row justify-content-center">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="PageName=='ResetPassword'">
                        <h4 class="text-dark mb-3">Redefinição de Senha</h4>
                        <p class="mb-3">Digite seu e-mail de cadastro com a Uniq Mobile. Enviaremos instruções de como
                            redefinir sua senha.</p>
                        <form ngNativeValidate [formGroup]="UserResetModel" autocomplete="off"
                            (submit)="onSubmitReset()">
                            <div class="row">
                                <div class="form-group col-md-12 mb-4">
                                    <label for="txtEmail">Digite seu email</label>
                                    <input class="form-control input-lg"
                                        [class.is-invalid]="UserResetModel.get('Email').touched && (UserResetModel.get('Email').errors?.required || UserResetModel.get('Email').errors?.email)"
                                        id="txtEmail" placeholder="Digite seu email" formControlName="Email"
                                        (ngModelChange)="onChangeFields()">
                                    <span class="invalid-feedback"
                                        *ngIf="UserResetModel.get('Email').touched && UserResetModel.get('Email').errors?.required">É
                                        necessário um email</span>
                                    <span class="invalid-feedback"
                                        *ngIf="UserResetModel.get('Email').touched && UserResetModel.get('Email').errors?.email">Este
                                        email é inválido</span>
                                    <div>
                                        <div class="d-flex my-2 justify-content-between message-danger"
                                            *ngIf="hasToShowError">
                                            <p class="text-red">{{ErrorMessage}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                  <button type="submit" id="resetPasswordButton" class="btn btn-lg btn-primary btn-block">
                                    <span class="button__text">
                                      Redefinir senha
                                    </span>
                                    
                                  </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="PageName=='Success'">
                        <h4 class="text-dark mb-3">Redefinição de Senha</h4>
                        <p class="mb-3">Dê uma olhada em sua caixa de entrada. Enviamos instruções de como criar uma
                            senha nova para {{UserResetModel.get('Email').value}}.</p>
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <button type="submit" class="btn btn-lg btn-primary btn-block"
                                    (click)="onClickResend()">Reenviar email</button>
                            </div>
                            <div class="col-md-12">
                                <a [routerLink]="['/login']" class="btn btn-lg btn-primary btn-block">Voltar para a
                                    página inicial </a>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="PageName=='ResetPasswordStep2'">
                        <h4 class="text-dark mb-3">Redefinir Senha</h4>
                        <!-- <p class="mb-3">Digite seu e-mail de cadastro com a Uniq Mobile. Enviaremos instruções de como
                            redefinir sua senha.</p> -->
                        <form ngNativeValidate [formGroup]="UserResetPasswordModel" autocomplete="off"
                            (submit)="onSubmitResetPassword()">
                            <div class="row">
                                <div class="form-group col-md-12 mb-4">
                                    <label for="txtPassword">Nova palavra-passe</label>
                                    <input class="form-control input-lg" type="password"
                                        [class.is-invalid]="UserResetPasswordModel.get('Password').touched && (UserResetPasswordModel.get('Password').errors?.required || UserResetPasswordModel.get('Password').errors?.minlength)"
                                        id="txtPassword" placeholder="Nova palavra-passe" formControlName="Password"
                                        (ngModelChange)="onChangeFields()">
                                    <span class="invalid-feedback"
                                        *ngIf="UserResetPasswordModel.get('Password').touched && UserResetPasswordModel.get('Password').errors?.required">É
                                        necessário uma palavra-passe</span>
                                    <span class="invalid-feedback"
                                        *ngIf="UserResetPasswordModel.get('Password').touched && UserResetPasswordModel.get('Password').errors?.minlength">A
                                        senha precisa de pelo menos 8 caracteres</span>
                                </div>
                                <div class="form-group col-md-12 mb-4">
                                    <label for="txtConfirmPassword">Confirme a nova palavra-passe</label>
                                    <input class="form-control input-lg" type="password"
                                        [class.is-invalid]="UserResetPasswordModel.get('ConfirmPassword').touched && (UserResetPasswordModel.get('ConfirmPassword').errors?.required || UserResetPasswordModel.get('ConfirmPassword').errors?.passwordMismatch)"
                                        id="txtConfirmPassword" placeholder="Confirmação de palavra-passe"
                                        formControlName="ConfirmPassword" (ngModelChange)="onChangeFields()">
                                    <span class="invalid-feedback"
                                        *ngIf="UserResetPasswordModel.get('ConfirmPassword').touched && UserResetPasswordModel.get('ConfirmPassword').errors?.required">É
                                        necessário uma palavra-passe</span>
                                    <span class="invalid-feedback"
                                        *ngIf="UserResetPasswordModel.get('ConfirmPassword').touched && UserResetPasswordModel.get('ConfirmPassword').errors?.passwordMismatch">As
                                        senhas não são iguais!</span>
                                    <div>
                                        <div class="d-flex my-2 justify-content-between message-danger"
                                            *ngIf="hasToShowError">
                                            <p class="text-red">{{ErrorMessage}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-lg btn-primary btn-block">Redefinir
                                        senha</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="PageName=='Success2'">
                        <h4 class="text-dark mb-3">Redefinir Senha</h4>
                        <p class="mb-3">Redefiniu com sucesso a sua palavra-passe Uniq.</p>
                        <div class="row">
                            <div class="col-md-12">
                                <a [routerLink]="['/login']" class="btn btn-lg btn-primary btn-block">Voltar na página
                                    de iniciar sessão</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
