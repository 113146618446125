import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (localStorage.getItem('UniqMobile_Message_Token') != null && req.url.indexOf('https://viacep.com.br/ws') !== 0 && req.url.indexOf('https://gist.githubusercontent.com/letanure') !== 0) {
            const clonedRequest = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('UniqMobile_Message_Token'))
            });
            return next.handle(clonedRequest).pipe(
                tap(
                    succ => {

                    },
                    err => {

                        switch (Number(err.status)) {
                            case 401:
                                localStorage.removeItem('UniqMobile_Message_Token');
                                this.router.navigate(['/login']);
                                break;
                            // case 404:
                            //     this.router.navigate([`/erro/${Number(err.status)}`])
                            //     break;
                            case 403:
                                this.router.navigate([`/erro/${Number(err.status)}`]);
                                break;
                            case 500:
                                this.router.navigate([`/erro/${Number(err.status)}`]);
                                break;
                        }

                    }
                )
            );
        } else {
return next.handle(req.clone());
}
    }
}
