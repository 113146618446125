<h3 class=" d-flex justify-content-between">
  <div class="breadcrumb-wrapper d-flex">
    <div style=" margin-right:1rem">
      <span class="mdi mdi-application-import font-size-20" style="height:100%;width:100%"></span>
    </div>
    <h1>Importar Contatos</h1>
  </div>

</h3>
<div class="row">
  <div class="col-xl-8 col-12">

    <div class="row" style="width:100%">
      <div class="col-xl-12 col-12">
        <!-- Sales Graph -->
        <div class="card card-default">
          <div class="card-header card-header-border-bottom justify-content-center">
            <h2>Importar Arquivo</h2>
          </div>
          <div class="card-body d-flex flex-column" style=" display:flex">
            <div class="m-4">
              <h3>Selecione o arquivo que você deseja importar para o SMS</h3>
              <p class="mt-2" style="color:#808080">Você pode inserir contatos armazenados em um arquivo Excel</p>
              <br />
              <div class="form-group col-12">
                <div class="input-group">
                  <div class="input-group-prepend d-flex" style="min-width:450px">
                    <!--<button class="btn btn-primary">Escolher Arquivo</button>-->
                    <input placeholder="Arquivo" class="form-control" style="" type="file" id="fileUpload"
                      (change)="onSelectFile($event)">
                    <p class="mb-auto mt-auto">
                      <!--Nome Arquivo-->
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <h3>A partir dessa importação...</h3>
              </div>
              <div class="col-6 mt-4 ml-1">
                <div class="form-check">
                  <label class="control control-radio radio-primary">
                    Associar as pessoas importadas a um grupo existente
                    <input class="form-check-input" type="radio" name="exampleRadios"
                      (click)="tipoImportacao('existente', true)" id="exampleRadios1" value="option1" />
                    <div class="control-indicator"></div>
                  </label>
                  <div *ngIf="isExistenteGrupo">
                    <div class="form-group">
                      <div *ngIf="listaGrupos.length > 0">
                        <label for="exampleFormControlInput3">Grupo</label>
                        <select class="form-control" [(ngModel)]="grupoIdSelecionado">
                          <option [value]="null">Selecione...</option>
                          <option *ngFor="let item of listaGrupos" [value]="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <div *ngIf="listaGrupos.length == 0">
                        <p>Você não possui grupos.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-check">
                  <label class="control control-radio radio-primary">
                    Associar as pessoas importadas a um novo grupo
                    <input class="form-check-input" type="radio" name="exampleRadios"
                      (click)="tipoImportacao('new_grupo', true)" id="exampleRadios2" value="option2" />
                    <div class="control-indicator"></div>
                  </label>
                  <div *ngIf="isNovoGrupo">
                    <div class="form-group">
                      <label for="exampleFormControlInput3">Novo Grupo</label>
                      <input type="text" class="form-control" id="exampleFormControlInput3" placeholder="Nome do Grupo"
                        [(ngModel)]="inputNomeGrupo">
                    </div>
                  </div>
                </div>
                <div class="form-check">
                  <label class="control control-radio radio-primary">
                    Somente adicionar e atualizar os existentes
                    <input class="form-check-input" type="radio" name="exampleRadios"
                      (click)="tipoImportacao('', false)" id="exampleRadios3" value="option3" />
                    <div class="control-indicator"></div>
                  </label>
                </div>

              </div>

              <br />
              <div class="form-group">
                <button class="btn btn-primary mt-auto mb-3" (click)="processarImportacao()">
                  Importar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 col-4">
    <div class="row" style="width: 100%">
      <div class="col-md-12 col-12">
        <!-- Sales Graph -->
        <div class="col-md-12 col-xl-12">
          <div class="card mb-4">
            <img class="card-img-top" src="assets/png/upload.jpg" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-primary mt-4">Importar Contatos</h5>
              <div class="ml-2 mr-2" style="display:block">
                <div>
                  <p style="color:#808080" class="card-text pb-3">
                    Importe de forma simples e prática a lista de números de celular dos seus
                    contatos
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title text-primary" mt-4>Baixar Modelo</h5>
              <div class="ml-2 mr-2" style="display:flex">
                <div>
                  <p style="color:#808080" class="card-text pb-3">
                    Os modelos devem ser seguidos. Caso o contrário, os dados poderão não ser importados.
                  </p>
                  <div>
                    <a class="btn btn-primary btn-block mt-auto mb-3" target="_blank" [href]="linksModelo"><i
                        class="mdi mdi-file-document mr-1"></i> Baixar Modelo</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title" style="color:#808080">Avisos e Dicas</h5>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  Sempre ignoramos o cabeçalho. Certifique-se de que não colocou informação na
                  primeira linha do seu arquivo
                </p>
              </div>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle-outline font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  O número do telefone deve estar no formato DDD+Número do Celular sem espaços
                </p>
              </div>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  O arquivo deve possuir tamanho máximo de 5MB para cada upload
                </p>
              </div>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle-outline font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  Celular é um campo obrigatório. Nenhum contato será importado se esta informação
                  não estiver contida em seu arquivo excel
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal envio confirmado -->
<div class="modal fade" id="ModalImportacao" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Importação de Contatos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Importação de contatos confirmada com sucesso!
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" (click)="redirectToDashboard()"
          data-dismiss="modal">Ok</button>

      </div>
    </div>
  </div>
</div>