import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListagemProviders } from './model_providers/listagemProviders.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  ListAllProviders(page: number = 1, query: string = '', active?): Observable<ListagemProviders> {
    if (active == undefined) {
      return this.http.get<ListagemProviders>(`${this.baseUrl}providers/ListagemFornecedores?page=${page}&query=${query}`);
    }
    return this.http.get<ListagemProviders>(`${this.baseUrl}providers/ListagemFornecedores?page=${page}&query=${query}&active=${active}`);
  }


  getProviderById(id: Number): Observable<Provider> {
    return this.http.get<Provider>(`${this.baseUrl}providers/edit/${id}`);
  }

  alteraStatusProvider(id: number, status: boolean) {
    return this.http.get(`${this.baseUrl}providers/verificaostatus?id=${id}&status=${status}`);
  }


  getRemocoesProvider(providerId: number) {
    return this.http.get(`${this.baseUrl}providers/Remocao?providerid=${providerId}`);
  }

  removeSaldoProvider(id: number) {
    return this.http.delete(`${this.baseUrl}providers/remocaosaldo?id=${id}`);
  }



  updateProvider(id: number, provider: any) {
    return this.http.put(`${this.baseUrl}providers/edit/${id}`, provider);
  }


  AddSaldo(saldo: any) {
    return this.http.post(`${this.baseUrl}providers/addvaliditybalanceprovider`, saldo);
  }

  ExportProvidersExcel(data: any, isCsv) {
    return this.http.post(`${this.baseUrl}reports/GetProvidersAsExcel?isCsv=${isCsv}`, data, { responseType: 'blob' });
  }



}
