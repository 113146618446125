import { ListagenModeloMessages } from './model_message/ListagenModeloMessages.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListagemHistoricMsg } from './model_message/ListagemHistoricMsg.mode';
import { ListagemSmsRespostas } from './model_message/ListagemSmsRespostas.model';
import { Mensagem } from './model_message/Mensagem_model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ListagemNiver } from './model_message/ListagemNiver.model';
import { ListagemListagemPalavrasSuspeitasNiver } from './model_message/ListagemPalavrasSuspeitas.model';
import { ListagemSuspeitasLinks } from './model_message/ListagemSuspeitasLinks.model';
import { MessageReschedule } from './model_message/MessageReschedule.model';
import { SuspiciousMessageListing } from './model_message/ListagemMsgSuspeitas.model';
import { Moment } from 'moment';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  SendMessagem(msg: Mensagem) {
    return this.http.post(`${this.baseUrl}messages/send`, msg);
  }


  SendMessagemForFile(msg: any) {
    return this.http.post(`${this.baseUrl}messages/sendforfile`, msg);
  }

  /**
   * Recupera o Status quantificado dos ultimos SMS emitidos pelo sistema a partir deste usuário
   *
   * @returns JSON com o resultado obtido
   */
  GetStatusOfLastMessages() {
    return this.http.get<any>(`${this.baseUrl}messages/GetStatusOfLastMessages`);
  }

  getHistoricoMensagens(page: number = 1, query: string = '', type?: number): Observable<ListagemHistoricMsg> {
    if (type == undefined) {
      return this.http.get<ListagemHistoricMsg>(`${this.baseUrl}messages/historicsmssends?page=${page}&query=${query}`);
    }
    return this.http.get<ListagemHistoricMsg>(`${this.baseUrl}messages/historicsmssends?page=${page}&query=${query}&type=${type}`);
  }


  DetalharMensagem(id: number, query: string) {
    return this.http.get(`${this.baseUrl}messages/detailsmssends?id=${id}&query=${query}`);
  }

  ListaRespostaRecebidadas(page: number = 1, query: string = ''): Observable<ListagemSmsRespostas> {
    return this.http.get<ListagemSmsRespostas>(`${this.baseUrl}messages/listreceivedmessages?page=${page}&query=${query}`);
  }

  deleteResposta(id: number) {
    return this.http.delete(`${this.baseUrl}messages/excluirrecebido/${id}`);
  }

  addNumberBlackList(numero: string) {
    return this.http.get(`${this.baseUrl}messages/addcontactblaclist?number=${numero}`);
  }

  loadSmsResposta(smsId: number) {
    return this.http.get(`${this.baseUrl}messages/carregasmsresposta/${smsId}`);
  }

  loadListaSmsResposta(startDate, endDate) {
    return this.http.get(`${this.baseUrl}messages/exportsmsreceivedsend/?startDate=${startDate}&endDate=${endDate}`, { responseType: "blob" });
  }

  loadExportExcelHistoricSms(startDate, endDate) {
    return this.http.get(`${this.baseUrl}messages/exporthistoricsms/?startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });

  }

  loadExportExcelDetailsSms(id: number) {
    return this.http.get(`${this.baseUrl}messages/exportdetailsmssends/${id}`);

  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // modelos
  carregaListaModelos(page: number = 1, query: string = ''): Observable<ListagenModeloMessages> {
    return this.http.get<ListagenModeloMessages>(`${this.baseUrl}modelos/v1/listamodelos?page=${page}&query=${query}`);
  }

  leituraParamentroModelo(template: string) {
    const body = {
      conteudo: template
    };
    return this.http.post(`${this.baseUrl}modelos/v1/ReadMessage`, body);
  }


  inserirModelo(data: any) {
    return this.http.post(`${this.baseUrl}modelos/v1/PostModelo`, data);

  }

  excluirModelo(idModelo: number) {
    return this.http.delete(`${this.baseUrl}modelos/v1/DeletaModelo/${idModelo}`);

  }
  carregarModeloById(idmodelo: number) {
    return this.http.get(`${this.baseUrl}modelos/v1/editmodelo/${idmodelo}`);

  }

  editarModelo(idmodelo: number, body: any) {
    return this.http.put(`${this.baseUrl}modelos/v1/editmodelo/${idmodelo}`, body);

  }

  // aniversariantes
  addNiver(data: any) {
    return this.http.post(`${this.baseUrl}birthdays/add`, data);
  }

  exclusaoNiver(id: number) {
    return this.http.delete(`${this.baseUrl}birthdays/remove/${id}`);

  }

  getNiverId(id: number) {
    return this.http.get(`${this.baseUrl}birthdays/getmsgbirthdatbyid/${id}`);
  }

  editNiver(id: number, data: any) {
    return this.http.put(`${this.baseUrl}birthdays/edit/${id}`, data);
  }

  listarNiver(page: number = 1, query: string = ''): Observable<ListagemNiver> {
    return this.http.get<ListagemNiver>(`${this.baseUrl}birthdays/listagemaniversariantes?page=${page}&query=${query}`);
  }

  gerarNivers(clientId: any) {
    return this.http.get(`${this.baseUrl}birthdays/GenerateBirthdaysMessages?clientId=${clientId}`);
  }

  // mensagens suspeitas

  addPalavraOuLinkSuspeito(conteudo: string, tipo: number){
    return this.http.get(`${this.baseUrl}suspiciouswords/addpalavraorlink?conteudo=${conteudo}&tipo=${tipo}`);
  }

  removerItemSuspeito(id: number){
    return this.http.get(`${this.baseUrl}suspiciouswords/Remove/${id}`);

  }

  listarPalavrasSuspeitas(page: number = 1, query: string = ''): Observable<ListagemListagemPalavrasSuspeitasNiver> {
    return this.http.get<ListagemListagemPalavrasSuspeitasNiver>(`${this.baseUrl}suspiciouswords/listagempalavras?page=${page}&query=${query}`);
  }

  listarLinksSuspeitas(page: number = 1, query: string = ''): Observable<ListagemSuspeitasLinks> {
    return this.http.get<ListagemSuspeitasLinks>(`${this.baseUrl}suspiciouswords/ListagemLinks?page=${page}&query=${query}`);
  }


  listarMgsSuspeitas(page: number = 1, query: string = ''): Observable<SuspiciousMessageListing> {
    return this.http.get<SuspiciousMessageListing>(`${this.baseUrl}SuspiciousWords/SuspiciousMessageListing?page=${page}&query=${query}`);
  }

  liberarMessages(messageId: number){
    return this.http.get(`${this.baseUrl}SuspiciousWords/ReleaseSuspiciousMessage/${messageId}`);

  }
  bloquearMessages(messageId: number) {
    return this.http.get(`${this.baseUrl}SuspiciousWords/BlockSuspiciousMessage/${messageId}`);

  }

  reagendarMensagem(id: number, newScheduled: string) {

    let model: MessageReschedule = { id, newScheduled };
    model.id = id;
    model.newScheduled = newScheduled;
    return this.http.post(`${this.baseUrl}messages/Reschedule/`, model);
  }

  cancelarMensagemAgendada(id: number) {
    let model = {
      id: id
    }
    return this.http.post(`${this.baseUrl}messages/CancelScheduled/`, model);
  }






}
