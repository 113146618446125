import { MessageService } from './../menssage.service';
import { Component, OnInit } from '@angular/core';
import { ListagemHistoricMsg } from '../model_message/ListagemHistoricMsg.mode';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
declare let $: any;

@Component({
  selector: 'app-historicsendmessage',
  templateUrl: './historicsendmessage.component.html',
  styleUrls: ['./historicsendmessage.component.css']
})
export class HistoricsendmessageComponent implements OnInit {

  constructor(private msgEnvidaService: MessageService, private clientService: UserService, private router: Router) { }

  searchQuery = '';

  page = 1;
  neighborPages = [];
  maxPagesOnPagination = 5;
  totalItems = 0;
  pageSize = '10';
  pageCount = 0
  hasPreviousPage = false;
  hasNextPage = false;

  load = false;
  public PeriodoDe = '';

  public PeriodoAte = '';

  listamsgEnviadas: any;
  TotalHistoric: any;

  mensagensEnviadas: any;

  userInformation: any;

  ngOnInit() {
    this.listaMensagensEnviadas();
    this.clientService.GetUserInformation().subscribe(res => this.userInformation = res);
  }

  detalharMsg(id) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/message/sms/${id}`])
    );

    window.open(url, '_blank');
    //window.open('/message/sms/')
  }


  //PAGINAÇÃO
  paginate(array: any[], page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.filter(x => x.content.toLowerCase().includes(this.searchQuery.toLowerCase())).slice((page_number - 1) * page_size, page_number * page_size);
  }

  getPaginate(page: number = 1) {
    this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
    this.page = page;
    this.listamsgEnviadas = this.paginate(this.TotalHistoric.lista, this.pageSize, this.page);
    this.UpdatePages();
  }

  UpdatePages() {
    //DADOS DAS PAGINAS
    var actualPageCount = this.pageCount;
    this.neighborPages = [];

    if (actualPageCount <= this.maxPagesOnPagination) {
      for (let i = 1; i <= actualPageCount; i++) {
        this.neighborPages.push(i);
      }
    } else {

      let ratioLeft = Math.floor(this.maxPagesOnPagination / 2);
      let ratioRight = ratioLeft;

      if ((this.page - 1) - ratioLeft < 0) {
        let result = ((this.page - 1) - ratioLeft) * -1;
        ratioRight += result;
        ratioLeft -= result;

      } else if ((this.page) + ratioRight > actualPageCount) {
        let result = ((this.page) + ratioRight) - actualPageCount;
        ratioLeft += result;
        ratioRight -= result;
      }

      //Adicionando vizinhos
      for (let i = this.page - ratioLeft; i <= this.page; i++) {
        this.neighborPages.push(i);
      }
      for (let i = this.page + 1; i <= this.page + ratioRight; i++) {
        this.neighborPages.push(i);
      }
    }
  }

  listaMensagensEnviadas(page: number = 1, type: number = null) {
    this.load = true;
    this.page = page;



    this.msgEnvidaService.getHistoricoMensagens(this.page, this.searchQuery, type).subscribe(
      (res: ListagemHistoricMsg) => {
        this.TotalHistoric = res;
        this.load = false;

        //DADOS PAGINAÇÃO
        this.totalItems = this.TotalHistoric.lista.length;
        this.pageCount = Math.floor((this.totalItems + parseInt(this.pageSize)) / parseInt(this.pageSize));
        this.hasPreviousPage = this.page == 1 ? false : true;
        this.hasNextPage = this.page == this.pageCount ? false : true;

        this.listamsgEnviadas = this.paginate(this.TotalHistoric.lista, this.pageSize, this.page);

        this.UpdatePages();

      },
      (erros) => {
        console.log(erros);
        this.load = false;
      }
    );
  }

  modalDetalharMensagem(id: number) {

    this.msgEnvidaService.DetalharMensagem(id, this.searchQuery).subscribe(
      (res: any) => {
        this.mensagensEnviadas = res;
        this.load = false;
        $('#modalDetalhesMensagensEnviadas').modal('show');

      },
      (erros) => {
        console.log(erros);
      }
    );
  }

  closeModal() {
    this.mensagensEnviadas = null;
  }

  exportaHistoricoMensagensEnviadas(startDate, endDate) {
    this.msgEnvidaService.loadExportExcelHistoricSms(startDate, endDate).subscribe(
      (res: any) => {
        const blob = new Blob([res],);
        let dateFromArray = startDate.split("-");
        let dateToArray = endDate.split("-");
        startDate = dateFromArray[2] + dateFromArray[1] + dateFromArray[0];
        endDate = dateToArray[2] + dateToArray[1] + dateToArray[0];

        saveAs(blob, this.userInformation.clientName + "_Historico_Enviadas_Periodo_" + startDate + "a" + endDate + ".xlsx");

      },
      (erros) => {
        console.log(erros);
      }
    );
  }

}
