import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ConversaoStatusSms'
})
export class ConversaoStatusSmsPipe implements PipeTransform {

  transform(id: number, args?: any): string {
    let statusName;
    switch (id.toString()) {
      case "-1":
        statusName = null;
        break;
      case "0":
        statusName = 'Agendado';
        break;
      case "1":
        statusName = 'Não Processado';
        break;
      case "2":
        statusName = 'Sendo Processado';
        break;
      case "3":
        statusName = 'Não Tarifado/Não Enviado';
        break;
      case "4":
        statusName = 'Não Enviado';
        break;
      case "5":
        statusName = 'Enviado';
        break;
      case "6":
        statusName = 'Enviado com Confirmação';
        break;

      //default:
      //  statusName = 'Sendo Processado';
      //  break;
    }
    return statusName;
  }

}
