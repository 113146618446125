import { Saldo } from './../../clients/listagem/saldo.model';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../shared/user.service';
import { SaldoService } from '../Saldo.service';
declare let $: any;

@Component({
  selector: 'app-transferencia',
  templateUrl: './transferencia.component.html',
  styleUrls: ['./transferencia.component.scss']
})
export class TransferenciaComponent implements OnInit {

  constructor(public userService: UserService, private saldoService: SaldoService, private toastr: ToastrService) { }

  public listagemCliente = [];

  public idCliente: number = 0;

  public QuantidadeCredito: number;

  public ListTotalCredit = 0;

  public ValidadeCredito = null;

  public DescricaoCredito = '';

  public FornecedorCredito = 0;

  public isIndetermined = false;

  public UserInformation: any = {
    Initials: '',
    FirstName: '',
    credits: 0
  };

  public listaSaldoUtizadoFromClienteSelecionaod = [];

  public creditoSelecionado: any;

  public listaFornecedores = [];



  async ngOnInit() {
    this.listaCliente();
    this.carregaListagemFornecedores();
    this.carregaDadosLogado();
  }

  carregaDadosLogado() {
    this.userService.GetUserInformation().subscribe((userInfo: any) => this.UserInformation = userInfo)
  }
  CheckIfRole(roles) {
    if (this.UserInformation == undefined) {
      return false;
    }
    return this.UserInformation.roleType ? roles.includes(this.UserInformation.roleType) : false;
  }

  openModal(credito: any) {
    this.creditoSelecionado = credito;

  }
  setIndetermined(flag) {
    this.isIndetermined = flag;
  }

  closeModal() {
    this.creditoSelecionado = null;
  }

  listaCliente() {
    this.saldoService.carregaClientes().subscribe(
      (sucesso: any) => {
        this.listagemCliente = sucesso;
        if (sucesso[0] == null) {
          this.toastr.warning("Você não possui clientes cadastrados")
        }
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar clientes');
        this.listagemCliente = [];
      }
    );
  }

  adicionarCredito() {

    if (this.isIndetermined) {
      this.ValidadeCredito = "0";
    }

    const saldoCredito: Saldo = { IdCliente: this.idCliente, QuantityBalance: this.QuantidadeCredito, ValidityBalance: this.ValidadeCredito, Transference: true, ProviderId: this.FornecedorCredito, Descricao: this.DescricaoCredito, isIndetermined: this.isIndetermined };

    this.saldoService.adicionarByTransferirSaldo(saldoCredito).subscribe((res: any) => {
      console.log(res);
      this.listaCliente();
      this.carregaDadosLogado();
      $('#ModalConfirmacao').modal('show');
      //this.toastr.success('Transferência de Saldo concluída com sucesso', 'Saldo');
      //setTimeout(() => {  }, 1000)
    },
      (erro: any) => {
        this.toastr.error('Ocorreu um erro ao transferir saldo', 'Saldo');
      }
    );
  }
  reloadPage() {
    window.location.reload();
  }

  carregaHistoricoCredito(idCliente) {
    this.ListTotalCredit = 0;
    this.saldoService.carregaHistoricoCliete(idCliente).subscribe(
      (sucesso: any) => {
        this.listaSaldoUtizadoFromClienteSelecionaod = sucesso;
        this.listaSaldoUtizadoFromClienteSelecionaod.forEach(elem => { this.ListTotalCredit += elem.credito });
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar historico de creditido do cliente');
        this.listaSaldoUtizadoFromClienteSelecionaod = [];
      }
    );
  }

  carregaListagemFornecedores() {
    this.saldoService.listaFornedoresByCredito().subscribe(
      (sucesso: any) => {
        this.listaFornecedores = sucesso;
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar fornecedores');
        this.listaFornecedores = [];
      }
    );
  }

  revogar(idsaldo: number) {
    this.saldoService.revogarCredito(idsaldo).subscribe(
      (sucesso: any) => {
        this.listaCliente();
        this.carregaDadosLogado();
        this.closeModal();
      },
      (erro: any) => {
        this.toastr.error('Não foi possivel revogar o credito');
        this.closeModal();
      }
    );
  }


}
