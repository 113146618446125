import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListagemBlackList } from './models_blacklist/ListagemBlackList.model';

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;


  getAllBlackList(page: number = 1, query: string = ''): Observable<ListagemBlackList> {
    return this.http.get<ListagemBlackList>(`${this.baseUrl}blacklist/listagemblacklist?page=${page}&query=${query}`);
  }

  removeBlackList(id: number){
    return this.http.delete(`${this.baseUrl}blacklist/Remocao/${id}`);
  }


  addBlackList(dados: FormData){
    return this.http.post(`${this.baseUrl}blacklist/add`, dados);
  }








}
