import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { scheduled } from 'rxjs';
import { UserService } from 'src/app/shared/user.service';
import { ListaGroupsToContato } from '../../contacts/listaGroupsToContato.model';
import { RegistroContatoService } from '../../contacts/registro-contato/registroContato.service';
import { MessageService } from '../menssage.service';
import { Mensagem } from '../model_message/Mensagem_model';
declare let $: any;

@Component({
  selector: 'app-sendmessage',
  templateUrl: './sendmessage.component.html',
  styleUrls: ['./sendmessage.component.scss']
})
export class SendmessageComponent implements OnInit {

  @ViewChildren('ModalEnvioConfirmado') modalConfirmacao: any;
  constructor(private fb: FormBuilder, private toastr: ToastrService, private msgService: MessageService, public router: Router, public registroService: RegistroContatoService, public clientService: UserService, public FB: FormBuilder, public datepipe: DatePipe) { }

  numbers: string;
  Content = '';
  isFlash: boolean;
  isSheduledMsg: boolean;
  Sheduled: string;
  SheduledDate: string;
  SheduledTime: string;
  MsgSent = false;
  from = '';
  limitMessage = 0;
  canFlash = 0;
  charCount = 0;
  totalDestinatarios = 0;
  listaGrupos = [];
  listaModelos = [];
  gruposSelecionados = [];
  listaParamentros = ['Campo Customizado', '[Nome]'];

  ngOnInit() {
    this.carregaListaGrupos();

    this.msgService.carregaListaModelos(1, '').subscribe(
      (res: any) => {
        this.listaModelos = res.lista;
        //this.load = false;
      },
      (erros) => {
        console.log(erros);
        //this.load = false;
      }
    );

    this.clientService.GetUserInformation().subscribe((userInfo: any) => {
      this.limitMessage = userInfo.limitMessage;
      this.canFlash = userInfo.canSMSFlash;
    });

  }

  SelectModelo(id) {
    if (id == "none" || id == undefined) {
      this.Content = '';

    } else {
      this.Content = this.listaModelos.find(x => x.id == id).content;
    }
    this.MaisSmg();
  }
  SelectGrupo(grupo, checked) {
    if (checked) {
      this.gruposSelecionados.push(grupo);
    } else {
      let index = this.gruposSelecionados.indexOf(grupo);
      this.gruposSelecionados.splice(index, 1);
    }
    this.GetGroupContactCount(grupo, checked);
  }
  GetGroupContactCount(grupo, flag) {
    if (flag) {
      this.totalDestinatarios += this.listaGrupos.find(g => g.id == grupo.id).quantidade;
    } else {
      this.totalDestinatarios -= this.listaGrupos.find(g => g.id == grupo.id).quantidade;
    }
  }
  SelectParameter(value) {
    if (value == '[Nome]') {
      if (!this.Content.includes('[Nome]')) {
        this.Content += '[Nome]';
      }
    } else {
      this.Content = this.Content.replace('[Nome]', '');
      $('#contentMessage').val(this.Content);
    }
    this.MaisSmg();
  }

  contadorMensagem() {

    let count = 0;

    if (!this.from || !this.from.trim()) {
    } else {
      count += this.from.length + 2;
    }

    let src = this.Content;

    let params = src.match(/\[(.*?)\]/gi);

    count += src.length;

    this.charCount = count;
  }

  PrintarContent() {
    console.log(this.Content);
  }

  GetHTMLFromContent() {
    let str = '';

    if (!this.from || !this.from.trim()) {
      //str is null, undefined, or contains only spaces
      str = this.Content;
    } else {
      str = this.from + ': ' + this.Content;
    }
    str.replace(/(\r\n|\n|\r)/gm, '<br/>');
    return str;
  }

  CheckScheduledMessage() {

    this.SheduledDate = this.Sheduled;
    this.SheduledDate = this.datepipe.transform(this.SheduledDate, 'dd/MM/yyyy');
    if ((this.Sheduled !== undefined && this.Sheduled !== null && this.Sheduled != '') && (this.SheduledTime !== undefined && this.SheduledTime !== null && this.Sheduled != '')) {

      //gambiarra braba. mudar dps
      let hour = this.SheduledTime[0] + this.SheduledTime[1];
      let min = this.SheduledTime[2] + this.SheduledTime[3];
      this.SheduledTime = hour + ":" + min;

      this.isSheduledMsg = true;
    } else {
      this.isSheduledMsg = false;
    }
  }

  CountNumbers() {
    if (this.numbers.includes(',')) {
      var destinatarios = this.numbers.split(',');
      for (let i = 0; i < destinatarios.length; i++) {
        if (destinatarios[i].trim() == "") {
          destinatarios.splice(i, 1);
          i--;
        }
      }
      this.totalDestinatarios = (destinatarios.length);
    } else {
      var destinatarios = this.numbers.split('\n');
      for (let i = 0; i < destinatarios.length; i++) {
        if (destinatarios[i].trim() == "") {
          destinatarios.splice(i, 1);
          i--;
        }
      }
      this.totalDestinatarios = (destinatarios.length);
    }
  }


  SubmitMensage() {
    const isErro = this.validaCampos();
    if (isErro) {
      return isErro;
    }

    let scheduled = null;
    if ((this.Sheduled !== undefined && this.Sheduled !== null && this.Sheduled != '') && (this.SheduledTime !== undefined && this.SheduledTime !== null && this.Sheduled != '')) {
      scheduled = this.Sheduled + ' ' + this.SheduledTime;
    } else {
      scheduled = null;
    }

    const msg: Mensagem = { numbers: this.numbers, Content: this.Content, isFlash: this.isFlash, Sheduled: scheduled, From: this.from, GrupoIds: this.gruposSelecionados.map(g => g.id) };
    this.msgService.SendMessagem(msg).subscribe(
      (data: any) => {
        document.getElementById('botaoConfirmacaoEnvio').click();
        console.log(data);
        //this.toastr.success("Enviado com sucesso", "Mensagem")
        this.numbers = null;
        this.Content = null;
        this.isFlash = null;
        this.Sheduled = null;


        //this.clientService.GetUserInformation().subscribe((userInfo) => u;
      },
      (erro: any) => {
        //TO DO: Exibir como erro a msg q é retornada do Back
        this.toastr.error('Erro ao enviar a mensagem', 'Mensagem');
        UserService;
      }
    );
  }

  RedirectToDashboard() {
    this.router.navigateByUrl('/');
  }

  carregaListaGrupos() {
    //this.load = true;
    this.registroService.listaGroups().subscribe(
      (data: ListaGroupsToContato[]) => {
        this.listaGrupos = data;
        //this.load = false

      },
      (erro: any) => {
        this.toastr.error('Não foi possivel carregar grupos!', 'Grupos');
        //this.load = false

      }
    );
  }

  validaCampos(): boolean {
    if (this.numbers === null || this.Content === null) {
      this.toastr.error('Favor preencha os campos', 'Mensagem');
      return true;
    }
    return false;
  }

  MaisSmg() {
    let count = this.from.length;
    if (count > 0) {
      count += 2;
    }
    let conteudo = this.Content;

    this.contadorMensagem();

    if (this.charCount > this.limitMessage - 1) {
      this.Content = this.Content.substring(0, this.limitMessage - 1);

      $('#avisoMessageLimit').modal('show');
      $('#contentMessage').val(this.Content);
    }

  }



}
