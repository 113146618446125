<!-- <div class="container"> -->
<div class="breadcrumb-wrapper d-flex">
  <i class="mdi mdi-email text-blue mr-4 font-size-20"></i>
  <h1>Envio de Mensagem por Arquivo</h1>
</div>
<div class="row">
  <div class="col-8 pl-0">
    <div class="col-12 pr-0">
      <div class="card card-default">
        <div class="card-header card-header-border-bottom">
          <h4>Nova Mensagem</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class=" col-8  mt-3">
              <label class="mb-2">Faça o upload da lista de contatos</label>
              <!--<label>Você pode inserir contatos armazenados e salvos em um excel</label>-->
              <input type="file" class="form-control" style="min-width:450px" (change)="onSelectFile($event)">
              <p style="font-size:small" class="mt-1"> <strong>Total de Contatos:</strong> {{totalContatos}}</p>
              <p style="font-size:small"> <strong>Parâmetros:</strong> {{parametrosView}}</p>
              <!-- <button type="submit" class="btn btn-primary">Register User</button> -->
            </div>
            
          </div>
          <div *ngIf="selectedFileData.length > 0">
            <hr />
            <h5>Pré-visualização do arquivo</h5>
            <div class="mt-2" style="width:100%; overflow-x:auto">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th *ngFor="let param of parametros, let i = index" scope="col">Parâmetro {{ i+1 }}</th>

                  </tr>
                </thead>
                <tbody *ngIf="selectedFileData">
                  <tr *ngFor="let item of selectedFileData | slice:0:5 , let i = index">
                    <td *ngFor="let param of parametros" scope="row">
                      {{ selectedFileData[i][param]  }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>
          </div>
          <div class="row mt-2">
            <div class=" col-6">

              <div class="form-group">
                <label>Campo Personalizado</label>
                <div class="d-flex">
                  <select (change)="SelectParameter($event.target.value)" class="form-control">
                    <option selected value="none">Selecione...</option>
                    <option *ngFor="let item of paramDict; let i = index"
                            [value]="item.value">
                      {{item.text}}
                    </option>
                  </select>

                </div>
                
              </div>
            </div>
            <div class="col-6">
              <div class="form-group d-flex flex-column h-100">
                <div style="display:flex; flex-direction:column; flex:1">
                  <label>Mensagem agendada(opcional)</label>
                  <div class=" d-flex justify-content-around">
                    <div class="col-8 pl-0">
                      <input type="date" (blur)="CheckScheduledMessage()" class="form-control" [(ngModel)]="Sheduled" />

                    </div>
                    <div class="col-4 pr-0">
                      <input type="text" (blur)="CheckScheduledMessage()" placeholder="00:00" mask="00:00" class="form-control" [(ngModel)]="SheduledTime" />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-6">
              <div class=" form-group d-flex flex-column h-100">
                <div style="display:flex; flex-direction:column; flex:1">
                  <label>Remetente</label>
                  <input type="text" class="form-control" [(ngModel)]="from" (input)="CountSMSChar()">

                  
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group d-flex flex-column h-100">
                <label>Modelo</label>
                <div class="d-flex">
                  <select (change)="SelectModelo($event.target.value)" class="form-control">
                    <option value="none">Selecione...</option>
                    <option *ngFor="let item of listaModelos" [value]="item.id">{{item.description}}</option>
                  </select>
                </div>
              </div>
            </div>
            
          </div>

          <div class="form-row">
            <div class="form-group ml-0 col-sm-12 col-md-12">
                <div class="form-group col-6 pl-0 mt-2">
                  <label>Corpo da mensagem</label>
                  <textarea class="form-control" id="contentMessage" [(ngModel)]="Content" (input)="CountSMSChar()" rows="5"></textarea>
                  <span>Quantidade de caracteres: {{charCount}}</span>&nbsp;&nbsp;
                </div>
                <div *ngIf="canFlash">
                  <label class="control outlined control-checkbox">
                    SMS Flash
                    <input class="form-check-input"
                           type="checkbox"
                           [(ngModel)]="isFlash" />
                    <div class="control-indicator"></div>
                  </label>
                </div>

                <!--<div class="form-group col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="isFlash" />
                    <label class="form-check-label" for="defaultCheck1">
                      Sms flash
                    </label>
                  </div>
                </div>-->
                <div class="form-group pl-0 col-12">
                  <div class="form-group d-flex justify-content-between">
                    <button data-toggle="modal" data-target="#PreviaModal" style="float: right;" class="btn btn-primary">
                      Ver Prévia
                    </button>
                    <button data-toggle="modal" data-target="#ConfirmarEnvioModal" style="float: right;"
                            class="btn btn-primary">
                      Confirmar Mensagem
                    </button>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>

  <div class="col-4 ">
    <div class="row" style="width: 100%">
      <div class="col-md-12 col-12  pl-0">
        <!-- Sales Graph -->
        <div class="col-md-12 col-xl-12">
          <div class="card mb-4">
            <img class="card-img-top" src="assets/png/message.jpg" alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title text-primary mt-4">Envio de SMS por Arquivo</h5>
              <div class="ml-2 mr-2" style="display:block">
                <div>
                  <p style="color:#808080" class="card-text pb-3">
                    Envie SMS de forma simples, rápida e prática para seus contatos através do seu arquivo excel
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title text-primary" mt-4>Baixar Modelo</h5>
              <div class="ml-2 mr-2" style="display:flex">
                <div>
                  <p style="color:#808080" class="card-text pb-3">
                    Os modelos devem ser seguidos. Caso o contrário, os dados poderão não ser importados.
                  </p>
                  <div>
                    <a class="btn btn-primary btn-block mt-auto mb-3" target="_blank" [href]="linksModelo"><i class="mdi mdi-file-document mr-1"></i> Baixar Modelo</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-xl-12">
          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title" style="color:#808080">Avisos e Dicas</h5>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  Sempre ignoramos o cabeçalho. Certifique-se de que não colocou informação na
                  primeira linha do seu arquivo
                </p>
              </div>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle-outline font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  O número do telefone deve estar no formato DDD+Número do Celular sem espaços
                </p>
              </div>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  Para cada campo personalizado do arquivo adicionado, será descontado os caracteres entre colchetes dos
                  160 caracteres
                </p>
              </div>

              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle-outline font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  Celular é um campo obrigatório. Nenhum contato será importado se esta informação
                  não estiver contida em seu arquivo excel
                </p>
              </div>
              <div class="ml-2 mr-2 mt-2 mb-2 " style="display:flex">
                <i style="color:#808080" class="mdi mdi-alert-circle font-size-20 mr-2"></i>
                <p style="color:#808080" class="card-text pb-3">
                  O arquivo deve possuir tamanho máximo de 5MB para cada upload
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" id="botaoConfirmacaoEnvio" data-toggle="modal" style="display:none" data-target="#ModalEnvioConfirmado"> ok</button>

<!-- </div> -->
<!-- aviso de limite execedio -->
<div class="modal fade" id="avisoMessageLimit" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none;"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Tamanho da mensagem excedida</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Você excedeu o limite de {{limitMessage}} caracteres
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Ok</button>

      </div>
    </div>
  </div>
</div>

<!-- Previa Modal -->
<div class="modal fade" id="PreviaModal" tabindex="-1" aria-labelledby="exampleModalLabel" style="display: none;"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Pré-visualização</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-xl-12 d-flex justify-content-center">
          <div class="card modal-previa">
            <div class="card-body">
              <div class="form-row">
                <div class="smartphone">
                  <div class="content">
                    <div style="width:100%;min-height:20%;height:auto;background:white;border-radius:15px;padding:1rem">
                      <p style="white-space: pre-wrap;">{{GetHTMLFromContent()}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>

<!-- Confirmar Envio Modal -->
<div class="modal fade" id="ConfirmarEnvioModal" tabindex="-1" aria-labelledby="exampleModalLabel"
     style="display: none;" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

        <p class="mb-2">Deseja prosseguir com a operação?</p>
        <p class="font-size-12">Total de Mensagens: {{totalContatos}}</p>
        <p class="font-size-12">Total de Destinatários: {{totalContatos}}</p>
        <p class="my-2">Enviar em: {{isSheduledMsg ? SheduledDate + " às " + SheduledTime : "Agora - Envio Imediato"}}</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger btn-pill" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="onSubmit()" class="btn btn-primary btn-pill" data-dismiss="modal">Confirmar</button>

      </div>
    </div>
  </div>
</div>

<!-- modal envio confirmado -->
<div class="modal fade" id="ModalEnvioConfirmado" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmação de Envio</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Envio de mensagem confirmada
      </div>
      <div class="modal-footer">
        <button type="button" (click)="RedirectToDashboard()" class="btn btn-primary btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>

  <!-- modal Padrão -->
<div class="modal fade" id="ModalFalhaPadrao" tabindex="-1" aria-labelledby="exampleModalLabel" #modalConfirmacao aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Falha na Importação</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Arquivo fora do padrão
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-pill" data-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>
