import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../clients/edicao/User.model';
import { ListaFornecedores } from './model_users_for_providers/ListaFornecedores.mode';
import { ListagemUsersForProviders } from './model_users_for_providers/ListagemUsersForProviders.model';

@Injectable({
  providedIn: 'root'
})
export class UsersForProvidersService {

  constructor(private http: HttpClient) { }

  baseUrl = `${environment.baseUrl}`;

  GetallUsersForProviders(page: number = 1, query: string = '', type?, active?): Observable<ListagemUsersForProviders> {
    if ((type == undefined || type == null) && (active == undefined || active == null)) {
      return this.http.get<ListagemUsersForProviders>(`${this.baseUrl}usersforproviders/listagem?page=${page}&query=${query}`);
    }
    if ((active != undefined && active != null) && (type == undefined || type == null)) {
      return this.http.get<ListagemUsersForProviders>(`${this.baseUrl}usersforproviders/listagem?page=${page}&active=${active}&query=${query}`);
    }
    if ((active == undefined || active == null) && (type != undefined && type != null)) {
      return this.http.get<ListagemUsersForProviders>(`${this.baseUrl}usersforproviders/listagem?page=${page}&type=${type}&query=${query}`);
    }
  }

  AlteraStatusUserForProvider(id: number, status: boolean) {
    return this.http.get(`${this.baseUrl}usersforproviders/analisestatus/${id}/${status}`);
  }

  GetallProviders(): Observable<ListaFornecedores> {
    return this.http.get<ListaFornecedores>(`${this.baseUrl}usersforproviders/listafornecedores`);
  }

  detalhesUsersProviderById(id: number): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}users/edit/${id}`);
  }


  deleteUserForProvider(id: number) {
    return this.http.delete(`${this.baseUrl}usersforproviders/delete/${id}`);
  }


  AlteraFornecedor(clientId: number, novoIdprovider: number) {
    return this.http.get(`${this.baseUrl}usersforproviders/alterarfornecedor/${clientId}/${novoIdprovider}`);
  }

  ExportUsersProvidersExcel(data, isCsv) {
    return this.http.post(`${this.baseUrl}reports/GetUsersProvidersAsExcel?isCsv=${isCsv}`, data, { responseType: 'blob' });
  }


}
