<div class="content">
  <div class="error-wrapper rounded border bg-white px-5">
    <div class="row justify-content-center align-items-center text-center">
      <div class="col-xl-4">
        <h1 class="text-primary bold error-title">{{ code }}</h1>
        <p class="pt-4 pb-5 error-subtitle">{{ msg }}</p>
        <a [routerLink]="['/']" class="btn btn-dark btn-pill">Voltar</a>
      </div>
      <!-- <div class="col-xl-6 pt-5 pt-xl-0 text-center">
            <img src="assets/img/lightenning.png" class="img-fluid" alt="Error Page Image">
        </div> -->
    </div>
  </div>
</div>
